import axios from 'axios';
import React, { Component } from 'react'
import { Button, Col, Container, Form, Row, Spinner} from 'react-bootstrap'
import validator from 'validator';
import APIs from '../../../../../../utils/APIs';
import userIco from '../../../../../../static/images/logged_in/user-icon.png'
import Cookies from 'js-cookie'
import MainLogo from '../../../../../../logo.png'
import Looger from '../../../../../../utils/Logger';

const Paddle = window.Paddle;

export default class PlanSelectionStep3 extends Component {
    constructor(props) {
        super(props)
        let userInfo = JSON.parse(localStorage.getItem('user'))
        let planQuantity = this.props.data.planQuantity 
        let PurchasedPlanQuantity = this.props.data.planQuantity 
        if (props.plantypes === 'upgrade'){
            planQuantity = (planQuantity-props.planQuantity+1)
        }

        let allEmails = []
        let allEmailsError = []
        for(let i=0;i<planQuantity-1;i++) {
            allEmails[i] = ''
            allEmailsError[i] = ''
        }
            
        this.state = {
            planQuantity     :   planQuantity,
            finalPlanQuantity:   PurchasedPlanQuantity?PurchasedPlanQuantity:1,
            buttonUnitPrice  :   this.props.data.unitPrice,
            currency         :   this.props.data.currency,
            selectedPlan     :   this.props.data.planID,
            insertedUserInfo :   [],
            email : userInfo.email,
            teamAllEmail:allEmails,
            teamEmailError:allEmailsError,
            defaultEmail : true,
            axiosSource : axios.CancelToken.source(),
            showLoad : false,
            showStep:0,
            emailDuplicateError:'',
            teamName:localStorage.getItem('team_name') || '',
        }
    }

    resetPlanSelection = (() => {
        try{
            let data = this.props.data;
            data.planStep = 2;
            this.props.resetPlanSelection(data)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    checkEmailExistInDB = () => {
        try{
            if(this.state.email.trim() === "" || this.state.defaultEmail) {
                return false
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    payForSubscription = (() => {
        try{
            let teamAllEmail = this.state.teamAllEmail
            let hostEmail    = this.state.email
            teamAllEmail.splice(0, 0, hostEmail); 
            let hostAndTeamEmail = [] 

            teamAllEmail.map((email)=>{
                let role = 'Team Member'
                if ( hostEmail === email ) {
                    role = 'Owner'
                    email = hostEmail
                }
                hostAndTeamEmail.push({email:email,role_name:role})
                return true
            })
            let data = {
                is_free : false,
                data : hostAndTeamEmail
            }

            this.setState({ insertedUserInfo:data})

            if(this.state.selectedPlan < 100) {
                data.is_free = true;
                this.saveTeamMemberInfo(data);
            } else {
                let userInfo = JSON.parse(localStorage.getItem('user'))
                userInfo.type = 'disallow'
                let quantity = this.state.planQuantity
                if (this.props.plantypes === 'upgrade'){
                    quantity = (quantity+this.props.planQuantity)-1
                }
                Paddle.Checkout.open({
                    product: this.state.selectedPlan,
                    email : userInfo.email,
                    disableLogout:true,
                    quantity : quantity,
                    successCallback: this.checkoutComplete,
                    closeCallback: this.checkoutClosed,
                    passthrough : userInfo,
                    displayModeTheme : localStorage.getItem('theme') ? localStorage.getItem('theme') : "light"
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    checkoutClosed = (data) => {
        try{
            this.setState({
                showLoad : false,
                teamAllEmail:[],
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    checkoutComplete = (data) => {
        try{
            let newData = this.state.insertedUserInfo
            setTimeout(() => {
                this.saveTeamMemberInfo(newData);
            }, 5000)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    accessAccountHandler = (function(e){
        try{
            this.setState({
                showStep:2
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
        
    })

    closeAccessAccountHandler  = (function(e){
        try{
            this.setState({
                showStep:3,
            })
            localStorage.removeItem('team_name')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    loadingMessageHtml = (function(step){
        try{
            this.refreshTokenHandler()
            return( 
                <>
                    {   step === 0 ? 
                            <center><Spinner animation="border" variant='primary' /></center>
                        : step === 1 ?
                            <>
                                <Col md={{span:12}} className="text-center">
                                    <i style={{fontSize:"18px"}} className={"material-icons todotask-icon done access_acc" }>check</i>
                                </Col>
                                <Col md={{span:12}} className="text-center my-5 access_acc_txt">
                                    <h5>Team members added</h5>
                                </Col>
                                <Col md={{span:12}} className="text-center mt-5">
                                    <Button className="access_acc_active" onClick={(e)=>this.accessAccountHandler(e)}>Access your account</Button>
                                </Col>
                            </> 
                        : step === 2 ?
                            <>  
                                <Col className="access_acc2" >
                                    <Col md={{span:12}} className="text-center">
                                        <Col className="main_logo" ><img src={MainLogo} alt="orrderly.com"/></Col>
                                    </Col>
                                    <Col md={{span:12}} className="text-center my-1 access_acc_txt">
                                        <h5>Welcome to Orrderly Pro!</h5>
                                    </Col>
                                    <Col md={{span:12}} className="text-center my-1 access_acc_txt">
                                        <span className='edit-subtask'>You’ve just taken a major step to staying</span>
                                        <br/>
                                        <span className='edit-subtask'>Organized and super productive!</span>
                                    </Col>
                                    <Col className="mt-5 access_acc_paln">
                                        <Col md={{span:12}} className="mt-5 access_acc_txt">
                                            <h6>Here’s what’s inside Orrderly Pro.</h6>
                                        </Col>
                                        <Col md={{span:12}} className="mt-3 access_acc_txt">
                                            <ul className="price">
                                                <li className="active-plan p-1">Create, share and delete tasks seamlessly</li>
                                                <li className="active-plan p-1">Reschedule tasks at your convenience</li>
                                                <li className="active-plan p-1">Assign tasks to team members</li>
                                                <li className="active-plan p-1">Create tags for different tasks</li>
                                                <li className="active-plan p-1">Make comments and create subtasks</li>
                                                <li className="active-plan p-1">Create recurring tasks</li>
                                                <li className="active-plan p-1">Make comments and create subtasks</li>
                                            </ul>
                                        </Col>
                                    </Col>
                                    <Col md={{span:12}} className="text-center mt-5 access_acc_txt">
                                        <Button className='w-100' onClick={(e)=>this.closeAccessAccountHandler(e)}>Continue</Button>
                                    </Col>
                                </Col>
                            </> 
                        :
                        <center><Spinner animation="border" variant='primary' /></center>
                    }
                </>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    saveTeamMemberInfo = ((data) => {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.post(
                APIs.BASE_URL + "/team/store",
                data,
                config
            ).then((response) => {
                if (response.data.status_code === 200){
                    this.setState({
                        showStep : 1
                    })
                    if(response.data.data.plan_info) {
                        localStorage.setItem('plan_info', btoa(JSON.stringify( response.data.data.plan_info )))
                    }
                }else{
                    setTimeout(() => {
                        this.saveTeamMemberInfo(data)
                    }, 2000)
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    addUserInArray = ((e) => {
        try{
            e.preventDefault()     
            let allEmailError = this.state.teamEmailError
            let allEmails = this.state.teamAllEmail
            let numOfError = 0
            for (let i = 0; i < allEmailError.length; i++) {
                if (allEmailError[i] === "Invalid email address" || allEmailError[i] === "Email duplicate entry not allowed") { 
                    numOfError++; 
                }
            }

            let numOfEmailEmpty = 0
            for (let i = 0; i < allEmails.length; i++) {
                if (allEmails[i] === '') {
                    numOfEmailEmpty++; 
                }
            }
            if (numOfError === 0 && numOfEmailEmpty === 0){
                const count = {};
                let dublicateEmailarray  = []
                allEmails.forEach(element => {
                    count[element] = (count[element] || 0) + 1;
                    dublicateEmailarray.push(count[element])
                });
                let dublicateEmail = Math.max(...dublicateEmailarray)
                
                if ( dublicateEmail === 1 || dublicateEmail == '-Infinity'){
                    this.setState({
                        showLoad : true
                    }, this.payForSubscription())
                    
                } else {
                    this.setState({
                        emailDuplicateError:'Email duplicate entry not allowed'
                    })
                }
            } else {
                if (numOfEmailEmpty > 0){
                    let teamEmptyEmail = []
                    for(let i = 0;i<numOfEmailEmpty;i++){
                        teamEmptyEmail[i] = 'Email field can’t be empty'
                    }
                    this.setState({
                        teamEmailError:teamEmptyEmail
                    })
                }
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    checkValidEmail = (function (email, i) {
        try{
            let emailError = ''
            if (!validator.isEmail(email) && email.length > 0) {
                emailError = "Invalid email address";
            } 
            if ( this.state.email === email ){
                emailError = "Email duplicate entry not allowed"
            }
            let teamAllEmail    = this.state.teamAllEmail
            let teamEmailError  = this.state.teamEmailError
            teamAllEmail[i]     = email
            teamEmailError[i]   = emailError
            this.setState({
                teamAllEmail: teamAllEmail, 
                teamEmailError:teamEmailError,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onInputChangeHandler = (function(event,i) {
        try{
            this.checkValidEmail(event.target.value,i)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
	}).bind(this);

    html = (function(i){
        try{
            return(
                <div key={i} className={"mb-2 " + (this.state.teamEmailError?"invalid":"")}>
                    <div className="input-group">
                        <input type="text" className="form-control shadow-none" name="email" placeholder="Team member" id="email" value={this.state.teamAllEmail[i]} onChange={(e)=>this.onInputChangeHandler(e,i)} autoComplete="off" />
                    </div>
                    {this.state.teamEmailError[i] ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.teamEmailError[i]}</div> : <></>}
                </div>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    numberofTeamField = (function(){  
        try{
            let teamQuantity = this.state.planQuantity-1
            let field = []
            for(let i=0; i < teamQuantity; i++){
                field.push(this.html(i))
            }
            return field 
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    refreshTokenHandler = (function(){
        try{
            let user = localStorage.getItem('session_token');
            let userData = localStorage.getItem('user');
            axios.post(
                APIs.BASE_URL + APIs.REFRESH_TOKEN_API,
                { refresh: localStorage.getItem('refresh_token') },
                { headers: { "Authorization": `Bearer ${user}` }, cancelToken : this.state.axiosSource.token }
            ).then((response) => {
                Cookies.set(btoa('user-data'), btoa(userData), { expires : 365, secure : true, domain : APIs.DOMAIN })
                let data = response.data;
                localStorage.setItem("session_token", data.data.token.access);
                localStorage.setItem("refresh_token", data.data.token.refresh);
                if(data.data.plan_info) {
                    localStorage.setItem('plan_info', btoa(JSON.stringify( data.data.plan_info)))
                }
                localStorage.setItem('confirm',parseInt(data.data.active))
                localStorage.setItem('account_info',data.data.filled)

            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
            <Container style={{padding:"29px 26px 32px 26px"}}>
                <Row>
                    {
                        this.state.showLoad ? 
                            this.loadingMessageHtml(this.state.showStep)
                        :
                        <Form onSubmit={(e) => {this.addUserInArray(e)}} className="team_add_process">
                            <Col md={12} style={{position:"relative"}}>
                                <i className="material-symbols-outlined" onClick={(e) => {this.resetPlanSelection()}} style={{"position":"absolute","left":"10px","width":"30px","height":"30px","cursor":"pointer"}}>keyboard_backspace</i>
                                <div className="plan-selection-modal-title"><img src={userIco} alt="guest"/></div>
                            </Col>
                            <Col md={12} style={{position:"relative"}} className="mt-2">
                                <div className="plan-selection-modal-title">Who’s on your team?</div>
                                <div className="plan-selection-modal-subtitle">Get your team up and running faster by sending invitation links to your team members.</div>
                            </Col>
                            <Col md={12} style={{position:"relative"}} className="mt-2">
                                <div className="team_seats">You have {(this.state.planQuantity === 0)?this.state.planQuantity:this.state.planQuantity-1} unused seats on your team.</div>
                            </Col>
                            { this.state.teamName !== '' && 
                                <Col md={12} style={{marginTop:25}}>
                                    <div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
                                        <label className="label">Team name</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control shadow-none" name="email" readOnly={true} placeholder="Enter member's email address" id="email" value={this.state.teamName} onChange={this.onInputChangeHandler} autoComplete="off" />
                                        </div>
                                    </div>
                                </Col>
                            }
                            <Col md={12} className="mt-4">
                                <div className={"mb-2 " + (this.state.emailError?"invalid":"")}>
                                    <label className="label">Add team members</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control shadow-none" name="email" placeholder="Enter member's email address" id="email" value={this.state.email} onChange={this.onInputChangeHandler} autoComplete="off" />
                                    </div>
                                </div>
                                {this.numberofTeamField()}
                            </Col>
                            <Col md={12} className="mt-4">
                                <Button type='submit' variant='primary' className='w-100'>Continue</Button>
                            </Col>
                        </Form>
                    }
                </Row>
            </Container>
        )
    }
}
