import React, { Component } from 'react'
import { Modal, Col, Row, Form, Button } from 'react-bootstrap'
import  confirmMessage from '../../../../../../static/images/logged_in/confirm_message.png';
import validator from 'validator';
import axios from 'axios';
import APIs from '../../../../../../utils/APIs';
import { Spinner } from 'react-bootstrap';
import Looger from '../../../../../../utils/Logger';

const maxWaitTime = 10
let timer = null 
class ConfirmAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code:0,
            showModal:false,
            digitCode:'',
            maxWaitTime:maxWaitTime,
            digitCodeError:'',
            userData:JSON.parse(localStorage.getItem('user')),
            axiosSource : axios.CancelToken.source(),
            isCodeSend:false,
            accountInfoFilled:localStorage.getItem('account_info'),
            showSpinner:false,
        }
    }

    verificationHandler = (function(e){
        try{
            let digitCode = e.target.value
            let digitCodeError = ''
            if (digitCode === '' ){
                digitCodeError = 'Confirmation code should not be empty'
            } else if(!validator.isNumeric(digitCode.trim())) {
                digitCodeError = 'Confirmation code should be in digits'
            } 
            this.setState({
                digitCode:digitCode,
                digitCodeError:digitCodeError
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    validateCodeHanlder = (function(e){
        try{
            let digitCodeError  = this.state.digitCodeError 
            let digitCode       = this.state.digitCode 
            if (digitCodeError === '') {
                if (digitCode !== '') {
                    this.setState({showSpinner:true})
                    let token   =   localStorage.getItem('session_token');
                    let config  =   { headers: { authorization: `Bearer ${token}` },cancelToken:this.state.axiosSource.token };
                    axios.get(
                        APIs.BASE_URL + APIs.VALIDATE_SIGNUP_TOKEN_API.replace(":id",digitCode),
                        config
                    ).then((response) => {
                        if (response.data.status_code === 200) {
                            this.props.callRefreshToken(true)
                            this.setState({showSpinner:false})
                        } else {
                            this.setState({
                                digitCodeError:"Please enter valid confirmation code",
                                showSpinner:false
                            })
                        }

                    }).catch((error) => {
            
                    })
                } else {
                    digitCodeError = 'Confirmation code should not be empty'
                }
            } else {
                
            }
            this.setState({
                digitCodeError:digitCodeError
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    resendCodeHanlder = (function(e){
        try{
            this.setState({isCodeSend:true})
            let token   =   localStorage.getItem('session_token');
            let parms   =   {resend_code:true}
            let config  =   { headers: { authorization: `Bearer ${token}` },cancelToken:this.state.axiosSource.token };
            axios.post(
                APIs.BASE_URL + APIs.RESEND_CODE,
                parms,
                config
            ).then((response) => {
                this.setState({isCodeSend:false})
                this.timerCountdown()
            }).catch((error) => {
                console.log(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    timerCountdown =  (function(){
        try{
            let time = this.state.maxWaitTime
            timer = setInterval(()=>{
                time--
                if (time === 0){
                    time = maxWaitTime
                    clearInterval(timer)
                }
                this.setState({
                    maxWaitTime:time,
                })
            },1000)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    goBackHandler = (function(){
        try{
            let token   =   localStorage.getItem('session_token');
            let parms   =   {resend_code:true}
            let config  =   { headers: { authorization: `Bearer ${token}` },parms,cancelToken:this.state.axiosSource.token };
            axios.delete(
                APIs.BASE_URL + APIs.BACK_STEP,
                config
            ).then((response) => {
                this.props.callRefreshToken(true)
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount(){
        let showModal = localStorage.getItem('confirm') > 0 ? false : true
        this.setState({
            showModal:showModal
        })
        this.timerCountdown()

    }

    render() {
        return (
            <div>
                <Modal show={this.state.showModal} size="md" backdrop="static" className='profile-completion-steps'>
                    <Modal.Body className="show-grid pb-5 pt-3">
                        <Row className="text-center mb-5 validation_c">
                            {
                                this.state.accountInfoFilled ==='false' && 
                                <Col md={{span:12}} className='confirm_message pb-5 text-start'>
                                    <span className='default_colors dtl' onClick={(e)=>this.goBackHandler(e)}>Go Back</span>
                                </Col>
                            }
                            <Col md={{span:12}} className='confirm_message'>
                                <img src={confirmMessage} alt="logo-dark"/>
                            </Col>
                            <Col md={{span:12}} className='mt-5'>
                                <h5 style={{fontWeight: 700}}>Check your email</h5>
                            </Col>
                            <Col md={{span:12}} className='mt-3'>
                                <p className='px-5' >Enter the 6 digit code we just sent to <span className="confirm_dark" >{this.state.userData.email}</span> to continue</p>
                            </Col>
                            <Col md={{span:12}} className='mt-3 code'>
                                <Form.Group  className='input-group px-5 digit_code' >
                                    <Form.Control type="text" maxLength={6} onChange={(e)=>this.verificationHandler(e)} placeholder="Enter digit code" value={this.state.digitCode}/>
                                    { this.state.digitCodeError !== "" ?
                                        <Form.Control.Feedback type="invalid" className="errorMessage">
                                            <i className="material-icons">warning</i>{this.state.digitCodeError}
                                        </Form.Control.Feedback>
                                        : <></>
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={{span:12}} xs={{span:12}} className='my-3 mb-2 verify_btn'>
                                <Button  disabled={(this.state.isCodeSend)} onClick={(e)=>{this.validateCodeHanlder(e)}} className='px-5 default_background verify_cu'>
                                    {this.state.showSpinner?<><Spinner size="sm" animation="border" variant="light" /></>:"Continue"}
                                </Button>
                                <Button disabled={(this.state.maxWaitTime<10?true:false)} onClick={(e)=>{this.resendCodeHanlder(e)}} className='px-5 resend_code default_background'>
                                    { this.state.isCodeSend ? 
                                        <> <Spinner size="sm" animation="border" variant="light" /> </>
                                        :
                                        <> 
                                            Resend 
                                            ({this.state.maxWaitTime}s) 
                                        </>
                                        
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}


  
export default ConfirmAccount;
