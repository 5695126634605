// import React, { useEffect } from 'react';
import axios from 'axios';
import APIs from '../../../../utils/APIs';
import Looger from '../../../../utils/Logger';

const GetTaskAssignee = (props) => {
    try{
        let search = props.search ? props.search: 'all'
        let param = { start: 0, length: 1000,search:search };
        let token = localStorage.getItem('session_token');
        let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : props.axiosSource.token };
        let data = axios.get(
            //APIs.BASE_URL + APIs.TEAM_API,
            APIs.BASE_URL + APIs.GET_TEAM_BY_PROJECT_ID.replace(":id",props.project_id),
            config
        ).then((response) => {
            if (response.data.status_code === 200) {
                let data = [];
                response.data.data.map((singleItem, index) => {
                    if(singleItem.invite === 0) {
                        return false;
                    }
                    
                    let name = singleItem.first_name !== "" ? singleItem.first_name + " " + singleItem.last_name : singleItem.email;
                    let image = (singleItem.profile_image !== "") ? singleItem.profile_image : "/placeholder.png";

                    if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                        let avatar_bg = (singleItem.avatar_bg?singleItem.avatar_bg:"5D6DF6").replace('#','')
                        image = `https://ui-avatars.com/api/?name=${name}&color=FFFFFF&length=1&background=${avatar_bg}`
                    }

                    let html =
                            <div className="options" key={singleItem.id} title={name}>
                                {/* <div className="checkbox" title={name}>
                                    <input type="checkbox" id={singleItem.id+'team'} value={singleItem.id}/>
                                </div> */}
                                <div className="options_image" title={name}>
                                    <img src={image} alt={name} />
                                </div>
                                <div className="options_label" title={name}>
                                    <div className="options_label_name">{name}</div>
                                    <div className="options_label_role">{singleItem.role}</div>
                                </div>
                            </div>
                    data.push({
                        labelKey: singleItem.id,
                        value: html
                    })
                    return null;
                });
                return data;
            } else if (response.data.status_code === 403) {
                return [];
            }
        }).catch((error) => {
            // Capture Error Logs
            Looger(error)
            return [];
        });
        return data.then((data) => data)
    } catch(error) {
        // Capture Error Logs
        Looger(error)
    }
}

export default GetTaskAssignee;