const STAGGING = "://stagging.orrderly.com"
const LIVE_URL = "://api.orrderly.com";
const LOCAL_URL = "://192.168.1.14:8080/";

let ENV = process.env.REACT_APP_MODE;
// ENV = "LIVE";
let URL = "https"+LIVE_URL+"/";
let WS_URL = "wss" +LIVE_URL+":8080/ws/sc";
let DOMAIN = "orrderly.com"
if(ENV === "LOCAL") {
    
    // URL = "http"+LOCAL_URL;
    // WS_URL = "ws"+LOCAL_URL+"ws/sc"

    URL = "http"+STAGGING+"/";
    WS_URL = "wss"+STAGGING+":8080/ws/sc"
    DOMAIN = "192.168.1.14";
}


const APIs = {
    DOMAIN : DOMAIN,
    ENV : ENV,
    WS_URL,
    BASE_URL: URL+"api/v1",
    IMAGE_URL: URL,
    LOGIN_API:"/login",
    SIGNUP_STEP_1_API:"/signup/step-1",
    SIGNUP_FINAL_STEP_API:"/signup",
    SIGNUP_API:"/signup",
    ADD_ACCOUNT_TYPE_INFORMATION:"/user/add/account-type",
    TEAM_API:"/team",
    TEAM_PENDING_INVITES_API:"/team/pending-invites",
    TEAM_INVITE_MEMBER_API:"/team/invite-member",
    TEAM_INVITE_MEMBER_URL_ACTION_API:"/team/invite-action",
    TEAM_MEMBER_REMOVE_API:"/team/remove-member",
    TEAM_MEMBER_RESTORE_API:"/team/restore-member",
    TEAM_MEMBER_ACCEPT_INVITE_API:"/team/accept-invite",
    TEAM_MEMBER_DECLINE_INVITE_API:"/team/decline-invite",
    TEAM_MEMBER_VALIDATION_API:"/team/validate",
    TEAM_ROLE_UPDATE:"/team/update-role",
    CREATE_TASK_API:"/task/store",
    DELETE_TASK_API:"/task/delete/:id",
    REFRESH_TOKEN_API:"/user/refresh-token",
    UPDATE_TASK_API:"/task/update",
    CREATE_SUB_TASK_API: "/task/subtask/store",
    DELETE_SUB_TASK_API: "/task/subtask/delete/:id", // DELETE
    UPDATE_SUB_TASK_API: "/task/subtask/update", // PATCH [{id,task_description}]
    CREATE_TASK_COMMENT_API: "/task/comment/store",
    ADD_TASK_COMMENT_FILE_API: "/task/comment/file",
    DELETE_TASK_COMMENT_API: "/task/comment/delete/:id", // DELETE
    UPDATE_TASK_COMMENT_API: "/task/comment/update", // PATCH [{id, comment}]
    UPDATE_TASK_COMMENT_EMOJI_API: "/task/comment/update/emoji", // PATCH [{id, comment}]
    GET_TASK_CALENDAR_API: "/task/calendar",
    GET_TASK_CALENDAR_SINGLE_DATE_API: "/task/calendar/single-date",
    GET_OVERDUE_TASKS_API: "/task/overdues",
    GET_SINGLE_TASK_API:"/task/:id",
    DONE_TASK_API:"/task/complete/:id",
    GET_TASK_API: "/task",
    FORGOT_PWD_API:"/forgot-password",
    RESET_PWD_API:"/reset-password",
    HELP_SUPPORT_TOPICS_LIST_API :"/topic/:id",
    PROFILE_API :"/profile",
    PROFILE_PICTURE_API :"/profile-image/update",
    GET_NOTIFICATION_SETTINGS_API:"/notification/setting",
    SET_NOTIFICATION_SETTINGS_API:"/notification/setting/update",
    GET_PLAN_API:"/plan",
    TASK_SECTION_API:"/task-section/:id",
    DELETE_SECTION_API:"/task-section/delete/:id",
    TASK_SECTION_ASSIGN_API:"/task-section/assign",
    TASK_SECTION_MOVE_API:"/task-section/move",
    TASK_SHARE_API:"/task_share",
    PROFILE_DELETE_API :"/user/delete",
    VALIDATE_RESET_TOKEN_API:"/validate-reset-token",
    VALIDATE_SIGNUP_TOKEN_API:"/signup/step-2/:id",
    UPDATE_MY_PLAN:"/update-plan",
    GET_MY_TASK_COUNT:"/task/count",
    SERVER_NOT_AVAILABLE:"Server is not reachable, please try again later",
    INVITE_UNSUCESSFULL: "Invite was unsuccessful, try again.",
    DELETE_TASK_TAGS:   "/task/tags",
    UPDATE_COMMENT: "/task/comment/update",
    CREATE_PROJECT_API:"/project/store",
    GET_ALL_PROJECT_LIST:"/project",
    UPDATE_PROJECT_NAME:"/project/update",
    DELETE_PROJECT:"/project/delete/:id",
    UPDATE_GET_ACTIVE_PROJECT:"/project/activate",
    GET_TEAM_BY_PROJECT_ID:"/project/team/:id",
    DELETE_PROJECT_TEAM:"/project/team/delete/:id",
    UPDATE_PROJECT_TEAM_ROLE:"/project/team/update",
    ADD_PROJECT_TEAM:"/project/teams/store",
    GET_USER_LOGS:"/user/logs",
    GET_USER_LOGS_COUNT:"/user/logs/count",
    READ_USER_LOGS:"/user/logs/read",
    GLOBAL_SERACH:"/search?keyword=:query",
    RESEND_CODE:'/resend',
    BACK_STEP:'/back-step'
}

export default APIs;
