import React, { Component } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { connect } from "react-redux";
import Avatar from '../Common/Avatar';
import { Marker } from 'react-mark.js';
import Looger from '../../../../utils/Logger';
TimeAgo.addLocale(en)

class ProjectBoardList extends Component {
    constructor(props) {
        super(props);
        this.allProjectList =[]
        this.state = {
            user_id: JSON.parse(localStorage.getItem("user")).id,
            axiosSource : axios.CancelToken.source(),
        };
    }

    boardProgressHtml = (function(status){
        try{
            return (
                <>
                { 
                status === 'Draft' ?
                    <h3 style={{borderRadius:"5px"}} ref={(el) => { if (el) {el.style.setProperty('color', '#ED713C', 'important');el.style.setProperty('background', '#FFEAE1', 'important');}}} >
                        <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                            {status}
                        </Marker>
                    </h3>
                :
                status === 'Due' ?
                    <h3 style={{borderRadius:"5px"}} ref={(el) => { if (el) {el.style.setProperty('color', '#dc6562', 'important');el.style.setProperty('background', '#ffe5e4', 'important');}}} >
                        <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                            {status}
                        </Marker>
                    </h3>
                :
                    <h3 style={{borderRadius:"5px"}} ref={(el) => { if (el) {el.style.setProperty('color', '#6C9D4A', 'important');el.style.setProperty('background', '#E7F8DC', 'important');}}} >
                        <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                            {status}
                        </Marker>
                    </h3>
                }
                </>
            )
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    })

    numberOfselfProject = (function(data){
        try{
            if (data.project_owner_id ===  this.state.user_id){
                    return data
            }
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    }).bind(this)

    projectBoardIList = (function () {
        try{
            let projectList  = this.allProjectList
            let projectListBySearch  = this.props.allPojectList
            if (projectListBySearch.length < projectList.length ){
                projectList  = projectListBySearch
            }
            let checkedProjectListCount = projectList.filter(this.numberOfselfProject).length
            if(projectList.length > 0) {
                    return projectList.map((data,index)=>{
                        return(
                            <Col md={{ span : 12 }} className={"member_sec"} key={index}>
                                <Col className="d-flex p-2 member_cols flex-wrap">
                                    <Col md={{span:5}} xs={{span:12}} className="p-2 board_name">
                                        <h3>
                                            <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                                                {data.name}
                                            </Marker>
                                        </h3>
                                        <h5><ReactTimeAgo date={new Date(data.updated_at)} locale="en" /></h5>
                                    </Col>
                                    <Col md={{span:7}} xs={{span:12}}>
                                        <Row >
                                            <Col md={{span:4}} xs={{span:3}} className="d-flex p-2 board_task_count">
                                                <h3><span className="material-symbols-outlined">assignment</span></h3>
                                                <h3>{data.task_count}</h3>
                                            </Col>
                                            <Col md={{span:4}} xs={{span:4}} className="p-2 board_progress">

                                                {this.boardProgressHtml(data.status)}
                                            
                                            </Col>
                                            <Col md={{span:3}} xs={{span:3}} className="p-2 board_avatar">
                                                <Avatar 
                                                    teamData={data.members}
                                                    limit={5} 
                                                />
                                            </Col>
                                            {   data.project_owner_id === this.state.user_id &&
                                                <Col md={{span:1}} xs={{span:2}} className="p-2 ellipsis board_edit">
                                                <Dropdown align="end">
                                                    <Dropdown.Toggle variant="default">
                                                        <i className="material-icons" style={{ color: "#9C9EAE" }}>more_vert</i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className='d-flex'  onClick={()=>{this.props.onClick(data,'update')}}><i className="material-icons">edit</i> &nbsp;Edit name</Dropdown.Item>

                                                        { checkedProjectListCount > 1 &&
                                                            <Dropdown.Item className='d-flex'  onClick={()=>{this.props.onClick(data,'delete')}} ><i className="material-icons">delete</i> &nbsp;Delete</Dropdown.Item>
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>
                        )
                    })
            } else {
                return(
                    <Col md={{ span : 12 }} className="member_sec no_project">
                        Not Found
                    </Col>
                )
            }
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    });

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {

        let reduxAllProjectList  = this.props.allPojectList
        if ( !reduxAllProjectList.length > 0){
            reduxAllProjectList     = JSON.parse(localStorage.getItem('allProjectList'))
        }
        this.allProjectList = reduxAllProjectList 
        return (
            <>
            <Row>
                {this.projectBoardIList()}
            </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    allPojectList:state.CurrentProjectBoard.all_project_list,
  });

export default connect(mapStateToProps)(ProjectBoardList);
// export default ProjectBoardList;