import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import TeamInviteAction from './components/Auth/TeamInviteAction';
import EmailConfirmation from './components/Auth/EmailConfirmation';

import Error404 from './components/Pages/Error/Error404';

import Home from './components/Pages/Home';
import About from './components/Pages/About';
import ContactUs from './components/Pages/ContactUs';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import TermsAndConditions from './components/Pages/TermsAndConditions';

import Dashboard from './components/Pages/Logedin/Dashboard';
import Profile from './components/Pages/Logedin/Profile/index';
import HelpSupport from './components/Pages/Logedin/Profile/HelpSupport';
import Settings from './components/Pages/Logedin/Profile/Settings';
import Upgrade from './components/Pages/Logedin/Profile/Upgrade';
import Overdue from './components/Pages/Logedin/Overdue';
import Calendar from './components/Pages/Logedin/Calendar';
import Team from './components/Pages/Logedin/Team';
import Helmet from 'react-helmet';
import SignupStep2 from './components/Auth/SignupStep2';
import ProjectBoard from './components/Pages/Logedin/ProjectBoard/ProjectBoard';
import BoardTeam from './components/Pages/Logedin/ProjectBoard/BoardTeam';

class AppRoutes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userIsLoggedin : false
		};
	}


	render() {
		return (
			<>
				<BrowserRouter>
					<Helmet>
						<title>Orrderly</title>
						<meta name="description" content="" />
						{/*<meta name="keywords" content="Music, Audio, Lyrics" />*/}
					</Helmet>
					<Routes>
						{/* Guest Pages Starts */}
							<Route path="/home" element={<Home />} />
							<Route path="/about" element={<About />} />
							<Route path="/contact-us" element={<ContactUs />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
						{/* Guest Pages Ends*/}

						{/* Auth Pages Starts */}
							<Route path="/confirm-email" element={<EmailConfirmation />} />
							<Route path="/reset-password/:token" element={<ResetPassword />} />
							<Route path="/team-invite-request/:token/:action" element={<TeamInviteAction />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/signup" element={<Signup />} />
							<Route path="/signup/step-2" element={<SignupStep2 />} />
							<Route path="/" element={<Login />} />
						{/* Auth Pages Ends */}
						
						{/* Loggedin Pages Starts */}
							<Route path="/dashboard" element={<Dashboard userIsLoggedin={this.state.userIsLoggedin} />} />
							<Route path="/dashboard/TSK/:date/:key/:id" element={<Dashboard userIsLoggedin={this.state.userIsLoggedin} />} />
							<Route path="/dashboard/:taskView" element={<Dashboard userIsLoggedin={this.state.userIsLoggedin} />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/help-and-support" element={<HelpSupport />} />
							<Route path="/settings/:selectedMenu" element={<Settings />} />
							<Route path="/settings" element={<Settings />} />
							<Route path="/upgrade-plan" element={<Upgrade />} />
							<Route path="/calendar" element={<Calendar />} />
							<Route path="/team" element={<Team />} />
							<Route path="/board-team" element={<BoardTeam />} />
							<Route path="/overdue" element={<Overdue />} />
							<Route path="/manage-board" element={<ProjectBoard />} />
						{/* Loggedin Pages Ends*/}
						
						{/* Error Pages Starts */}
							<Route path="*" element={<Error404 />} />
						{/* Error Pages Ends */}
					</Routes>
				</BrowserRouter>
			</>
		);
	}
}

export default AppRoutes;