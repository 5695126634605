import axios from 'axios';
import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, Col, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import APIs from '../../../../../utils/APIs';
import Labels from '../../../../../utils/Labels';
import { connect } from "react-redux";
import Looger from '../../../../../utils/Logger';
import { loader } from '../../../../../reducers/CurrentProjectBoard';

class NotificationsSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading : false,
            showAlert : false,
            showAlertMessage : "",
            showAlertType : "",
            notificationData: Labels.NOTIFICATION_TYPES,
            apiCalled : 0,
            showCancel : false,
            axiosSource : axios.CancelToken.source(),
        }
    }

    loadNotificationsSettings = (function() {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_NOTIFICATION_SETTINGS_API,
                config
            ).then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({
                        notificationData: response.data.data,
                        apiCalled: 1,
                        showCancel:false
                    });
                } else {
                    this.setState({
                        apiCalled: 1,
                        showCancel:false
                    });
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    handleFormSubmit = (function(e) {
        try{
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                showLoading: true
            });
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            let data = this.state.notificationData

            axios.patch(
                APIs.BASE_URL + APIs.SET_NOTIFICATION_SETTINGS_API,
                { data : data},
                config
            ).then((response) => {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    showAlertMessage: "Notification settings updated successfully",
                    showAlertType: "success",
                    showCancel : false
                });
                setTimeout(() => {
                    this.setState({ showAlert: false });
                }, 5000);
            }).catch((error) => {
                this.setState({
                    showLoading: false
                });
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    selectNotificationOption = (function(index, option) {
        try{
            let notificationData = this.state.notificationData;
            if(option === "none") {
                notificationData[index].selected_option = "none";
            } else {
                if(notificationData[index].selected_option.indexOf(option) > -1) {
                    notificationData[index].selected_option = notificationData[index].selected_option.replace(option, '').replace('|', '')
                } else {
                    if(notificationData[index].selected_option === "none") {
                        notificationData[index].selected_option = option
                    } else {
                        notificationData[index].selected_option = "email|in_app"
                    }
                }
            }
            if(notificationData[index].selected_option === "") {
                notificationData[index].selected_option = "none"
            }
            this.setState({
                notificationData: notificationData,
                showCancel:true
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }
    
    componentDidMount(){
        this.props.loader({loader:false})
    }

    render() {
        if (this.state.apiCalled === 0) {
            this.loadNotificationsSettings();
        }

        return (
            <Row>
                <Helmet>
                    <title>My Notifications - Orrderly</title>
                </Helmet>
                <Col md={{span:12}}>
                    <Row className="task">
                        {!this.state.showAlert ? <></> :
                            <Col md={{span:10}} xs={{span:12}} sm={{span:10}} className="mb-4">
                                <Alert variant={this.state.showAlertType} onClose={() => { this.setState({ showAlert: false }) }} dismissible style={{ marginBottom: "-15px", marginTop: "10px" }}>
                                    <p>{this.state.showAlertMessage}</p>
                                </Alert>
                            </Col>
                        }
                    </Row>
                </Col>
                <Col md={{span:12}}>
                    <Row className="notify_settings">
                        <Col md={{span:6}} xs={{span:5}} sm={{span:6}} className="d-flex align-items-center">
                            <h4>Notification settings</h4>
                        </Col>
                        <Col md={{span:6}} xs={{span:7}} sm={{span:6}} className={"text-end " + (this.state.showCancel?"d-flex justify-content-end":"")}>
                            {this.state.showCancel ? 
                                <Button className="profile-btn" variant='light' onClick={() => this.loadNotificationsSettings()}>Cancel</Button> : <></>
                            }
                            <Button className="profile-btn" variant='dark' disabled={this.state.showLoading} onClick={(e) => { this.handleFormSubmit(e) }}>{this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Update settings"}
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={{span:12}}>
                    {
                        this.state.notificationData.map((data, index) => {
                            return (
                                <Row key={"notification-type-id-" + data.type_id + "-" + index} className="notify_settings list">
                                    <Col md={{span:6}} xs={{span:4}} sm={{span:6}} className="d-flex align-items-center">
                                        <h5>{data.type_name}</h5>
                                    </Col>
                                    <Col md={{span:6}} xs={{span:8}} sm={{span:6}} className="text-end">
                                        <ButtonGroup className="notify_btn_group">
                                            <Button variant='light' className={data.selected_option === "none" ? "active" : ""} onClick={(e) => this.selectNotificationOption(index, 'none')}>None</Button>
                                            <Button variant='light' className={data.selected_option.indexOf("in_app")> -1 ? "active" : ""} onClick={(e) => this.selectNotificationOption(index, 'in_app')}>In app</Button>
                                            <Button variant='light' className={data.selected_option.indexOf("email")> -1 ? "active" : ""} onClick={(e) => this.selectNotificationOption(index, 'email')}>Email</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    loader: state.CurrentProjectBoard.loader,
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(NotificationsSettings);
