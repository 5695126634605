const Labels = {
    "LOGIN_EMAIL"               :   "Email address",
    "LOGIN_PWD"                 :   "Password",
    "CREATE_PWD"                :   "Create password",
    "CREATE_PWD_PLACEHOLDER"    :   "Create password",
    "QUES_HAVE_NO_ACCOUNT"      :   "Don’t have an account with Orrderly?",
    "QUES_HAVE_ACCOUNT"         :   "Already have an account with Orrderly?",
    "SIGN_UP_LABEL"             :   "Sign Up",
    "SIGN_IN_LABEL"             :   "Log In",
    "QUES_FORGOT_PWD"           :   "Forgotten password?",
    "LOG_IN_H3"                 :   "Log in to your account",
    "LOG_IN_H6"                 :   "",
    "FORGOT_PWD_H3"             :   "Forgot password?",
    "FORGOT_PWD_H6"             :   "",
    "RESET_PWD_H3"              :   "Create your new Password",
    "RESET_PWD_H6"              :   "",
    "SIGN_UP_H3"                :   "Get started with your account",
    "SIGN_UP_H6"                :   "Create your Orrderly Account today and start managing your tasks more efficiently",
    "EMAIL_PLACEHOLDER"         :   "Enter email address",
    "PWD_PLACEHOLDER"           :   "Enter password",
    "CONF_PWD_PLACEHOLDER"      :   "Re-Enter password",
    "BACK_TO_LOGIN"             :   "Back to Login",
    "EMAIL_PWD_NOT_EMPTY"       :   "Email and Password should not be empty.",
    "LOGIN_SUCCESS"             :   "Login successfully, redirecting please wait",
    "USER_EMAIL_ADDRESS"        :   "User email address",
    "USER_ROLE"                 :   "User role",
    "TASK_TYPE": [
        {
            "labelKey": "one_time",
            "value": "One-Time Task",
            "isSelected":true
        },
        {
            "labelKey": "recurring",
            "value": "Recurring Task",
            "isSelected": false
        }
    ],
    "TASK_FREQUENCY": [
        {
            "labelKey": "daily",
            "value": "Daily",
            "isSelected": true
        },
        {
            "labelKey": "weekly",
            "value": "Weekly",
            "isSelected": false
        },
        {
            "labelKey": "monthly",
            "value": "Monthly",
            "isSelected": false
        },
        {
            "labelKey": "yearly",
            "value": "Yearly",
            "isSelected": false
        }
    ],
    "ADD_UPDATE_TASK_DROPDOWNS" : {
        "task_type": "taskTypeOptions",
        "task_assignee": "assigneeOptions",
        "task_frequency": "taskFrequencyOptions"
    },
    "TAGS_COLOR" : [
        "Independence",
        "ChineseOrange",
        "TerraCotta",
        "DollarBill",
        "BlueJeans",
        "BrightLavender",
        "CopperRed",
        "SuperPink",
        "RoseTaupe",
    ],
    TAGS_COLOR_OPTIONS : [
        {
            "labelKey": "Independence",
            "value": <span key="dropdown-value-Independence" className="tags-bg no-color-text bg-Independence">COLOR</span>,
            "isSelected" : true,
        },
        {
            "labelKey": "ChineseOrange",
            "value": <span key="dropdown-value-ChineseOrange" className="tags-bg no-color-text bg-ChineseOrange">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "TerraCotta",
            "value": <span key="dropdown-value-TerraCotta" className="tags-bg no-color-text bg-TerraCotta">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "DollarBill",
            "value": <span key="dropdown-value-DollarBill" className="tags-bg no-color-text bg-DollarBill">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "BlueJeans",
            "value": <span key="dropdown-value-BlueJeans" className="tags-bg no-color-text bg-BlueJeans">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "BrightLavender",
            "value": <span key="dropdown-value-BrightLavender" className="tags-bg no-color-text bg-BrightLavender">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "CopperRed",
            "value": <span key="dropdown-value-CopperRed" className="tags-bg no-color-text bg-CopperRed">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "SuperPink",
            "value": <span key="dropdown-value-SuperPink" className="tags-bg no-color-text bg-SuperPink">COLOR</span>,
            "isSelected": false,
        },
        {
            "labelKey": "RoseTaupe",
            "value": <span key="dropdown-value-RoseTaupe" className="tags-bg no-color-text bg-RoseTaupe">COLOR</span>,
            "isSelected": false,
        },
    ],
    MONTH_NAMES: [
        {
            "labelKey": "0",
            "value": "January",
            "isSelected": false,
        },
        {
            "labelKey": "1",
            "value": "February",
            "isSelected": false,
        },
        {
            "labelKey": "2",
            "value": "March",
            "isSelected": false,
        },
        {
            "labelKey": "3",
            "value": "April",
            "isSelected": false,
        },
        {
            "labelKey": "4",
            "value": "May",
            "isSelected": false,
        },
        {
            "labelKey": "5",
            "value": "June",
            "isSelected": false,
        },
        {
            "labelKey": "6",
            "value": "July",
            "isSelected": false,
        },
        {
            "labelKey": "7",
            "value": "August",
            "isSelected": false,
        },
        {
            "labelKey": "8",
            "value": "September",
            "isSelected": false,
        },
        {
            "labelKey": "9",
            "value": "October",
            "isSelected": false,
        },
        {
            "labelKey": "10",
            "value": "November",
            "isSelected": false,
        },
        {
            "labelKey": "11",
            "value": "December",
            "isSelected": false,
        }
    ],
    WEEK_NAMES : [
        {
            "labelKey": "1",
            "short_value": "Mon",
            "value": "Monday",
        },
        {
            "labelKey": "2",
            "short_value": "Tue",
            "value": "Tuesday",
        },
        {
            "labelKey": "3",
            "short_value": "Wed",
            "value": "Wednesday",
        },
        {
            "labelKey": "4",
            "short_value": "Thu",
            "value": "Thursday",
        },
        {
            "labelKey": "5",
            "short_value": "Fri",
            "value": "Friday",
        },
        {
            "labelKey": "6",
            "short_value": "Sat",
            "value": "Saturday",
        },
        {
            "labelKey": "0",
            "short_value": "Sun",
            "value": "Sunday",
        }
    ],
    TIMEZONE_OPTIONS: ["GMT-11:00", "GMT-10:00", "GMT-09:00", "GMT-08:00", "GMT-07:00", "GMT-06:00", "GMT-05:00", "GMT-04:30", "GMT-04:00", "GMT-03:30", "GMT-03:00", "GMT-02:00", "GMT-01:00", "GMT+00:00", "GMT+01:00", "GMT+02:00", "GMT+03:00", "GMT+03:30", "GMT+04:00", "GMT+04:30", "GMT+05:00", "GMT+05:30", "GMT+05:45", "GMT+06:00", "GMT+06:30", "GMT+07:00", "GMT+08:00", "GMT+09:00", "GMT+09:30", "GMT+10:00", "GMT+11:00", "GMT+12:00", "GMT+13:00"],
    NOTIFICATION_TYPES: [{ "type_id": 1, "type_name": "I create a task", "selected_option": "none" }, { "type_id": 2, "type_name": "I'm assgined a task", "selected_option": "none" }, { "type_id": 3, "type_name": "A task is due", "selected_option": "none" }, { "type_id": 4, "type_name": "A task is updated", "selected_option": "none" }, { "type_id": 5, "type_name": "New subtask added", "selected_option": "none" }, { "type_id": 6, "type_name": "Someone comment on my task", "selected_option": "none" }],
    FILE_TYPES : {
        "pdf" : "pdf",
        "msword" : "doc",
        "vnd.openxmlformats-officedocument.wordprocessingml.document" : "doc",
        "vnd.ms-powerpoint" : "ppt",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" : "ppt",
        "vnd.ms-excel" : "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "xls",
    },
    EMOJI_URL: "https://cdn.jsdelivr.net/npm/emoji-datasource-google@14.0.0/img/google/64",
    INDUSTRY_LIST: [
        {
            'id':1,
            'name':'Software',
            'icon':'webhook',
            'isSelected':true
        },
        {
            'id':2,
            'name':'Sports',
            'icon':'sports_soccer',
            'isSelected':false
        },
        {
            'id':3,
            'name':'Manufacturing',
            'icon':'factory',
            'isSelected':false
        },  
        {
            'id':4,
            'name':'Hospitality',
            'icon':'medication',
            'isSelected':false
        },
        {
            'id':5,
            'name':'Aerospace',
            'icon':'rocket_launch',
            'isSelected':false
        },
        {
            'id':6,
            'name':'Food',
            'icon':'restaurant',
            'isSelected':false
        },
        {
            'id':5,
            'name':'Services',
            'icon':'design_services',
            'isSelected':false
        },
    ]
};

export default Labels;