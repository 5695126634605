import React, { Component } from 'react';
import validator from 'validator';
import axios from "axios";
import BeforeLoginLayout from './BeforeLoginLayout';
import Labels from "../../utils/Labels";
import APIs from '../../utils/APIs';
import { Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Looger from '../../utils/Logger';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email 				: 	"",
            submitButtonText 	: 	"Continue",
            submitButtonProp 	: 	false,
			apiResponse 		: 	"",
			emailError 			: 	"",
			axiosSource : axios.CancelToken.source(),
        }
    }

    /**
	 * @function onChange
	 * @param {event} event
	 * @description On change of input value assign that value in respective state
	 * @returns void
	 */
	onChange = (function(event) {
		try{
			this.setState({ [event.target.name]: event.target.value, emailError : "" });
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

    /**
	 * @function onFormSubmit
	 * @param {event} event to stop html form default behaviour
	 * @description based on username and password call the login API, and display respective message in parent toast
	 * @returns void
	 */
	onFormSubmit = (function(event) {
		try{
			event.preventDefault();
			let email = this.state.email.toLowerCase();
			let isError = false;
			let emailError = "";
			if(email.trim() === "") {
				emailError = "Email should not be empty.";
				isError = true;
			} else if(!validator.isEmail(email)) {
				emailError = "Please enter a valid email.";
				isError = true;
			}
			this.setState({
				emailError: emailError
			});
			if (!isError) {
				this.setState({ emailError: "", apiResponse: "", submitButtonProp: true, submitButtonText : "Loading"});
				axios.post(APIs.BASE_URL + APIs.FORGOT_PWD_API, {
					email: email
				}, {cancelToken : this.state.axiosSource.token}).then((response) => {
					let emailsError = "";
					let apiResponse = "";
					if (response.data.status_code === 401) {
						emailsError = response.data.message;
					} else {
						apiResponse = response.data.message;
					}
					this.setState({ emailError: emailsError, apiResponse: apiResponse, submitButtonProp: false, submitButtonText : "Continue" });
				}).catch((error) => {
					//
				});
			}
			event.stopPropagation();
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
			<BeforeLoginLayout
				footerQues={"Remember password?"}
				footerLinkText={"Sign in to your account"}
				footerLink={"/"}
				formTagLine={this.state.apiResponse === "" ? "Enter your email to change your password" : <span className="text-center">{this.state.apiResponse} <NavLink className="custom-link" to={"/"}> Click here</NavLink> to login</span>}
				formHeaderTagLine={"Forgotten password"}
				seoData={{
					"title" : "Reset Your Orrderly Password - Orrderly",
					"meta": [
						{"name":"description","content":"Reset your Orrderly password to gain access to your account."}
					]
				}}
			>
				{
				this.state.apiResponse !== "" ? <></> :
				<form onSubmit={this.onFormSubmit} className="signup_form form_sec mt-5" autoComplete='off'>
					<div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
						<label className="label d-none">{Labels.LOGIN_EMAIL} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">mail</i></span>
							<input type="text" className="form-control shadow-none" name="email" placeholder={Labels.EMAIL_PLACEHOLDER} id="email" value={this.state.email} onChange={this.onChange} autoComplete="off" />
						</div>
						{this.state.emailError ? <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.emailError}</div> : <></>}
					</div>
					<div className="d-grid pt-3">
						<button type="submit" className="btn btn-primary btn-block" disabled={this.state.submitButtonProp}>{this.state.submitButtonProp ? <Spinner size="sm" animation="border" variant="light" /> : this.state.submitButtonText}</button>
					</div>
				</form>
				}
			</BeforeLoginLayout>
        );
    }
}

export default ForgotPassword;