import React, { Component } from 'react';
import {Row, Col,} from 'react-bootstrap';
import axios from 'axios';
import LoggedInLayout from './LoggedInLayout';
import APIs from '../../../utils/APIs';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import AddTaskModal from './Common/Modals/AddTaskModal';
import GetTaskAssignee from './Common/GetTaskAssignee';
import PopUpModal from './Common/Modals/PopUpModal';
import Looger from '../../../utils/Logger';
import { loader } from '../../../reducers/CurrentProjectBoard'



class Calendar extends Component {
    static  project_id  = 0
    constructor(props) {
        super(props);
        this.project_id = this.props.project_id
        this.state = {
            showAddTaskModal : false,
            taskModalType:'',
            defaultViews:{'label':'Monthly','value':'dayGridMonth'},
            assigneeOption: [],
            modalData: {},
            showConfirmRemoveTaskModal:false,
            removeTaskData:{},
            axiosSource : axios.CancelToken.source()
        };
    }

    hideModal = (function() {
        try{
            this.loadCalendarTasks();
            this.setState({
                showTasksListModal : false,
                selectedDateData: [],
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    taskAddUpdate(date){
       console.log("")
    }

    handleEventClick = (function(data){
        try{
            let taskData    = data.event 
            let taskIds     = taskData.id 
            let token   = localStorage.getItem('session_token');
            let config  = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                axios.get(
                    APIs.BASE_URL + APIs.GET_SINGLE_TASK_API.replace(':id',taskIds),
                    config
                ).then((response) => {
                    let taskData = response.data.data 
                    taskData.assigned_to_id = taskData.assigned_to 
                    this.setState({
                        showAddTaskModal : true,
                        selectedTaskIds: taskIds,
                        taskModalType:'Edit',
                        modalData:taskData,
                        assigneeOption:data,
                        // isPageLoading:true,
                    });
                }).catch((error) => {
                    this.setState({
                        showAddTaskModal: false,
                        selectedTaskIds: taskIds,
                        assigneeOption:data
                    });
                })
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    handleDateSelect = (function(data){
        //console.log(data,'handleDateSelect')
    })

    hideAddEditTaskModal = (function(){
        try{
            this.setState({
                showAddTaskModal: false,
                assigneeOption:[],
                modalData:{},
                taskModalType:''
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    deleteTaskModal =  (function(data){
        try{
            this.setState({
                showConfirmRemoveTaskModal : true,
                removeTaskData: data,
            })
            this.hideAddEditTaskModal()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    confirmRemoveTaskModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="" show={this.state.showConfirmRemoveTaskModal} onChange={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title'>Remove Task</div>
                    <div className='warning-message'>Are you sure you want to remove this task from your task list?</div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>No, cancel</button>
                        <button className='btn btn-primary' onClick={() => { this.handleRemoveTaskSubmit(this.state.removeTaskData) }}>Yes, remove</button>
                    </div>
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);


    handleRemoveTaskSubmit = (function(data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}`}, cancelToken: this.state.axiosSource.token };
            axios.delete(
                APIs.BASE_URL + APIs.DELETE_TASK_API.replace(":id", data.id),
                config
            ).then((response) => {
                if(response.data.status_code === 200) {
                    this.setState({
                        showRemoveTaskResponseMessage: `Task : ${data.task_name} has been deleted!`,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: "success"
                    });
                } else {
                    this.setState({
                        showRemoveTaskResponseMessage: response.data.message,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: ""
                    });
                }
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
                this.loadTasks();
            }).catch((error) => {
                this.setState({
                    showRemoveTaskResponseMessage: error.message,
                    showConfirmRemoveTaskModal: false,
                    actionIcon: ""
                });
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);
    
    onTaskAdd =  (function(data){
        try{
            this.hideAddEditTaskModal()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    AddTaskHandler = (function(){
        try{
            GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                this.setState({
                    showAddTaskModal:true,
                    taskModalType:'Add',
                    assigneeOption:data
                })
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount(){
        // this.setState({
        //     isPageLoading:true
        // })
        // setTimeout(()=>{
        //     this.setState({
        //         isPageLoading:false
        //     })
        // },2000)
    }

    
    render() {

        let project_id = this.props.project_id
        if (isNaN(project_id)){
            project_id = parseInt(localStorage.getItem('project_id'))
        }
        if (this.project_id !== project_id){
            this.project_id = project_id
        }


        return (
            <LoggedInLayout>
                <Helmet>
                    <title>Calendar View - Orrderly</title>
                </Helmet>
                <Row key={"calendar-main-start"}>
                    <Col md={{span:12}} className="my-3 custom_calendar">
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                right: 'AddTask',
                                center: '',
                                left: 'dayGridMonth timeGridWeek timeGridDay title prev,next',
                            }}
                            customButtons= {{
                                AddTask: {
                                  text: 'Add New Task',
                                  click: (function() {
                                    this.AddTaskHandler()
                                  }).bind(this)
                                }
                            }}
                            eventTimeFormat = {{
                                hour: 'numeric',
                                minute: '2-digit',   
                            }}
                            navLinks={ true}
                            nowIndicator={true}
                            buttonText={{
                                month:    'Monthly',
                                week:     'Weekly',
                                day:      'Daily',
                            }}
                            views = {{
                                "timeGridWeek": { 
                                    dayHeaderFormat:{ weekday: 'short',  day: 'numeric', },
                                    dayHeaderContent: ({date}) => {
                                        const cutomeday = date.getDay()
                                        const cutomeDate = date.getDate()
                                        const weekdays = ['SUN', 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT']
                                        return {
                                            html: `<div class="custom_calendarhead">${weekdays[cutomeday]}</div><div class="custom_calendarhead_date">${cutomeDate}</div>`
                                        }
                                    },
                                },
                                "timeGridDay": { 
                                    dayHeaderContent: ({date}) => {
                                        const cutomeday = date.getDay()
                                        const cutomeDate = date.getDate()
                                        const cutomeMonth = date.toLocaleString('default', { month: 'long' })
                                        const cutomeFullYear = date.getFullYear()
                                        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                                        return {
                                            html: `<div class="custom_calendarhead dailyView"><span>${weekdays[cutomeday]}, </span><span>${cutomeDate} </span><span>${cutomeMonth} </span><span>${cutomeFullYear}</span></div>`
                                        }
                                    },
                                },
                                "dayGridMonth": { 
                                    dayHeaderContent: ({date}) => {
                                        const cutomeday = date.getDay()
                                        const weekdays = ['SUN', 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT']
                                        return {
                                            html: `<div class="custom_calendarhead">${weekdays[cutomeday]}</div>`
                                        }
                                    },
                                    moreLinkContent:function(args){
                                        return args.num+' Tasks more';
                                    },
                                },
                            }}
                            slotLabelFormat={{
                                hour: 'numeric',
                                minute: '2-digit',            
                            }}
                            allDaySlot={false}
                            initialView='dayGridMonth'                           
                            events= {(function(info, successCallback, failureCallback) {
                                let startDate   = info.startStr.replace('T',' ').replace('+','.+')
                                let endDate     = info.endStr.replace('T',' ').replace('+','.+') 
                                let token = localStorage.getItem('session_token');
                                let param = { start_date: startDate, end_date: endDate,board_id:this.project_id };
                                let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
                                this.props.loader({loader:true})
                                axios.get(
                                    APIs.BASE_URL + APIs.GET_TASK_CALENDAR_API, config
                                ).then((response) => {
                                    this.props.loader({loader:false})
                                    let taskData = response.data.data
                                    let allTaskData = []
                                    taskData.map((data,index)=>{
                                        if (data.end < 100){
                                            let numDate = new Date(data.start);
                                            numDate.setHours(23,59,59)
                                            data.end = numDate
                                        }
                                        allTaskData.push(data)

                                        return true

                                    })
                                    successCallback(allTaskData)
                                })
                            }).bind(this)}
                            eventMaxStack={1}
                            showNonCurrentDates ={false}  
                            fixedWeekCount={false} 
                            firstDay={1}
                            editable={false}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={true}
                            select={this.handleDateSelect}
                            eventClick={this.handleEventClick}
                            // eventContent={renderEventContent} // custom render function
                            // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                            //  you can update a remote database when these fire:
                            // eventAdd={function(){}}
                            // eventChange={function(){}}
                            // eventRemove={function(){}}
                            // 
                        />
                    </Col>
                    {this.state.showConfirmRemoveTaskModal ? this.confirmRemoveTaskModal() : <></>}
                    { this.state.showAddTaskModal &&
                        <AddTaskModal
                            modalSize="lg"
                            contentClassName="add_new_member_form add_task"
                            show={this.state.showAddTaskModal}
                            onTaskAdd={(data) => this.onTaskAdd(data)}
                            taskModalType={this.state.taskModalType}
                            hideAddEditTaskModal={() => this.hideAddEditTaskModal()}
                            modalData={this.state.modalData}
                            assigneeOption={this.state.assigneeOption}
                            onDeleteTask={(data) => this.deleteTaskModal(data)}
                        />
                    }
                </Row>
            </LoggedInLayout>
        );
    }
}

//export default Calendar;

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id
});

const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(Calendar);