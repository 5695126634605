import React, { Component } from 'react';
import BeforeLoginLayout from './BeforeLoginLayout';
import APIs from '../../utils/APIs';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Looger from '../../utils/Logger';

function TeamInviteAction(props) {
	let { token, action} = useParams();

	return <TeamInviteActionClass token={token} action={action}/>
}

class TeamInviteActionClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
			token: props.token,
			action: props.action,
			message : "",
			messageType: "",
			axiosSource 	 : 	 axios.CancelToken.source(),
        }
    }

	componentDidMount() {
		//TEAM_INVITE_MEMBER_URL_ACTION_API
		let data = {
			token : this.state.token,
			action: this.state.action
		}
		axios.patch(
			APIs.BASE_URL + APIs.TEAM_INVITE_MEMBER_URL_ACTION_API,
			data, {cancelToken : this.state.axiosSource.token}
		).then((response) => {
			let message = "You have successfully accepted the team request.";
			let messageType = "success";
			if (response.data.status_code === 200) {
				if(data.action === "decline") {
					messageType  = 'decline'
					message = "You have successfully declined the team request.";
				}
			} else {
				if(response.data.message.trim().toLowerCase() === "team invite link has been expired") {
					response.data.message = "The team invite link is expired.";
				}
				message = response.data.message;
				messageType="danger"
			}
			this.setState({
				message : message,
				messageType: messageType
			});
		}).catch((error) => {
			// Capture Error Logs
			Looger(error)
		})
	}

	componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
            <BeforeLoginLayout
				footerQues={""}
				footerLinkText={"Sign in to your account"}
				footerLink={"/"}
				formTagLine={
					<div className={'d-flex justify-content-center align-items-center '+this.state.messageType} 
					style={{
							minHeight:150, 
							color:"#cd666c", 
							fontSize: 20,
							lineHeight: 1.2,
					}}
					>
					{this.state.message}
					</div>
				}
				formHeaderTagLine={"Team invite request"}
				seoData={{
					"title" : "Team invite request response - Orrderly",
					"meta": [
						{"name":"description","content":"Accept or decline team invite request."}
					]
				}}
			>
			</BeforeLoginLayout>
        );
    }
}

export default TeamInviteAction;