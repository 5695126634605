import Cropper from 'react-easy-crop'
import React, { Component } from 'react';
import { Button, Col, Row,Spinner } from 'react-bootstrap';
import PopUpModal from '../../Common/Modals/PopUpModal';
import axios from 'axios';
import APIs from '../../../../../utils/APIs';
import Looger from '../../../../../utils/Logger';



class ImageCropper extends Component {
    canvas = {};
    constructor(props) {
        super(props);
        this.state = {
            crop: { x: 0, y: 0 },
            zoom: 2,
            axiosSource : axios.CancelToken.source(),
            croppedAreaPixels : {},
            loader:false
        };
    }

    handleHideModal = (function() {
        try{
            this.props.onChange()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    onCropChange = (crop) => {
        try{
            this.setState({crop})
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
      }
    onCropComplete = (function (croppedArea, croppedAreaPixels) {
        try{
            this.setState({
                croppedAreaPixels : croppedAreaPixels
            }, () => {this.drawImage()})
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    drawImage = (function() {
        try{
            const croppedAreaPixels = this.state.croppedAreaPixels;
            this.ctx.drawImage(this.image, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, croppedAreaPixels.width, croppedAreaPixels.height);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    onZoomChange = (e) => {
        try{
            this.setState({ zoom : e.target.value})
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    imageType = (type) =>{
        try{
            if (type === 'cover_image'){
                return true
            } else {
                return false
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    uploadImage = (function (file) {
        try{
            let imageFor = this.props.imagetype
            if (file === undefined) {
                return false;
            }
            let formData = new FormData();
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            formData.append('image_for', imageFor);
            formData.append('image', this.canvas.toDataURL());
            axios.patch(
                APIs.BASE_URL + APIs.PROFILE_PICTURE_API,
                formData,
                config
            ).then((response) => {
                let userInfo = response.data.data;
                if(userInfo.dob !== null) {
                    userInfo.dob = new Date(userInfo.dob);
                }
                this.setState({
                    userInfo: userInfo,
                    showAlert: true,
                    loader:false,
                    showAlertMessage: (imageFor === "profile_image" ? "Profile" : "Cover") + " Image updated successfully",
                    showAlertType: "success",
                })
                
                localStorage.setItem('user', JSON.stringify(this.state.userInfo))
                if(imageFor === "profile_image") {
                    document.getElementsByClassName('border border-1 border-secondary rounded-circle')[0].src=userInfo.profile_image;
                    document.getElementById("user-name-top-bar").textContent = userInfo.first_name;
                    if(userInfo.first_name.trim() === "") {
                        document.getElementById("user-name-top-bar").textContent = userInfo.email;
                    }
                }
                this.props.actionComplete({
                    userInfo: userInfo,
                    showAlert: true,
                    showAlertMessage: (imageFor === "profile_image" ? "Profile" : "Cover") + " Image updated successfully",
                    showAlertType: "success",
                })
            }).catch((error) => {
                //document.getElementById('profile-image-form').reset()
                //document.getElementById('cover-image-form').reset()
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }

    }).bind(this);

    cropperAction = (e,action_type)=>{
        try{
            e.preventDefault();
            if(action_type === 'cancel'){
                this.props.hideCropper()
            } else {
                this.setState({loader:true})
                this.uploadImage(this.props.data)
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }
    
    imageCropperForm = (function(){
        try{
            let typecheck = this.imageType(this.props.imagetype)
            return (
                <Row style={{minHeight:250}}>
                    <Col md={{ span:12 }}>
                        <div className="my-canvas d-none">
                            <img 
                                ref={imageRef => this.image = imageRef}
                                id="source"
                                style={{display: 'none'}}
                                src={this.props.data} 
                                alt={this.props.imagetype} />
                            <canvas
                                ref={canvas => {
                                    this.canvas = canvas;
                                    canvas && (this.ctx = canvas.getContext('2d'))
                                }}
                                width={this.state.croppedAreaPixels.width}
                                height={this.state.croppedAreaPixels.height}
                            />
                        </div>
                        <div className="crop-container">
                            <Cropper
                                image={this.props.data}
                                crop={this.state.crop}
                                aspect={ typecheck ? 227 / 39:1}
                                zoomWithScroll={false}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                objectFit="horizontal-cover"
                                restrictPosition={true}
                                style={{
                                containerStyle: { width: '100%', height: 190 },
                                cropAreaStyle: { width: '100%', height: 190 },
                                }}
                                zoom={this.state.zoom}
                                showGrid={false}
                                onZoomChange={this.onZoomChange}
                            />
                        </div>
                        <div className="controls"> 
                                <input type="range" onChange={this.onZoomChange} value={this.state.zoom} min={1} max={3} step={0.1} style={{width: "100%"}}/>
                        </div>
                        <Col md={{ span:12 }} className="btn-container">
                            <Button onClick={(e) => {this.cropperAction(e,'cancel')}} className='cropper-action cancel'>Cancel</Button>
                            <Button onClick={(e) => {this.cropperAction(e,'crop')}} className='cropper-action crop'>
                                
                            {  this.state.loader ? <Spinner size="sm" animation="border" variant="light" /> 
                                :"Crop & Save"
                            }   
                            </Button>
                        </Col>
                    </Col>
                </Row>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
        
    render() {
        let typecheck = this.imageType(this.props.imagetype)
        return (
            <PopUpModal contentClassName="add_new_member_form" show = {this.props.show} onChange={()=> {this.props.hideCropper()}} modalSize={typecheck ? "xl" : "md"} >
                {this.imageCropperForm()}
            </PopUpModal>
        )
    }
}

export default ImageCropper;