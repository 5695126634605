import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import LoggedInLayout from '../LoggedInLayout';
import DeleteAccount from './SettingsPages/DeleteAccount';
import NotificationsSettings from './SettingsPages/Notifications';
import PlansSettings from './SettingsPages/PlansSettings';
import ProfileSetting from './SettingsPages/ProfileSetting';
import Looger from '../../../../utils/Logger';

function Settings(props) {
    let { selectedMenu } = useParams();
    if (selectedMenu === undefined) {
        selectedMenu = "profile";
    }
    return <SettingsClass selectedMenu={selectedMenu} />
}

class SettingsClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: props.selectedMenu
        }
    }

    buttonClicked = (function (menuName, e) {
        try{
            this.setState({
                activeMenu: menuName
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);
    
    render() {
        return (
            <>
                <LoggedInLayout className="mt-0">
                    <Row>
                        <Col md={{ span: 2 }} className="profile_setting_tab_sec p-0">
                            <ul className="navbar-nav">
                                <h4>Settings</h4>
                                <li className="nav-item">
                                    <NavLink
                                        to="/settings/profile"
                                        className={"nav-link " + (this.state.activeMenu === "profile" ? " active" : "")}
                                        onClick={(e) => { this.buttonClicked('profile', e) }}
                                    >Profile</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/settings/notification"
                                        className={"nav-link " + (this.state.activeMenu === "notification" ? " active" : "")}
                                        onClick={(e) => { this.buttonClicked('notification', e) }}
                                    >Notifications</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/settings/plans"
                                        className={"nav-link " + (this.state.activeMenu === "plans" ? " active" : "")}
                                        onClick={(e) => { this.buttonClicked('plans', e) }}
                                    >Plans and billings</NavLink>
                                </li>
                                <li className="nav-item delete-account">
                                    <NavLink
                                        to="/settings/delete-account"
                                        className={"nav-link " + (this.state.activeMenu === "delete-account" ? " active" : "")}
                                        onClick={(e) => { this.buttonClicked('delete-account', e) }}
                                    >Delete account</NavLink>
                                </li>
                            </ul>
                        </Col>
                        <Col md={{span:10}}>
                            {
                                this.state.activeMenu === "profile" ? <ProfileSetting /> : <></>
                            }
                            {
                                this.state.activeMenu === "notification" ? <NotificationsSettings /> : <></>
                            }
                            {
                                this.state.activeMenu === "plans" ? <PlansSettings /> : <></>
                            }
                            {
                                this.state.activeMenu === "delete-account" ? <DeleteAccount onClick={(data, e) => { this.buttonClicked(data, e)}}/> : <></>
                            }
                        </Col>
                    </Row>
                </LoggedInLayout>
            </>
        );
    }
}

export default Settings;