import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import {Col } from 'react-bootstrap';
import ProjectList from '..//ProjectBoard/ProjectList'
import MainLogoLight from '../../../../logo.png';
import MainLogoDark from '../../../../logo-white.png';
import ReactTooltip from "react-tooltip";
import calendar from '../../../../static/images/logged_in/calendar.png';
import team from '../../../../static/images/logged_in/team.png';
import today from '../../../../static/images/logged_in/today.png';
import overdue from '../../../../static/images/logged_in/overdue.png';
import calendar_active from '../../../../static/images/logged_in/calendar_blue.png';
import team_active from '../../../../static/images/logged_in/team_blue.png';
import today_active from '../../../../static/images/logged_in/today_blue.png';
import overdue_active from '../../../../static/images/logged_in/overdue_blue.png';
import ReactTimeAgo from 'react-time-ago';

class Sidebar extends Component {
    render() {
        let planData = JSON.parse(atob(localStorage.getItem('plan_info') || 'e30=' ))
        let showPopUp = false
        let planExpireDate = new Date().getTime()
        if (Object.keys(planData).length > 0){
            planExpireDate = planData.expire_date*1000
            showPopUp = planData.subscription_plan_id === 30 && planData.plan_side==='admin_trial' ?true:false
        }
        
        return (
            <Col md={{ span: 2}} className="left_side offcanvas offcanvas-start" id="sidebar_side">
                <NavLink to="/dashboard" className="logo_sec">
                    <img src={MainLogoLight} alt="logo-dark" className="dark" />
                    <img src={MainLogoDark} alt="logo-light" className="light" />
                </NavLink>
                <ul className="navbar-nav mt-0 board_menu pb-0">
                    <li className="nav-item">
                        <ProjectList/>
                    </li>
                </ul>
                <ul className="navbar-nav">
					<li className="nav-item">
						<NavLink className="nav-link" to="/dashboard">
                            <img src={today} className="gray" alt="today menu inactive"/>
                            <img src={today_active} className="blue" alt="today menu active" />
                            Today
                        </NavLink>
					</li>
					<li className="nav-item calendar_m_view">
						<NavLink className="nav-link" to="/calendar">
                            <img src={calendar} className="gray" alt="calendar menu inactive" />
                            <img src={calendar_active} className="blue" alt="calendar menu active" />
                            Calendar
                        </NavLink>
					</li>
					{/*<li className="nav-item">
						<NavLink className="nav-link" to="/archive">
                            <img src={archive} className="gray" alt="archive tasks menu inactive" />
                            <img src={archive_active} className="blue" alt="archive tasks menu active" />
                            Archive
                        </NavLink>
					</li>*/}
                    <li className="nav-item">
						<NavLink className="nav-link" to="/board-team">
                            <img src={team} className="gray" alt="team menu inactive" />
                            <img src={team_active} className="blue" alt="team menu inactive" />
                            Team
                        </NavLink>
					</li>
					<li className="nav-item">
						<NavLink className="nav-link" to="/overdue">
                            <img src={overdue} className="gray" alt="overdue tasks menu inactive" />
                            <img src={overdue_active} className="blue" alt="overdue tasks menu active" />
                            My Tasks
                        </NavLink>
					</li>
				</ul>
                <ul  className="mt-3 navbar-nav">
                    <li className="nav-item">
						<NavLink className="nav-link" to="/team">
                            <img src={team} className="gray" alt="team menu inactive" />
                            <img src={team_active} className="blue" alt="team menu inactive" />
                            All Team
                        </NavLink>
					</li>
                    <li className="nav-item">
						<NavLink className="nav-link" to="/manage-board">
                            <span className="material-symbols-outlined sidebar-icon">dashboard</span>
                            <span className="sidebar_title">My Project</span>
                        </NavLink>
					</li>
                </ul>
                { showPopUp &&
                    <ul  className="mt-3 navbar-nav">
                        <li className="nav-item">
                            <ReactTooltip id="registerTip" place="right" effect="solid">
                                <span>Your trial plan will expire </span>
                                { planExpireDate &&
                                    <ReactTimeAgo future date={planExpireDate} locale="en"/>
                                }
                            </ReactTooltip>
                            <NavLink className="nav-link d-flex notifications" to="/upgrade-plan">
                                <span className='planex'>Upgrade Plan</span>
                                <span  style={{color:"#dc6562",cursor:"pointer"}} data-tip data-for="registerTip" className="material-symbols-outlined notifications_badge">notification_important</span>
                            </NavLink>
                        </li>
                    </ul>
                    
                }
            </Col>
        );
    }
}

export default Sidebar;