import { Tab } from 'bootstrap';
import React, { Component } from 'react';
import { Col, Row, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import LoggedInLayout from '../LoggedInLayout';
import GetPlans from './SettingsPages/GetPlans';
import { connect } from "react-redux";
import Looger from '../../../../utils/Logger';
import {loader} from '../../../../reducers/CurrentProjectBoard'

class Upgrade extends Component {
    constructor(props){
        super(props)
        this.state = {
            key:new Date().getTime()
        }
    }

    setKey = (function(){
        try{
            this.setState({
                key:new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    componentDidMount(){
        this.props.loader({loader:false})
    }
    render() {
        return (
            <>
                <LoggedInLayout>
                    <Helmet>
                        <title>Upgrade Account - Orrderly</title>
                    </Helmet>
                    <Row className="task">
                        <Col md={{span:2}} xs={{span:12}} sm={{span:12}}  className="left_col">
                            <h2 className="head">Upgrade plan</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{span:12}} xs={{span:12}} sm={{span:12}}>
                            <Row className="mt-4">
                                <Tabs
                                    defaultActiveKey="month"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="justify-content-center plan_type_btn_container"
                                    onSelect={(k) => this.setKey(k)}
                                >
                                    <Tab eventKey="month" title={<>Monthly</>}>
                                        <GetPlans s key={this.state.key} type="month" />
                                    </Tab>
                                    <Tab eventKey="year" title={<>Yearly</>}>
                                        <GetPlans key={this.state.key} type="year" />
                                    </Tab>
                                </Tabs>
                            </Row>
                        </Col>
                    </Row>
                </LoggedInLayout>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loader: state.CurrentProjectBoard.loader,
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(Upgrade);