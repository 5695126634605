import React, { Component } from 'react';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import Scrollbars from 'react-custom-scrollbars-2';
import Labels from '../../../../utils/Labels';
import axios from 'axios';
import APIs from '../../../../utils/APIs';
import validator from "validator";
import Looger from '../../../../utils/Logger';


class AddTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: props.oldTags,
            newTagText:"",
            tagsColorClasses: Labels.TAGS_COLOR_OPTIONS,
            colorClass:"Independence",
            tags_text : "",
            tagNameError:"",
            axiosSource : axios.CancelToken.source(),
        };
    }

    handleHideModal = (function() {
        try{
            this.props.hideTagsModal(this.state.tags);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    setDropdownValue = (function (e) {
        try{
            let tagsColorClasses = this.state.tagsColorClasses.map((data) => {
                data.isSelected = data.labelKey === e.selectedKey[0] ? true : false;
                if (data.isSelected) {
                    this.setState({ colorClass: data.labelKey });
                }
                return data;
            });
            this.setState({ tagsColorClasses: tagsColorClasses });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    updateTagsState = (function () {
        try{
            let data = {
                tag_class: this.state.colorClass,
                tag_name: this.state.tags_text
            };
            let previouslyAdded = false;
            this.state.tags.map((tagsData) => {
                if(tagsData.tag_name === this.state.tags_text) {
                    previouslyAdded = true;
                }
                return false;
            })
            if(this.state.tags_text.trim() !== "" && !previouslyAdded) {
                let previousTags = this.state.tags;
                previousTags.push(data);
                this.setState({
                    tags: previousTags,
                    tags_text : ""
                });
            } else {
                this.setState({
                    tags_text : ""
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    /* Delete Tags */
    deletetags = (function(index) {
        try{
            let task_id = this.props.taskid
            let taskTags = this.state.tags;
            taskTags.splice(index,1)
            this.setState({ tags: taskTags})
            if (task_id !== ''){
                let params = {
                    id:  task_id,
                    tags: taskTags,
                }
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };

                axios.patch(
                APIs.BASE_URL +  APIs.DELETE_TASK_TAGS,
                params,
                config
                ).then((response) => {
                    if (response.data.status_code === 200){
                        this.setState({ tags: taskTags})
                    }
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    taskTagHandler = (function(e){
        try{
            let tagName = e.target.value 
            let tagNameError = ""
            if (validator.isLength(tagName,20)) {
                tagNameError = "Tag name must be less then 20 character.";
            } 
            this.setState({ 
                tags_text: e.target.value,
                tagNameError:tagNameError
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={() => { this.handleHideModal() }}
                backdrop="static"
                keyboard={true}
                contentClassName={this.props.contentClassName}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="tags-select">{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-1 pb-3 col-md-12">
                    <Scrollbars style={{ height: 135 }} className="boardWrap">
                        {
                            this.state.tags.map((data, index) => {
                                return (
                                    <span key={"tag-key-"+index} className={'tags_sec '+data.tag_class}>#{data.tag_name}
                                    <span onClick={() =>this.deletetags(index) } className="material-symbols-outlined tag-icon">cancel</span>
                                    </span>
                                )
                            })
                        }
                    </Scrollbars>
                    <Row className="task_tags">
                        <Col md={{span:8}} xs={{span:8}} sm={{span:8}}>
                            <input type="text" placeholder="Tag name" value={this.state.tags_text} name="tags_text" className="tags-input-field" onChange={(e) => this.taskTagHandler(e)}/>
                        </Col>
                        <Col md={{span:2}} xs={{span:2}} sm={{span:2}}>
                            <BootstrapSelect
                                className="tags col-md-12 col-sm-12 col-12"
                                key={"add-tags-dropdown"}
                                options={this.state.tagsColorClasses}
                                onChange={(e) => this.setDropdownValue(e, "task_assignee")}

                            />
                        </Col>
                        <Col md={{span:2}} xs={{span:2}} sm={{span:2}} className="add_tags">
                            <button disabled={!(this.state.tagNameError === '')} onClick={() => this.updateTagsState()} className="btn" style={{ padding: "3px 17px" }}>Add</button>
                        </Col>
                        { this.state.tagNameError === '' ? <></>
                            :
                            <Form.Control.Feedback type="invalid" className="errorMessage">
                            <i className="material-icons">warning</i>{this.state.tagNameError}
                            </Form.Control.Feedback>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AddTags;