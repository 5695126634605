import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../../static/images/logged_in/close.png';

class PopUpModalAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleHideModal = (function () {
        this.props.onChange()
    }).bind(this)

    render() {
        let primaryKeyExist = this.props.contentClassName.indexOf('primary');
        
        return (
            <Modal
                show={this.props.show}
                onHide={() => this.handleHideModal()}
                backdrop="static"
                keyboard={true}
                contentClassName={this.props.contentClassName}
            >
                <Modal.Body>
                    <img src={close} className="close" alt="close button" onClick={()=>this.handleHideModal()} />
                    <div className="d-flex">
                        <div className="success">
                            <i className="material-icons">{primaryKeyExist >= 0 ? "check" : "close"}</i>
                        </div>
                        <div>
                            <h3>{this.props.title}</h3>
                            <h5>{this.props.message}</h5>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default PopUpModalAlert;