import React, { Component } from 'react';

class TermsAndConditions extends Component {
    render() {
        return (
            <div>
                TermsAndConditions
            </div>
        );
    }
}

export default TermsAndConditions;