import React, { Component } from 'react';
import Sidebar from './Common/Sidebar';
import Topbar from './Common/Topbar';
import { Container, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import '../../../static/css/LoggedIn.css';
import axios from 'axios';
import APIs from '../../../utils/APIs';
import CompleteProfileSteps from './Common/Modals/CompleteProfileSteps'
import { connect } from "react-redux";
import { update_current_project_board_id,fetch_all_project_list,global_search_query } from '../../../reducers/CurrentProjectBoard'
import ConfirmAccount from './Common/Modals/CompleteProfileSteps/ConfirmAccount';
import WorkOptionType from './Common/Modals/CompleteProfileSteps/OptionType'
import GlobalSearch from './GlobalSearch';
import TrailPackModal from './Common/Modals/TrailPackModal';
import Looger  from '../../../utils/Logger';
import LoaderOverlay from './Common/LoaderOverlay';


class LoggedInLayout extends Component {
    constructor(props) {
        super(props);
        this.ws = null
        this.state = {
            isLogin : true,
            isPlanPurchased:false,
            isAccountConfirmed:false,
            isFilledInfo:false,
            axiosSource : axios.CancelToken.source()
        };
    }

    refreshTokenHandler = (function(companyName){
        try{
            if (companyName !== undefined) {
                localStorage.setItem('team_name',companyName)
            }
            let user = localStorage.getItem('session_token');
            let userData = localStorage.getItem('user');
            axios.post(
                APIs.BASE_URL + APIs.REFRESH_TOKEN_API,
                { refresh: localStorage.getItem('refresh_token') },
                { headers: { "Authorization": `Bearer ${user}` }, cancelToken : this.state.axiosSource.token }
            ).then((response) => {
                Cookies.set(btoa('user-data'), btoa(userData), { expires : 365, secure : true, domain : APIs.DOMAIN })
                let data = response.data;
                localStorage.setItem("session_token", data.data.token.access);
                localStorage.setItem("refresh_token", data.data.token.refresh);
                let isPlanPurchased = false
                if(data.data.plan_info) {
                    isPlanPurchased = true
                    localStorage.setItem('plan_info', btoa(JSON.stringify( data.data.plan_info)))
                }
                localStorage.setItem('confirm',parseInt(data.data.active))
                localStorage.setItem('account_info',data.data.filled)
                this.setState({ 
                    isLogin: true,
                    isPlanPurchased:isPlanPurchased,
                });
            }).catch((error) => {
                if(error.message === "Landing Component got unmounted") {
                    //
                } else {
                    Cookies.remove(btoa('user-data'))
                    localStorage.removeItem("session_token");
                    localStorage.removeItem("refresh_token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("plan_info");
                    window.location.href = "/";
                    this.setState({ isLogin: false });
                }
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount() {
        let user = localStorage.getItem('session_token');
        let userData = localStorage.getItem('user');
        let cookie = Cookies.get(btoa('user-data'))
        if(!cookie && APIs.ENV !== "LOCAL") {
            localStorage.removeItem("session_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user");
            localStorage.removeItem("plan_info");
            window.location.href = "/";
            this.setState({ isLogin: false });
        } else if (user !== "" && user !== null) {
            this.refreshTokenHandler()

        }

        if (this.ws === null){
            this.ws = new WebSocket(APIs.WS_URL)
        }
        this.ws.onopen = () => {
            this.ws.send(JSON.stringify({"user_id":JSON.parse(userData).id}))
        }
        this.ws.onmessage = evt => {
            let projectList = JSON.parse(evt.data)
            let isArray = Array.isArray(projectList)
            if (isArray){
                let activeProjectInfo = projectList.filter(CheckActiveProject)
                function CheckActiveProject(data){if (data.is_current === 1){return data}}

                let project_id     =    activeProjectInfo[0].project_id 
                let project_name   =    activeProjectInfo[0].name 
                
                this.props.fetch_all_project_list({allProjectList: projectList})
                this.props.update_current_project_board_id({project_id:project_id,project_name:project_name})

                localStorage.setItem('allProjectList',JSON.stringify(projectList))
                localStorage.setItem('project_id',project_id)
                localStorage.setItem('project_name',project_name)
            }
        }
        
    }

    componentWillUnmount() {
        this.props.global_search_query({searchQuery:""})
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
        this.ws.close()

    }


    render() {
        let isAccountConfirmed  = localStorage.getItem('confirm') > 0 ? true:false
        // let isAccountInfoFilled = localStorage.getItem('account_info').toString() === 'false'?false:true
        let isAccountInfoFilled = localStorage.getItem('account_info') || false
        isAccountInfoFilled     =  isAccountInfoFilled.toString() === 'false' ? false : true;
        let isPlanPurchased     = localStorage.getItem('plan_info')?true:false

        let planData = JSON.parse(atob(localStorage.getItem('plan_info') || 'e30=' ))
        let showPopUp = false
        if (Object.keys(planData).length > 0){
            let planExpireDate = planData.expire_date*1000
            let NowDatetime = new Date().getTime()
            showPopUp = planData.subscription_plan_id === 30 && planData.plan_side==='admin_trial' && NowDatetime>planExpireDate ?true:false
        }

        return (
            <>
            {
                !this.state.isLogin ? <Navigate to="/" /> : 
                <>
                    <LoaderOverlay/>
                    <Container fluid>
                        {
                            !isAccountConfirmed ? <ConfirmAccount callRefreshToken={(e)=>this.refreshTokenHandler(e)}/> : !isAccountInfoFilled?<WorkOptionType callRefreshToken={(e)=>this.refreshTokenHandler(e)}/>:!isPlanPurchased?<CompleteProfileSteps/>:<></>
                        }
                        <Row>
                            <Sidebar />
                            <Topbar className={this.props.className}>
                                {
                                    this.props.searchQuery ?
                                    
                                        <GlobalSearch searchQuery={this.props.searchQuery} />
                                    :
                                        <>
                                            {this.props.children}
                                        </>
                                }
                            </Topbar>
                        </Row>
                        <TrailPackModal showPopUp={showPopUp} planData={planData} />
                    </Container>
                </>
            }
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    allPojectlList:state.CurrentProjectBoard.all_project_list,
    searchQuery:state.CurrentProjectBoard.searchQuery
  });
  
const mapDispatchToProps = {fetch_all_project_list,update_current_project_board_id,global_search_query};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInLayout);