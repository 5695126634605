import React, { Component } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import Looger from '../../../../../utils/Logger';
import ReactTooltip from "react-tooltip";


class UpgradePlanStep1 extends Component {
    constructor(props){
        super(props);
        this.state = {
            data:this.props.data,
            planQuantity:this.props.data.planQuantity,
            previousQuantity:this.props.data.previousQuantity,
            planPrice:this.props.data.userSelectedPlan.plan_price.replace(",", ""),
            price:this.props.data.previousQuantity,
            currency:'$',
            buttonUnitPrice:this.props.data.planQuantity,
            maximumMemberLimit:this.props.data.maximumMemberLimit
        }
    }

    componentDidMount(){
        let planPrice   = this.state.planPrice
        let price       =   planPrice.replace(/[^0-9.-]+/g,"");
        let currency    =   planPrice.replace(price, "");
        this.setState({
            price:price,
            currency:currency
        })
    }

    decrementPlanQty = ((e) => {
        try{
            let previousQty     = this.state.planQuantity
            let teamQuantity    = this.state.previousQuantity
            if (e.target.value-1 > teamQuantity-1){
                previousQty = (previousQty-1 >= 1) ? (previousQty-1) : 1
            }
            this.setState({
                planQuantity    :   previousQty,
                InputKey        :   new Date().getTime(),
                buttonUnitPrice :   previousQty
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    incrementPlanQty = ((e) => {
        try{
            let maximumMemberLimit = this.state.maximumMemberLimit
            let previousQty = this.state.planQuantity
            let limit = (previousQty+1)
            if (previousQty === maximumMemberLimit){
                limit = maximumMemberLimit
            }
            this.setState({
                planQuantity    : limit,
                InputKey        : new Date().getTime(),
                buttonUnitPrice : limit
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    updatePlanQuantity = ((e) => {
        try{
            let planQuantity = 0
            if(!isNaN(e.target.value)) {
                planQuantity =parseInt(e.target.value);
            }
            this.setState({
                planQuantity    : planQuantity,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render() {
        return (
            <>
            <Row>
                <Col md={{span:6}} className="mt-2 mb-2">
                    <div className='plan-step-1-0'>
                        <div className="plan-step-inner-1-0" onClick={()=>{this.props.onChange({stepType:'back',planQuantity:this.state.planQuantity})}}>
                            <span className="material-symbols-outlined">keyboard_backspace</span>
                            <span className='plan-step'>Payment details</span>
                        </div>  
                    </div>
                    <div className='plan-step-1-0 mt-1'>
                        <div className="plan-step-inner-1-0">
                            <span className='plan-step-guide'>Give us further information on the other users you’re paying for</span>
                        </div>  
                    </div>
                    <div className='plan-step-1-1 mt-4 plan-tab-content'>
                        <div className="plan-step-inner-1-1">
                            <span className='plan-step'>Plan selected</span>
                        </div>
                        <Col md={{span:12}} className="clicked mt-4 plan_div">
                            <div className="columns">
                                <div className="price">
                                    <Row>
                                        <Col md={{span:1}}  xs={{span:1}} sm={{span:1}}></Col>
                                        <Col md={{span:9}}  xs={{span:9}} sm={{span:9}} className={"activated"}>
                                            <div className='plan_content'>
                                                <div className="header plan-name">{this.state.data.userSelectedPlan.plan_name}</div>
                                                <div className="grey plan-price">{(this.state.data.userSelectedPlan.plan_price === 0 ? `1 ${this.state.data.userSelectedPlan.plan_type} - 0` : `1 ${this.state.data.userSelectedPlan.plan_type} - `+this.state.data.userSelectedPlan.plan_price)}</div>
                                                        <div className="plan-description">{this.state.data.userSelectedPlan.description}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>                                    
                            </div>
                        </Col>
                    </div>
                    <div className='plan-step-1-2 mt-4'>
                        <div className="plan-step-inner-1-2">
                            <span className='plan-step'>Number of users</span>
                        </div>
                        <Col md={{span:12}} className='mt-2'>
                            <Row>
                                <Col md={3}  xs={{span:5}} sm={{span:5}}>
                                    <InputGroup className="mb-3">
                                        <Button variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.decrementPlanQty(e)}} value={this.state.planQuantity} key={"decrease-plan-quantity"}>-</Button>
                                        <FormControl type="number"className="no-border-text-box" onChange={(e) => {this.updatePlanQuantity(e)}} readOnly key={this.state.InputKey} defaultValue={this.state.planQuantity}  />
                                        <Button variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.incrementPlanQty(e)}} value={this.state.planQuantity} key={"increase-plan-quantity"}>+</Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>  
                    </div>
                    <div className='plan-step-1-3 mt-4'>
                        <ReactTooltip id="registerTip" place="right" effect="solid">
                            Paddle manage payment our side
                        </ReactTooltip>
                        <div className="plan-step-inner-1-3">
                            <span className='plan-step'>Total payment <sup  style={{color:"red",cursor:"pointer"}} data-tip data-for="registerTip">*</sup></span>
                        </div>  
                    </div>
                    <div className='plan-step-1-4 mt-2'>
                        <div className="plan-step-inner-1-4">
                            <span className='plan-step price'>{this.state.currency}{(this.state.buttonUnitPrice*this.state.price).toFixed(2)}</span>
                        </div>  
                    </div>
                    <div className='plan-step-1-4 mt-4'>
                        <div className="plan-step-inner-1-4">
                            <button variant="dark" onClick={()=>{this.props.onChange({stepType:'next',planQuantity:this.state.planQuantity})}} className="btn dlt-btn next-step-btn">Continue</button>
                        </div>  
                    </div>
                </Col>
            </Row>
            </>
        )
    }
}

export default UpgradePlanStep1;