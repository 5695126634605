import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import PlanSelectionStep1 from './PlanSelectionStep1'
import PlanSelectionStep2 from './PlanSelectionStep2'
import PlanSelectionStep3 from './PlanSelectionStep3'
import Looger from '../../../../../../utils/Logger'

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal : true,
            planQuantity : 1,
            unitPrice : 0,
            isPlanSelected : false,
            currency : "$",
            planID : null,
            planInfo : {},
            planStep : 1,
        }
    }

    userPlanSelect = ((data) => {
        try{
            this.setState({
                planQuantity : data.planQuantity,
                unitPrice : data.unitPrice,
                isPlanSelected : data.isPlanSelected,
                currency : data.currency,
                planID : data.planID,
                planInfo : data.planInfo,
                planStep : data.planStep,
                maximumMemberLimit:data.maximumMemberLimit
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    resetPlanSelection = ((data = null) => {
        try{
            if(data) {
                this.userPlanSelect(data)
            } else {
                this.setState({
                    planQuantity : 1,
                    unitPrice : 0,
                    isPlanSelected : false,
                    currency : "$",
                    planID : null,
                    planInfo : {},
                    planStep : 1
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render() {
        return (
            <div>
                <Modal show={this.state.showModal} size="md" backdrop="static" className='profile-completion-steps'>
                    <Modal.Body className="show-grid">
                        {(this.state.planStep === 1) ? <PlanSelectionStep1 modalTitle="Choose your plan" onPlanSelection={(data) => this.userPlanSelect(data)}/> : <></>}
                        {(this.state.planStep === 2) ? <PlanSelectionStep2 resetPlanSelection={() => this.resetPlanSelection()} onPlanSelection={(data) => this.userPlanSelect(data)} data={this.state} /> : <></>}
                        {(this.state.planStep === 3) ? <PlanSelectionStep3  plantypes={'new'} resetPlanSelection={(data) => this.resetPlanSelection(data)} onPlanSelection={(data) => this.userPlanSelect(data)} data={this.state} /> : <></>}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
