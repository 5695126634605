import React, { Component } from 'react';
import { Alert, Button, Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import APIs from '../../../../../utils/APIs';
import Labels from '../../../../../utils/Labels';
import Helmet from 'react-helmet';
import UploadDropZone from '../Cropper/Options';
import validator from 'validator';
import { connect } from "react-redux";
import Looger from '../../../../../utils/Logger';
import { loader } from '../../../../../reducers/CurrentProjectBoard';

const BirthDateCompoment = React.forwardRef(({ value, onClick }, ref) => (
    <div className="input-group">
        <input type="text" className="form-control shadow-none" name="dob" placeholder="Your birthday" id="dob" value={value === "" ? "Your birthday" : value} autoComplete="off" onClick={onClick} ref={ref} readOnly={true} />
    </div>
));

const DateOfBirthContainer = ({ className, children }) => {
    return (
      <div className='calendar-my-custom-container'>
        <CalendarContainer className={className}>
            <div className='calendar-label'>Birthday</div>
            <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
};

class ProfileSetting extends Component {
    constructor(props) {
        super(props);
        let oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 13);
        this.state = {
            showLoading: false,
            showAlert: false,
            showAlertMessage: "",
            showAlertType: "",
            userInfo: {},
            userDate:JSON.parse(localStorage.getItem('user')),
            showCancel:false,
            maxDate : oneYearFromNow,
            isShowUploadModel:false,
            imageType:'' ,        
            firstNameError:'',
            axiosSource : axios.CancelToken.source(),
        };
    }

    loadProfileData = (function () {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.PROFILE_API,
                config
            ).then((response) => {
                let userInfo = response.data.data;
                if(userInfo.dob !== null) {
                    userInfo.dob = new Date(userInfo.dob);
                }
                this.setState({
                    userInfo: userInfo,
                    showCancel:false
                })
            }).catch((error) => {
                this.setState({
                    userInfo: {}
                })
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onInputChangeHandler = (function (e) {
        try{
            let userInfo = this.state.userInfo;
            if (e.target.name === "first_name") {
                let firstNameError = ''
                let fullName = e.target.value
                if (fullName.trim() === '') {
                    firstNameError = "Full name is required"
                } else if (!validator.isAlpha(fullName.trim(),["en-US"], { ignore: " " })){
                    firstNameError = "Full name should be only character"
                } 
                userInfo.first_name = fullName
                this.setState({
                    firstNameError:firstNameError
                });
            }
            if (e.target.name === "gmt") {
                userInfo.gmt = e.target.value;
            }
            if(userInfo.first_name.length === userInfo.first_name.trim().length || userInfo.first_name.length === userInfo.first_name.trim().length+1) {

                this.setState({
                    userInfo: userInfo,
                    showCancel:true
                });

            } else {
                userInfo.first_name = userInfo.first_name.replaceAll("  ", " ")
                this.setState({
                    userInfo: userInfo,
                    showCancel:true,
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    showUploadImageModel = (function (e,image_type) {
        try{
            this.setState({
                isShowUploadModel:true,
                imageType:image_type
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleFormSubmit = (function (e) {
        try{
            e.preventDefault();
            e.stopPropagation();
            if ( this.state.firstNameError === '' ){
                this.setState({
                    showLoading: true
                });
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                let data = {
                    first_name: this.state.userInfo.first_name,
                    dob: this.state.userInfo.dob !== null ? this.getDobString(this.state.userInfo.dob) : null,
                    gmt: this.state.userInfo.gmt
                }
                
                axios.patch(
                    APIs.BASE_URL + APIs.PROFILE_API,
                    data,
                    config
                ).then((response) => {
                    this.loadProfileData()
                    document.getElementById("user-name-top-bar").textContent = this.state.userInfo.first_name;
                    document.getElementsByClassName("top-nav-heading")[0].textContent = this.state.userInfo.first_name;
                    if(this.state.userInfo.first_name.trim() === "") {
                        document.getElementById("user-name-top-bar").textContent = this.state.userInfo.email;
                        document.getElementsByClassName("top-nav-heading")[0].textContent = this.state.userInfo.email;
                    }
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        showAlertMessage: "Profile updated successfully",
                        showAlertType: "success",
                        showCancel:false
                    });
                    setTimeout(() => {
                        this.setState({ showAlert: false });
                    }, 5000);
                }).catch((error) => {
                    this.setState({
                        showLoading: false
                    });
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getDobString(dob) {
        try{
            let dateObject = new Date(dob);
            let month = dateObject.getMonth() + 1;
            let day = dateObject.getDate();
            let year = dateObject.getFullYear();
            if (month < 10) {
                month = `0${month}`;
            }
            if (day < 10) {
                day = `0${day}`;
            }
            return `${year}-${month}-${day}`
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    setStartDate = (function (date) {
        try{
            let userInfo = this.state.userInfo;
            userInfo.dob = date;
            this.setState({ userInfo: userInfo, showCancel:true });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    componentDidMount(){
        this.props.loader({loader:false})
    }

    uploadSuccess = (data) =>{
        try{
            this.setState(data)
            setTimeout(() => {
                this.setState({ showAlert: false });
            }, 5000);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    render() {
        if (Object.keys(this.state.userInfo).length === 0) {
            this.loadProfileData();

        }
        let image = this.state.userInfo.profile_image
        if ( image!== undefined ){ 
            if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                let avatar_bg = (this.state.userDate.avatar_bg?this.state.userDate.avatar_bg:"5D6DF6").replace('#','')
                let UserName = this.state.userInfo.first_name
                image = `https://ui-avatars.com/api/?name=${UserName}&color=FFFFFF&length=1&background=${avatar_bg}`
            }
        }

        return (
            <>
                <Helmet>
                    <title>My Profile - Orrderly</title>
                    <meta name="description" content="Personalize your account by setting up your Orrderly profile today. " />
                    {/*<meta name="keywords" content="Music, Audio, Lyrics" />*/}
                </Helmet>
                <Row className="task">
                    {!this.state.showAlert ? <></> :
                        <Col md={{ span: 10 }}>
                            <Alert variant={this.state.showAlertType} onClose={() => { this.setState({ showAlert: false }) }} dismissible style={{marginBottom:"-15px", marginTop:"10px"}}>
                                <p>{this.state.showAlertMessage}</p>
                            </Alert>
                        </Col>
                    }
                </Row>
                {
                    Object.keys(this.state.userInfo).length === 0 ? <></> :
                        <>
                            <Row>
                                <Col md={{ span: 12 }} className="mt-4" style={{ position: "relative" }}>
                                    <div>
                                        <img src={this.state.userInfo.cover_image} className="w-100 cover-image" alt={"cover-user-" + this.state.userInfo.first_name} />
                                        <div className='cover-image-form' id="cover-image-form">
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="default" className='text-uppercase' >
                                                    <label className='icon-color' htmlFor="cover_image" style={{bottom:"41px"}}>
                                                        <span className="material-icons-outlined">photo_camera</span>
                                                    </label>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={ (e)=>{this.showUploadImageModel(e,'profile_image')} } className="image-cropper">
                                                        <span className="material-symbols-outlined image-cropper-icon">add_box</span>
                                                        <span>Upload profile picture</span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={ (e)=>{this.showUploadImageModel(e,'cover_image')}}  className="image-cropper">
                                                        <span className="material-symbols-outlined image-cropper-icon">border_color</span>
                                                        <span>Upload profile background image</span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="profile-image-container">
                                        
                                        <img src={image} className="w-100 profile-image" alt={"profile-user-" + this.state.userInfo.first_name} />
                                    </div>
                                </Col>
                                <Col md={{ span: 10, offset: 2 }} style={{ marginBottom: "30px", paddingTop:"10px", paddingLeft:"0px"}} className="profile_setting">
                                    <Row className='profile-form-button-labels add_new_member_form offset-md-1'>
                                        <Col md={{span:6}} xs={{span:6}}>
                                            <label className="label" style={{fontSize:"16px"}}>Profile Settings</label>
                                        </Col>
                                        <Col md={{span:6}} xs={{span:6}} className={"update_profile_bth " +(this.state.showCancel?"d-flex justify-content-end":"")}>
                                            {this.state.showCancel ? <Button className="profile-btn" variant='light' onClick={() => this.loadProfileData()}>Cancel</Button> : <></>}
                                            <Button className="profile-btn" variant='dark' disabled={ this.state.showLoading || !this.state.showCancel ? true : false} onClick={(e) => { this.handleFormSubmit(e) }}>
                                                {this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Update settings"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-4 mb-4 add_new_member_form'>
                                <Col md={{ span: 6 }}>
                                    <form onSubmit={(e) => { this.handleFormSubmit(e) }} autoComplete='off'>
                                        <Row>
                                            <Col md={{ span: 12 }}>
                                                <div className={this.state.firstNameError ? "mb-3 invalid" : "mb-3"}>
                                                    <label className="label">Full Name</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control shadow-none" name="first_name" placeholder="Your full name" id="first_name" value={this.state.userInfo.first_name} onChange={(e) => { this.onInputChangeHandler(e) }} autoComplete="off" />
                                                    </div>
                                                    {this.state.firstNameError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.firstNameError}</div> : <></>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{ span: 12 }}>
                                                <div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
                                                    <label className="label">Email</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control shadow-none" name="email" placeholder="Your Email" id="email" value={this.state.userInfo.email} onChange={(e) => { this.onInputChangeHandler(e) }} autoComplete="off" readOnly={true} />
                                                    </div>
                                                    {this.state.emailError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.emailError}</div> : <></>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{ span: 12 }}>
                                                <div className='mb-3 dob'>
                                                    <label className="label">Birthday</label>
                                                    <DatePicker
                                                        selected={this.state.userInfo.dob}
                                                        showPopperArrow={false}
                                                        dateFormat="dd, MMMM yyyy"
                                                        onChange={(date) => this.setStartDate(date)}
                                                        maxDate={this.state.maxDate}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                        calendarContainer={DateOfBirthContainer}
                                                        customInput={
                                                            <BirthDateCompoment value="Date of Birth" />
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{ span: 12 }}>
                                                <div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
                                                    <label className="label">Timezone</label>
                                                    <div className="input-group">
                                                        <select className="form-control shadow-none" name="gmt" onChange={(e) => { this.onInputChangeHandler(e) }} defaultValue={this.state.userInfo.gmt}>
                                                            {
                                                                Labels.TIMEZONE_OPTIONS.map((data) => {
                                                                    return (
                                                                        <option key={"timezone-" + data} value={data}>{data}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {this.state.timeZoneError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.timeZoneError}</div> : <></>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-grid pt-1 col-md-4 mt-2 d-none">
                                            <Button variant="dark" type="submit" disabled={this.state.showLoading}>
                                                {
                                                    this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Save"
                                                }
                                            </Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                            <UploadDropZone uploadSuccess={(data)=>this.uploadSuccess(data)} image_type={this.state.imageType} hideCropper={() => {this.setState({isShowUploadModel:false,imageBase64:'',})}} show={this.state.isShowUploadModel}/>
                        </>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loader: state.CurrentProjectBoard.loader,
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(ProfileSetting);
