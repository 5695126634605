import React, { Component } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import LoggedInLayout from '../LoggedInLayout';
import HelpSupportImage from '../../../../static/images/help-support.png';
import ContactUsMaskImage from '../../../../static/images/contact-us-mask.png';
import axios from 'axios';
import Looger from '../../../../utils/Logger';
import APIs from '../../../../utils/APIs';

class HelpSupport extends Component {
    constructor(props) {
        super(props);
        this.timeout = 0;
        this.state = {
            topicsArray : [],
            help_support_search : "",
            axiosSource : axios.CancelToken.source(),
            topicInfo : {
                id  :   0,
                topic_description :   "",
                topic_name :   ""
            },
        }
    }

    loadTopics = (function(topicID) {
        try{
            axios.get(
                APIs.BASE_URL + APIs.HELP_SUPPORT_TOPICS_LIST_API.replace(":id", topicID),
                {
                    params : {
                        search: this.state.help_support_search
                    },
                    cancelToken : this.state.axiosSource.token
                }
            ).then((response) => {
                if (!isNaN(topicID)) {
                    this.setState({
                        topicInfo: response.data.data
                    });
                } else {
                    if (response.data.data.length > 0) {
                        this.loadTopics(response.data.data[0].id)
                    }
                    this.setState({
                        topicsArray: response.data.data,
                        topicInfo: {
                            id: 0,
                            topic_description: "",
                            topic_name: ""
                        },
                    });
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    onSearchKeywordChange = (function(e) {
        try{
            if (this.timeout) clearTimeout(this.timeout);
            this.setState({
                [e.target.name] : e.target.value
            });

            this.timeout = setTimeout(() => {
                this.loadTopics('all')
            }, 500);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount() {
        this.loadTopics('all');
    }

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }
    
    render() {
        return (
            <>
                <LoggedInLayout>
                    <Row>
                        <Col md={{span:4}}>
                            <Card className="help-support mb-4">
                                <Card.Body>
                                    <Row className="task">
                                        <Col md={{ span: 12 }} className="left_col">
                                            <h2 className="head">Help &amp; Support</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span:12}} className="overdue_task mt-4 mb-3">
                                            <InputGroup className="mb-1">
                                                <FormControl
                                                    placeholder="Start typing"
                                                    name="help_support_search"
                                                    id="help_support_search"
                                                    onChange={(e) => {this.onSearchKeywordChange(e)}}
                                                />
                                                <InputGroup.Text>
                                                    <span className="material-icons-outlined">search</span>
                                                </InputGroup.Text>
                                            </InputGroup>
                                            <Form.Label className="search-label">Type a question or search keyword</Form.Label>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="help-support mb-4">
                                <Card.Body>
                                    <Row className="task">
                                        <Col md={{ span: 12 }} className="left_col">
                                            <h4 className="topics-label">Topics</h4>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        {
                                            this.state.topicsArray.map((data, index) => {
                                                return (
                                                    <Col key={"col-topic-id-" + data.id} md={{ span: 12 }} onClick={(e) => {this.loadTopics(data.id)}}>
                                                        <div key={"topic-id-" + data.id} className={"topic " + (this.state.topicInfo.id === data.id ? " active" : "")}>{data.topic_name}</div>
                                                    </Col>
                                                )
                                            })
                                        }
                                        {
                                            this.state.topicsArray.length > 0 ? <></> :
                                            <Col md={{ span: 12 }}>
                                                <div className="topic">
                                                    No topic found
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="help-support mb-4 contact-us-card" style={{ backgroundImage: "url(" + ContactUsMaskImage+")"}}>
                                <Card.Body>
                                    <div className="contact-us-card-heading">
                                        Do you still need our help?
                                    </div>
                                    <div className='contact-us-card-body-text'>
                                        We can set up a zoom call to help you work it out just fine
                                    </div>
                                    <button className='btn btn-light'>Contact us</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={{ span: 8 }}>
                            {
                            this.state.topicInfo.topic_name === "" ? <></> :
                            <Row>
                                <Col md={{span:12}} style={{position:"relative"}}>
                                    <img src={HelpSupportImage} alt="help-and-support" style={{ "height": "157px", "userSelect": "none" }} />
                                    <div className="help-support-topic-title">{this.state.topicInfo.topic_name}</div>
                                </Col>
                                <Col md={{ span: 12 }} className='topic-decription mt-3'>
                                    <div dangerouslySetInnerHTML={{ __html : this.state.topicInfo.topic_description}}></div>
                                </Col>
                            </Row>
                            }
                        </Col>
                    </Row>
                </LoggedInLayout>
            </>
        );
    }
}

export default HelpSupport;