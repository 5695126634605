import React, { Component } from 'react';
import PopUpModal from '../Modals/PopUpModal';
import Labels from '../../../../../utils/Labels';
import APIs from '../../../../../utils/APIs';
import axios from 'axios';
import validator from "validator";
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Looger from '../../../../../utils/Logger'

class ShareTask extends Component {
    constructor(props){
        super(props)
        this.state = {
            showShareTaskSucessModal:false,
            shareTaskEmail:'',
            errorShareTaskEmail:'',
            showShareTaskSpinner:false,
            axiosSource : axios.CancelToken.source(),
        }
    }

    addShareTaskEmailOnChange = (function(e){
        try{
            e.preventDefault()
            let errorShareTaskEmail = ''
            let teamEmail = e.target.value.trim()
            if (!validator.isEmail(teamEmail) && teamEmail.length >0){
                errorShareTaskEmail = "Invalid email address.";
            }
            this.setState({
                errorShareTaskEmail:errorShareTaskEmail,
                shareTaskEmail:teamEmail
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    taskShareHandler = (function(e,type){
        try{
            e.preventDefault()
            let errorShareTaskEmail = this.state.errorShareTaskEmail
            if ( type === 'share'){
                let email = this.state.shareTaskEmail
                if (email !== ''){
                    if ( errorShareTaskEmail === ''){
                        this.setState({showShareTaskSpinner:true})
                        let token = localStorage.getItem('session_token');
                        let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                        let params = {team_mail:this.state.shareTaskEmail,share_url:this.props.shareUrl}
                        axios.post(
                            APIs.BASE_URL + APIs.TASK_SHARE_API,
                            params,
                            config
                        ).then((response)=>{
                            let data = response.data 
                            if (data.status_code === 200){
                                this.setState({showShareTaskSucessModal:true,showShareTaskSpinner:false})
                            } else {
                                this.setState({errorShareTaskEmail:data.message,showShareTaskSpinner:false})
                            } 
                        }).catch((error)=>{

                        })
                    }
                } else {
                    errorShareTaskEmail = 'Email is required.'
                }
            } 
            this.setState({
                showShareTaskModal:true,
                errorShareTaskEmail:errorShareTaskEmail
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    shareTaskCloseHandler =  (function(e){
        try{
            this.setState({
                shareTaskEmail:'',
                errorShareTaskEmail:'',
                showShareTaskSucessModal:false
            })
            this.props.onClick()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

  render() {
    return (
      <PopUpModal contentClassName="add_new_member_form  dlt-model share_task task_list mb-0" title={this.state.showShareTaskSucessModal?"":"Share Task"} show={this.props.showShareTaskModal}  onChange={(e) =>{this.shareTaskCloseHandler(e)}}>
        <form autoComplete='off'>
            <Row>
            <Col md={{span:10}} className='container my-3'>
                { this.state.showShareTaskSucessModal ? 
                        <>
                        <Col md={{span:12}} className="text-center">
                            <i style={{fontSize:"18px"}} className={"material-icons todotask-icon done access_acc" }>check</i>
                        </Col>
                        <Col md={{span:12}} className="text-center my-5">
                            <h5>Task shared successfully</h5>
                        </Col>
                        </>
                    :
                        <>
                        <Col md={{ span: 12}} className={"mb-4 share_mail "+(this.state.errorShareTaskEmail!==''?"invalid":"")}>
                            <label className="label mb-2 mt-2">Share task with:</label>
                            <div className="input-group">
                                <span className="input-group-text"><i className="material-icons">mail</i></span>
                                <input type="text" className="form-control shadow-none border-0" name="email" placeholder={Labels.EMAIL_PLACEHOLDER} id="email" value={this.state.shareTaskEmail} onChange={this.addShareTaskEmailOnChange} autoComplete="off" />
                            </div>
                            { this.state.errorShareTaskEmail &&
                                <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.errorShareTaskEmail}</div>
                            }
                        </Col>
                        <Col md={{ span: 12}} className='text-center'>
                            <Button type="submit" onClick={(e)=>{this.taskShareHandler(e,'share')}} className="w-100 default_background">
                                { this.state.showShareTaskSpinner?
                                    <Spinner size="sm" animation="border" variant="light" />:"Share"
                                }
                            </Button>
                        </Col>
                        </>
                }
                </Col>
            </Row>
        </form>
    </PopUpModal>
    )
  }
}

export default ShareTask
