import React, { Component } from 'react';
import { NavLink, Navigate } from "react-router-dom";
import axios from "axios";
import BeforeLoginLayout from './BeforeLoginLayout';
import Labels from "../../utils/Labels";
import APIs from '../../utils/APIs';
import Cookies from 'js-cookie';
import { Spinner } from 'react-bootstrap';
import validator from 'validator';
import Looger from '../../utils/Logger';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email : "",
            password : "",
			isLogin :  false,
            submitButtonText :   "Continue",
            submitButtonProp :   false,
			showPassword 	 :   false,
			emailError       :   "",
			passwordError    :   "",
			axiosSource 	 : 	 axios.CancelToken.source(),
        }
    }

	componentDidMount() {
		let user = localStorage.getItem('session_token');
		let userData = localStorage.getItem('user');
		let cookie = Cookies.get(btoa('user-data'))
        if(!cookie && APIs.ENV !== "LOCAL") {
            localStorage.removeItem("session_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user");
            localStorage.removeItem("plan_info");
            // window.location.href = "/";
            // this.setState({ isLogin: false });
        } else if (user !== "" && user !== null) {
			axios.post(
				APIs.BASE_URL + APIs.REFRESH_TOKEN_API,
				{ refresh: localStorage.getItem('refresh_token') },
				{ headers: { "Authorization": `Bearer ${user}` }, cancelToken : this.state.axiosSource.token }
			).then((response) => {
				Cookies.set(btoa('user-data'), btoa(userData), { expires : 365, secure : true, domain : APIs.DOMAIN })
				let data = response.data;
				localStorage.setItem("session_token", data.data.token.access);
				localStorage.setItem("refresh_token", data.data.token.refresh);
				localStorage.setItem('plan_info', btoa(JSON.stringify( data.data.plan_info)))
				this.setState({ isLogin: true });
			}).catch((error) => {
				if(error.message === "Landing Component got unmounted") {
					//
				} else {
					Cookies.remove(btoa('user-data'))
					localStorage.removeItem("session_token");
					localStorage.removeItem("refresh_token");
					localStorage.removeItem("user");
					localStorage.removeItem("plan_info");
				}
			});
		} else {
			Cookies.remove(btoa('user-data'))
		}
    }

	/**
	 * @function onChange
	 * @param {event} event
	 * @description On change of input value assign that value in respective state
	 * @returns void
	 */
	 onChange = (function(event) {
		try{
			if (event.target.name === "email" && this.state.emailError !== "") {
				this.setState({ [event.target.name]: event.target.value.trim(), emailError : ""});
			} else if (event.target.name === "password" && this.state.passwordError !== "") {
				this.setState({ [event.target.name]: event.target.value, passwordError: "" });
			} else {
				this.setState({ [event.target.name]: event.target.value });
			}
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function setErrorMessagesState
	 * @param {JSON Object} data
	 * @description To set error messages and button text
	 * @returns void
	 */
	setErrorMessagesState = (function(data) {
		try{
			let emailError 			= 	data.emailError;
			let passwordError 		= 	data.passwordError;
			let submitButtonProp 	= 	data.submitButtonProp;
			let isLogin 			= 	data.isLogin;
			let submitButtonText 	= 	submitButtonProp ? "Loading" : "Continue";

			this.setState({
				emailError			: 	emailError,
				isLogin				: 	isLogin,
				passwordError		: 	passwordError,
				submitButtonText	: 	submitButtonText,
				submitButtonProp	: 	submitButtonProp
			});
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function checkValidEmail
	 * @param {string} email
	 * @description validate the email string while typing and display the message
	 * @returns string
	 */
	 checkValidEmail = (function(email) {
		try{
			let emailError = "";
			if (!validator.isEmail(email) && email.length > 0) {
				emailError = "Invalid email address";
			}
			this.setState({ email: email, emailError: emailError });
			return emailError;
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function onFormSubmit
	 * @param {event} event to stop html form default behaviour
	 * @description based on username and password call the login API, and display respective message if have any error
	 * @returns void
	 */
	onFormSubmit = (function(event) {
		try{
			event.preventDefault();
			this.setErrorMessagesState({ emailError: "", passwordError: "", submitButtonProp: true, isLogin: false });
			let email 			= 	this.state.email.toLowerCase();
			let password 		= 	this.state.password;
			let emailError 		= 	(email.trim() === "") ? "Email address is required" : "";
			let passwordError 	= 	(password.trim() === "") ? "Password is required" : "";
			emailError 			= 	emailError === "" ? this.checkValidEmail(email) : emailError;
			if (emailError === "" && passwordError === "") {
				axios.post(APIs.BASE_URL + APIs.LOGIN_API, {
					email: email,
					password: password
				}, {cancelToken : this.state.axiosSource.token}).then((response) => {
					if(response.data.status_code === 401) {
						this.setErrorMessagesState({ emailError: response.data.message, passwordError: "", submitButtonProp: false, isLogin: false });
					} else if(response.data.status_code === 200) {
						this.setLocalStroageData(response.data);
						this.setErrorMessagesState({ emailError: "", passwordError: "", submitButtonProp: false, isLogin: true });
						event.stopPropagation();
					}
				}).catch((error) => {
					let message = error.message;
					if(error.message === "Network Error") {
						message = APIs.SERVER_NOT_AVAILABLE;
					} else if(error.message === "Landing Component got unmounted") {
						//
					}
					this.setErrorMessagesState({ emailError: message, passwordError: "", submitButtonProp: false, isLogin : false });

					// Capture Error Logs
					Looger(error)
				});
			} else {
				this.setErrorMessagesState({ emailError: emailError, passwordError: passwordError, submitButtonProp: false, isLogin : false });
			}
			event.stopPropagation();
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function setLocalStroageData
	 * @param {JSON Object} data
	 * @description set the value of "session_token" and "user" in localStorage
	 * @returns void
	 */
	setLocalStroageData = (data) => {
		try{
			localStorage.setItem("allProjectList",JSON.stringify(data.data.allProjectList));
			localStorage.setItem("project_id", data.data.project_info.id);
			localStorage.setItem("project_name", data.data.project_info.project_name);
			localStorage.setItem("session_token", data.data.token.access);
			localStorage.setItem("refresh_token", data.data.token.refresh);
			localStorage.setItem('confirm',parseInt(data.data.active))
			localStorage.setItem('account_info',data.data.filled)
			if(data.data.plan_info) {
				localStorage.setItem("plan_info", btoa(JSON.stringify(data.data.plan_info)));
			}
			localStorage.setItem("user", JSON.stringify(data.data.user_info));
			Cookies.set(btoa('user-data'), btoa(JSON.stringify(data.data.user_info)), { expires : 365, secure : true, domain : APIs.DOMAIN })
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	};

	togglePasswordField = (function (e) {
		try{
			this.setState({
				showPassword: !this.state.showPassword
			});
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	});

	componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
			<BeforeLoginLayout
				footerQues={Labels.QUES_HAVE_NO_ACCOUNT}
				footerLinkText={Labels.SIGN_UP_LABEL}
				footerLink={"/signup"}
				formTagLine={"Enter your email and password"}
				formHeaderTagLine={Labels.LOG_IN_H3}
				seoData={{
					"title" : "Orrderly Log In - Orrderly",
					"meta": [
						{"name":"description","content":"Log in to your Orrderly account get organized and be productive."}
					]
				}}
			>
				{this.state.isLogin ? <Navigate to="/dashboard" /> : ""}
				<form onSubmit={this.onFormSubmit} className="signup_form form_sec mt-5" autoComplete='off'>
					<div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
						<label className="label d-none">{Labels.LOGIN_EMAIL} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">mail</i></span>
							<input type="text" className="form-control shadow-none" name="email" placeholder={Labels.EMAIL_PLACEHOLDER} id="email" value={this.state.email} onChange={this.onChange} autoComplete="off" />
						</div>
						{this.state.emailError ? <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.emailError}</div> : <></>}
					</div>
					<div className={this.state.passwordError ? "mb-3 invalid" : "mb-3"}>
						<label className="label d-none">{Labels.LOGIN_PWD} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">lock</i></span>
							<input type={!this.state.showPassword ? "password" : "text"} className="form-control shadow-none" placeholder={Labels.PWD_PLACEHOLDER} name="password" id="password" value={this.state.password} onChange={this.onChange} autoComplete="off" />
							<span style={{ cursor: "pointer", userSelect: "none" }} className="input-group-text" onClick={(e) => this.togglePasswordField(e)}><i className="material-icons">{!this.state.showPassword ? "visibility" : "visibility_off"}</i></span>
						</div>
						{this.state.passwordError ? <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.passwordError}</div> : <></>}
					</div>
					<div className="d-grid pt-3">
						<button type="submit" className="btn btn-primary btn-block" disabled={this.state.submitButtonProp}>{this.state.submitButtonProp ? <Spinner size="sm" animation="border" variant="light" /> : this.state.submitButtonText}</button>
					</div>
					<NavLink to="/forgot-password" className="link-below-submit-button">{Labels.QUES_FORGOT_PWD}</NavLink>
				</form>
			</BeforeLoginLayout>
        );
    }
}

export default Login;