import axios from 'axios'
import React, { Component } from 'react'
import { Button, Col, Container, Form, FormControl, InputGroup, Nav, Row, Spinner, Tab } from 'react-bootstrap'
import APIs from '../../../../../../utils/APIs'
import Looger from '../../../../../../utils/Logger'
const Paddle = window.Paddle;

export default class PlanSelectionStep1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMPlanLoaded    :   false,
            isYPlanLoaded    :   false,
            monthlyPlans     :   [],
            yearlyPlans      :   [],
            selectedPlan     :   -1,
            planQuantity     :   props.planQuantity? props.planQuantity:1,
            buttonUnitPrice  :   0,
            currency         :  '$',
            maximumMemberLimit:  0,
            axiosSource      :   axios.CancelToken.source(),
            InputKey         :   new Date().getTime(),
        }
    }
    
    async componentDidMount() {
        const params = { active: false };
        await axios.get(
            APIs.BASE_URL + APIs.GET_PLAN_API, {params},{ cancelToken : this.state.axiosSource.token }
        ).then((response) => {
            let monthlyPlans = [];
            let yearlyPlans = [];
            response.data.data.map((data) => {
                if(data.plan_id > 100) {
                    Paddle.Product.Prices(data.plan_id, 1, (e) => {
                        data.plan_price = e.recurring.price.gross;
                        if(data.plan_type === "month") {
                            if (data.plan_name === "pro"){
                                document.getElementById('replace-price').innerHTML = data.plan_price
                            }
                            monthlyPlans.push(data)
                        } else {
                            yearlyPlans.push(data)
                        }
                    })
                } else {
                    data.plan_price = 0;
                    if(data.plan_type === "month") {
                        monthlyPlans.push(data)
                    } else {
                        yearlyPlans.push(data)
                    }
                }
                return null;
            });
            this.setState({
                monthlyPlans : monthlyPlans, 
                yearlyPlans : yearlyPlans
            })
            setTimeout(() => {
                this.setState({isMPlanLoaded : true})
            }, 1000)
        })
    }

    getAllPlansDiv = ((type='month') => {
        try{
            let data = [];
            if(type === "month") {
                data = this.state.monthlyPlans
            } else if(type === "year") {
                data = this.state.yearlyPlans
            }
            let planData = data.sort((a,b)=>a.id-b.id)
            let planType = this.props.modalTitle
            if (planType.indexOf('Upgrade')  >-1){
                planData = planData.filter((data)=>{return data.plan_id > 30})
            } else {
                planData = planData.filter((data)=>{return data.plan_name!=='trial'})
            } 
            return planData.map((singlePlanInfo, index) => {
                return this.planInfo(singlePlanInfo, index, type)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    planInfo =  ((singlePlanInfo, index, type) => {
        try{
            return (
                <div key={singlePlanInfo.plan_name + "-"+type+"-"+index} className={"plan-container" + (this.state.selectedPlan === singlePlanInfo.plan_id ? " active" : "")}>
                    <div className='plan-selection'>
                        <Form.Check type="radio"  name="selected_plan" data-price={singlePlanInfo.plan_price} checked={(this.state.selectedPlan === singlePlanInfo.plan_id)} value={singlePlanInfo.plan_id} onChange={(e) => {this.onRadioInputChange(e,singlePlanInfo.features)}} id={"plan-"+type+"-"+index} label={
                            <div className='plan-info'>
                                <div className="plan-name">{singlePlanInfo.plan_name}</div>
                                <div className='plan-price'>{(singlePlanInfo.plan_price===0 ? `1 ${type} - Free` : `1 ${type} - `+singlePlanInfo.plan_price + `/user`)}</div>
                                <div className="plan-description">{singlePlanInfo.description}</div>
                            </div>
                        } />
                    </div>
                </div>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    onRadioInputChange = ((e,features) => {
        try{
            var regex = /\d+/g;
            features.filter((data)=>{
                if (data.label.indexOf('team members') > -1 && data.label.indexOf('assign tasks to team members') < 0){
                    let matches = data.label.match(regex);
                    let teamMemberLimit = 'infinity'
                    if (matches!==null){
                        teamMemberLimit = parseInt(matches[0])
                    }
                    this.setState({maximumMemberLimit:teamMemberLimit})
                } 
                return true
            })
            let wholePrice  =   e.target.attributes.getNamedItem('data-price').value.replace(",", "");
            //eslint-disable-next-line
            let price       =   wholePrice.replace(/[^0-9\.-]+/g,"");
            let currency    =   wholePrice.replace(price, "");

            if(currency === "") {
                currency = "$"
            }
            this.setState({
                selectedPlan        :   parseInt(e.target.value),
                showActionButtons   :   true,
                planQuantity        :   this.props.planQuantity?this.props.planQuantity:1,
                InputKey            :   new Date().getTime(),
                buttonUnitPrice     :   price,
                currency            :   currency
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    changeTab = ((eventKey, event) => {
        try{
            let isMonth = false;
            if(eventKey === "month") {
                isMonth = true;
            }
            this.setState({
                isYPlanLoaded       : !isMonth,
                isMPlanLoaded       : isMonth,
                showActionButtons   : false,
                selectedPlan        : -1,
                planQuantity        : this.props.planQuantity?this.props.planQuantity:1,
                InputKey            : new Date().getTime()
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    decrementPlanQty = ((e) => {
        try{
            let previousQty = this.state.planQuantity
            let planQty = this.props.planQuantity?this.props.planQuantity:1
            this.setState({
                planQuantity    :   (previousQty-1 >= planQty) ? (previousQty-1) : planQty,
                InputKey        :   new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    incrementPlanQty = ((e) => {
        try{
            let maximumMemberLimit = this.state.maximumMemberLimit
            let previousQty = this.state.planQuantity
            let limit = (previousQty+1)
            if (previousQty === maximumMemberLimit){
                limit = maximumMemberLimit
            }
            this.setState({
                planQuantity    : limit,
                InputKey        : new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    updatePlanQuantity = ((e) => {
        try{
            let price = 0
            if(!isNaN(e.target.value)) {
                price =parseInt(e.target.value);
            }
            this.setState({
                planQuantity    : price,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    selectThisPlanAndQty = ((e) => {
        try{
            let selectedPlanInfo = {};
            let plans = this.state.monthlyPlans.concat(this.state.yearlyPlans);
            plans.map((data) => {
                if(data.plan_id === this.state.selectedPlan) {
                    selectedPlanInfo = data
                }
                return null;
            })
            this.props.onPlanSelection({
                planQuantity : this.state.planQuantity,
                unitPrice : this.state.buttonUnitPrice,
                isPlanSelected : true,
                currency : this.state.currency,
                planID : this.state.selectedPlan,
                planInfo : selectedPlanInfo,
                planStep : 2,
                maximumMemberLimit:this.state.maximumMemberLimit
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
            <Container style={{padding:"29px 26px 32px 26px"}}>
                <Row>
                    <Col md={{span:12}}>
                        <div className="plan-selection-modal-title">{this.props.modalTitle}</div>
                        <div className="plan-selection-modal-subtitle">Continue for free or as low as <span id="replace-price">$3</span></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:12}} style={{marginTop:27}}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="month" onSelect={this.changeTab}>
                            <Row>
                                <Col sm={12}>
                                    <Nav variant="pills" className="justify-content-center">
                                        <Nav.Item>
                                            <Nav.Link className="py-1 px-3 custom-btn" eventKey="month">Monthly</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link className="py-1 px-3 custom-btn" eventKey="year">Yearly</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content className="mt-5">
                                        <Tab.Pane eventKey="month">
                                            {
                                                !this.state.isMPlanLoaded ?
                                                <Spinner className="d-flex justify-content-center align-items-center m-auto" animation="border" variant="primary" /> :
                                                this.getAllPlansDiv('month')
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="year">
                                            {
                                                !this.state.isYPlanLoaded ?
                                                <Spinner className="d-flex justify-content-center align-items-center m-auto" animation="border" variant="primary" /> :
                                                this.getAllPlansDiv('year')
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                    <Col md={{span:12}} style={{marginTop:19, marginBottom:14}}>
                        <div className='payment-feature'><i className="material-icons">check</i> Easy cancellation</div>
                        <div className='payment-feature'><i className="material-icons">check</i> Refund policy</div>
                    </Col>
                    <Col md={{span:12}}>
                        <Row>
                            {
                                (this.state.selectedPlan < 100) ? <></> :
                                <Col md={5} xs={{span:6}} sm={{span:5}}>
                                    <InputGroup className="mb-3">
                                        <Button variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.decrementPlanQty(e)}} key={"decrease-plan-quantity"}>-</Button>
                                        <FormControl type="number" readOnly={true} className="no-border-text-box" onChange={(e) => {this.updatePlanQuantity(e)}} key={this.state.InputKey} defaultValue={this.state.planQuantity}  />
                                        <Button variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.incrementPlanQty(e)}} key={"increase-plan-quantity"}>+</Button>
                                    </InputGroup>
                                </Col>
                            }
                            <Col md={(this.state.selectedPlan<100)?12:7} xs={(this.state.selectedPlan<100)?12:12} sm={(this.state.selectedPlan<100)?12:7}>
                                <Button variant='primary' onClick={(e) => this.selectThisPlanAndQty(e)} disabled={(this.state.selectedPlan=== -1)} className='w-100'>
                                    {parseInt(this.state.buttonUnitPrice) === 0 ? 'Next' : 
                                    `Next (${this.state.currency} ${(this.state.buttonUnitPrice*this.state.planQuantity).toFixed(2)})`}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
