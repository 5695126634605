import React, { Component } from 'react'
import { Spinner,Col } from 'react-bootstrap'
import { connect } from "react-redux";
import Looger from '../../../../utils/Logger';

class LoaderOverlay extends Component {
  constructor(props){
    super(props)
  }

  addClassHandler = (function(){
    try{
      let loader = this.props.loader
      let element = document.body
      if (loader){
        element.classList.add('loder_enable');
      } else {
        element.classList.remove("loder_enable")
      }
    } catch(error) {
      // Capture Error Logs
      Looger(error)
    }
  })

  render() {
    this.addClassHandler()
    return (
      <>
        { this.props.loader &&
          <Col md={{span:12}} xs={{span:12}} sm={{span:12}} className='loading-container'>
            <Spinner size="sm" animation="border" variant="primary" />
          </Col>
        } 
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loader:state.CurrentProjectBoard.loader
});


export default connect(mapStateToProps)(LoaderOverlay);
