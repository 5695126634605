import React, { Component } from 'react';
import { Col, Row,} from 'react-bootstrap';
import PopUpModal from '../../Common/Modals/PopUpModal';
import Dropzone from 'react-dropzone';
import ImageCropper from './ImageCropper';
import Looger from '../../../../../utils/Logger';

class UploadDropZone extends Component {
    constructor(props) {
        super(props);
          this.state = {
            files: [],
            is_valid:false,
            showCropper : false,
            baseURL:[]
        };
    }

    onDrop = (files) => {
        try{
            this.setState({
                files,
                showCropper:true,
            })
            this.props.hideCropper()
            if (files.length > 0){        

                // Make new FileReader
                let reader = new FileReader();

                // Convert the file to base64 text
                reader.readAsDataURL(files[0]);

                // on reader load somthing...
                reader.onload = () => {
                    // Make a fileInfo Object
                    let baseURL = reader.result;
                    this.setState({
                        baseURL:baseURL
                    })
                };
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    };

    hideCropper = (function(){
        try{
            this.setState({
                showCropper:false,
                imageBase64:''
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    uploadSuccess = (function(data){
        try{
            data.isShowUploadModel = false;
            this.props.uploadSuccess(data)
            this.hideCropper()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render(){
        return (
            <>
            <PopUpModal contentClassName="add_new_member_form" show = {this.props.show} onChange={()=> {this.props.hideCropper()}} modalSize='md'>
                <Dropzone maxFiles={1} accept={{"image/png":[],"image/jpg":[],"image/jpeg":[]}} onDrop={this.onDrop}>
                    {({getRootProps, getInputProps}) => (
                    <section className="container">
                        <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <Col md={{ span: 12 }}>
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <h5  className="text-center dropzone">Upload your image here</h5>
                                    <p className="text-center dropzone">PNG and JPG files are allowed</p>   
                                </Col>
                                <Col md={{ span: 12 }}>
                                    <Row>
                                        <Col md={{ span: 12 }} className='dropzone-container border'>
                                            <p className="text-center"><span className="material-symbols-outlined dropzone-icon theme-color">cloud_upload</span></p>
                                            <p className="text-center dropzone-content">Drag and drop or <span><u>browse</u></span> to choose a file</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </Col>
                        </div>
                    </section>
                    )}
                </Dropzone>
            </PopUpModal>
            {this.state.showCropper && <ImageCropper actionComplete={(data)=>{this.uploadSuccess(data)}} hideCropper={() => {this.hideCropper()}} show={this.state.showCropper} data = {this.state.baseURL} imagetype={this.props.image_type}/> }
            </>
        )
    }
}

export default UploadDropZone;