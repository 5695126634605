import React, { Component } from 'react'
import { Button, Col, Container, Form, FormControl, InputGroup, Row} from 'react-bootstrap'
import Looger from '../../../../../../utils/Logger'

export default class PlanSelectionStep2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            planQuantity     :   this.props.data.planQuantity,
            buttonUnitPrice  :   this.props.data.unitPrice,
            currency         :   this.props.data.currency,
            selectedPlan     :   this.props.data.planID,
            maximumMemberLimit:  this.props.data.maximumMemberLimit
        }
    }
    
    decrementPlanQty = ((e) => {
        try{
            let previousQty = this.state.planQuantity
            let planQty = this.props.planQuantity?this.props.planQuantity:1
            this.setState({
                planQuantity    :   (previousQty-1 >= planQty) ? (previousQty-1) : planQty,
                InputKey        :   new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    incrementPlanQty = ((e) => {
        try{
            let maximumMemberLimit = this.state.maximumMemberLimit
            let previousQty = this.state.planQuantity
            let limit = (previousQty+1)
            if (previousQty === maximumMemberLimit){
                limit = maximumMemberLimit
            }
            this.setState({
                planQuantity    : limit,
                InputKey        : new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    updatePlanQuantity = ((e) => {
        try{
            let price = 0
            if(!isNaN(e.target.value)) {
                price =parseInt(e.target.value);
            }
            this.setState({
                planQuantity    : price,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    selectThisPlanAndQty = ((e) => {
        try{
            let data = this.props.data;
            data.planQuantity = this.state.planQuantity
            data.planStep = 3;
            this.props.onPlanSelection(data)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render() {
        return (
            <Container style={{padding:"29px 26px 32px 26px"}}>
                <Row>
                    <Col md={12} style={{position:"relative"}}>
                        <i className="material-symbols-outlined" onClick={(e) => {this.props.resetPlanSelection()}} style={{"position":"absolute","left":"10px","width":"30px","height":"30px","cursor":"pointer"}}>keyboard_backspace</i>
                        <div className="plan-selection-modal-title">Payment details</div>
                        <div className="plan-selection-modal-subtitle">Give us further information on the other users you're paying for</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className='selected-plan-tag-line'>Plan selected</div>
                        <div className='selected-plan-info'>
                            <div className={"plan-container active"}>
                                <div className='plan-selection'>
                                    <Form.Check type="radio" defaultChecked={true} name="selected_plan"
                                        label={
                                            <div className='plan-info'>
                                                <div className="plan-name">{this.props.data.planInfo.plan_name}</div>
                                                <div className='plan-price'>
                                                    1   {this.props.data.planInfo.plan_type} 
                                                    -   {this.props.data.planInfo.plan_price===0?"Free":this.props.data.planInfo.plan_price}
                                                    /user
                                                </div>
                                                <div className="plan-description">{this.props.data.planInfo.description}</div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <Row>
                            <div className='product-quantity-label'>Number of users</div>
                            <Col md={5} xs={{span:6}} sm={{span:6}}>
                                <InputGroup className="mb-3">
                                    <Button disabled={(this.props.data.planInfo.plan_id < 100)} variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.decrementPlanQty(e)}} key={"decrease-plan-quantity"}>-</Button>
                                    <FormControl type="number" readOnly={true} className="no-border-text-box" onChange={(e) => {this.updatePlanQuantity(e)}} key={this.state.InputKey} defaultValue={this.state.planQuantity}  />
                                    <Button disabled={(this.props.data.planInfo.plan_id < 100)} variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.incrementPlanQty(e)}} key={"increase-plan-quantity"}>+</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            { 
                                this.props.data.planInfo.plan_price === 0 ? <></> 
                                :
                                    <>
                                    <div className='product-quantity-label'>Total payment </div>
                                    <Col md={12}>
                                        <div className='final-price'>
                                        {`${this.state.currency} ${(this.state.buttonUnitPrice*this.state.planQuantity).toFixed(2)}`}
                                        </div>
                                    </Col>
                                    </>
                            }
                            <Col md={12} style={{marginTop:66}}>
                                <Button variant='primary' onClick={(e) => this.selectThisPlanAndQty(e)} disabled={(this.state.selectedPlan=== -1)} className='w-100'>Continue</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
