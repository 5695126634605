import React, { Component } from 'react';
import LoggedInLayout from '../LoggedInLayout';
import axios from 'axios';
import { Row, Col, Button,InputGroup, FormControl, Spinner,} from 'react-bootstrap';
import APIs from '../../../../utils/APIs';
import { Helmet } from 'react-helmet';
import ProjectBoardList from './ProjectBoardList';
import PopUpModal from '../Common/Modals/PopUpModal';
import randomColor from "randomcolor";
import validator from 'validator';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { connect } from "react-redux";
import Looger from '../../../../utils/Logger';
import { fetch_all_project_list,update_current_project_board_id,loader } from '../../../../reducers/CurrentProjectBoard'

let filterTypeOptions =  [
    {
        "labelKey": "modified",
        "value": "Last modified",
        "isSelected":true
    },
    {
        "labelKey": "created",
        "value": "Date created",
        "isSelected": false
    }
]
class ProjectBoard extends Component {
    constructor(props) {
        super(props);
        this.timeout = 0;
        this.state = {
            teamMembersList : [],
            allProjectList:[],
            newProjectName:"",
            projectNameError:"",
            showSpinner:false,
            modelTitle:"",
            buttonText:"",
            processType:"",
            selectedKey:'modified',
            searchKey:'',
            BoardId:0,
            planType:JSON.parse(atob(localStorage.getItem('plan_info'))).subscription_plan_id,
            filterTypeOptions:filterTypeOptions,
            showCreateNewProjectModel:false,
            axiosSource : axios.CancelToken.source()
        };
    }

    getallProjectList = (function(filter=null){
        try{
            this.props.loader({loader:true})
            let token = localStorage.getItem('session_token');
            let selectedKey = this.state.selectedKey
            let filter_1 = filter !== null ? filter : selectedKey;
            let param = {
                filter:filter_1,
                search:this.state.searchKey
            }
            let config = { headers: { authorization: `Bearer ${token}` },params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_ALL_PROJECT_LIST,
                config
            ).then((response) => {
                let data = response.data
                if (data.status_code === 200){ 
                    this.props.fetch_all_project_list({allProjectList: data.data})
                    this.setState({
                        allProjectList :  data.data,
                    })
                }
                this.props.loader({loader:false})
            }).catch((error)=>{
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)


    handleSelect = (function(data,type){
        try{
            let newProjectName = this.state.newProjectName
            let modelTitle =  ''
            let buttonText = ''
            let project_id = ''
            let showCreateNewProjectModel = true
            if (type === 'update') {
                modelTitle = 'Update project name'
                buttonText = "Update"
                project_id = data.project_id
                newProjectName = data.name
            } else if (type === 'delete') {
                modelTitle =  'Delete project'
                buttonText = 'Continue'
                newProjectName = 'delete'
                project_id = data.project_id
            } else {
                modelTitle =  'Create new project'
                buttonText = 'Create'
            }
            this.setState({
                showCreateNewProjectModel:showCreateNewProjectModel,
                modelTitle:modelTitle,
                newProjectName:newProjectName,
                buttonText:buttonText,
                processType:type,
                BoardId:project_id
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)


    handleHideModal = (function(){
        try{
            this.setState({
                showCreateNewProjectModel:false,
                newProjectName:"",
                projectNameError:""
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)
    

    onFormSubmit = (function(e){
        try{
            e.preventDefault();
            let project_Name = this.state.newProjectName
            let checkProjectError = this.state.projectNameError
            if (checkProjectError === "" && project_Name !== '') {
                this.setState({showSpinner:true})
                let backGroundRandomColor = randomColor({luminosity: 'bright',format: 'rgb'})
                let parms = {name:this.state.newProjectName,color:backGroundRandomColor,is_current:0}
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                let axiosType  = ''
                let processType = this.state.processType
                if (processType === 'update') {
                    let parms = {id:this.state.BoardId,name:this.state.newProjectName}
                    axiosType = axios.patch(
                        APIs.BASE_URL + APIs.UPDATE_PROJECT_NAME, 
                        parms,
                        config
                    )
                } else if (processType === 'delete') {
                    axiosType = axios.delete(
                        APIs.BASE_URL + APIs.DELETE_PROJECT.replace(':id',this.state.BoardId), 
                        config
                    )
                } else {
                    axiosType = axios.post(
                        APIs.BASE_URL + APIs.CREATE_PROJECT_API,
                        parms,
                        config
                    )
                }
                axiosType.then((response) => {
                    let data = response.data
                    if (data.status_code === 200) {
                        this.getallProjectList(this.state.dropDwownTitle)
                        this.setState({
                            showSpinner:false,
                            newProjectName:'',
                            showCreateNewProjectModel:false,
                        })
                        if (processType === 'delete'){

                            let activeProjectInfo = data.data.filter(CheckActiveProject)
                            function CheckActiveProject(data){if (data.is_current === 1){return data}}

                            let project_id = activeProjectInfo[0].project_id 
                            let project_name = activeProjectInfo[0].name 
                            
                            this.props.fetch_all_project_list({allProjectList: data.data})
                            this.props.update_current_project_board_id({project_id:project_id,project_name:project_name})

                            localStorage.setItem('allProjectList',JSON.stringify(data.data))
                            localStorage.setItem('project_id',project_id)
                            localStorage.setItem('project_name',project_name)
                        }
                        
                    } else {
                        this.setState({
                            projectNameError:data.message,
                            showSpinner:false
                        })
                    }
                }).catch((error)=>{
                    // Capture Error Logs
                    Looger(error)
                })
            } else {
                this.setState({
                    projectNameError:"Enter project name"
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    onInputChangeHandler = (function(e){
        try{
            let  projectName = e.target.value 
            this.checkValidEmail(projectName);
            this.setState({
                newProjectName:projectName
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)


    checkValidEmail = (function (projectName) {
        try{
            let projectNameError = "";
            if (validator.isEmpty(projectName)){
                projectNameError = "Enter project name";
            } else if (!validator.isAlphanumeric(projectName, ["en-US"],{ ignore: "!#$%^&*()_+-={}|[]\\:;,.?/ \"'s" })) {
                projectNameError = "invalid Project name";
            }
            this.setState({ 
                projectNameError: projectNameError 
            });
            return projectNameError;
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);
    
    modelChildrenHtml = (function(){
        try{
            return(
                <>
                {this.state.processType === 'new' || this.state.processType === 'update' ?
                    <div className={"mb-3 " + (this.state.projectNameError ? "invalid" : "")}>
                        <label className="label">Name<span className="asterisk">*</span></label>
                        <div className="input-group">
                            <input type="text" className="form-control shadow-none" placeholder="Enter project Name" id="role" value={this.state.newProjectName} onChange={this.onInputChangeHandler} autoComplete="off" />
                        </div>
                        {this.state.projectNameError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.projectNameError}</div> : <></>}
                    </div>
                    :
                    <div className="mb-3">Are you sure, you want to delete this project ?</div>
                }
                </>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    handleFilterSelected = (function(filter_type){
        try{
            this.setState({selectedKey:filter_type.selectedKey[0]}, this.getallProjectList(filter_type.selectedKey[0]))
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    onSearch = (function(e){
        try{
            if (this.timeout) clearTimeout(this.timeout);
                let searchKey = e.target.value.trim()
                this.setState({
                    searchKey:searchKey,
                    isPageLoading:true
                })
                this.timeout = setTimeout(() => {
                    this.getallProjectList()
                }, 500);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    componentDidMount(){
        this.getallProjectList(this.state.dropDwownTitle)
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>My Team- Orrderly</title>
                </Helmet>
                <LoggedInLayout>
                    <Row className="task overdue_task mb-3">
                        <Col md={{span:8}} xs={{span:6}} className="left_col">
                            <h2 className="head">Project board</h2>
                        </Col>

                        {  this.state.planType < 30 ?<></>:
                            <Col md={{span:4}} xs={{span:6}} className="add-newboard">
                                <Button onClick={()=>{this.handleSelect({},'new')}} className="border-0">
                                    <span className="material-symbols-outlined">add</span>
                                    <span>Add new</span>
                                </Button>
                            </Col>
                        }
                    </Row>
                    <Row className="task overdue_task">
                        <Col md={{span:6}} xs={{span:7}} className="left_col filter_board">
                            <BootstrapSelect
                                options={this.state.filterTypeOptions}
                                onChange={(e) => this.handleFilterSelected(e)}
                                key={"filter board"}
                                icon={"icon-class-name"}
                            />
                        </Col>
                        <Col md={{span:6}} xs={{span:5}} className="search_project">
                            <InputGroup className="col-md-3 col-3 justify-content-end me_project">
                                <FormControl
                                    placeholder="Search project"
                                    name="overdue_search"
                                    id="overdue_search"
                                    value={this.state.searchKey}
                                    onChange={(e) => { this.onSearch(e) }}
                                ></FormControl>
                                <InputGroup.Text>
                                    <span className="material-icons-outlined">search</span>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="team_member mt-3">
                        <Col md={{span:12}} className="team-member-container">
                            <ProjectBoardList searchQuery={this.state.searchKey} onClick={(data,type)=>{this.handleSelect(data,type)}} projectBoardData={this.state.allProjectList}/>
                        </Col>
                    </Row>
                    <PopUpModal contentClassName="add_new_member_form" title={this.state.modelTitle} show={this.state.showCreateNewProjectModel} onChange={this.handleHideModal} >
                        <form onSubmit={(e) => {this.onFormSubmit(e)}} autoComplete='off'>
                            {this.modelChildrenHtml()}
                            <div className="d-grid pt-1">
                                <button className="btn btn-primary btn-block invite_sent_btn">
                                {this.state.showSpinner ? 
                                    <Spinner size="sm" animation="border" variant="light" /> 
                                    : this.state.buttonText
                                }
                                </button>
                            </div>
                        </form>
                    </PopUpModal>
                </LoggedInLayout>
            </>
        );
        }
    }
    
const mapStateToProps = (state) => ({
    allPojectlList:state.CurrentProjectBoard.all_project_list,
  });
  
const mapDispatchToProps = {update_current_project_board_id,fetch_all_project_list,loader};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBoard);
