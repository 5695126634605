import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Col, DropdownButton, Row } from 'react-bootstrap';
import axios from 'axios';
import APIs from '../../../../utils/APIs';
import { connect } from "react-redux";
import { update_current_project_board_id,fetch_all_project_list,global_search_query } from '../../../../reducers/CurrentProjectBoard'
import Looger from '../../../../utils/Logger';

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.activeProject = []
        this.allProjectList =[]
        this.state = {
            projectName:"",
            showSpinner:false,
            backGroundColor:'',
            axiosSource:axios.CancelToken.source()
		};
    }

    handleSelect = (function(projectNameId){
        try{
            this.props.global_search_query({searchQuery:""})
            if (projectNameId !== 'new' ) {
                let projectNameIdArray = projectNameId.split("-");
                this.setState({
                    projectName:projectNameIdArray[0],
                    backGroundColor:projectNameIdArray[2]
                })
                localStorage.setItem("project_id", projectNameIdArray[1]);
                localStorage.setItem("project_name", projectNameIdArray[0]);
                this.props.update_current_project_board_id({project_id:projectNameIdArray[1],project_name:projectNameIdArray[0]})
                this.activateSelectedProject(projectNameIdArray[3])
            }
            if (window.location.href.search('dashboard') === -1 ) {
                window.history.pushState({page: "WhatsAPPHome"+(new Date().getTime())}, "---", "/dashboard");
                window.location.reload()
            }
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}


    }).bind(this)

    activateSelectedProject =  (function(projectId){
        try{
            let token = localStorage.getItem('session_token');
            let param = { id: projectId};
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.patch(
                APIs.BASE_URL + APIs.UPDATE_GET_ACTIVE_PROJECT,
                param,
                config
            ).then((response) => {
                let data = response.data
                if (data.status_code === 200){
                    localStorage.setItem('allProjectList',JSON.stringify(data.data))
                    this.props.fetch_all_project_list({allProjectList: data.data})
                }
            }).catch((error)=>{
                // Capture Error Logs
			    Looger(error)
            })
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    }).bind(this)

    getallProjectList = (function(){
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_ALL_PROJECT_LIST,
                config
            ).then((response) => {
                let data = response.data
                if (data.status_code === 200){
                    if (data.data.length>0){
                        localStorage.setItem('allProjectList',JSON.stringify(data.data))
                        this.props.fetch_all_project_list({allProjectList: data.data})
                    }
                }
            }).catch((error)=>{
                Looger(error)
            })
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    }).bind(this)
      
    showAllProjectList = (function(e){
        try{
            let data = this.allProjectList
            return data.map((data,index)=>{
                return (
                    <Col md={{span:12}} className="dropdown_item" key={index}> 
                        <Row>
                            <Col md={{span:3}} xs={{span:3}} sm={{span:3}} className="ps-4 pe-1">
                                <div className="project_icon" style={{backgroundColor:data.color}}>
                                    <span>{data.name.charAt(0)}</span>
                                </div>
                            </Col>
                            <Col md={{span:9}} xs={{span:3}} sm={{span:3}} className="project_title">
                                <Dropdown.Item key={'projectlist'+index} eventKey={data.name+"-"+data.project_id+"-"+data.color+"-"+data.id}>{data.name}</Dropdown.Item>
                            </Col>
                        </Row>
                    </Col>
                )
            })
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}

    }).bind(this)

    onInputChangeHandler = (function(e){
        try{
            let  projectName = e.target.value 
            this.checkValidEmail(projectName);
            this.setState({
                newProjectName:projectName
            })
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    }).bind(this)

    toggleShowProjectListHandler  = (function(e){
        console.log('ddd');
       
    })

    componentDidMount(){
        this.getallProjectList()
    }

    render() {  

        let reduxActiveProjectInfo  = this.props.activeProjectInfo
        let reduxAllProjectList     = this.props.allPojectList
        if (! reduxActiveProjectInfo.length > 0){
            reduxAllProjectList     = JSON.parse(localStorage.getItem('allProjectList'))
            reduxActiveProjectInfo  = reduxAllProjectList.filter(CheckActiveProject)
            function CheckActiveProject(data){
                if (data.is_current === 1){
                    return data
                }
            }
        }
        this.allProjectList = reduxAllProjectList 
        this.activeProject = reduxActiveProjectInfo
        return (
        <>
            <Row className="task selection-board">
                    <Col md={{span:3}} className="view_sec pe-2">
                        <div className="project_icon" style={{backgroundColor:this.activeProject[0].color}}>
                            <span>{this.activeProject[0].name.charAt(0)}</span>
                        </div>
                    </Col>
                    <Col md={{span:8}} className="view_sec ps-0 pe-0" onClick={(e)=>{this.showAllProjectList(e)}}>
                        <DropdownButton
                            title={this.activeProject[0].name.substring(0,21)}
                            id="dropdown-basic-button"
                            className='project_name'
                            onSelect={this.handleSelect}
                            key='down'
                            drop='down'
                        >
                        {this.showAllProjectList()}
                        </DropdownButton>
                    </Col>
                    {/* <Col md={{span:3}} className="view_sec ps-0" style={{textAlign:"start",paddingLeft:"10px"}}>
                        <span  onClick={(e)=>{this.toggleShowProjectListHandler(e)}} className="material-symbols-outlined" style={{lineHeight: "27px"}}>expand_more</span>
                    </Col>
                    <Col md={{span:3}} className="view_sec ps-0" style={{textAlign:"start",paddingLeft:"10px"}}>
                        <NavLink  className="g_team" to="/board-team">
                            <span className="material-symbols-outlined">group_add</span>
                        </NavLink>
                    </Col> */}
            </Row>
        </>
        );
    }
}

// export default ProjectList;

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id,
    project_name:state.CurrentProjectBoard.project_name,
    allPojectList:state.CurrentProjectBoard.all_project_list,
    activeProjectInfo:state.CurrentProjectBoard.activeProjectInfo
  });
  
const mapDispatchToProps = { update_current_project_board_id,fetch_all_project_list,global_search_query };

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);