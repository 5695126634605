import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import PlanSelectionStep1 from './CompleteProfileSteps/PlanSelectionStep1';
import PlanSelectionStep2 from './CompleteProfileSteps/PlanSelectionStep2';
import PlanSelectionStep3 from './CompleteProfileSteps/PlanSelectionStep3';
import Looger from '../../../../../utils/Logger';

class TrailPackModal extends Component {
    constructor(props){
        super(props)
        this.state  = {
            showPopUp:props.showPopUp,
            finalPrice:0,
            unitPrice : 0,
            isPlanSelected : false,
            currency : "$",
            planID : null,
            planInfo : {},
            planStep : 1,
            planQuantity:1,
        }
    }

    userPlanSelect = (data)=>{
        try{
            this.setState({
                finalPrice:(data.unitPrice*data.planQuantity).toFixed(2),
                planQuantity : data.planQuantity,
                unitPrice : data.unitPrice,
                isPlanSelected : data.isPlanSelected,
                currency : data.currency,
                planID : data.planID,
                planInfo : data.planInfo,
                planStep : data.planStep
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    resetPlanSelection = ((data = null) => {
        try{
            if (data){
                this.userPlanSelect(data)
            } else {
                this.setState({
                    planQuantity : 1,
                    unitPrice : 0,
                    isPlanSelected : false,
                    currency : "$",
                    planID : null,
                    planInfo : {},
                    planStep : 1
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    render() {
        return (
            <Modal show={this.props.showPopUp}
            size="md"
            backdrop="static"
            className='profile-completion-steps'>
            <Modal.Body className="show-grid">
                {  (this.state.planStep === 1) ?
                    <PlanSelectionStep1 planQuantity={this.props.planData.quantity} modalTitle="Upgrade Your Plan" onPlanSelection={(data) => this.userPlanSelect(data)}/>
                    :  (this.state.planStep === 2) ?
                    <PlanSelectionStep2 planQuantity={this.props.planData.quantity} resetPlanSelection={() => this.resetPlanSelection()} onPlanSelection={(data) => this.userPlanSelect(data)} data={this.state} /> 
                    : (this.state.planStep === 3) ?
                    <PlanSelectionStep3 plantypes={'upgrade'}  planQuantity={this.props.planData.quantity} resetPlanSelection={(data) => this.resetPlanSelection(data)} onPlanSelection={(data) => this.userPlanSelect(data)} data={this.state} /> : <></>
                }
            </Modal.Body>
        </Modal>
        )
    }
}

export default  TrailPackModal
