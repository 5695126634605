import React, { Component } from 'react';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoggedInLayout from '../LoggedInLayout';
import axios from 'axios';
import APIs from '../../../../utils/APIs';
import Labels from '../../../../utils/Labels';
import Helmet from 'react-helmet';
import Looger from '../../../../utils/Logger';

const BirthDateCompoment = React.forwardRef(({ value, onClick }, ref) => (
    <div className="input-group">
        <input type="text" className="form-control shadow-none" name="dob" placeholder="Your birthday" id="dob" value={value === "" ? "Your birthday" : value} autoComplete="off" onClick={onClick} ref={ref} readOnly={true} />
    </div>
));
const DateOfBirthContainer = ({ className, children }) => {
    return (
      <div className='calendar-my-custom-container'>
        <CalendarContainer className={className}>
            <div className='calendar-label'>Birthday</div>
            <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
};

class Profile extends Component {
    constructor(props) {
        super(props);
        let oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 13);
        this.state = {
            showLoading : false,
            showAlert : false,
            showAlertMessage : "",
            showAlertType : "",
            userInfo : {},
            username_error_message : "",
            axiosSource : axios.CancelToken.source(),
            maxDate : oneYearFromNow,
            showImageCroppr:false,
            imageBase64:''
        };
    }

    loadProfileData = (function() {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.PROFILE_API,
                config
            ).then((response) => {
                let userInfo = response.data.data;
                if(userInfo.dob !== null) {
                    userInfo.dob = new Date(userInfo.dob);
                }
                this.setState({
                    userInfo: userInfo
                })
            }).catch((error) => {
                this.setState({
                    userInfo : {}
                })
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onInputChangeHandler = (function(e) {
        try{
            let userInfo = this.state.userInfo;
            if(e.target.name === "first_name") {
                userInfo.first_name = e.target.value;
            }
            if (e.target.name === "gmt") {
                userInfo.gmt = e.target.value;
            }
            if(userInfo.first_name.length === userInfo.first_name.trim().length || userInfo.first_name.length === userInfo.first_name.trim().length+1) {
                this.setState({
                    userInfo: userInfo
                });
            } else {
                userInfo.first_name = userInfo.first_name.replaceAll("  ", " ")
                this.setState({
                    userInfo: userInfo
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);
    
    getBase64 = file => {
        try{
            return new Promise(resolve => {
                let baseURL = "";
                // Make new FileReader
                let reader = new FileReader();

                // Convert the file to base64 text
                reader.readAsDataURL(file);

                // on reader load somthing...
                reader.onload = () => {
                    // Make a fileInfo Object
                    baseURL = reader.result;
                    resolve(baseURL);
                };
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    };

    onImageChange = (function(e) {
        try{
            this.uploadImage(e.target.name, e.target.files[0]);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    uploadImage = (function(imageFor, file){
        try{
            if(file === undefined) {
                return false;
            }
            let formData = new FormData();
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            formData.append('image_for', imageFor);
            formData.append('image', file);

            axios.patch(
                APIs.BASE_URL + APIs.PROFILE_PICTURE_API,
                formData,
                config
            ).then((response) => {
                let userInfo = response.data.data;
                //userInfo.dob = new Date(userInfo.dob);
                if(userInfo.dob !== null) {
                    userInfo.dob = new Date(userInfo.dob);
                }
                this.setState({
                    userInfo: userInfo,
                    showAlert: true,
                    showAlertMessage: (imageFor === "profile_image" ? "Profile" : "Cover") + " Image updated successfully",
                    showAlertType: "success",
                    showImageCroppr:true
                })
                localStorage.setItem('user', JSON.stringify(this.state.userInfo))
                if(imageFor === "profile_image") {
                    document.getElementsByClassName('border border-1 border-secondary rounded-circle')[0].src=userInfo.profile_image;
                    document.getElementById("user-name-top-bar").textContent = userInfo.first_name;
                    if(userInfo.first_name.trim() === "") {
                        document.getElementById("user-name-top-bar").textContent = userInfo.email;
                    }
                }
                document.getElementById('profile-image-form').reset();
                document.getElementById('cover-image-form').reset();
                setTimeout(() => {
                    this.setState({ showAlert: false });
                }, 5000);
            }).catch((error) => {
                document.getElementById('profile-image-form').reset()
                document.getElementById('cover-image-form').reset()
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleFormSubmit = (function (e) {
        try{
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                showLoading : true
            });
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            let data = {
                first_name : this.state.userInfo.first_name.trim(),
                dob: this.state.userInfo.dob !== null ? this.getDobString(this.state.userInfo.dob) : null,
                gmt : this.state.userInfo.gmt
            }

            axios.patch(
                APIs.BASE_URL + APIs.PROFILE_API,
                data,
                config
            ).then((response) => {
                localStorage.setItem('user', JSON.stringify(this.state.userInfo))
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    showAlertMessage: "Profile updated successfully",
                    showAlertType: "success",
                });
                document.getElementById("user-name-top-bar").textContent = this.state.userInfo.first_name;
                document.getElementsByClassName("top-nav-heading")[0].textContent = this.state.userInfo.first_name;
                if(this.state.userInfo.first_name.trim() === "") {
                    document.getElementById("user-name-top-bar").textContent = this.state.userInfo.email;
                    document.getElementsByClassName("top-nav-heading")[0].textContent = this.state.userInfo.email;
                }
                setTimeout(() => {
                    this.setState({ showAlert: false });
                }, 5000);
            }).catch((error) => {
                this.setState({
                    showLoading: false
                });
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getDobString(dob) {
        try{
            let dateObject = new Date(dob);
            let month = dateObject.getMonth() + 1;
            let day = dateObject.getDate();
            let year = dateObject.getFullYear();
            if (month < 10) {
                month = `0${month}`;
            }
            if (day < 10) {
                day = `0${day}`;
            }
            return `${year}-${month}-${day}`
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    setStartDate = (function (date) {
        try{
            let userInfo = this.state.userInfo;
            userInfo.dob =  date;
            this.setState({ userInfo: userInfo });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        if (Object.keys(this.state.userInfo).length === 0) {
            this.loadProfileData();
        }
        return (
            <>
                <LoggedInLayout>
                    <Helmet>
                        <title>My Profile - Orrderly</title>
                        <meta name="description" content="Personalize your account by setting up your Orrderly profile today. " />
                        {/*<meta name="keywords" content="Music, Audio, Lyrics" />*/}
                    </Helmet>
                    <Row className="task">
                        <Col md={{ span: 2 }} className="left_col">
                            <h2 className="head">Profile</h2>
                        </Col>
                        {!this.state.showAlert ? <></> :
                        <Col md={{ span: 10 }}>
                            <Alert variant={this.state.showAlertType} onClose={() => { this.setState({ showAlert: false})}} dismissible>
                                <p>{this.state.showAlertMessage}</p>
                            </Alert>
                        </Col>
                        }
                    </Row>
                    {
                    Object.keys(this.state.userInfo).length === 0 ? <></> :
                    <>
                        <Row>
                            <Col md={{span:12}} className="mt-4" style={{position:"relative"}}>
                                <img src={this.state.userInfo.cover_image} className="w-100 cover-image" alt={"cover-user-"+this.state.userInfo.first_name} />
                                <form className='cover-image-form' id="cover-image-form">
                                    <input type="file" id="cover_image" name="cover_image" className='d-none' accept='.png,.jpg,.jpeg' onChange={(e) => { this.onImageChange(e)}} />
                                    <label htmlFor="cover_image"><span className="material-icons-outlined">photo_camera</span></label>
                                </form>
                                {/* <ImageCropper hideCropper={() => {this.setState({showImageCroppr:false,imageBase64:'',})}} show={this.state.showImageCroppr} data = {this.state.imageBase64}/> */}
                            </Col>
                        </Row>
                        <Row className='mt-4 mb-4 add_new_member_form'>
                            <Col md={{span:6}}>
                                <form onSubmit={(e) => {this.handleFormSubmit(e)}} autoComplete='off'>
                                    <Row>
                                        <Col md={{span:12}}>
                                            <div className={this.state.firstNameError ? "mb-3 invalid" : "mb-3"}>
                                                <label className="label">Full Name</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control shadow-none" name="first_name" placeholder="Your full name" id="first_name" value={this.state.userInfo.first_name} onChange={(e) => { this.onInputChangeHandler(e) }} autoComplete="off" />
                                                </div>
                                                {this.state.firstNameError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.firstNameError}</div> : <></>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
                                                <label className="label">Email</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control shadow-none" name="email" placeholder="Your Email" id="email" value={this.state.userInfo.email} onChange={(e) => { this.onInputChangeHandler(e) }} autoComplete="off" readOnly={true} />
                                                </div>
                                                {this.state.emailError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.emailError}</div> : <></>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <div className='mb-3 dob'>
                                                <label className="label">Birthday</label>
                                                <DatePicker
                                                    selected={this.state.userInfo.dob}
                                                    showPopperArrow={false}
                                                    dateFormat="dd, MMMM yyyy"
                                                    onChange={(date) => this.setStartDate(date)}
                                                    maxDate={this.state.maxDate}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    calendarContainer={DateOfBirthContainer}
                                                    customInput={
                                                        <BirthDateCompoment value="Date of Birth" />
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
                                                <label className="label">Timezone</label>
                                                <div className="input-group">
                                                    <select className="form-control shadow-none" name="gmt" onChange={(e) => { this.onInputChangeHandler(e) }} defaultValue={this.state.userInfo.gmt}>
                                                        {
                                                            Labels.TIMEZONE_OPTIONS.map((data) => {
                                                                return (
                                                                    <option key={"timezone-" + data} value={data}>{data}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {this.state.timeZoneError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.timeZoneError}</div> : <></>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-grid pt-1 col-md-4 mt-2">
                                        <Button variant="dark" type="submit" disabled={this.state.showLoading}>
                                            {
                                                this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Save"
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </Col>
                            <Col md={{span:6}}>
                                <Row className="mt-3">
                                    <Col md={{ span: 6, offset:3 }} className="mt-4" style={{ position: "relative" }}>
                                        <img src={this.state.userInfo.profile_image} className="w-100 profile-image" alt={"profile-user-" + this.state.userInfo.first_name} />
                                        <form className='profile-image-form' id="profile-image-form">
                                            <input type="file" id="profile_image" name="profile_image" className='d-none' accept='.png,.jpg,.jpeg' onChange={(e) => { this.onImageChange(e) }} />
                                            <label htmlFor="profile_image"><span className="material-icons-outlined">photo_camera</span></label>
                                        </form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    }
                </LoggedInLayout>
            </>
        );
    }
}

export default Profile;