import { Component } from "react";
import { Alert, Button, Col, Form, Modal, Spinner, } from "react-bootstrap";
import validator from 'validator';
import axios from 'axios';
import APIs from "../../../../../utils/APIs";
import Cookies from "js-cookie";
import Looger from '../../../../../utils/Logger'
const Paddle = window.Paddle;

class UpgradePlanStep2 extends Component{
    constructor(props){
        super(props)
        let allEmails = []
        let allEmailsError = []
        for(let i=0;i<props.planQuantity;i++) {
            allEmails[i] = ''
            allEmailsError[i] = ''
        }
        this.state = {
            planQuantity:this.props.planQuantity,
            data:this.props.data,
            emailError: allEmailsError,
            email : allEmails,
            numbersOfEmailEmpty:0,
            showRemoveMemberMessage: "",
            showModal:false,
            axiosSource : axios.CancelToken.source(), 
            userData:JSON.parse(localStorage.getItem('user'))   
        }
    }

    checkValidEmail = (function (email, i) {
        try{
            let emailError = ''
            if (!validator.isEmail(email) && email.length > 0) {
                emailError = "Invalid email address";
            }
            let allEmails = this.state.email
            let allEmailsError = this.state.emailError
            
            allEmails[i] = email
            allEmailsError[i] = emailError

            this.setState({
                email: allEmails, 
                emailError:allEmailsError,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onInputChangeHandler = (function(event,i) {
        try{
            this.checkValidEmail(event.target.value,i)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
	}).bind(this);

    html = (function(i){
        try{
            let placeholder  = `Team member ${i+1} email here`
            return(
                <div key={i} className={this.state.emailError[i] ? "mb-1 invalid" : "mb-1"}>
                    <div key={i}  className="input-group">
                        <input type="text"  key={i} value={this.state.email[i]} onChange={(e)=>{this.onInputChangeHandler(e,i)}} className="form-control shadow-none" name="email" placeholder={placeholder} id={"email_"+i} autoComplete="off" />
                    </div>
                    {this.state.emailError[i] ?<div className='errorMessage'><i className="material-icons">warning</i>{this.state.emailError[i]}</div> : <div className='errorMessage'>&nbsp;</div>}
                </div>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    numberofTeamField = (function(teamQuantity){  
        try{
            let field = []
            for(let i=0; i < teamQuantity; i++){
                field.push(this.html(i))
            }
            return field 
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    checkoutClosed =(function(){
    
    })

    makeCheckout = (function(subscriptionPlanId) {
        try{
            let selectedPlanInfo = JSON.parse(atob(localStorage.getItem('plan_info')))
            let userInfo         = JSON.parse(localStorage.getItem('user'))
            //if(!(selectedPlanInfo.subscription_plan_id === subscriptionPlanId)) {
                if(selectedPlanInfo.subscription_plan_id > 100) {
                    if(subscriptionPlanId > 100) {
                        this.setState({
                            showModal:true,
                            modalTitle: "Subscription plan update!!",
                            modalBodyText: "You are about to change your subscription plan, Are you sure?",
                            buttonText : "UPDATE SUBSCRIPTION",
                            continueButtonVarient : "success",
                            closeButtonVarient:"danger",
                            paymentJson : {
                                type : "upgrade",
                                team_email:this.state.email,
                                teamQty     : this.props.totalqty,
                                subscription_id: selectedPlanInfo.subscription_id,
                                newPlanId : subscriptionPlanId,
                                modalActionTaken:false,
                                oldPlanId:this.props.oldPlanId
                            }
                        });
                    } else {
                        this.setState({
                            showModal:true,
                            modalTitle: "Subscription cancellation!!",
                            modalBodyText: "Your subscription will be disabled and billing will be turned off immediately. If you qualify for a credit, it will be processed within 60 days.",
                            buttonText : "CANCEL SUBSCRIPTION",
                            continueButtonVarient : "danger",
                            closeButtonVarient:"success",
                            paymentJson : {
                                type : "cancel",
                                subscription_id: selectedPlanInfo.subscription_id,
                                newPlanId : subscriptionPlanId,
                                modalActionTaken:false,
                                oldPlanId:this.props.oldPlanId
                            }
                        });
                    }
                } else if(selectedPlanInfo.subscription_plan_id < 100) {
                    if(subscriptionPlanId > 100) {
                        userInfo.type = 'disallow'
                        Paddle.Checkout.open({
                            product: subscriptionPlanId,
                            email : userInfo.email,
                            disableLogout:true,
                            successCallback: this.checkoutComplete,
                            closeCallback: this.checkoutClosed,
                            passthrough : userInfo,
                            quantity:this.props.totalqty
                            
                        });
                    } else {
                        // switch free plan
                        this.setState({
                            showModal:true,
                            modalTitle: "Subscription plan update!!",
                            modalBodyText: "You are about to change your subscription plan, Are you sure?",
                            buttonText : "UPDATE SUBSCRIPTION",
                            continueButtonVarient : "success",
                            closeButtonVarient:"danger",
                            paymentJson : {
                                allTeamEmail:this.state.email,
                                teamQty     : this.props.totalqty,
                                type : "free",
                                subscription_id: selectedPlanInfo.subscription_id,
                                newPlanId : subscriptionPlanId,
                                modalActionTaken:false,
                                oldPlanId:this.props.oldPlanId
                            }
                        });
                    }
                } else {
                    // Something else
                }
            //} else {
                // same plan selected no payment
            //}
            // Paddle.Spinner.show()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    handleCloseModalEvent = (function(e) {
        try{
            this.setState({
                showModal : false,
                paymentJson : {},
                modalActionTaken : false
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    handleSubmitModalEvent = (function(e) {
        try{
            let token = localStorage.getItem('session_token');
            let userData = localStorage.getItem('user');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            this.setState({ modalLoading : true});
            axios.post(
                APIs.BASE_URL + APIs.UPDATE_MY_PLAN,
                this.state.paymentJson,
                config
            ).then((response) => {
                setTimeout( () => {
                    let user = localStorage.getItem('session_token');
                    axios.post(
                        APIs.BASE_URL + APIs.REFRESH_TOKEN_API,
                        { refresh: localStorage.getItem('refresh_token') },
                        { headers: { "Authorization": `Bearer ${user}` }, cancelToken : this.state.axiosSource.token }
                    ).then((response1) => {
                        Cookies.set(btoa('user-data'), btoa(userData), { expires : 365, secure : true, domain : APIs.DOMAIN })
                        let data = response1.data;
                        localStorage.setItem("session_token", data.data.token.access);
                        localStorage.setItem("refresh_token", data.data.token.refresh);
                        localStorage.setItem('plan_info', btoa(JSON.stringify( data.data.plan_info)))
                        let userSelectedPlan = data.data.plan_info;
                        let message = ""
                        if(this.state.buttonText === "CANCEL SUBSCRIPTION") {
                            message = "Your current plan has been deactivated and now you are now on free plan"
                        } else {
                            message = "Your subscription plan has been updated successfully"
                        }
                        this.setState({
                            paymentJson : {},
                            modalLoading : false,
                            modalBodyText: message,
                            modalActionTaken : true,
                            userSelectedPlan : userSelectedPlan
                        });
                        setTimeout(
                            () => this.setState({ showModal: false, modalActionTaken:false }), 
                            3000
                        );
                    }).catch((error) => {
                        Cookies.remove(btoa('user-data'))
                        localStorage.removeItem("session_token");
                        localStorage.removeItem("refresh_token");
                        localStorage.removeItem("user");
                        localStorage.removeItem("plan_info");
                        window.location.href = "/";
                        this.setState({
                            paymentJson : {},
                            modalLoading : false,
                            modalBodyText: "There was an error, Please contact administrator",
                            modalActionTaken : true
                        })
                    })
                }, 1000);
                window.location.href = '/team'
            }).catch((error) => {
                this.setState({
                    paymentJson : {},
                    modalLoading : false,
                    modalBodyText: "There was an error, try again later",
                    modalActionTaken : true
                })
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    addTeamMember = (function(e){
        try{
            e.preventDefault();
            this.setState({
                showRemoveMemberMessage:""
            })
            let allEmailError = this.state.emailError
            let allEmails = this.state.email
            let numOfError = 0
            for (let i = 0; i < allEmailError.length; i++) {
                if (allEmailError[i] === "Invalid email address") { //increment if true
                    numOfError++; 
                }
            }
            let numOfEmailEmpty = 0
            for (let i = 0; i < allEmails.length; i++) {
                if (allEmails[i] === '') { //increment if true
                    numOfEmailEmpty++; 
                }
            }
            if (numOfError === 0 && numOfEmailEmpty === 0){

                let allTeamEmail = this.state.email
                const count = {};
                let dublicateEmailarray  = []
                allTeamEmail.forEach(element => {
                count[element] = (count[element] || 0) + 1;
                dublicateEmailarray.push(count[element])
                });
                let dublicateEmail = Math.max(...dublicateEmailarray)

                if ( dublicateEmail === 1){
                    let token       =   localStorage.getItem('session_token');
                    let config      =   { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                    axios.post(
                        APIs.BASE_URL + APIs.TEAM_MEMBER_VALIDATION_API,
                        allTeamEmail,
                        config
                    ).then((response) => {
                        let data = response.data
                        if (data.status_code === 401){
                            this.setState({
                                emailError:data.data
                            })
                        }else{
                            this.setState({
                                email:allTeamEmail
                            })
                            this.makeCheckout(this.props.data.userSelectedPlan.plan_id)
                        }
                    }).catch((error) => {
                        console.log(error)
                    });

                }else{
                    this.setState({
                        showRemoveMemberMessage:'Email duplicate entry not allowed'
                    })
                }
            }
            else {
                if (numOfEmailEmpty > 0){
                    let emptyEmail = []
                    for(let i = 0;i<numOfEmailEmpty;i++){
                        emptyEmail[i] = 'Email field can’t be empty'
                    }
                    this.setState({
                        emailError:emptyEmail
                    })
                }
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    checkoutComplete = (data) => {
        try{
            this.setState({
                paymentJson : {
                    type : "new",
                    team_email:this.state.email,
                    teamQty     : this.props.totalqty,
                    subscription_id: 0,
                    newPlanId : 0,
                    oldPlanId:0,
                    modalActionTaken:false
                }
            })
            this.handleSubmitModalEvent()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    render(){
        return(
            <>
            <Col md={{span:12}} className="mt-2 mb-2">
                <Col md={{span:6}}>
                    <div className='plan-step-1-0'>
                        <div className="plan-step-inner-1-0" onClick={()=>{this.props.onChange({stepType:'back',planQuantity:this.state.planQuantity})}}>
                            <span className="material-symbols-outlined">keyboard_backspace</span>
                            <span className='plan-step'>Payment details</span>
                        </div>  
                    </div>
                </Col>
                <Col md={{span:8}} className="mt-1 plan-step3 plan-final-step">
                    <div className='plan-step-2-1'>
                        <div className="plan-step-inner-2-1">
                            <span className='plan-step-guide'>We realized that you are logged in as {this.state.userData.email}. This account has been selected as the team lead account, input the emails for the other accounts you are paying for</span>
                        </div>  
                    </div>
                    <div className='plan-step-2-2 mt-3'>
                        <Form>
                            {this.numberofTeamField(this.state.planQuantity)}
                        </Form>
                    </div>
                    <div className='plan-step-2-3 mt-4'>
                        <div className="plan-step-inner-2-3">
                            <button variant="dark" onClick={(e)=>{this.addTeamMember(e)}} className="btn dlt-btn next-step-btn">Continue</button>
                            {/* <button variant="dark" onClick={(e)=>{this.makeCheckout(this.props.data.userSelectedPlan.plan_id)}} className="btn dlt-btn next-step-btn">Continue</button> */}
                        </div>  
                    </div>
                    { this.state.showRemoveMemberMessage !== "" ?
                        <Alert variant="warning" style={{display:'flex',justifyContent:'space-between'}} dismissible className='remove-member-message mt-4 close'>
                            <Col  md={{span:8}}  className='d-block'>
                                <span className="message">{this.state.showRemoveMemberMessage}</span>
                            </Col>
                        </Alert>
                        : ""
                    }
                </Col>
            </Col>
                {
                    !this.state.showModal?<></>:
                        <Modal show={this.state.showModal} onHide={this.handleCloseModalEvent}>
                        {
                            this.state.modalLoading ? <div style={{display: "flex",justifyContent: "center",alignItems: "center", height: "100px"}}><Spinner animation="border" variant="primary" /></div> :
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.state.modalTitle}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{this.state.modalBodyText}</Modal.Body>
                                {
                                    this.state.modalActionTaken ? <></> :
                                    <>
                                        <Modal.Footer>
                                            <Button variant={this.state.closeButtonVarient} onClick={this.handleCloseModalEvent}>Close</Button>
                                            <Button variant={this.state.continueButtonVarient} onClick={this.handleSubmitModalEvent}>{this.state.buttonText}</Button>
                                        </Modal.Footer>
                                    </>
                                }
                            </>
                        }
                    </Modal>
                }
            </>
        )
    }
}  

export default UpgradePlanStep2


