import React, { Component } from 'react';
import AppRoutes from './AppRoutes';

class App extends Component {
	render() {
		return (
			<>
				<AppRoutes />
			</>
		);
	}
}

export default App;