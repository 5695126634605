import React, { Component } from 'react';
import { Col, Row,  } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import GetPlans from './GetPlans';
import { connect } from "react-redux";
import Looger from '../../../../../utils/Logger';
import { loader } from '../../../../../reducers/CurrentProjectBoard';

class PlansSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab:"month"
        }
    }

    selectedTab = (function(e){
        try{
            this.setState({
                selectedTab:e.target.value
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount(){
        this.props.loader({loader:false})
    }

    render() {
        return (
            <Row>
                <Helmet>
                    <title>Plans &amp; Billing - Orrderly</title>
                </Helmet>
                <Col md={{ span: 12 }}>
                    <Row className="notify_settings">
                        <Col md={{span:6}} xs={{span:5}} sm={{span:6}} className="d-flex align-items-center">
                            <h4>Plans and billings</h4>
                        </Col>
                        <Col md={{span:6}} xs={{span:7}} sm={{span:6}}  className="d-flex align-items-center justify-content-end py-2 plan_tab">
                            <button type="button" value='month' className={ this.state.selectedTab === "month" ? "btn default_background active":"btn"} onClick={(e)=>{ this.selectedTab(e)}} data-bs-toggle="button" autoComplete="off">Monthly</button>
                            <button type="button" value='year' className={ this.state.selectedTab === "year" ? "btn default_background active":"btn"} onClick={(e)=>{ this.selectedTab(e)}} data-bs-toggle="button" autoComplete="off">Yearly</button>
                        </Col>
                    </Row>
                </Col>
                <Col md={{span:12}}>
                    <Row>
                        <GetPlans key={new Date().getTime()} type={this.state.selectedTab} />
                    </Row>
                </Col>
            </Row>
        );
    }
}
const mapStateToProps = (state) => ({
    loader: state.CurrentProjectBoard.loader,
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(PlansSettings);