import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, Col, Row, Tabs, Tab, Spinner } from 'react-bootstrap';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago';
import DatePicker, {CalendarContainer} from "react-datepicker";
import validator from "validator";
import "react-datepicker/dist/react-datepicker.css";
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';
import APIs from '../../../../../utils/APIs';
import Labels from '../../../../../utils/Labels';
import AddTags from '../AddTags';
import { connect } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import GetTaskAssignee from '../GetTaskAssignee';
import ColorPallet from './ColorPallet'
import ShareTask from './ShareTask';
import Looger from '../../../../../utils/Logger';

TimeAgo.addLocale(en)
const StartDateCompoment = React.forwardRef(({ value, onClick }, ref) => (
    <Button variant="light" className={value === "" ? "empty-input" : "input-added"} onClick={onClick} ref={ref}>{value === "" ? "Start date" : value}</Button>
));
const EndDateCompoment = React.forwardRef(({ value, onClick }, ref) => (
    <Button variant="light" className={value === "" ? "empty-input" : "input-added"} onClick={onClick} ref={ref}>{value === "" ? "Deadline date" : value}</Button>
));
const StartTimeCompoment = React.forwardRef(({ value, onClick }, ref) => (
    <Button variant="light" className={value === "" ? "empty-input" : "input-added"} onClick={onClick} ref={ref}>{value === "" ? "--:--" : value}</Button>
));
const EndTimeCompoment = React.forwardRef(({ value, onClick }, ref) => (
    <Button variant="light" className={value === "" ? "empty-input" : "input-added"} onClick={onClick} ref={ref}>{value === "" ? "--:--" : value}</Button>
));

const StartDateContainer = ({ className, children }) => {
    try{
        return (
        <div className='calendar-my-custom-container'>
            <CalendarContainer className={className}>
                <div className='calendar-label'>Start date</div>
                <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
        </div>
        );
    } catch(error) {
        // Capture Error Logs
        Looger(error)
    }
};

const DeadLineDateContainer = ({ className, children }) => {
    try{
        return (
            <div className='calendar-my-custom-container'>
            <CalendarContainer className={className}>
                <div className='calendar-label'>Deadline Date</div>
                <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
        </div>
        );
    } catch(error) {
        // Capture Error Logs
        Looger(error)
    }
};

function bytesToSize(bytes) {
    try{
        if(bytes === undefined) {
            return "0 byte"
        }
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    } catch(error) {
        // Capture Error Logs
        Looger(error)
    }
}

class AddTaskForm extends Component {
    constructor(props) {
        super(props);

        let taskData = props.taskFormData;
        if(!taskData.type) {
            taskData.type = "one_time";
            taskData.assigned_to_id  = []
        }

        let taskType = Labels.TASK_TYPE.map((data) => {
            data.isSelected = false;
            if (taskData.id > 0) {
                data.isSelected = false;
            }
            if (taskData.type === data.labelKey) {
                data.isSelected = true;
            }
            return data;
        });

        let taskFrequency = Labels.TASK_FREQUENCY.map((data) => {
            if (taskData.id > 0) {
                data.isSelected = false;
            }
            if (taskData.frequency === data.labelKey) {
                data.isSelected = true;
            }
            return data;
        });
        let propsAssigneeOptions = props.assigneeOption ? props.assigneeOption : [];


        let assigneeOption = propsAssigneeOptions.map((data) => {
            if (taskData.id > 0) {
                data.isSelected = false;
            }
            if (taskData.assigned_to_id.indexOf(data.labelKey) > -1) {
                data.isSelected = true;
            } else {
                data.isSelected = false;
            }
            return data;
        });

        
       let filterAssigneeOption =  assigneeOption.filter(checkTeam);
        function checkTeam(data) {
            return data.isSelected === true
        }

        let showFrequency = false
        if (taskData.type && taskData.type === "recurring") {
            showFrequency = true;
        }

        let taskFormType = "Add";
        if (taskData.id && taskData.id > 0) {
            taskFormType = "Edit";
        }
        let userSelectedPlan = props.PlanFeatures;
        let owner_id = userSelectedPlan.project_owner_id
        let PlanFeatures = [];
        for(let i=0; i< userSelectedPlan.features.length;i++) {
            PlanFeatures[userSelectedPlan.features[i].label.replaceAll(" ", "_")] = userSelectedPlan.features[i].is_active
        }
        if(taskData.my_custom_status) {
            taskData.status = taskData.my_custom_status;
        }
        var nowTaskStartDate = new Date();
        nowTaskStartDate.setMinutes(nowTaskStartDate.getMinutes() + 30);
        nowTaskStartDate = new Date(nowTaskStartDate);

        this.state = {
            validated:false,
            taskFormType: taskFormType,
            task_id: (taskData.id ? taskData.id : ""),
            taskNameError : "",
            taskDescriptionError : "",
            taskTypeError: "",
            taskFrequencyError: "",
            taskEndDateError: "",
            taskAssigneeError: "",
            isDoneTask: taskData.status === "done" ? "active" : "",
            showFrequency: showFrequency,
            taskTypeOptions: taskType,
            tabStatusKey: "comments",
            textAreaKey: Date.now(),
            textAreaKeyForComment: Date.now(),
            tempSubTaskError : "",
            tempCommentError : "",
            tempSubTask : "",
            tempComment : "",
            task_status : taskData.status ? taskData.status : "yet_to_start",
            task_statusModel : taskData.status ? taskData.status : "yet_to_start",
            taskSubTasks : [],
            errorSubTasks : [],
            taskComments : [],
            taskTags: (taskData.tags ? taskData.tags : []),
            taskFrequencyOptions: taskFrequency,
            task_name: (taskData.task_name ? taskData.task_name : ""),
            task_description: (taskData.task_description ? taskData.task_description : ""),
            start_date: (taskData.start_date ? taskData.start_date : new Date()),
            end_date: (taskData.end_date ? taskData.end_date : nowTaskStartDate),
            assigned_to_id: (taskData.assigned_to_id ? taskData.assigned_to_id : []),
            assigned_by_id: (taskData.assigned_by_id ? taskData.assigned_by_id : ""),
            type: (taskData.type ? taskData.type : ""),
            frequency: (taskData.frequency ? taskData.frequency : ""),
            tags: (taskData.tags ? taskData.tags : ""),
            assigneeOption: assigneeOption,
            assigneeOptionKey : "some-key",
            start_date_button : "Start date",
            showTagsModal : false,
            showLoading : false,
            showMainCommentEmoji : false,
            showReplyCommentEmoji : false,
            isOverdue:true,
            comment_attachment : null,
            mainCommentEmoji : null,
            replyCommentEmoji : null,
            subTaskText:null,
            PlanFeatures : PlanFeatures,
            sub_task_count:taskData.sub_task_count,
            comments_count:taskData.comments_count,
            user_id: JSON.parse(localStorage.getItem("user")).id,
            replayloder:false,
            editCommentId:0,
            editSubTaskId:0,
            comments:"",
            showEditCommentModel:false,
            showEditSubTaskModel:false,
            commentTextArea:false,
            selectedAssignee:filterAssigneeOption,
            owner_id:owner_id,
            projectOwnerId:0,
            enabelEditor:false,
            showAssigneeDropDown:false,
            allSubTask:[],
            allSubTaskError:[],
            defaultColor:(taskData.color_code ? taskData.color_code : "5eafdc"),
            showShareTaskModal:false,
            taskUrl:taskData.share_url,
            axiosSource : axios.CancelToken.source(),
            
        }   
        this.onEmojiClickFunc = this.onEmojiClickFunc.bind(this);
        this.onEmojiClickFuncReply = this.onEmojiClickFuncReply.bind(this);
    }

    onInputChange = (function(e) {
        try{
            this.setState({
                [e.target.name] : e.target.value
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    setFileInState = (function(e) {
        try{
            this.setState({[e.target.name] : e.target.files[0]});
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    updateTaskStatus = (function () {
        try{
            let taskStatus = this.state.task_status === "yet_to_start" ? "done" : "yet_to_start";
            let isDoneTask = taskStatus === "done" ? "active" : "";
            this.setState({
                task_status: taskStatus,
                isDoneTask: isDoneTask,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onDeleteTask = (function () {
        try{
            let data = {
                id : this.state.task_id,
                task_name: this.state.task_name
            }
            this.props.onDeleteTask(data)
            this.props.handleHideModal()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    setDropdownValue = (function(e, DropDownFor = "task_type") {
        try{
            if (DropDownFor === "task_type") {
                let taskTypeOptions = this.state.taskTypeOptions.map((data) => {
                    data.isSelected = data.labelKey === e.selectedKey[0] ? true : false;
                    if(data.isSelected) {
                        this.setState({ type: data.labelKey });
                    }
                    return data;
                });
                this.setState({ taskTypeOptions: taskTypeOptions, showFrequency: (e.selectedKey[0] === "recurring") ? true : false });
            } else if (DropDownFor === "task_frequency") {
                let taskFrequencyOptions = this.state.taskFrequencyOptions.map((data) => {
                    data.isSelected = data.labelKey === e.selectedKey[0] ? true : false;
                    if (data.isSelected) {
                        this.setState({ frequency: data.labelKey });
                    }
                    return data;
                });
                this.setState({ taskFrequencyOptions: taskFrequencyOptions });
            } else if (DropDownFor === "task_assignee") {
                let assignedToId = []
                e.map((data,index) => {
                    assignedToId.push(data.labelKey)
                    return null
                });
                this.setState({ assigned_to_id: assignedToId });
                this.setState({ selectedAssignee: e });
                

            } else if (DropDownFor === "task_assignee_remove") {
                let assignedToId = []
                e.map((data,index) => {
                    assignedToId.push(data.labelKey)
                    return null
                });
                this.setState({ assigned_to_id: assignedToId });
                this.setState({ selectedAssignee: e });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    onEmojiClickFunc (event, emojiObject) {
        try{
            this.setState({
                mainCommentEmoji: emojiObject.originalUnified,
                showMainCommentEmoji: false
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    toggleEmojiForReply (commentID) {
        try{
            if(commentID !== this.state.showReplyCommentEmojiForID) {
                this.setState({
                showReplyCommentEmoji: true,
                showReplyCommentEmojiForID: commentID,
                showMainCommentEmoji: false
                });
            } else {
                this.setState({
                    showReplyCommentEmoji: !this.state.showReplyCommentEmoji,
                    showReplyCommentEmojiForID: commentID,
                    showMainCommentEmoji: false
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    onEmojiClickFuncReply (event, emojiObject, commentID = null) {
        try{
            let comments = this.state.taskComments[0].comments;
            this.setState({
                replyCommentEmoji: emojiObject.originalUnified,
                showReplyCommentEmoji: false
            });
            let params = "";
            if(commentID === null){
            params = { "comment_id":this.state.showReplyCommentEmojiForID,"comment":comments, "task_id":this.state.task_id, "emoji":emojiObject.originalUnified };
            } else {

            params = { "comment_id":commentID, "task_id":this.state.task_id, "emoji":emojiObject.originalUnified };
            }

            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.patch(
            APIs.BASE_URL +  APIs.UPDATE_TASK_COMMENT_EMOJI_API,
            params,
            config
            ).then((response) => {
                this.setState({taskComments:response.data.data.all_comments})
                /*let key = this.state.taskComments.findIndex((element) => element.id === this.state.showReplyCommentEmojiForID);
                if(key > -1) {
                    taskComments[key].emoji = response.data.data;
                    this.setState({
                        taskComments : taskComments
                    });
                } else {
                    let childCommentPosition = "";
                    let parentCommentPosition = "";
                    this.state.taskComments.map((data, index) => {
                        let key = data.child_comment.findIndex((element) => element.id === this.state.showReplyCommentEmojiForID);
                        if(key > -1) {
                            childCommentPosition = key;
                            parentCommentPosition = index;
                        }
                        return false;
                    })
                    taskComments[parentCommentPosition]['child_comment'][childCommentPosition].emoji = response.data.data;
                    this.setState({
                        taskComments : taskComments
                    });
                }*/
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    checkFormSubmittion = (function(e) {
        try{
            e.preventDefault();
            let taskNameError           =   "";
            let taskDescriptionError    =   "";
            let taskTypeError           =   "";
            let taskFrequencyError      =   "";
            let taskEndDateError        =   "";
            let taskAssigneeError       =   "";

            let task_name = this.state.task_name;
            let description = this.state.task_description;
            this.setState({showLoading:true});

            if (validator.isEmpty(task_name)) {
                taskNameError = "Enter a valid task name";
            } else if (!validator.isAlphanumeric(task_name, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && task_name.length > 0) {
                taskNameError = "Enter a valid task name";
            }

            if (!validator.isAlphanumeric(description, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && description.length > 0) {
                taskDescriptionError = "Enter a valid task description";
            }

            if (this.state.type.length === 0) {
                taskTypeError = this.generateErrorObject("Please select task type");
            }

            if (this.state.type === "recurring") {
                if (this.state.frequency.length === 0) {
                    taskFrequencyError = this.generateErrorObject("Please select frequency of task");
                }
            }

            let start_date = this.state.start_date;
            let end_date = this.state.end_date;

            if (this.state.type === "one_time") {
                if (this.state.start_date === "" || this.state.end_date === "") {
                    // taskEndDateError = this.generateErrorObject("Please select task dates");
                    if (this.state.start_date === "") {
                        start_date = new Date();
                        start_date.setHours(0, 0, 0, 0);
                    } else {
                        start_date = this.state.start_date;
                    }
                    if (this.state.start_date === "") {
                        end_date = new Date(start_date.getTime());
                        end_date.setHours(23, 59, 0, 0);
                    } else {
                        end_date = this.state.end_date;
                    }
                    this.setState({
                        start_date: start_date,
                        end_date: end_date,
                    });
                } else if (new Date(this.state.start_date).getTime() > new Date(this.state.end_date).getTime()) {
                    taskEndDateError = this.generateErrorObject("Deadline date must be greater than start date");
                }
            } else {
                if (this.state.start_date === "") {
                    // taskEndDateError = this.generateErrorObject("Please select task start dates");
                    if (this.state.start_date === "") {
                        start_date = new Date();
                        start_date.setHours(0, 0, 0, 0);
                    } else {
                        start_date = this.state.start_date;
                    }
                    this.setState({
                        start_date: start_date,
                    });
                }
            }
            let assigned_to_id = this.state.assigned_to_id;
            if (this.state.assigned_to_id.length === 0) {
                //taskAssigneeError = this.generateErrorObject("Please select task assignee");
                let user = JSON.parse(localStorage.getItem('user'));
                assigned_to_id = [user.id];
            }

            this.setState({
                taskTypeError: taskTypeError,
                taskFrequencyError: taskFrequencyError,
                taskEndDateError: taskEndDateError,
                taskAssigneeError: taskAssigneeError,
                taskDescriptionError: taskDescriptionError,
                taskNameError: taskNameError,
            });
            if (!(taskTypeError !== "" || taskFrequencyError !== "" || taskEndDateError !== "" || taskAssigneeError !== "" || taskDescriptionError !== "" || taskNameError !== "")) {
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                let assignee = assigned_to_id;
                let frequency = null;
                let taskType = this.state.type;
                let startDate = this.getFormatedDate(start_date);
                let endDate = null;

                if (taskType !== "one_time") {
                    frequency = this.state.frequency.toLowerCase();
                }
                if (taskType !== "recurring") {
                    endDate = this.getFormatedDate(end_date);
                }
                let section_id = null;
                if(this.props.taskFormData.section_id) {
                    section_id = this.props.taskFormData.section_id
                }

                let project_id = this.props.project_id
                if (isNaN(project_id)){
                    project_id = parseInt(localStorage.getItem('project_id'))
                }
                let params = {
                    task_name: task_name,
                    task_description: description,
                    type: taskType,
                    assigned_to: assignee,
                    frequency: frequency,
                    start_date: startDate,
                    end_date: endDate,
                    tags: this.state.taskTags,
                    status : this.state.task_status,
                    section : section_id,
                    board:project_id,
                    color_code:this.state.defaultColor
                }
                if(this.state.taskFormType === "Add") {
                    params.comments = this.state.taskComments;
                    params.sub_task = this.state.taskSubTasks;
                } else {
                    params.id  = this.state.task_id;
                }
                if (this.state.taskFormType === "Add") {
                    axios.post(
                        APIs.BASE_URL + APIs.CREATE_TASK_API,
                        params,
                        config
                    ).then((response) => {
                        if (response.data.status_code === 401) {
                            taskNameError = response.data.message
                        }
                        this.setState({
                            showLoading : false,
                            taskNameError:taskNameError
                        });
                        let taskID = response.data.data.id;
                        this.props.onTaskAdd(taskID);
                    }).catch((error) => {
                        // Capture Error Logs
                        Looger(error)
                    });
                } else {
                    axios.patch(
                        APIs.BASE_URL + APIs.UPDATE_TASK_API,
                        params,
                        config
                    ).then((response) => {
                        this.props.onTaskAdd(this.state.task_id);
                    }).catch((error) => {
                        // Capture Error Logs
                        Looger(error)
                    });
                }
            } else {
                this.setState({showLoading: false});
            }
            e.stopPropagation()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getFormatedDate = (date) => {
        try{
            let dateObject = new Date(date);
            let month = dateObject.getMonth() + 1;
            let day = dateObject.getDate();
            let year = dateObject.getFullYear();
            let hours = dateObject.getHours();
            let minutes = dateObject.getMinutes();
            let seconds = "00";
            //eslint-disable-next-line
            let timezone = dateObject.toString().match(/([-\+][0-9]+)\s/)[1];
            timezone = timezone.substr(0, 3) + ":" + timezone.substr(3);
            if (month < 10) {
                month = `0${month}`;
            }
            if (day < 10) {
                day = `0${day}`;
            }
            if (hours < 10) {
                hours = `0${hours}`;
            }
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${timezone}`
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    generateErrorObject = (function (message) {
        try{
            return (
                <div className="errorMessage"><i className="material-icons">warning</i>{message}</div>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
 
    setStartDate = (function (date) {
        try{
            let end_date = new Date(date);
            end_date.setHours(23,59,59,0)
            if(this.state.end_date === ""){
                this.setState({ start_date: date, end_date: end_date });
            } else {
                this.setState({ start_date: date});
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    setEndDate = (function (date) {
        try{
            this.setState({ end_date: date });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    addComment = (function(e) {
        try{
            e.preventDefault()
            let tempCommentError = "";
            let comments = this.state.tempComment;
            let File_Type = "none";
            // if ( ! this.state.comment_attachment === ''){
                if (!(this.state.comment_attachment === null || this.state.comment_attachment === undefined)) {
                    File_Type = this.state.comment_attachment.type.split("/")[0];
                    if (File_Type === "application") {
                        File_Type = this.state.comment_attachment.type.split("/")[1];
                        if (Labels.FILE_TYPES[File_Type] !== undefined) {
                            File_Type = Labels.FILE_TYPES[File_Type]
                        }
                    }
                    File_Type = File_Type + "|" + this.state.comment_attachment.size;
                }
            // }
            
            if (validator.isEmpty(comments)) {
                tempCommentError = "Enter a valid comment";
            } else if (!validator.isAlphanumeric(comments, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && comments.length > 0) {
                // ,.:!\/@#$%&*?()[]{}-'s
                tempCommentError = "Enter a valid comment";
            }
            let taskComments = this.state.taskComments;
            if (taskComments.findIndex(i => i.comments === comments) > -1) {
                tempCommentError = "Comment already added.";
            }
            if (tempCommentError === "") {
                this.setState({isPublishButtonDisable : true})
                if (this.state.task_id > 0) {
                    let token = localStorage.getItem('session_token');
                    let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                    // mainCommentEmoji
                    let emoji = "";
                    if (this.state.mainCommentEmoji) {
                        emoji = this.state.mainCommentEmoji;
                    }
                    let params = { task: this.state.task_id, comment: comments, parent_comment: 0, emoji: emoji }
                    axios.post(
                        APIs.BASE_URL + APIs.CREATE_TASK_COMMENT_API,
                        params,
                        config
                    ).then((response) => {
                        response.data.data.child_comment = [];
                        response.data.data.files = [];
                        if (emoji !== "") {
                            if (!Array.isArray(response.data.data.emoji)) {
                                response.data.data.emoji = []
                            }
                            response.data.data.emoji.push({ "emoji": emoji, comment_id: response.data.data.id})
                        } else {
                            if (!Array.isArray(response.data.data.emoji)) {
                                response.data.data.emoji = []
                            }
                        }
                        if (File_Type === "none") {
                            taskComments.unshift(response.data.data);
                            this.setState({
                                taskComments: taskComments,
                                tempComment: "",
                                tempCommentError: "",
                                comment_attachment: null,
                                textAreaKeyForComment: Date.now(),
                                mainCommentEmoji:null,
                                isPublishButtonDisable : false,
                                comments_count:response.data.data.comment_count
                                
                            });
                        } else {
                            let formData = new FormData();
                            formData.append('type', File_Type);
                            formData.append('comment_id', response.data.data.id);
                            formData.append('file', this.state.comment_attachment);
                            axios.post(APIs.BASE_URL + APIs.ADD_TASK_COMMENT_FILE_API, formData, config).then((res) => {
                                response.data.data.child_comment = [];
                                response.data.data.files = [];
                                response.data.data.files.unshift(res.data.data);
                                taskComments.unshift(response.data.data);
                                this.setState({
                                    taskComments: taskComments,
                                    tempComment: "",
                                    tempCommentError: "",
                                    comment_attachment: null,
                                    textAreaKeyForComment: Date.now(),
                                    mainCommentEmoji: null,
                                    isPublishButtonDisable : false,
                                    comments_count:response.data.data.comment_count
                                });
                            }).catch((error) => {
                                response.data.data.child_comment = [];
                                response.data.data.files = [];
                                taskComments.unshift(response.data.data);
                                this.setState({
                                    taskComments: taskComments,
                                    tempComment: "",
                                    tempCommentError: "",
                                    comment_attachment: null,
                                    textAreaKeyForComment: Date.now(),
                                    mainCommentEmoji: null,
                                    isPublishButtonDisable : false
                                });
                            })
                        }
                    }).catch((error) => {
                        // Capture Error Logs
                        Looger(error)
                    })
                } else {
                    let user = JSON.parse(localStorage.getItem('user'));
                    let userName = user.email;
                    if (user.first_name !== "") {
                        userName = `${user.first_name} ${user.last_name}`;
                    }

                    let emoji = [];
                    if(this.state.mainCommentEmoji) {
                        emoji.push({emoji: this.state.mainCommentEmoji, comment_id: 0})
                    }
                    this.setState({mainCommentEmoji:"",comment_attachment:null})

                    if (File_Type === "none") {
                        let newComment = {
                            id : 0,
                            task_id : 0,
                            user_id: user.id,
                            parent_comment : 0,
                            comments: comments,
                            user_name: userName,
                            profile_image: user.profile_image,
                            created_at: new Date().getTime()/1000,
                            child_comment : [],
                            files : [],
                            emoji : emoji
                        };
        
                        taskComments.unshift(newComment);
                        this.setState({
                            taskComments: taskComments,
                            tempComment: "",
                            tempCommentError: "",
                            textAreaKeyForComment: Date.now(),
                            isPublishButtonDisable : false
                        });
                    } else {

                        let token = localStorage.getItem('session_token');
                        let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                        
                        let formData = new FormData();
                        formData.append('type', File_Type);
                        formData.append('comment_id', '');
                        formData.append('file', this.state.comment_attachment);

                        axios.post(APIs.BASE_URL + APIs.ADD_TASK_COMMENT_FILE_API, formData, config).then((res) => {
                            let newComment = {
                                id : 0,
                                task_id : 0,
                                user_id: user.id,
                                parent_comment : 0,
                                comments: comments,
                                user_name: userName,
                                profile_image: user.profile_image,
                                created_at: new Date().getTime()/1000,
                                child_comment : [],
                                files : [res.data.data],
                                emoji : emoji
                            };
            
                            taskComments.unshift(newComment);
                            this.setState({
                                taskComments: taskComments,
                                tempComment: "",
                                tempCommentError: "",
                                textAreaKeyForComment: Date.now(),
                                isPublishButtonDisable : false
                            });
                        }).catch((error) => {
                            let newComment = {
                                id : 0,
                                task_id : 0,
                                user_id: user.id,
                                parent_comment : 0,
                                comments: comments,
                                user_name: userName,
                                profile_image: user.profile_image,
                                created_at: new Date().getTime()/1000,
                                child_comment : [],
                                files : [],
                                emoji : emoji
                            };
            
                            taskComments.unshift(newComment);
                            this.setState({
                                taskComments: taskComments,
                                tempComment: "",
                                tempCommentError: "",
                                textAreaKeyForComment: Date.now(),
                                isPublishButtonDisable : false
                            });
                            // Capture Error Logs
                            Looger(error)
                        })
                    }
                }
            } else {
                this.setState({
                    tempCommentError: tempCommentError
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    commentStateUpdate = (function (e) {
        try{
            let tempCommentError = "";
            let tempComment = e.target.value;
            if (validator.isEmpty(tempComment)) {
                tempCommentError = "Enter a valid comment";
            } else if (!validator.isAlphanumeric(tempComment, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && tempComment.length > 0) {
                // ,.:!\/@#$%&*?()[]{}-'s
                tempCommentError = "Enter a valid comment";
            }
            this.setState({
                tempCommentError: tempCommentError,
                tempComment: tempComment
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getEmojisSpan = (function (data) {
        try{
            let emojiWithCount = [];
            data.map((item, index) => {
                let key = emojiWithCount.findIndex((element) => element.emoji === item.emoji);
                if (key > -1) {
                    emojiWithCount[key].count += 1;
                } else {
                    emojiWithCount.push({ emoji: item.emoji, count: 1, comment_id: item.comment_id})
                }
                return false;
            });
            return emojiWithCount.map((commontData, index) => {
                let EmojiData = {originalUnified: commontData.emoji};
                return (
                    <div className="p-2 px-1" key={"emoji-for-comment-" + commontData.comment_id + "-count-" + index}
                    onClick={(e) => { this.updateEmojiCount(e, EmojiData, commontData.comment_id)}}>
                        <span className='comment-reaction emoji-picker-react'>
                            <img className="emoji-img" src={Labels.EMOJI_URL + "/" + commontData.emoji + ".png"} alt={"comment-emoji-" + commontData.emoji} /> <span style={{lineHeight:"18px", paddingTop:3}}>{commontData.count}</span>
                        </span>
                    </div>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    updateEmojiCount = (function(event, EmojiData, comment_id) {
        try{
            this.onEmojiClickFuncReply(event, EmojiData, comment_id)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    openhideEditCommentModel = (async function(event,type,comment_id){
        try{
            event.preventDefault()
            if (type === 'save'){
                this.setState({replayloder:true})
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                let params = { id: comment_id, comment: this.state.replyCommentText}
                await axios.patch(
                    APIs.BASE_URL + APIs.UPDATE_COMMENT,
                    params,
                    config
                ).then((response) => {

                    this.setState({
                        editCommentId:0,
                        showEditCommentModel: false,
                        commentTextArea:false,
                        replayloder:false,
                    })
                }).catch((error) => {
                    this.setState({
                        editCommentId:0,
                        showEditCommentModel: false,
                        commentTextArea:false,
                        replayloder:false,
                    })
                    // Capture Error Logs
                    Looger(error)
                })
                this.getTaskData();
            }else{
                this.setState({
                    editCommentId:comment_id,
                    showEditCommentModel: this.state.editCommentId === comment_id ? !this.state.showEditCommentModel : true,
                    commentTextArea:true
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    captureTextAreaEvent = (function(e){
        console.log('')
    })

    getComments = (function(data, index) {
        try{
            let replyComment = (index.toString().match(/sub-/g) || []).length
            let image = (data.profile_image !== "") ? data.profile_image : "/placeholder.png";
            if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                let avatar_bg = (data.avatar_bg?data.avatar_bg:"5D6DF6").replace('#','')
                image = `https://ui-avatars.com/api/?name=${data.user_name}&color=FFFFFF&length=1&background=${avatar_bg}`
            }
            return (
                <div className={"comment " + ((index.toString().indexOf("-") === -1) ? " pt-4 p-3 pb-4" : " p-3 pb-0 pt-0")} key={"comment-"+index}>
                    <div className="d-flex comment-thread">
                        <div className="d-flex">
                            <div className="p-2 profile"><img src={image} alt={data.user_name}/></div>
                            <div className="p-2"><h3>{data.user_name}</h3></div>
                            <div className="p-2"><h5><ReactTimeAgo date={new Date(data.created_at*1000)} locale="en" /></h5></div>
                        </div>
                        { 
                            data.user_id === this.state.user_id && this.state.taskFormType !== "Add"  ?
                            <div className="edit-comment">
                                <span onClick={(e)=>{this.openhideEditCommentModel(e,'open',data.id)}} className="material-symbols-outlined edit-icon">border_color</span>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    { this.state.editCommentId  === data.id && this.state.showEditCommentModel ?
                        <div className="edit-comment-container">
                            <Row className="mb-2">
                                <Col md={{span:12}}>
                                    <Form.Group>
                                        <Form.Control as="textarea" rows={3} style={{ resize: "none" }} defaultValue={data.comments } onChange={(e) => this.setState({ replyCommentText: e.target.value.trim()})}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-2 edit-comment-bth">
                                <Col md={{span:3}} xs={{span:3}} sm={{span:3}} className="text-center">
                                    <Button variant='primary'  className='profile-btn btn btn-dark px-4 m-auto save-edit-comment edit-comment-btn-action' disabled={(this.state.replyCommentText===""?true:false)} type="button" onClick={(e) => {this.openhideEditCommentModel(e,'save',data.id)}}>{this.state.replayloder ? <Spinner animation="border" variant="light" style={{marginLeft: 25, marginRight: 25}} size="sm" /> : "Save"}</Button>
                                </Col>
                                <Col md={{span:3}} xs={{span:3}} sm={{span:3}} className="text-center">
                                    <Button variant='light' className='profile-btn px-4 edit-comment-btn-action m-auto' disabled={this.state.replayloder} type="button" onClick={(e) => { this.openhideEditCommentModel(e,'cancel',data.id) }}>Cancel</Button>
                                </Col>
                                <Col md={{span:1}} xs={{span:1}} sm={{span:1}} className="text-center">
                                    <div className="edit-comment-emoji" onClick={(e) => { this.toggleEmojiForReply(data.id)}}>
                                        <i className="material-symbols-outlined">sentiment_satisfied</i>
                                        {
                                            (this.state.showReplyCommentEmoji && this.state.showReplyCommentEmojiForID === data.id) &&
                                            <div className="reply-section-emoji">
                                                <Picker
                                                    skinTone={SKIN_TONE_NEUTRAL}
                                                    emojiUrl={Labels.EMOJI_URL}
                                                    disableSkinTonePicker={false}
                                                    disableSearchBar={true}
                                                    onEmojiClick={this.onEmojiClickFuncReply}
                                                />
                                            </div>
                                        }
                                    </div>
                                </Col>
                                <Col md={{span:5}} xs={{span:5}} sm={{span:5}} className="text-center" style={{ display: "flex", cursor:"pointer" }} >
                                    <div className="edit-comment-emoji-display"style={{ display: "flex", flexWrap:"wrap", cursor:"pointer" }}>
                                        {this.getEmojisSpan(data.emoji)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <p dangerouslySetInnerHTML={{ __html: this.urlify(data.comments) }}></p>
                    }

                    { this.state.editCommentId  === data.id && this.state.showEditCommentModel ? <></>:
                        <>
                            { replyComment <= 3 &&
                            <>
                                {
                                data.files.length > 0 &&
                                    <a className="text-decoration-none" href={data.files[0].attachment_path} target="_blank" rel="noreferrer" >
                                    <div className="d-flex" style={{ height: 32 }} >
                                        <div className="p-2 profile"><i className="material-icons rotate-icon-45deg">attachment</i></div>
                                        <div className="p-2"><span className="folder">{data.files[0].attachment_path.replaceAll("https://orrderly-storage.s3.amazonaws.com/file/", "")} {bytesToSize(data.files[0].type.split("|")[1])}</span></div>
                                    </div>
                                </a>
                                }
                                <div className="d-flex" style={{ alignItems: "center" }}>
                                    <div className="p-0 px-1 profile" onClick={(e) => { this.toggleEmojiForReply(data.id)}} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor:"pointer" }}><i className="material-symbols-outlined">sentiment_satisfied</i></div>
                                    {
                                        (this.state.showReplyCommentEmoji && this.state.showReplyCommentEmojiForID === data.id) &&
                                        <div className="reply-section-emoji">
                                            <Picker
                                                skinTone={SKIN_TONE_NEUTRAL}
                                                emojiUrl={Labels.EMOJI_URL}
                                                disableSkinTonePicker={false}
                                                disableSearchBar={true}
                                                onEmojiClick={this.onEmojiClickFuncReply}
                                            />
                                        </div>
                                    }
                                    <div style={{display:"flex",flexFlow:"wrap", maxWidth:"257px"}}>
                                        {this.getEmojisSpan(data.emoji)}
                                    </div>
                                    {
                                        !this.state.PlanFeatures['comment_on_tasks'] ? <></> : 
                                    <>
                                        {
                                            ( replyComment < 3 && data.id !== 0 && this.state.taskFormType !== "Add"  ) ? 
                                            <div className="p-2 px-1"><span className="reply" onClick={(e) => { this.openReplyInput(e, data.id) }}>Reply</span></div>
                                            : <div className="p-2 px-1">&nbsp;</div>
                                        }
                                    </>
                                    }
                                </div>
                                {
                                (this.state.addCommentFor === data.id /*&& index.toString().indexOf("-") === -1*/ ) &&
                                <div className="comment-reply">
                                    <Row className="mb-2">
                                        <Col md={{span:12}}>
                                            <Form.Group>
                                                <Form.Control as="textarea" rows={3} style={{ resize: "none" }} defaultValue={this.state.replyCommentText} onChange={(e) => this.setState({ replyCommentText: e.target.value})}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col md={{span:3}} xs={{span:4}} sm={{span:4}} className="text-center">
                                            <Button variant='primary' className='profile-btn btn btn-dark px-4 m-auto' disabled={this.state.replayloder} type="button" onClick={(e) => {this.submitCommentReply(data.id)}}>{this.state.replayloder ? <Spinner animation="border" variant="light" style={{marginLeft: 25, marginRight: 25}} size="sm" /> : "Publish"}</Button>
                                        </Col>
                                        <Col md={{span:3}} xs={{span:4}} sm={{span:4}} className="text-center">
                                            <Button variant='light' className='profile-btn px-4 m-auto' disabled={this.state.replayloder} type="button" onClick={(e) => { this.hideOpenedReplyInput() }}>Cancel</Button>
                                        </Col>
                                    </Row>
                                </div>
                                }
                                <div className='sub-comments'>
                                    {data.child_comment.map((data, index1) => { return this.getComments(data, "sub-" + index + "-" + index1) })}
                                </div>
                            </>
                            }
                        </>
                    }
                </div>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    openReplyInput = (function(e, id) {
        try{
            this.setState({
                addCommentFor : id,
                replyCommentText : "",
                replyCommentTextError : "",
                isPublishButtonDisable : false
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    hideOpenedReplyInput = (function() {
        try{
            this.setState({
                addCommentFor : 0,
                replyCommentText : "",
                replyCommentTextError : "",
                isPublishButtonDisable : false
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    submitCommentReply = (function(commentID) {
        try{
            let tempCommentError = "";
            let comments = this.state.replyCommentText;
            if (validator.isEmpty(comments)) {
                tempCommentError = "Enter a valid comment";
            } else if (!validator.isAlphanumeric(comments, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && comments.length > 0) {
                // ,.:!\/@#$%&*?()[]{}-'s
                tempCommentError = "Enter a valid comment";
            }
            let taskComments = this.state.taskComments;
            if (taskComments.findIndex(i => i.comments === comments) > -1) {
                tempCommentError = "Comment already added.";
            }
            if (tempCommentError === "") {
                this.setState({replayloder:true})
                if (this.state.task_id > 0) {
                    let token = localStorage.getItem('session_token');
                    let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                    let params = { task: this.state.task_id, comment: comments, parent_comment: commentID, emoji: "" }
                    axios.post(
                        APIs.BASE_URL + APIs.CREATE_TASK_COMMENT_API,
                        params,
                        config
                    ).then((response) => {
                        this.setState({
                            taskComments : response.data.data.all_comments, 
                            replayloder:false,
                            comments_count:response.data.data.comment_count
                        });
                        this.hideOpenedReplyInput();
                    }).catch((error) => {
                        this.setState({replayloder:false})
                    })
                }
            } else {
                this.setState({
                    tempCommentError: tempCommentError
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    addSubTask = (function () {
        try{
            let tempSubTaskError = "";
            let taskDescription = this.state.tempSubTask;
            if (validator.isEmpty(taskDescription)) {
                tempSubTaskError = "Enter a valid sub task";
            } else if (!validator.isAlphanumeric(taskDescription, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && taskDescription.length > 0) {
                tempSubTaskError = "Enter a valid sub task";
            }
            let subTask = this.state.taskSubTasks;
            if (subTask.findIndex(i => i.task_description === taskDescription) > -1) {
                tempSubTaskError = "Sub task already added.";
            }
            if (tempSubTaskError === "") {
                this.setState({isSubTaskPublishButtonDisable:true})
                if (this.state.task_id > 0) {
                    let token = localStorage.getItem('session_token');
                    let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                    let params = { parent_task: this.state.task_id, task_description: taskDescription, status:"yet_to_start" }
                    axios.post(
                        APIs.BASE_URL + APIs.CREATE_SUB_TASK_API,
                        params,
                        config
                    ).then((response) => {
                        subTask.push(response.data.data);
                        this.setState({
                            taskSubTasks: subTask,
                            tempSubTask: "",
                            tempSubTaskError: "",
                            textAreaKey: Date.now(),
                            isSubTaskPublishButtonDisable:false
                        });
                    }).catch((error) => {
                        //
                    })
                } else {
                    subTask.push({ task_description: taskDescription, status: "yet_to_start" });
                    this.setState({
                        taskSubTasks: subTask,
                        tempSubTask: "",
                        tempSubTaskError: "",
                        textAreaKey: Date.now(),
                        isSubTaskPublishButtonDisable:false
                    });
                }
            } else {
                this.setState({
                    tempSubTaskError: tempSubTaskError
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    subTaskStateUpdate = (function (e) {
        try{
            let tempSubTaskError = "";
            let taskDescription = e.target.value;
            if (validator.isEmpty(taskDescription)) {
                tempSubTaskError = "Enter a valid sub task";
            } else if (!validator.isAlphanumeric(taskDescription, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && taskDescription.length > 0) {
                tempSubTaskError = "Enter a valid sub task";
            }
            this.setState({
                tempSubTaskError: tempSubTaskError,
                tempSubTask: taskDescription
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    setSubTaskDone = (function (index, data) {
        try{
            let taskId = data.id;
            let status = (data.status === "yet_to_start" || data.status === null) ? "done" : "yet_to_start";
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.patch(
                APIs.BASE_URL + APIs.DONE_TASK_API.replace(":id", taskId),
                { status: status},
                config
            ).then((response) => {
                let subTaskData = this.state.taskSubTasks;
                subTaskData[index].status = status
                this.setState({
                    taskSubTasks: subTaskData
                });
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    getTaskData = (function() {
        try{
            let param   =   { start: 0, length: 1000 };
            let token   =   localStorage.getItem('session_token');
            let config  =   { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_SINGLE_TASK_API.replace(":id", this.state.task_id),
                config
            ).then((response) => {
                if (response.data.status_code === 200) {

                    let subTaskArray = response.data.data.sub_task
                    let allSubTask = []
                    let allSubTaskError = []
                    for(let i=0;i<subTaskArray.length;i++) {
                        allSubTask[i] = ''
                        allSubTaskError[i] = ''
                    }

                    this.setState({
                        taskComments: response.data.data.comments,
                        taskSubTasks: response.data.data.sub_task,
                        taskTags: response.data.data.tags,
                        task_id: response.data.data.id,
                        isOverdue : response.data.data.is_overdues,
                        projectOwnerId:response.data.data.project_owner_id,
                        defaultColor:response.data.data.color_code,
                        allSubTask:allSubTask,
                        allSubTaskError:allSubTaskError,
                    })
                } else {
                    this.setState({
                        taskComments: [],
                        taskSubTasks: [],
                        taskTags: [],
                        isOverdue: false
                    })
                }
            }).catch((error) => {
                this.setState({
                    taskComments: [],
                    taskSubTasks: [],
                    taskTags: [],
                    isOverdue: false
                })
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    componentDidMount() {
        if (this.state.task_id > 0) {
            this.getTaskData()
        }
    }

    openhideEditSubTaskModel = (function(e,type,subTaskId,index){
        try{
            e.preventDefault()
            let allSubTaskError = this.state.allSubTaskError
            let task_description = this.state.allSubTask[index] 
            if(type === 'open') {
                allSubTaskError[index] = ''
                this.setState({
                    editSubTaskId:subTaskId,
                    showEditSubTaskModel: this.state.editSubTaskId === subTaskId ? !this.state.showEditSubTaskModel : true,
                    allSubTaskError:allSubTaskError
                })
            } else if (type === 'save') {
                if (task_description !== ''){
                    let token  = localStorage.getItem('session_token');
                    let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                    let params = {id:subTaskId,task_description:task_description}
                    axios.patch(
                        APIs.BASE_URL +  APIs.UPDATE_SUB_TASK_API,
                        params,
                        config
                    ).then((response) => {
                        let data = response.data
                        if  (data.status_code === 200){
                            this.getTaskData()
                            this.setState({
                                showEditSubTaskModel: false,
                            })
                        }
                    }).catch((error) => {

                    })
                } else {
                    allSubTaskError[index] = 'Sub task should not be empty'
                    this.setState({
                        showEditSubTaskModel: true,
                        allSubTaskError:allSubTaskError
                    })
                }
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    updateSubTask  = (function(e,index){
        try{
            let subTask = e.target.value.trim()
            let subTaskError = ''
            if (subTask === ''){
                subTaskError = 'Sub task should not be empty'
            }
            let allSubTask = this.state.allSubTask
            let allSubTaskError = this.state.allSubTaskError 

            allSubTask[index] = subTask
            allSubTaskError[index] = subTaskError

            this.setState({
                allSubTask: allSubTask, 
                allSubTaskError:allSubTaskError,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    getSubTasks = (function (data, index) {
        return (
            <Row  key={"task-subtask1-" + index} className={"mb-2 subtask_list" + (data.user_id === this.state.user_id?"":" active_subtask_edit")}>
                <Col md={{span:(data.user_id===this.state.user_id)?10:12}} xs={{span:(data.user_id===this.state.user_id)?10:12}} sm={{span:(data.user_id===this.state.user_id)?10:12}} className={"d-flex text-white justify-content-start task_sec" + (this.state.editSubTaskId === data.id   && this.state.showEditSubTaskModel ?" active_editor":"")} key={"task-subtask3-" + index}>
                    <div className="schedule" key={"task-subtask5-" + index}>
                        <span className="time_schedule" key={"task-subtask7-" + index}>
                            <i style={{fontSize:"18px"}} className={"material-icons todotask-icon" + (data.status === "done" ? " active" : "")} onClick={() => this.setSubTaskDone(index, data)}>check</i>
                        </span>
                    </div>
                    {
                        this.state.editSubTaskId === data.id   && this.state.showEditSubTaskModel ? 
                        <Col md={{span:10}} xs={{span:10}}  sm={{span:10}} >
                            <Form.Group>
                                <Form.Control as="textarea" className='edit' rows={3} style={{ resize: "none" }} defaultValue={data.task_description } onChange={(e) => {this.updateSubTask(e,index)}}/>
                            </Form.Group>
                            { this.state.allSubTaskError[index] !== '' &&
                                <div className="errorMessage"><i className="material-icons">warning</i>{this.state.allSubTaskError[index]}</div>
                            }
                        </Col>
                        : 
                        <div className="d-grid align-items-center head_sec">
                            <h4 className={"task_head d-block" + (data.status === "done" ? " done" : "")} style={{ whiteSpace: "initial" }}>{data.task_description}</h4>
                        </div>
                    }

                </Col>
                {  data.user_id === this.state.user_id &&
                    <Col md={{span:2}}  xs={{span:(data.user_id===this.state.user_id)?2:4}} sm={{span:(data.user_id===this.state.user_id)?2:4}} className="edit-task mb-2 p-2" key={"task-subtask67-" + index}>
                            <div className="edit-comment edit-subtask" key={"task-subtask80-" + index}>
                                
                                    { this.state.editSubTaskId === data.id   && this.state.showEditSubTaskModel ?
                                        <Row>
                                            <span  onClick={(e)=>{this.openhideEditSubTaskModel(e,'open',data.id,index)}} className="material-symbols-outlined col-md-6 col-sm-6 col-6">cancel</span>
                                            { this.state.subTaskText === '' ?
                                                <span className="material-symbols-outlined col-md-6 col-sm-6 col-6">save</span>
                                                :
                                                <span onClick={(e)=>{this.openhideEditSubTaskModel(e,'save',data.id,index)}} className="material-symbols-outlined col-md-6 col-sm-6 col-6">save</span>
                                            }
                                        </Row> 
                                    :
                                        <span onClick={(e)=>{this.openhideEditSubTaskModel(e,'open',data.id,index)}} className="material-symbols-outlined edit-icon">border_color</span>
                                    }
                                
                            </div>
                    </Col>
                }
            </Row>
        )
    });

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    componentPermission(){
        let projectOwnerId = this.state.owner_id 
        const host_id =  this.state.assigned_by_id ? this.state.assigned_by_id:this.state.user_id
        if (host_id ===  this.state.user_id || projectOwnerId === this.state.user_id ){
            return false
        }else{
            return true
        }
    }

    deleteComponentPermission(){
        try{
            let projectOwnerId = this.state.owner_id 
            if ( projectOwnerId === this.state.user_id ){
                return true
            }else{
                return false
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    captureBodyClickEvent = ( function(data){
        try{
            this.setState({
                showMainCommentEmoji:false,
                showReplyCommentEmoji:false
            })
            if ( !this.props.taskFormFooter){
                this.setState({
                    showMainCommentEmoji:false,
                    showReplyCommentEmoji:false,

                })
            } 
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }  

    }).bind(this)

    editorHandel = (function(e,taskFormType){
        try{
            e.preventDefault()
            if (taskFormType === 'Edit'){
                let enabelEditor = !this.state.enabelEditor
                this.setState({
                    enabelEditor:enabelEditor
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    urlify =  (function(text) {
        try{
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, function(url) {
            return '<a class="generated_link" target="_blank" href="' + url + '">' + url + '</a>';
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    noRefCheck = (function(data) {
        this.setDropdownValue(data, "task_assignee")
    }).bind(this)

    onRemove = (function(data) {
        this.setDropdownValue(data, "task_assignee_remove")
    }).bind(this)

    componentPlaceholderPermission = (function(){
        try{
            let selectedAssignee = this.state.selectedAssignee
            let placeHolderPermission = false
            if (selectedAssignee.length > 0){
                placeHolderPermission = true
            }
            return placeHolderPermission
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    showAssigneeDropDown = (function(e){
        try{
            let showAssigneeDropDown = !this.state.showAssigneeDropDown
            this.setState({showAssigneeDropDown:showAssigneeDropDown})
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    teamSearchHandler =  (function(e){
        try{
            setTimeout(() => {
                GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.props.project_id,search:e}).then((data) => {
                    this.setState({
                        assigneeOption: data
                    });
                })
            }, 2000);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    selectColorPalletHandler = (function(colorCode){
        try{
            this.setState({
                defaultColor:colorCode,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    taskShareHandler = (function(type){
        try{
            let showShareTaskModal = true
            if (type === 'close'){
                showShareTaskModal = false
            }  
            this.setState({
                showShareTaskModal:showShareTaskModal,
            }) 
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })


    render() {
        return (
            <>
            <Form noValidate validated={this.state.validated} onSubmit={(e)=>this.checkFormSubmittion(e)}>
                <Row>
                    <Col md={{span:10}}  xs={{span:9}} sm={{span:10}} className="pe-1">
                        <Form.Group className='input-group task_name_container'>
                            {   ! this.componentPermission() ? 
                                <i className={'material-icons todotask-icon ' + this.state.isDoneTask} onClick={() => {this.updateTaskStatus()}}>check</i>
                            :
                                <i className={'material-icons todotask-icon ' + this.state.isDoneTask}>check</i>
                            }
                            <Form.Control readOnly = {this.componentPermission()}  type="text" placeholder="Task name" name="task_name" id="task_name" className="task_name" value={this.state.task_name} required style={{borderColor:"transparent"}} onChange={(e) => this.onInputChange(e)} />
                            {
                                this.state.taskNameError !== "" ?
                                <Form.Control.Feedback type="invalid" className="errorMessage">
                                        <i className="material-icons">warning</i>{this.state.taskNameError}
                                    </Form.Control.Feedback>
                                : <></>
                            }
                        </Form.Group>
                    </Col>
                    {  this.deleteComponentPermission() &&
                        <Col md={{span:2}} xs={{span:3}} sm={{span:2}} className="ps-0">
                            {this.state.taskFormType === "Add" ? "" :
                            <div className="text-start ps-1">
                                <i className="material-icons task_share dtl" onClick={(e)=>this.taskShareHandler('open')} style={{width:"23px"}}>share</i>
                                {
                                    this.state.task_statusModel === "done" ? <></> :
                                    <i className="material-icons dtl"  onClick={() => { this.onDeleteTask() }}>delete</i>
                                }
                            </div>
                            }
                        </Col>
                    }
                    {   !this.componentPermission() &&
                        <Col md={{ span: 12 }} className="d-flex justify-content-end description_editor" >
                            {this.state.taskFormType === "Add" ? "" :
                            <div className="edit-comment" onClick={(e) => this.editorHandel(e,this.state.taskFormType)}>
                                <span className="material-symbols-outlined edit-icon">border_color</span>
                            </div>
                            }
                        </Col>
                    }
                    <Col md={{ span: 12 }} >
                        <Form.Group className='input-group'>
                            { this.state.enabelEditor || this.state.taskFormType === "Add" ?
                                <>
                                <Form.Control readOnly = {this.componentPermission()} as="textarea" rows={3} placeholder="Task description" name="task_description" id="task_description" className="task_description shadow-none" required defaultValue={this.state.task_description} style={{ borderColor: "transparent" }} onChange={(e) => this.onInputChange(e)} />
                                {
                                    this.state.taskDescriptionError !== "" ?
                                        <Form.Control.Feedback type="invalid" className="errorMessage">
                                            <i className="material-icons">warning</i>{this.state.taskDescriptionError}
                                        </Form.Control.Feedback>
                                        : <></>
                                }
                                    </>
                                :
                                <>
                                    <Form.Control as="pre" rows={3} name="task_description" id="task_description" className="task_description shadow-none" dangerouslySetInnerHTML={{ __html: this.urlify(this.state.task_description) }}  style={{ borderColor: "transparent" }} />
                                </>
                            }

                        </Form.Group>
                    </Col>
                </Row>
                <hr className="popup-hr-edge-to-edge" />
                <Row>
                    {this.state.taskFormType=== "Edit" ? "" :
                    <Col md={{span:12}} className="popup_task_info">
                        <Row>
                            <Col md={{span:3}} xs={{span:4}} sm={{span:3}}><h3>Type</h3></Col>
                            <Col md={{span:6}} xs={{span:8}} sm={{span:9}}>
                                <BootstrapSelect
                                    options={this.state.taskTypeOptions}
                                    placeholder="Type of Task"
                                    onChange={(e) => this.setDropdownValue(e, "task_type")}
                                    key={"task_type"}
                                    className={"col-md-12 col-sm-12 col-12"}
                                />
                            </Col>
                        </Row>
                        <hr className="popup-hr-edge-to-edge" />
                    </Col>
                    }
                    {!this.state.showFrequency ? <></> :
                    <Col md={{ span: 12 }} className="popup_task_info">
                        <Row>
                            <Col md={{span:3}} xs={{span:4}} sm={{span:3}}><h3>Frequency</h3></Col>
                            <Col md={{span:6}} xs={{span:7}} sm={{span:9}}>
                                <BootstrapSelect 
                                    disabled= { this.componentPermission()}
                                    options={this.state.taskFrequencyOptions}
                                    placeholder="Type of Task"
                                    onChange={(e) => this.setDropdownValue(e, "task_frequency")}
                                    key={"frequency_type"}
                                    className={"col-md-12 col-sm-12 col-12"}
                                />
                                { this.state.taskFrequencyOptions.filter((data)=>data.isSelected === true).length === 0 &&
                                    <div className="errorMessage invalid-feedback"><i className="material-icons">warning</i>Frequency is required.</div>
                                }
                            </Col>
                        </Row>
                    </Col>
                    }
                    <Col md={{ span: 12 }} className={"popup_task_info " +  (!this.state.PlanFeatures['assign_tasks_to_team_members'] ? "d-none" : "")}>
                        <Row>
                            <Col md={{span:3}} xs={{span:4}} sm={{span:3}}><h3>Assignee</h3></Col>
                            <Col md={{span:7}} xs={{span:6}} sm={{span:7}}>
                                <Multiselect
                                    displayValue="value"
                                    onSelect={this.noRefCheck}
                                    options={this.state.assigneeOption}
                                    onRemove={this.onRemove}
                                    disable={this.componentPermission()}
                                    onSearch={this.teamSearchHandler}
                                    className={
                                        (this.state.showAssigneeDropDown ? "showdrop  col-md-7 col-sm-7 col-3 ":"col-md-7 col-sm-7 col-3 " )
                                        +   
                                        (this.componentPlaceholderPermission() ? "exist" :"")
                                    }
                                    showCheckbox
                                    hidePlaceholder={this.componentPlaceholderPermission()}
                                    placeholder="Assign team member"
                                    selectedValues={this.state.selectedAssignee}
                                />
                            </Col>
                            { ! this.componentPermission() &&
                            <Col md={{span:2}} xs={{span:2}} sm={{span:2}} className="text-center asign_drop">
                                <i className="material-icons dtl" onClick={(e)=>{this.showAssigneeDropDown(e)}} style={{ fontSize: "21px" }}>add_circle_outline</i>
                            </Col>
                            }
                        </Row>
                    </Col>
                    <Col md={{ span: 12 }} className="popup_task_info">
                        <Row>
                            <Col md={{span:3}} xs={{span:4}} sm={{span:3}}><h3>Date</h3></Col>
                            <Col md={{span:3}} xs={{span:3}} sm={{span:3}}>
                                <DatePicker
                                    readOnly = {this.componentPermission()}
                                    selected={this.state.start_date}
                                    dateFormat="E d MMM"
                                    onChange={(date) => this.setStartDate(date)}
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                    calendarContainer={StartDateContainer}
                                    customInput={
                                        <StartDateCompoment value="Start date"/>
                                    }
                                />
                            </Col>
                            <Col md={{span:4}} xs={{span:3}} sm={{span:4}}>
                                {this.state.showFrequency ? <></> :
                                <DatePicker 
                                    readOnly = {this.componentPermission()}
                                    selected={this.state.end_date}
                                    dateFormat="E d MMM"
                                    minDate={this.state.start_date}
                                    onChange={(date) => this.setEndDate(date)}
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                    calendarContainer={DeadLineDateContainer}
                                    customInput={
                                        <EndDateCompoment value="Deadline date" />
                                    }
                                />
                                }
                            </Col>
                            <Col md={{span:2}} xs={{span:2}} sm={{span:2}} className="text-center">
                                <i className="material-icons" style={{ fontSize: "21px" }}>date_range</i>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{span:12}} className="popup_task_info">
                        <Row>
                            <Col md={{span:3}} xs={{span:4}} sm={{span:3}}><h3>Time</h3></Col>
                            <Col md={{span:3}} xs={{span:3}} sm={{span:3}}>
                                <DatePicker
                                    readOnly = {this.componentPermission()}
                                    selected={this.state.start_date}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    onChange={(date) => this.setStartDate(date)}
                                    customInput={
                                        <StartTimeCompoment value="Start date" />
                                    }
                                />
                            </Col>
                            <Col md={{span:4}} xs={{span:3}} sm={{span:4}}>
                                {this.state.showFrequency ? <></> :
                                <DatePicker
                                    readOnly = {this.componentPermission()}
                                    selected={this.state.end_date}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    minDate={this.state.start_date}
                                    onChange={(date) => this.setEndDate(date)}
                                    customInput={
                                        <EndTimeCompoment value="Deadline date" />
                                    }
                                />
                                }
                            </Col>
                            <Col md={{span:2}} xs={{span:2}} sm={{span:2}} className="text-center">
                                <i className="material-icons" style={{ fontSize: "21px" }}>access_time</i>
                            </Col>
                        </Row>
                        {this.state.taskEndDateError}
                    </Col>
                </Row>
                {((!this.state.PlanFeatures['labels'] && this.state.taskFormType === "Add") || (this.state.taskFormType === 'Edit' && this.state.taskTags.length === 0 && !this.state.PlanFeatures['labels'])) ? <></> :
                <> 
                    <hr className="popup-hr-edge-to-edge" />
                    <Row>
                        <Col md={{span:10}} xs={{span:10}} sm={{span:10}}>
                            <div className="task-tags">
                                {
                                    this.state.taskTags.map((data, index) => {
                                        return (
                                            <span key={"a-1-tag-key-" + index} className={'tags_sec ' + data.tag_class}>#{data.tag_name}</span>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.componentPermission() ?
                                    <>
                                        {
                                            this.state.taskTags.length < 1 &&
                                            <div className='empty-tags'>
                                                <i className="material-icons" style={{ fontSize: "21px", marginRight:"7px" }}>add_circle_outline</i>Task Tags
                                            </div>
                                        }
                                    </>
                                :
                                    !this.state.PlanFeatures['labels'] ? <></> : 
                                    <div className='empty-tags' onClick={() => { this.setState({ showTagsModal: !this.componentPermission() })}}  >
                                        <i className="material-icons" style={{ fontSize: "21px", marginRight:"7px" }}>add_circle_outline</i> Add a tag
                                    </div>
                            }
                            <AddTags show={this.state.showTagsModal} taskid = {this.state.task_id} contentClassName="add_new_member_form add_task add-tags-popup" oldTags={this.state.taskTags} title="Select tags" hideTagsModal={(data) => this.setState({ showTagsModal: false, taskTags: data })} />
                        </Col>
                        <Col md={{span:2}} xs={{span:2}} sm={{span:10}} className="task_color_">
                            <ColorPallet selectedColorPallet={(colorCode)=>this.selectColorPalletHandler(colorCode)} defaultColor={this.state.defaultColor}/>
                        </Col>
                    </Row>
                </>
                }
                { 
                    this.props.taskFormFooter ? <></> :
                <>
                    <hr className="popup-hr-edge-to-edge" />
                    {   

                        ((!this.state.PlanFeatures['add_subtasks'] && !this.state.PlanFeatures['comment_on_tasks'] && this.state.taskFormType === "Add") || ((this.state.taskComments.length === 0 && !this.state.PlanFeatures['comment_on_tasks']) && (this.state.taskSubTasks.length === 0 && !this.state.PlanFeatures['add_subtasks']) && this.state.taskFormType === "Edit")) ? <></> :
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.tabStatusKey}
                            onSelect={(k) => { this.setState({ tabStatusKey: k }) }}
                            className="mb-3"
                        >
                            {
                            (this.state.taskComments.length === 0 && !this.state.PlanFeatures['comment_on_tasks']) ?
                            <Tab eventKey="comments" title={<>Comments </>}></Tab> :
                            <Tab eventKey="comments" title={<>Comments { this.state.taskFormType !== 'Add' && <span className='badge'>{this.state.comments_count} </span>}</>}>
                                {
                                !this.state.PlanFeatures['comment_on_tasks'] ? <></> :
                                <Row>
                                    <Col span={{ md: 12 }}>
                                        <div className="comment_sec">
                                            <div className="comment_box_wrap" style={{ padding: "21px 0px" }}>
                                                <div className="comment_box">
                                                    <div className="comment_textarea">
                                                        <Form.Group>
                                                            <Form.Control as="textarea" rows={3} style={{ resize: "none", background:"transparent" }} key={this.state.textAreaKeyForComment} onChange={(e) => this.commentStateUpdate(e)} placeholder="Add comment...." name="comment" maxLength={200} defaultValue={this.state.tempComment} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="comment_actions">
                                                            <div className="emoji-picker-orrderly">
                                                                <div className='selected-emoji' onClick={(e) => { this.setState({ showMainCommentEmoji: !this.state.showMainCommentEmoji, showReplyCommentEmoji:false})}}>
                                                                    {
                                                                        this.state.mainCommentEmoji ? <span className='comment-reaction emoji-picker-react' style={{border: 0,background: "transparent", marginTop: "-7px" }}><img className="emoji-img" src={Labels.EMOJI_URL + "/" + this.state.mainCommentEmoji + ".png"} alt={"comment-emoji-" + this.state.mainCommentEmoji} /></span> : <span className='material-symbols-outlined'>sentiment_satisfied</span>
                                                                    }
                                                                </div>
                                                                {
                                                                    this.state.showMainCommentEmoji &&
                                                                    <Picker
                                                                        skinTone={SKIN_TONE_NEUTRAL}
                                                                        emojiUrl={Labels.EMOJI_URL}
                                                                        disableSkinTonePicker={false}
                                                                        disableSearchBar={true}
                                                                        onEmojiClick={this.onEmojiClickFunc}
                                                                    />
                                                                }
                                                            </div>
                                                        <label className={"label_for_comment_attachment" + (this.state.comment_attachment ? " selected" : "")} htmlFor="comment_attachment"><span className='material-icons rotate-icon-45deg'>attachment</span></label>

                                                        <button className="publish" disabled={this.state.isPublishButtonDisable} onClick={(e) => this.addComment(e)}>
                                                            {this.state.isPublishButtonDisable ? <Spinner animation="border" variant="light" style={{marginLeft: 25, marginRight: 25}} size="sm" /> : "Publish"}
                                                        </button>
                                                        <input type="file" className='d-none' name="comment_attachment" id="comment_attachment" onChange={(e) => this.setFileInState(e)} accept="image/*,video/*,.pdf,.txt,.docx,.doc,.xls,.xlsx"/>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.tempCommentError !== "" ?
                                                    <><div className="errorMessage"><i className="material-icons">warning</i>{this.state.tempCommentError}</div></> :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                }
                                <Row className="row task_list">
                                    <Col md={{span:12}} className="mt-2">
                                        <Row className="row">
                                            {this.state.taskComments.map((data, index) => { return this.getComments(data, index) })}
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                            }
                            {
                            (this.state.taskSubTasks.length === 0 && !this.state.PlanFeatures['add_subtasks']) ?
                            <Tab eventKey="subtasks" title={<>Subtasks </>}></Tab> :
                            <Tab eventKey="subtasks" title={<>Subtasks { this.state.taskFormType !== 'Add' && <span className='badge'>{this.state.sub_task_count}</span>}</>}>
                                {
                                !this.state.PlanFeatures['add_subtasks'] ? <></> :
                                <Row>
                                    <Col span={{md:12}}>
                                        <div className="comment_sec">
                                            <div className="comment_box_wrap" style={{ padding: "21px 0px" }}>
                                                <div className="comment_box">
                                                    <div className="comment_textarea">
                                                        <Form.Group>
                                                            <Form.Control as="textarea" rows={3} style={{ resize: "none", background: "transparent" }} key={this.state.textAreaKey} onChange={(e) => this.subTaskStateUpdate(e)} placeholder="Add sub-task...." name="sub_task" maxLength={200} defaultValue={this.state.tempSubTask} />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="comment_actions">
                                                        <span className="publish" onClick={(e) => this.addSubTask()}>
                                                            {
                                                                this.state.isSubTaskPublishButtonDisable ? 
                                                                    <Spinner animation="border" variant="light" style={{marginLeft: 25, marginRight: 25}} size="sm" />
                                                                :
                                                                "Add task"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.tempSubTaskError !== "" ?
                                                        <><div className="errorMessage"><i className="material-icons">warning</i>{this.state.tempSubTaskError}</div></> :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                }
                                <Row className="task_list">
                                    <Col md={{span:12}} className="mt-2">
                                        {this.state.taskSubTasks.map((data, index) => {return this.getSubTasks(data, index)})}
                                    </Col>
                                </Row>
                            </Tab>
                            }
                        </Tabs>
                    }
                    { !this.componentPermission() &&
                        <>
                            {   this.state.task_statusModel !== 'done'  &&
                                <div className="d-grid pt-1 col-md-4 offset-md-4 mt-2">
                                    <Button variant="primary" type="submit" disabled={this.state.showLoading}>
                                        {
                                            this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : (this.state.taskFormType === "Add" ? "Save Task" : "Update Task")
                                        }
                                    </Button>
                                </div>

                            }
                        </>
                    }
                </>
                }
            </Form>
            <ShareTask shareUrl={this.state.taskUrl} showShareTaskModal={this.state.showShareTaskModal} onClick={()=>{this.taskShareHandler('close')}}/>
            </> 
        );
    }
}

//export default AddTaskForm;

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id,
});

export default connect(mapStateToProps)(AddTaskForm);