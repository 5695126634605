import React, { Component } from 'react';
import LoggedInLayout from './LoggedInLayout';
import TaskListView from './Common/TaskListView';
import TaskBoardView from './Common/TaskBoardView';
import { NavLink, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { connect } from "react-redux";
import { task_switcher } from '../../../reducers/CurrentProjectBoard'
import AddTaskModal from './Common/Modals/AddTaskModal';
import GetTaskAssignee from './Common/GetTaskAssignee';
import axios from 'axios';
import Looger from '../../../utils/Logger';
import APIs from '../../../utils/APIs';

function Dashboard(props) {
    let active_tab = localStorage.getItem('active_tab')
    if (active_tab == null){
        active_tab = 'list'
    }
    let { taskView,date,key,id } = useParams();


    let taskUrl = ''
    if ( date !== undefined && key !== undefined  && id !== undefined ){
        taskUrl = `TSK/${date}/${key}/${id}`
    }

    if (!taskView) {
        taskView = active_tab
    }

    let switcher =  localStorage.getItem('switcher')
    
    if (!switcher){
        localStorage.setItem('switcher',0)
    }

    localStorage.setItem('active_tab',taskView)

    let changeSwitcher = (function(e){
        props.task_switcher({taskSwitcher:e})
    })

    return <DashboardClass taskView={taskView} taskUrl={taskUrl} projectId={props.project_id} taskSwitcher={props.taskSwitcher} changeSwitcher = {(e)=>{changeSwitcher(e)}} />
}

class DashboardClass extends Component {
    static  project_id  = 0
    constructor(props) {
        super(props);
        this.project_id = props.projectId
        let dayName = new Date().toLocaleString('en-us', { weekday: 'short' });
        let monthName = new Date().toLocaleString('en-us', { month: 'short' });
        let dateNumber = new Date().getDate();
        this.state = {
            date: dayName + " " + dateNumber + " " + monthName,
			todayDoneTask :  0,
            defaultTaskView: props.taskView,
            taskUrl:props.taskUrl, 
            showTaskModal:false,
            taskModalType:'Edit',
            modalData:{},
            axiosSource : axios.CancelToken.source(),
		};
    }

    taskSwitcher = (function(e){
        try{
            let  taskSwitcherValue = 0
            if (e.target.checked) { 
                taskSwitcherValue = 1
            }
            this.props.changeSwitcher(taskSwitcherValue)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }

    }).bind(this)

    hideAddEditTaskModal = (function(){
        try{
            this.setState({
                showTaskModal:false,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    onTaskAdd = (function(data){
        try{
            this.setState({
                showTaskModal:false,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })


    getTaskData = (function() {
        try{
            let task_id  = parseInt(this.state.taskUrl.split("/")[3])
            this.setState({showNotificationModal:false})
            let param   =   { start: 0, length: 1000 };
            let token   =   localStorage.getItem('session_token');
            let config  =   { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_SINGLE_TASK_API.replace(":id", task_id),
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    response.data.data.assigned_by_id = response.data.data.assigned_by
                    response.data.data.assigned_to_id = response.data.data.assigned_to
                    this.setState({
                        modalData: response.data.data,
                    },this.openTaskModal(response.data.data))
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this) 

    openTaskModal = (function (taskData = {}) {
        try{
            GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                if(!taskData.start_date) {
                    let date = new Date(this.props.title);
                    let date2 = new Date(this.props.title);
                    date.setHours(0,0,0,0);
                    date2.setHours(23,59,59,0);
                    taskData.start_date = date.getTime()
                    taskData.end_date = date2.getTime()
                    taskData.type = "one_time"
                }
                this.setState({
                    assigneeOption: data,
                    showTaskModal:true,
                    modalData:taskData
                });
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    componentDidMount(){
        let showTaskModelBySharedLink = ( this.props.taskUrl === '' ? false:true)
        if ( showTaskModelBySharedLink ){
            this.getTaskData()
        }
    }
    
    render() {
        return (
            <LoggedInLayout>
                <Helmet>
                    <title>Main Dashboard - Orrderly</title>
                    <meta name="description" content="Orrderly helps you to become organized and be super productive." />
                    {/*<meta name="keywords" content="Music, Audio, Lyrics" />*/}
                </Helmet>
                <div className="row task task_top_view">
                    <div className="col-md-6 left_col">
                        <h2 className="head">All tasks for today  <span className="date">{this.state.date}</span></h2>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end text-end view_sec">
                        <div className=' d-flex task_switch'>
                            <span>All</span>
                            <Form>
                                <Form.Check value={this.props.taskSwitcher} checked={(parseInt(this.props.taskSwitcher) === 1)} onChange={(e)=>this.taskSwitcher(e)} type="switch" id="custom-switch"/>
                            </Form>
                            <span>My Tasks</span>
                        </div>
                        <NavLink className={"btn btn-primary" + (this.state.defaultTaskView === "list" ? " active" : "")} to="/dashboard/list" onClick={() => { this.setState({ defaultTaskView: "list" }) }}>
                            <div><i className='material-icons'>format_list_bulleted</i> <span style={{marginLeft:"0.5rem"}}>List view</span></div>
                        </NavLink>
                        <NavLink className={"btn btn-primary" + (this.state.defaultTaskView === "board" ? " active" : "")} to="/dashboard/board" onClick={() => { this.setState({ defaultTaskView: "board" }) }}>
                            <div><i className='material-icons rotate-icon'>web</i> <span style={{ marginLeft: "0.5rem" }}>Board view</span></div>
                        </NavLink>
                    </div>
                </div>
                {
                    this.state.defaultTaskView === "list" ? <TaskListView/> : <TaskBoardView/>
                }

                { this.state.showTaskModal &&
                    <AddTaskModal
                        modalSize="lg"
                        contentClassName="add_new_member_form add_task"
                        show={this.state.showTaskModal}
                        onTaskAdd={(data) => this.onTaskAdd(data)}
                        taskModalType='Edit'
                        hideAddEditTaskModal={() => this.hideAddEditTaskModal()}
                        modalData={this.state.modalData}
                        assigneeOption={this.state.assigneeOption}
                        onDeleteTask={(data) => this.deleteTaskModal(data)}
                    />
                }
            </LoggedInLayout>
        );
    }
}


const mapStateToProps = (state) => ({
    taskSwitcher:state.CurrentProjectBoard.taskSwitcher,
    project_id: state.CurrentProjectBoard.project_id
  });
  
const mapDispatchToProps = { task_switcher };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)