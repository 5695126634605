import React, { Component } from 'react'
import { Modal, Col, Row, Form, Button } from 'react-bootstrap'
import  icohouseg from '../../../../../../static/images/logged_in/icohouseg.png';
import  icobriefcase from '../../../../../../static/images/logged_in/icobriefcase.png';
import validator from 'validator';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import axios from 'axios';
import APIs from '../../../../../../utils/APIs';
import Labels from '../../../../../../utils/Labels'
import Looger from '../../../../../../utils/Logger'
let industryListData    = Labels.INDUSTRY_LIST;

let industryList        = []
industryListData.map((data,index)=>{
    let html =
        <div className="industry_div" key={index}>
            <Row>
                <Col md={{span:1}}  xs={{span:1}} sm={{span:1}}  className="industry_ico pe-1">
                    <span className="material-symbols-outlined">{data.icon}</span>
                </Col>
                <Col md={{span:10}} xs={{span:10}} sm={{span:10}} className="industry_name">
                    <div className="options_label_name">{data.name}</div>
                </Col>
            </Row>
        </div>
    industryList.push({
        labelKey: data.name,
        value: html,
        isSelected:data.isSelected
    })
    return true
})


class WorkOptionType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code:0,
            showModal:true,
            optionType:'',
            nextStapEnabel:false,
            companyName:'',
            companyNamError:'',
            industryName:'',
            role:'',
            industryList:industryList,
            roleNamError:'',
            size:0,
            userData:JSON.parse(localStorage.getItem('user')),
            axiosSource : axios.CancelToken.source(),

        }
    }

    optionTypeHandler = (function(selectedOption){
        try{
            if (selectedOption === 'peronsal') {
                this.addInformationApiHanlder(selectedOption)
            } else {
                setTimeout(()=>{
                    this.setState({
                        nextStapEnabel:true
                    })
                },500)
            }
            this.setState({
                optionType:selectedOption,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    addInformationApiHanlder =  (function(type='work'){
        try{
            let companyNamError  = this.state.companyNamError
            let roleNamError     = this.state.roleNamError
            let companyName      = this.state.companyName
            let industryName     = this.state.industryName
            let role             = this.state.role
            let teamSize         = parseInt(this.state.size)
            let parms = {
                type:type,
                company:companyName,
                industry:industryName,
                role:role,
                size:teamSize
            }
            let token   = localStorage.getItem('session_token');
            let config  = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
            
            let isValid = false
            if (type === 'work'){
                let size = parms.size === 0 ? 2 : teamSize
                parms.size = size 
                if ( roleNamError === '' &&  companyNamError === '' && companyName !== ''  && role !== ''){
                    isValid = true
                } else if (companyName === '' && role === '' ) {
                    companyNamError = 'Company name is empty'
                    roleNamError    = 'Your role name is empty'
                } else if (companyName === ''){
                    companyNamError = 'Company name is empty'
                } else if (role === '') {
                    roleNamError = 'Your role is empty'
                }  
                this.setState({
                    companyNamError:companyNamError,
                    roleNamError:roleNamError
                })
            } else {
                isValid = true
            }

            if (isValid){
                axios.post(
                    APIs.BASE_URL + APIs.ADD_ACCOUNT_TYPE_INFORMATION,
                    parms,
                    config
                ).then((response)=>{
                    let data = response.data
                    if (data.status_code === 200) {
                        this.props.callRefreshToken(this.state.companyName)
                    }
                }).catch((error)=>{

                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    validationHanlder = (function(type,value){
        try{
            let companyNamError  = ''
            let roleNamError     = ''
            if ( type === 'cname'){
                if (validator.isLength(value,26)) {
                    companyNamError = 'Company name should not be grater then 25 character'
                }
            } else if ( type === 'role' ) {
                if (validator.isLength(value,16)) {
                    roleNamError = 'Role name should not be grater then 15 character'
                }
            }
            this.setState({
                companyNamError:companyNamError,
                roleNamError:roleNamError
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }   
    })

    addInformationHanlder = (function(e,type){
        try{
            let value = e.target.value
            this.validationHanlder(type,value)
            if ( type === 'cname'){ 
                this.setState({
                    companyName:value, 
                })
            } else if ( type === 'role' ) {
                this.setState({
                    role:value 
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    addIndustryNameHandler = (function(e){
        try{
            this.setState({
                industryName:e.selectedKey[0] 
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    teamSizeHandler = (function(e){
        try{
            this.setState({
                size:e.target.value
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    render() {
        return (
            <div>
                <Modal show={this.state.showModal} size="md" backdrop="static" className='profile-completion-steps'>
                    <Modal.Body className="show-grid py-5">
                        { 
                        ! this.state.nextStapEnabel ?                       
                            <Row className="text-center validation_c">
                                <Col md={{span:12}}>
                                    <h4 style={{fontWeight: 700}}>Welcome {this.state.userData.first_name}!</h4>
                                </Col>
                                <Col md={{span:12}} className='mt-3'>
                                    <p className='px-5' >It’s great to have you here with us! To help us optimize your experience, tell us how you plan to use Orrderly</p>
                                </Col>
                                <Col md={{span:12}} className='mt-3 px-5'>
                                    <Col md={{span:12}} className={'option_type dtl py-3 px-2 ' + (this.state.optionType==='peronsal'?'active_bg':'')} onClick={()=>this.optionTypeHandler('peronsal')}>
                                        <Row>
                                            <Col md={{span:2}} xs={{span:2}} sm={{span:2}} className='p-0'>
                                                <img src={icohouseg} alt="house"/>
                                            </Col>
                                            <Col md={{span:4}} xs={{span:4}} sm={{span:4}}  className='p-0 text-start'>
                                                <span>Personal</span>
                                            </Col>
                                                <Col md={{span:4}} xs={{span:4}} sm={{span:4}}  className='offset-md-2 text-end'>
                                                { 
                                                    this.state.optionType==='peronsal' &&
                                                    <span className="material-symbols-outlined">arrow_right_alt</span>
                                                }
                                                </Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col md={{span:12}} className='mt-3 px-5'>
                                    <Col md={{span:12}} className={'option_type dtl py-3 px-2 ' + (this.state.optionType==='work'?'active_bg':'')} onClick={()=>this.optionTypeHandler('work')}>
                                        <Row>
                                            <Col md={{span:2}} xs={{span:2}} sm={{span:2}}className='p-0'>
                                                <img src={icobriefcase} alt="briefcase"/>
                                            </Col>
                                            <Col md={{span:4}} xs={{span:4}} sm={{span:4}} className='p-0 text-start'>
                                                <span>Work</span>
                                            </Col>
                                                <Col md={{span:4}} xs={{span:4}} sm={{span:4}} className='offset-md-2 text-end'>
                                                { 
                                                    this.state.optionType==='work' &&
                                                    <span className="material-symbols-outlined">arrow_right_alt</span>
                                                }
                                                </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                            :
                            <>
                            { this.state.optionType==='work' &&
                                <Row className="text-center validation_c c_info">
                                    <Col md={{span:12}}>
                                        <h4 style={{fontWeight: 700}}>Your Company</h4>
                                    </Col>
                                    <Col md={{span:12}} className='mt-3'>
                                        <p className='px-5' >Tell us about your company. You will be able to invite your team mates in the next step.</p>
                                    </Col>
                                    <Col md={{span:12}} className='mt-3'>
                                        <Form>
                                            <Form.Group className="px-5">
                                                <Form.Label>Company name</Form.Label>
                                                <Form.Control type="text" onChange={(e)=>this.addInformationHanlder(e,'cname')} value={this.state.companyName}/>
                                                { this.state.companyNamError !== "" &&
                                                    <Form.Control.Feedback type="invalid" className="errorMessage">
                                                        <i className="material-icons">warning</i>{this.state.companyNamError}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                            <Form.Group className="px-5 indus_list">
                                                <Col md={{span:12}} className='p-0'>
                                                    <Form.Label>Industry name</Form.Label>
                                                </Col>
                                                <Col md={{span:12}} className='p-0 industry_list'>
                                                    <BootstrapSelect
                                                        options={this.state.industryList}
                                                        onChange={this.addIndustryNameHandler}
                                                        key={this.state.assigneeOptionKey}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="px-5">
                                                <Form.Label>Your role</Form.Label>
                                                <Form.Control type="text" placeholder='Owner / Team Lead' onChange={(e)=>this.addInformationHanlder(e,'role')} value={this.state.role}/>
                                                { this.state.roleNamError !== "" &&
                                                    <Form.Control.Feedback type="invalid" className="errorMessage">
                                                        <i className="material-icons">warning</i>{this.state.roleNamError}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                            <Form.Group className="px-5">
                                                <Form.Label>Company size</Form.Label>
                                                <Form.Select onChange={(e)=>this.teamSizeHandler(e)} aria-label="Default select example">
                                                    <option>2</option>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Button onClick={()=>this.addInformationApiHanlder()} className='default_background mt-3'>Continue</Button>
                                        </Form>
                                    </Col>
                                </Row>
                            }
                            </>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
  
export default WorkOptionType;
