import axios from 'axios';
import React, { Component } from 'react';
import { Alert, Col, Dropdown, Row, Form, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import APIs from '../../../../utils/APIs';
import AddTaskButton from './AddTaskButton';
import GetTaskAssignee from './GetTaskAssignee';
import AddTaskModal from './Modals/AddTaskModal';
import PopUpModal from './Modals/PopUpModal';
import { connect } from "react-redux";
import Avatar from './Avatar';
import ShareTask from './Forms/ShareTask';
import Looger from '../../../../utils/Logger'
import { loader } from '../../../../reducers/CurrentProjectBoard'

class TaskListView extends Component {
    static  project_id  = 0
    constructor(props) {
        super(props);
        this.project_id = this.props.project_id
        this.taskSwitcher = this.props.taskSwitcher
        let dayName = new Date().toLocaleString('en-us', { weekday: 'short' });
        let monthName = new Date().toLocaleString('en-us', { month: 'short' });
        let dateNumber = new Date().getDate();
        this.state = {
            date: dayName + " " + dateNumber + " " + monthName,
            task_data : {},
            pendingTaskCount : 0,
            doneTaskId : 0,
            taskTypeInfo : {
                overdues_tasks: "Overdue tasks",
                recurring_tasks: "Recurring tasks",
                today_tasks: "Today tasks",
            },
            actionIcon : "",
            showRemoveTaskResponseMessage : "",
            showConfirmRemoveTaskModal : false,
            removeTaskData : {},
            showAddTaskModal :  false,
            taskModalType: "",
            modalData: {},
            section_start_date_error : "",
            assigneeOption : [],
            customTaskSections: [],
            user_id: JSON.parse(localStorage.getItem("user")).id,
            showShareTaskModal:false,
            taskUrl:'',
            axiosSource : axios.CancelToken.source()
        }
    }
    
    getTaskTags = (function (data, index,taskId) {
        try{
            return (
                <span key={"tag-key-" + index} className={'tags_sec ' + data.tag_class}>#{data.tag_name}</span>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    getHeaderDropdown = (function (taskType, customSectionData = {}) {
        try{
            if (taskType !== "customSection") {
                if (taskType === "overdue_tasks") {
                    return (<></>);
                } else {
                    let data = {}
                    if (taskType === "recurring_tasks") {
                        data = {
                            id:0,
                            type : "recurring",
                            frequency : "daily",
                            assigned_to_id : []
                        }
                    }
                    return (
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="default" id={"member-action-dropdown-logout"}>
                                <i className="material-icons-outlined">more_horiz</i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className='d-flex' onClick={(e) => this.openAddEditTaskModal("Add", data)}><i className="material-icons-outlined">add_box</i>Create New Task</Dropdown.Item>
                                {
                                    taskType === "today_tasks" && <Dropdown.Item onClick={(e) => this.showHideMoveSectionPopup(false, { id: 0, label: "Today's Tasks" })} className='d-flex'><i className="material-icons-outlined">ios_share</i> Move to another day</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
            return (
                <Dropdown align="end">
                    <Dropdown.Toggle variant="default" id={"member-action-dropdown-logout"}>
                        <i className="material-icons-outlined">more_horiz</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className='d-flex' onClick={(e) => this.openAddEditTaskModal("Add", {})}><i className="material-icons-outlined">add_box</i>Create New Task</Dropdown.Item>
                        <Dropdown.Item className='d-flex' onClick={(e) => this.showAddUpdateSectionPopup('Updat', customSectionData)}><i className="material-icons-outlined">border_color</i> Edit Section</Dropdown.Item>
                        <Dropdown.Item className='d-flex' onClick={(e) => this.showHideMoveSectionPopup(false, customSectionData)}><i className="material-icons-outlined">ios_share</i> Move to another day</Dropdown.Item>
                        <Dropdown.Item className='d-flex' onClick={(e) => this.showConfirmDeleteSectionModal(customSectionData)}><i className="material-icons-outlined">delete_outline</i> Delete Section</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    taskGroupHeading = (function (task_type) {
        try{
            return (
                <div className="col-md-11" key={"task-type-heading" + task_type}>
                    <h2 className="heading">
                        {this.state.taskTypeInfo[task_type]} <span className="date">{this.state.date}</span>
                        <span className='actions'>{task_type !== "overdues_tasks" ? this.getHeaderDropdown(task_type, {}) : ""}</span>
                    </h2>
                </div>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    urlify =  (function(text) {
        try{
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, function(url) {
            return '<a class="generated_link" target="_blank" href="' + url + '">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    taskShareHandler = (function (data={},type) {
        try{
            let showShareTaskModal = false
            let shareUrl = ''
            if (type === 'open'){
                shareUrl = data.share_url
                showShareTaskModal = true
            }
            this.setState({
                showShareTaskModal:showShareTaskModal,
                taskUrl:shareUrl,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    singleTaskDiv = (function (data, taskType) {
        try{
            let hours = String(new Date(data.start_date).getHours()).padStart(2, "0");
            let minutes = String(new Date(data.start_date).getMinutes()).padStart(2,"0");
            let AM_PM = (hours >= 12) ? "PM" : "AM";
            return (
                <div className='col-md-12 mt-2' key={"list-task-id-" + data.id}>
                    <div className="row">
                        <div className="col-md-11">              
                            <div className={this.state.doneTaskId === data.id ? "d-flex text-white justify-content-start task_sec active " : "d-flex text-white justify-content-start task_sec "}>
                                <Col md={{span:2}} className="schedule">
                                    { taskType === "overdues_tasks" ?
                                        <span className="time_schedule">
                                            <i className='material-icons-outlined' style={{ cursor: "pointer" }}>sync_problem</i> Reschedule
                                        </span>
                                    :
                                        <Row className="time">
                                            <Col md={{span:3}} className="ps-3">
                                                <i className="material-icons todotask-icon" style={{ cursor: "pointer" }} onClick={(e)=>{this.doneTask(e, data)}}>check</i>
                                            </Col>
                                            <Col md={{span:8}}>
                                                <span className='time_schedule_ongoing '>{hours + ":" + minutes + " "+AM_PM}</span>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                <Col className="vl"></Col>
                                <Col  md={{span:9}} className="d-grid align-items-center head_sec pointer" onClick={(e) => this.openAddEditTaskModal("Edit", data)}>
                                    <h4 className="task_head">{data.task_name}</h4>
                                    <h5 className="task_detail" dangerouslySetInnerHTML={{ __html: this.urlify(data.task_description) }}></h5>
                                    <div className="popup_task_info">
                                        {data.tags.map((tagData, tagIndex) => { return (this.getTaskTags(tagData, tagIndex, data.id)) })}
                                    </div>
                                    <ul className="task_actions">
                                        <li><i className='material-icons-outlined'>sms</i> {data.comments_count} comments</li>
                                        <li><i className='material-icons-outlined'>assignment</i> {data.sub_task_count} Sub-tasks</li>
                                        <li>
                                            <Avatar 
                                                teamData={data.members}
                                                limit={3} 
                                            />
                                            <span style={{paddingLeft: 10}}>{data.members.length} Assignee</span>
                                        </li>
                                    </ul>
                                </Col>
                            </div>
                        </div>
                        <div className={this.state.doneTaskId === data.id ? "col-md-1 right_side_action p-0 active" : "col-md-1 right_side_action p-0 "}>
                            {  data.owner_id === this.state.user_id &&
                                <>
                                    <i className="material-icons task_share dtl" onClick={()=>this.taskShareHandler(data,'open')} style={{width:"23px"}}>share</i>
                                    <i className="material-icons-outlined" style={{cursor:"pointer"}} onClick={(e) => { this.deleteTaskModal(data)}}>delete</i>
                                </>
                            }   
                        </div>
                    </div>
                </div>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    tasksList =  (function(data, taskType) {
        try{
            if (data.length > 0) {
                return (
                    <Row className={"task_list " + taskType} key={"task-type-" + taskType}>
                        { this.taskGroupHeading(taskType) }
                        {
                            data.map((singleTask) => {
                                return (this.singleTaskDiv(singleTask, taskType))
                            })
                        }
                    </Row>
                );
            } else {
                return (<></>);
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    onTaskAdd = (function(data) {
        try{
            this.hideAddEditTaskModal();
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    deleteTaskModal = (function (data) {
        try{
            this.setState({
                showConfirmRemoveTaskModal : true,
                removeTaskData: data,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    doneTask = (function (e, data) {
        try{
            this.setState({
                doneTaskId : data.id
            });
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
            axios.patch(
                APIs.BASE_URL + APIs.DONE_TASK_API.replace(":id", data.id),
                {status : "done"},
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    this.setState({
                        showRemoveTaskResponseMessage: `Task : ${data.task_name} has been done!`,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: "success"
                    });
                } else {
                    this.setState({
                        showRemoveTaskResponseMessage: response.data.message,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: ""
                    });
                }
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                    this.loadTasks();
                }, 2000);
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)

                this.setState({
                    showRemoveTaskResponseMessage: error.message,
                    showConfirmRemoveTaskModal: false,
                    actionIcon: ""
                });
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                    this.loadTasks();
                }, 5000);
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleRemoveTaskSubmit = (function(data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}`}, cancelToken: this.state.axiosSource.token };
            axios.delete(
                APIs.BASE_URL + APIs.DELETE_TASK_API.replace(":id", data.id),
                config
            ).then((response) => {
                if(response.data.status_code === 200) {
                    this.setState({
                        showRemoveTaskResponseMessage: `Task : ${data.task_name} has been deleted!`,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: "success"
                    });
                } else {
                    this.setState({
                        showRemoveTaskResponseMessage: response.data.message,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: ""
                    });
                }
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
                this.loadTasks();
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
                this.setState({
                    showRemoveTaskResponseMessage: error.message,
                    showConfirmRemoveTaskModal: false,
                    actionIcon: ""
                });
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    loadTasks = (function () {
        try{
            this.props.loader({loader:true})
            let switcher = localStorage.getItem('switcher');
            let token = localStorage.getItem('session_token');
            let start_date = new Date();
            let end_date = new Date();
            start_date.setHours(0, 0, 0, 0)
            end_date.setHours(23, 59, 59, 0)
            let params = {
                start_date: this.getFormatedDate(start_date),
                end_date: this.getFormatedDate(end_date),
                current_date: this.getFormatedDate(new Date()),
                board_id:this.project_id,
                switcher:switcher
            }
            let config = { headers: { authorization: `Bearer ${token}` }, params: params, cancelToken: this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_TASK_API,
                config
            ).then((response) => {
                let isShowloader = true
                if (response.data.status_code === 200) {
                    this.setState({
                        customSectionTasksData: {},
                    });
                    
                    let taskData = {
                        overdues_tasks: response.data.data.overdues_tasks,
                        recurring_tasks: response.data.data.recurring_tasks,
                        today_tasks: response.data.data.today_tasks,
                    }

                    this.setState({
                        task_data: taskData,
                        pendingTaskCount: (response.data.data.overdues_tasks.length + response.data.data.recurring_tasks.length + response.data.data.today_tasks.length),
                    });

                    GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                        this.setState({
                            assigneeOption: data
                        });
                    });
                    isShowloader = false
                } else {
                    isShowloader = false
                }
                this.props.loader({loader:isShowloader})
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
                this.props.loader({loader:false})
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getFormatedDate = (dateObject) => {
        try{
            let month = dateObject.getMonth() + 1;
            let day = dateObject.getDate();
            let year = dateObject.getFullYear();
            let hours = dateObject.getHours();
            let minutes = dateObject.getMinutes();
            let seconds = dateObject.getSeconds();
            //eslint-disable-next-line
            let timezone = dateObject.toString().match(/([-\+][0-9]+)\s/)[1];
            timezone = timezone.substr(0, 3) + ":" + timezone.substr(3);
            if (month < 10) {
                month = `0${month}`;
            }
            if (day < 10) {
                day = `0${day}`;
            }
            if (hours < 10) {
                hours = `0${hours}`;
            }
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000${timezone}`
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    confirmRemoveTaskModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="" show={this.state.showConfirmRemoveTaskModal} onChange={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title'>Remove Task</div>
                    <div className='warning-message'>Are you sure you want to remove this task from your task list?</div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>No, cancel</button>
                        <button className='btn btn-primary' onClick={() => { this.handleRemoveTaskSubmit(this.state.removeTaskData) }}>Yes, remove</button>
                    </div>
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    openAddEditTaskModal = (function(modalType = "Add", data = {}) {
        try{
            this.setState({
                showAddTaskModal : true,
                taskModalType: modalType,
                modalData : data
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    hideAddEditTaskModal = (function () {
        try{
            this.setState({
                showAddTaskModal: false,
                taskModalType: "",
                modalData: {}
            });
            this.loadTasks();
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    showHideMoveSectionPopup = (function (toggle = false, customSectionData) {
        try{
            this.setState({
                moveSectionPopup: !toggle,
                moveSectionData: !toggle ? customSectionData : {},
                section_start_date: ""
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    setSectionStartDate = (function (date) {
        try{
            this.setState({ section_start_date: date, section_start_date_error: "" });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    removeTaskResponseMessage = (function () {
        try{
            return (
                <Alert variant="warning" dismissible className='remove-task-message' style={{position:"absolute", top:0}}>
                    <div className='d-block'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className={this.state.actionIcon + " material-icons"}>{this.state.actionIcon !== "" ? "check" : "priority_high"}</i>
                            <span className="message">{this.state.showRemoveTaskResponseMessage}</span>
                        </div>
                    </div>
                </Alert>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleSectionMoveToAnotherDay = (function (e) {
        try{
            e.preventDefault();
            if (this.state.section_start_date === "") {
                this.setState({
                    section_start_date_error: "Please select a valid date"
                })
            } else {
                this.setState({
                    loading: true
                });
                let today_start_date = new Date();
                let today_end_date = new Date();
                today_start_date.setHours(0, 0, 0, 0)
                today_end_date.setHours(23, 59, 59, 0)
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
                let data = {
                    section_id: this.state.moveSectionData.id,
                    date: this.getFormatedDate(new Date(this.state.section_start_date)).split(" ")[0],
                    today_start_date: this.getFormatedDate(today_start_date),
                    today_end_date: this.getFormatedDate(today_end_date),
                    task_view: "list_view",
                };
                axios.post(
                    APIs.BASE_URL + APIs.TASK_SECTION_MOVE_API,
                    data,
                    config
                ).then((response) => {
                    if (response.data.status_code === 200) {
                        this.setState({
                            loading: false
                        });
                        this.showHideMoveSectionPopup(true, {})
                    }
                    this.loadTasks();
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                    this.showHideMoveSectionPopup(true, {})
                    this.loadTasks();
                    // Capture Error Logs
                    Looger(error)
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    componentWillUnmount () {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        let project_id = this.props.project_id
        if (isNaN(project_id)){
            project_id = parseInt(localStorage.getItem('project_id'))
        }
        if (this.project_id !== project_id){
            this.project_id = project_id
            this.loadTasks();
        }
        if(Object.keys(this.state.task_data).length === 0) {
            this.loadTasks();
        }

        let taskSwitcher = this.props.taskSwitcher
        if (isNaN(taskSwitcher)){
            taskSwitcher = parseInt(localStorage.getItem('switcher'))
        }
    
        if (this.taskSwitcher !== taskSwitcher){
            this.taskSwitcher = taskSwitcher
            this.loadTasks();
        }
        return (
            <>
                {
                    Object.keys(this.state.task_data).map((task_type) => {
                        return (
                            <Row className='mt-2 list_view_sec board_view_avatar' key={"task-list-view-for" + task_type}>
                                <Col md={{ span: 9 }} className='left_section'>
                                    {this.tasksList(this.state.task_data[task_type], task_type)}
                                </Col>
                            </Row>
                        )
                    })
                }
                <AddTaskButton pendingTaskCount={this.state.pendingTaskCount} openAddEditTaskModal={() => this.openAddEditTaskModal("Add", {})}/>
                {this.state.showConfirmRemoveTaskModal ? this.confirmRemoveTaskModal() : <></>}
                {this.state.showRemoveTaskResponseMessage ? this.removeTaskResponseMessage() : <></>}
                {
                    this.state.showAddTaskModal ?
                    <AddTaskModal
                        modalSize="lg"
                        contentClassName="add_new_member_form add_task"
                        show={this.state.showAddTaskModal}
                        onTaskAdd={(data) => this.onTaskAdd(data)}
                        taskModalType={this.state.taskModalType}
                        hideAddEditTaskModal={() => this.hideAddEditTaskModal()}
                        modalData={this.state.modalData}
                        assigneeOption={this.state.assigneeOption}
                        onDeleteTask={(data) => this.deleteTaskModal(data)}
                    />
                    :<></>
                }
                {
                    this.state.moveSectionPopup && <PopUpModal contentClassName="add_new_member_form" title={"Move section tasks to another day"} show={this.state.moveSectionPopup} onChange={(e) => { this.showHideMoveSectionPopup(true, {}) }}>
                        <p className='model-sub-heading'>Move tasks in “{this.state.moveSectionData.label}” section to another day</p>
                        <form onSubmit={(e) => { this.handleSectionMoveToAnotherDay(e) }} autoComplete='off'>
                            <div className={this.state.sectionError ? "mb-3 invalid" : "mb-3"}>
                                <label className="label">New Date <span className="asterisk">*</span></label>
                                <div className="input-group section-new-date-picker">
                                    <DatePicker
                                        selected={this.state.section_start_date}
                                        dateFormat="E d MMM, yyyy"
                                        minDate={new Date().setDate(new Date().getDate() + 1)}
                                        onChange={(date) => this.setSectionStartDate(date)}
                                        placeholderText={'Select new start date'}
                                        onKeyDown={(e) => {e.preventDefault();}}
                                    />
                                    {
                                        this.state.section_start_date_error !== "" ?
                                            <Form.Control.Feedback type="invalid" className="errorMessage">
                                                <i className="material-icons">warning</i>{this.state.section_start_date_error}
                                            </Form.Control.Feedback>
                                            : <></>
                                    }
                                </div>
                                {this.state.sectionError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.sectionError}</div> : <></>}
                            </div>
                            <div className="d-flex pt-1" style={{ justifyContent: "space-between" }}>
                                <button onClick={(e) => { this.showHideMoveSectionPopup(true, {}) }} type="reset" style={{ width: "164px" }} className="profile-btn btn btn-light" disabled={this.state.showLoading}>Cancel</button>
                                <button type="submit" style={{ width: "164px" }} className="profile-btn btn btn-primary" disabled={this.state.showLoading}>
                                    {
                                        this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Continue"
                                    }
                                </button>
                            </div>
                        </form>
                    </PopUpModal>
                }
                <ShareTask shareUrl={this.state.taskUrl} showShareTaskModal={this.state.showShareTaskModal} onClick={()=>{this.taskShareHandler({},'close')}}/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id,
    taskSwitcher:state.CurrentProjectBoard.taskSwitcher
});

const mapDispatchToProps = { loader };

export default connect(mapStateToProps,mapDispatchToProps)(TaskListView);