import {  createSlice } from '@reduxjs/toolkit'
export const CurrentProjectBoard = createSlice({
    name: 'CurrentProjectBoard',
    initialState: {
        project_id: parseInt(localStorage.getItem('project_id')),
        project_name:localStorage.getItem('project_name'),
        all_project_list:[],
        activeProjectInfo:[],
        searchQuery:'',
        taskSwitcher: localStorage.getItem('switcher') ? localStorage.getItem('switcher'):0,
        loader:true
    },
    reducers: {
        "update_current_project_board_id" : (state, action) => {
            state.project_id = parseInt(action.payload.project_id)
            state.project_name    = action.payload.project_name
        },
        "fetch_all_project_list" : (state, action) => {
            state.all_project_list = action.payload.allProjectList
            let activeProjectInfo = action.payload.allProjectList.filter(CheckActiveProject)
            function CheckActiveProject(data){if (data.is_current === 1){return data}}
            state.activeProjectInfo=activeProjectInfo
        },
        "task_switcher" : (state, action) => {
            localStorage.setItem('switcher',action.payload.taskSwitcher)
            state.taskSwitcher=action.payload.taskSwitcher
        },
        "global_search_query" : (state, action) => {
            state.searchQuery = action.payload.searchQuery
        },
        "loader" : (state, action) => {
            state.loader = action.payload.loader
        }

    }
});

export const { update_current_project_board_id,fetch_all_project_list,task_switcher,global_search_query,loader } = CurrentProjectBoard.actions;


export default CurrentProjectBoard.reducer