import React, { Component } from 'react';
import { Row, Col, Dropdown, Alert, Spinner } from 'react-bootstrap';
import APIs from '../../../../utils/APIs';
import PopUpModal from './Modals/PopUpModal';
import PopUpModalAlert from './Modals/PopUpModalAlert';
import validator from 'validator';
import axios from 'axios';
import Labels from '../../../../utils/Labels';
import { Marker } from 'react-mark.js';
import Looger from '../../../../utils/Logger';

class TeamMembers extends Component {
    constructor(props) {
        super(props);
        let userSelectedPlan = {};
        try {
            userSelectedPlan = JSON.parse(atob(localStorage.getItem('plan_info')));
        } catch(e) {
            userSelectedPlan = JSON.parse(localStorage.getItem('plan_info'));
        }
        let PlanFeatures = [];
        for(let i=0; i< userSelectedPlan.features.length;i++) {
            PlanFeatures[userSelectedPlan.features[i].label.replaceAll(" ", "_")] = userSelectedPlan.features[i].is_active
        }
        this.state = {
            showNewMemberModal : false,
            showMessageModal : false,
            emailError : "",
            roleError : "",
            removeMemberData : {},
            showRemoveMemberMessage : "",
            restoreMember : false,
            showLoading : false,
            actionIcon : "",
            PlanFeatures : PlanFeatures,
            userSelectedPlan : userSelectedPlan,
            axiosSource : axios.CancelToken.source(),
        };
    }

    singleTeamMemberDiv = (function (data, index) {
        try{
            if(data === null && index === 1) {
                return <></>
            }
            let classNameIs = "";
            if(data) {
                classNameIs = data.invite === 1 ? "" : " pending"
            }
            if(this.state.userSelectedPlan.quantity < index) {
                if(data === null) {
                    return <></>
                }
            }
            return (
                <Col md={{ span : 4 }} className={"member_sec" + classNameIs} key={"single-team-member-"+index}>
                    {data !== null ? this.teamInfoDiv(data) : (this.state.PlanFeatures['team_inbox_and_billing'] ? this.emptyDiv() : <></>)}
                </Col>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    teamInfoDiv = (function (data) {
        try{
            let name = data.first_name !== "" ? data.first_name + " " + data.last_name : data.email;
            let image = (data.profile_image !== "") ? data.profile_image : "/placeholder.png";
            if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                let avatar_bg = (data.avatar_bg?data.avatar_bg:"5D6DF6").replace('#','')
                image = `https://ui-avatars.com/api/?name=${name}&color=FFFFFF&length=1&background=${avatar_bg}`
            }
            let currentUser = JSON.parse(localStorage.getItem('user'))
            return (
                <div className="d-flex p-2 member_cols">
                    <Col md={{span:2}}  xs={{span:2}} sm={{span:2}} className="p-2">
                        <img src={image} alt={"profile pic " + name}/>
                    </Col>
                    <Col md={{span:8}} xs={{span:8}} sm={{span:8}}  className="p-2">
                        <h3>
                            <Marker mark={this.props.searchQuery?this.props.searchQuery:''} options={{ className: "custom-marker-1" }}>
                                {name}
                            </Marker>
                        </h3>
                        <h5>
                            <Marker mark={this.props.searchQuery?this.props.searchQuery:''} options={{ className: "custom-marker-1" }}>
                                {data.role}
                            </Marker>
                        </h5>
                    </Col>
                    <Col  md={{span:2}} xs={{span:2}} sm={{span:2}} className="p-2 ellipsis">
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="default" id={"member-action-dropdown-" + data.id}>
                                <i className="material-icons" style={{ color: "#9C9EAE" }}>more_vert</i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>this.handleUpdateTeamMember(data)} className='d-flex'><i className="material-icons">edit</i> &nbsp;Edit role</Dropdown.Item>
                                {
                                currentUser.id !== data.id &&
                                    <Dropdown.Item onClick={()=>this.confirmRemoveMember(data)} className='d-flex'><i className="material-icons">delete</i> &nbsp;Remove member</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </div>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    confirmRemoveMember = (function (data) {
        try{
            this.setState({ showConfirmRemoveMemberModal: true, removeMemberData : data});
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    emptyDiv = (function() {
        return (
            <>
                <div className="p-3 member_cols" onClick={() => this.toggleNewTeamMemberModal()}>
                    <h2 className="new_member_sec">
                        <i className='material-icons'>add</i> &nbsp;Add New Member
                    </h2>
                </div>
            </>
        );
    });

    toggleNewTeamMemberModal = (function (data = {}) {
        try{
            let modelTitle          =   "";
            let showNewMemberModal  =   !this.state.showNewMemberModal;
            let userObject          =   {
                email               :   "",
                role                :   ""
            };
            if(Object.keys(data).length === 0) {
                modelTitle          =   "Add new member";
            } else {
                modelTitle          =   "Update member";
                userObject          =   data;
            }
            this.setState({
                showNewMemberModal  :   showNewMemberModal,
                modalTitle          :   modelTitle,
                userObject          :   userObject,
                email               :   userObject.email,
                role                :   userObject.role,
                emailError          :   "",
                roleError           :   "",
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleUpdateTeamMember = (function (data) {
        try{
            this.toggleNewTeamMemberModal(data);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    toggleMessageModal = (function (modalClass = "", modalTitle = "", modalMessage = "") {
        try{
            this.setState({
                showNewMemberModal: false,
                showMessageModal: !this.state.showMessageModal,
                modalTitle: modalTitle,
                modalMessage: modalMessage,
                modalClass: modalClass,
                removeMemberData: {},
                userObject: [],
                email: "",
                role: "",
                emailError: "",
                roleError: "",
                actionIcon : "",
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    toggleConfirmRemoveMemberModal = (function () {
        try{
            this.setState({
                showNewMemberModal: false,
                showMessageModal: false,
                showConfirmRemoveMemberModal :  false,
                removeMemberData : {},
                modalTitle: "",
                modalMessage: "",
                modalClass: "",
                userObject: [],
                email: "",
                role: "",
                emailError: "",
                roleError: "",
                actionIcon: "",
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    handleFormSubmit = (function (event) {
        try{
            event.preventDefault();
            this.setErrorMessagesState({ emailError: "", roleError: "", submitButtonProp: true });
            let email       =   this.state.email;
            let role        =   this.state.role;
            let emailError  =   (email.trim() === "") ? "Email address is required" : "";
            let roleError   =   (role.trim() === "") ? "Role is required" : "";
            let token       =   localStorage.getItem('session_token');
            let config      =   { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            let param       =   {};
            roleError       =   roleError === "" ? this.checkValidRole(role) : roleError;
            emailError      =   emailError === "" ? this.checkValidEmail(email) : emailError;

            if (emailError === "" && roleError === "") {
                let member_id = 0;
                let API_URl = APIs.BASE_URL + APIs.TEAM_INVITE_MEMBER_API;
                if(this.state.modalTitle === "Update member") {
                    // userObject
                    member_id = this.state.userObject.id
                    API_URl = APIs.BASE_URL + APIs.TEAM_ROLE_UPDATE;
                }
                param = { email: this.state.email, role_name: this.state.role, team_id : member_id };
                let axiosReq = null;
                if(this.state.modalTitle === "Update member") {
                    axiosReq = axios.patch(API_URl, param, config)
                } else {
                    axiosReq = axios.post(API_URl, param, config)
                }
                axiosReq.then((response) => {
                    this.setState({ showLoading: false });
                    if (response.data.status_code === 200) {
                        let headingText = this.state.modalTitle === "Update member" ? "Role updated" : "Invite sent"
                        this.toggleMessageModal("primary", headingText, response.data.message);
                        let token = localStorage.getItem('session_token');
                        this.props.refreshData(token)
                    } else {
                        this.toggleMessageModal("warning", "Oh snap!", response.data.message);
                    }
                }).catch((error) => {
                    this.toggleMessageModal("danger", "Oh snap!", APIs.INVITE_UNSUCESSFULL);
                });
            } else {
                this.setErrorMessagesState({ emailError: emailError, roleError: roleError, submitButtonProp: false});
            }
            event.stopPropagation();
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onInputChangeHandler = (function(event) {
        try{
            if (event.target.name === "email") {
                this.checkValidEmail(event.target.value);
            } else if (event.target.name === "role") {
                this.checkValidRole(event.target.value);
            } else {
                this.setState({ [event.target.name]: event.target.value });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
	}).bind(this);

    /**
     * @function checkValidEmail
     * @param {string} email
     * @description validate the email string while typing and display the message
     * @returns string
     */
    checkValidEmail = (function (email) {
        try{
            let emailError = "";
            if (!validator.isEmail(email) && email.length > 0) {
                emailError = "Invalid email address";
            }
            this.setState({ email: email, emailError: emailError });
            return emailError;
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    /**
     * @function checkValidRole
     * @param {string} role
     * @description validate the role string while typing and display the message
     * @returns string
     */
    checkValidRole = (function (role) {
        try{
            let roleError = "";
            if (!validator.isAlphanumeric(role, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && role.length > 0) {
                roleError = "Enter a valid role";
            }
            this.setState({ role: role, roleError: roleError });
            return roleError;
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    /**
     * @function setErrorMessagesState
     * @param {JSON Object} data
     * @description To set error messages and button text
     * @returns void
     */
    setErrorMessagesState = (function (data) {
        try{
            let emailError          =   data.emailError;
            let roleError           =   data.roleError;
            let submitButtonProp    =   data.submitButtonProp;
            let submitButtonText    =   submitButtonProp ? "Loading" : "Continue";
            let showLoading         =   submitButtonText === "Loading" ? true : false;
            this.setState({
                emailError          :   emailError,
                roleError           :   roleError,
                submitButtonText    :   submitButtonText,
                submitButtonProp    :   submitButtonProp,
                actionIcon          :   "",
                showLoading         :   showLoading,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    memberAddUpdateForm = (function() {
        try{
            return (
                <form onSubmit={this.handleFormSubmit} autoComplete='off'>
                    <div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
                        <label className="label">{Labels.USER_EMAIL_ADDRESS} <span className="asterisk">*</span></label>
                        <div className="input-group">
                            <input type="text" className="form-control shadow-none" name="email" placeholder="Enter member's email address" id="email" readOnly={this.state.modalTitle === "Update member"} value={this.state.email} onChange={this.onInputChangeHandler} autoComplete="off" />
                        </div>
                        {this.state.emailError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.emailError}</div> : <></>}
                    </div>
                    <div className={this.state.roleError ? "mb-3 mt-3 invalid" : "mb-3 mt-3"}>
                        <label className="label">{Labels.USER_ROLE} <span className="asterisk">*</span></label>
                        <div className="input-group">
                            <input type="text" className="form-control shadow-none" name="role" placeholder="Enter new member role" id="role" value={this.state.role} onChange={this.onInputChangeHandler} autoComplete="off" />
                        </div>
                        {this.state.roleError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.roleError}</div> : <></>}
                    </div>
                    <div className="d-grid pt-1">
                        <button type="submit" className="btn btn-primary btn-block invite_sent_btn" disabled={this.state.showLoading}>
                            {
                                this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Continue"
                            }
                        </button>
                    </div>
                </form>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    addNewMemberModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title={this.state.modalTitle} show={this.state.showNewMemberModal} onChange={()=>this.toggleNewTeamMemberModal()}>
                    {this.memberAddUpdateForm()}
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    messagePopUpAlert = (function () {
        try{
            return (
                <PopUpModalAlert contentClassName={"invite_modal " + this.state.modalClass} title={this.state.modalTitle} message={this.state.modalMessage} show={this.state.showMessageModal} onChange={() => this.toggleMessageModal()} />
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    confirmRemoveMemberModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="" show={this.state.showConfirmRemoveMemberModal} onChange={() => this.toggleConfirmRemoveMemberModal()}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title'>Remove member</div>
                    <div className='warning-message'>Are you sure you want to remove this member  from your team?</div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showConfirmRemoveMemberModal: false, removeMemberData: {} }) }} disabled={this.state.showLoading}>No, cancel</button>
                        <button className='btn btn-primary' onClick={() => { this.handleRemoveMemberSubmit(this.state.removeMemberData) }} disabled={this.state.showLoading}>
                            {this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Yes, remove"}
                        </button>
                    </div>
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);


    handleRemoveMemberSubmit = (function (data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            let param = {};
            this.setState({ showLoading : true});

            param = { id: data.id };
            axios.patch(
                APIs.BASE_URL + APIs.TEAM_MEMBER_REMOVE_API,
                param,
                config
            ).then((response) => {
                let restoreMember = false
                if (response.data.status_code === 200) {
                    restoreMember = true;
                    let indexPosition = this.props.teamData.indexOf(data);
                    this.props.teamData.splice(indexPosition, 1);
                }
                this.setState({
                    showRemoveMemberMessage: response.data.message,
                    showMessageModal: false,
                    showLoading: false,
                    showConfirmRemoveMemberModal: false,
                    restoreMember: restoreMember,
                    actionIcon: "",
                });
                setTimeout(() => {
                    this.setState({ showRemoveMemberMessage:""});
                }, 5000);
            }).catch((error) => {
                this.setState({
                    showRemoveMemberMessage: APIs.SERVER_NOT_AVAILABLE,
                    showMessageModal: false,
                    showLoading: false,
                    showConfirmRemoveMemberModal: false,
                    restoreMember: false,
                    actionIcon: "",
                });
                setTimeout(() => {
                    this.setState({ showRemoveMemberMessage: "" });
                }, 5000);

                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleRestoreMemberSubmit = (function (data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            let param = {};
            param = { id: data.id };
            axios.patch(
                APIs.BASE_URL + APIs.TEAM_MEMBER_RESTORE_API,
                param,
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    this.props.teamData.push(data);
                }
                this.setState({
                    showRemoveMemberMessage: response.data.message,
                    showMessageModal: false,
                    showConfirmRemoveMemberModal: false,
                    restoreMember: false,
                    actionIcon : "success"
                });
                setTimeout(() => {
                    this.setState({ showRemoveMemberMessage: "" });
                }, 5000);
            }).catch((error) => {
                this.setState({
                    showRemoveMemberMessage: APIs.SERVER_NOT_AVAILABLE,
                    showMessageModal: false,
                    showConfirmRemoveMemberModal: false,
                    restoreMember: false,
                    actionIcon: "",
                });
                setTimeout(() => {
                    this.setState({ showRemoveMemberMessage: "" });
                }, 5000);
                
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
            <>
                <Row>
                    { this.props.teamData.map((data, index) => this.singleTeamMemberDiv(data, index)) }
                    {this.singleTeamMemberDiv(null, (this.props.teamData.length+1))}
                </Row>
                { this.state.showNewMemberModal ?  this.addNewMemberModal() : <></> }
                {this.state.showMessageModal ?  this.messagePopUpAlert() : <></> }
                {this.state.showConfirmRemoveMemberModal ? this.confirmRemoveMemberModal() : <></> }
                {
                    this.state.showRemoveMemberMessage !== "" ? 
                    <Alert variant="warning" dismissible className='remove-member-message'>
                        <div className='d-block'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                    <i className={this.state.actionIcon +" material-icons"}>{this.state.actionIcon !== "" ? "check": "priority_high"}</i>
                                <span className="message">{this.state.showRemoveMemberMessage}</span>
                            </div>
                            {/* {
                                this.state.restoreMember ?
                                <button type="button" className="close float-right" data-dismiss="alert" aria-label="Close" onClick={() => { this.handleRestoreMemberSubmit(this.state.removeMemberData)}}>
                                    <span aria-hidden="true">Undo</span>
                                </button>
                                : <></>
                            } */}
                        </div>
                    </Alert>
                    : <></>
                }
            </>
        );
    }
}

export default TeamMembers;