import axios from 'axios';
import React, { Component } from 'react';
import {  Button, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import APIs from '../../../../../utils/APIs';
import PopUpModal from '../../Common/Modals/PopUpModal';
import { Spinner } from 'react-bootstrap';
import { connect } from "react-redux";
import Looger from '../../../../../utils/Logger';
import { loader } from '../../../../../reducers/CurrentProjectBoard';

class DeleteAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show : false,
            passwordError : "",
            password:"",
            showLoading : false,
            successMessage : "",
            showPassword : false,
            axiosSource : axios.CancelToken.source(),
            technical:false,
            expensive:false,
            another:false,
            missing:false,
            survey:false,
            errorMessage:"",
            surveyValue:"",
            userComment:""
        }
    }

    onInputChangeHandler = (function(e) {
        try{
            this.setState({
                [e.target.name] : e.target.value.trim(),
                passwordError:''
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    togglePasswordField = (function (e) {
        try{
            this.setState({
                showPassword: !this.state.showPassword
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    handleDeleteAccountForm = (function(e) {
        try{
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                showLoading : true,
            });

            if (this.state.password !=='') {
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, data : {password: this.state.password,reason:this.state.surveyValue,comment:this.state.userComment}, cancelToken : this.state.axiosSource.token };
                axios.delete(
                    APIs.BASE_URL + APIs.PROFILE_DELETE_API,
                    config
                ).then((response) => {
                    this.setState({
                        showLoading: false,
                        passwordError: response.data.status_code !== 200 ? response.data.message : "",
                        successMessage: response.data.status_code === 200 ? response.data.message : ""
                    });
                    if (response.data.status_code === 200) {
                        localStorage.clear();
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 5000)
                    }
                }).catch((error) => {
                    this.setState({
                        showLoading: false,
                    });
                })
            } else {
                this.setState({
                    showLoading: false,
                    passwordError:'Password is required.'
                });
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }
    
    userSelectValue = (function(e){
        try{
            let value = e.target.value 
            this.setState({
                technical:  value==='technical',
                expensive:  value==='expensive',
                another:    value==='another',
                missing:    value==='missing',
                surveyValue:value,
                errorMessage:''
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    surveyForm = (function(e){
        try{
            e.preventDefault()
            let surveyModel = false
            let errorMessage = 'Please select at least one option.'
            if (this.state.technical || this.state.expensive || this.state.another || this.state.missing){
                surveyModel  = true
                errorMessage = ''
            }
            this.setState({
                survey:surveyModel,
                errorMessage:errorMessage
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)


    userOutput = (function(e){
        try{
            let usercomment = e.target.value 
            this.setState({
                userComment:usercomment,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)


    showDeleteAccountModel = (function(e){
        try{
            e.preventDefault()
            this.setState({
                survey:false,
                show:true
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)


    componentDidMount(){
        this.props.loader({loader:false})
    }

    render() {
        return (
            <div className='mt-2 mb-2'>
                <Helmet>
                    <title>Delete Account - Orrderly</title>
                </Helmet>
                <Row>
                    <Col md={{span:12}}>
                        <h6>Delete account</h6>
                        <h6 className='mt-5'>Account deletion is final. There will be no way to restore your account.</h6>
                    </Col>
                    <Col md={{ span: 12}} className='mt-1'>
                        <div className="col-md-6 dtl-subcontent"> 
                            <span className='dlt'>Can we know why you want to delete your Orrderly account? Every bit of feedback helps!</span>
                        </div>
                    </Col>
                    <form onSubmit = {(e)=>{this.surveyForm(e)}} className="delete_acc">
                        <Col md={{ span: 12}}  className='mt-2' style={{height:"170px"}}>
                                <div className="form-check mt-3">
                                    <input className="form-check-input dtl" checked={this.state.technical} value="technical" onChange={this.userSelectValue} type="radio" name="dlt" id="dtl1"/>
                                    <label className="form-check-label dtl" htmlFor="dtl1">Are you experiencing technical issues?</label>
                                </div>
                                <div className="form-check mt-3">
                                    <input className="form-check-input dtl"  checked={this.state.expensive} value="expensive" onChange={this.userSelectValue}  type="radio" name="dlt" id="dtl2"/>
                                    <label className="form-check-label dtl" htmlFor="dtl2">Is the product too expensive?</label>
                                </div>
                                <div className="form-check mt-3">
                                    <input className="form-check-input dtl"  checked={this.state.another} value="another" onChange={this.userSelectValue}  type="radio" name="dlt" id="dtl3"/>
                                    <label className="form-check-label dtl" htmlFor="dtl3">Are you switching to another product?</label>
                                </div>
                                <div className="form-check mt-3">
                                    <input className="form-check-input dtl"  checked={this.state.missing} value="missing" onChange={this.userSelectValue}  type="radio" name="dlt" id="dtl4"/>
                                    <label className="form-check-label dtl" htmlFor="dtl4">Missing features you need</label>
                                </div>
                                { this.state.errorMessage !== '' &&
                                    <div className="errorMessage">
                                        <i className="material-icons">warning</i>{this.state.errorMessage}
                                    </div>
                                }
                        </Col>
                        <Col md={{ span: 12}}  className='mt-2'>
                            <button type="submit" className="btn dlt-btn">Continue</button>
                        </Col>
                    </form>
                </Row>
                <PopUpModal contentClassName="add_new_member_form task_list dlt-model mb-0" title="Delete account" show={this.state.show} onChange={(e) => { this.setState({ show: false }) }}>
                    {
                        this.state.successMessage !== "" ? <p className='task_detail mb-2'>{this.state.successMessage}</p> : 
                    <>
                        <p className='task_detail mb-2'>Account deletion is final. There will be no way to restore your account.</p>
                        <form autoComplete='off' onSubmit={(e) => {this.handleDeleteAccountForm(e)}}>
                            <Row>
                                <Col md={{ span: 12}} className='mb-4'>
                                    <div className={this.state.passwordError ? "invalid" : ""}>
                                        <div className="input-group">
                                            <span className="input-group-text"><i className="material-icons lock_ico">lock</i></span>
                                            <input type={!this.state.showPassword ? "password" : "text"} className="form-control shadow-none" placeholder="Enter your password" name="password" id="password" value={this.state.password} onChange={(e) => {this.onInputChangeHandler(e)}} autoComplete="off" />
                                        </div>
                                        {this.state.passwordError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.passwordError}</div> : <></>}
                                    </div>
                                </Col>
                                <Col md={{ span: 12}} className='text-center'>
                                    <Col md={{ span: 7}} className='m-auto'>
                                        <Button type="submit" className="profile-btn w-100">
                                            {
                                                this.state.showLoading?
                                                <><Spinner size="sm" animation="border" variant="primary"/></>
                                                :'Delete account'
                                            }
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </form>
                    </>
                    }
                </PopUpModal>    
                <PopUpModal contentClassName="add_new_member_form  dlt-model task_list mb-0" title="Delete account" show={this.state.survey} onChange={(e) => { this.setState({ survey: false }) }}>
                    <p className='task_detail mb-2'>
                        {this.state.technical && <>Kindly let us know what technical issues you’re experiencing, you can also check our <a className="default_colors" href="https://docs.orrderly.com/" target="_blank"  rel="noreferrer"> help &amp; support</a> page, you might find solutions to the issues there</>
                        }
                        {this.state.expensive && <>Did you find Orrderly to be too expensive, tell us more about that we’ll appreciate your feedback.</>}
                        {this.state.another && <>Are you switching to another product? if yes kindly tell us why so we can improve, thanks.</>}
                        {this.state.missing && <>Let us know which features you wish was available on Orrderly.</>}
                    </p>
                    <form autoComplete='off'>
                        <Row>
                            <Col md={{ span: 12}} className='mb-4'>
                                <textarea onChange={this.userOutput}  placeholder="Commenting is optional" style= {{width:"100%", border:0,borderRadius:10,background:"#D1D2DA38",padding:20,height:116}} />
                            </Col>
                            <Col md={{ span: 12}} className='text-center'>
                                <Button type="submit" onClick={(e)=>{this.showDeleteAccountModel(e)}} className="w-50" >Continue</Button>
                            </Col>
                        </Row>
                    </form>
                </PopUpModal>  
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    loader: state.CurrentProjectBoard.loader,
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(DeleteAccount);
