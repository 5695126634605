import React, { Component } from 'react'
import {Row, Col} from 'react-bootstrap'
import { Marker } from "react-mark.js";
import APIs from '../../../utils/APIs';
import axios from 'axios';
import { connect } from "react-redux";
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import AddTaskModal from './Common/Modals/AddTaskModal';
import GetTaskAssignee from './Common/GetTaskAssignee';
import Avatar from './Common/Avatar';
import Looger from '../../../utils/Logger';
import {loader} from '../../../reducers/CurrentProjectBoard'
TimeAgo.addLocale(en)

class GlobalSearch extends Component {
    constructor(props){
        super(props)
        this.searchQuery = this.props.searchQuery
        this.state = {
            teamData:[],
            taskData:[],
            projectData:[],
            assigneeOption : [],
            showAddTaskModal:false,
            taskModalType:'',
            removeTaskData:{},
            axiosSource : axios.CancelToken.source()
        }
    }


    globalSearchHandler = (function(){
        try{
            this.props.loader({loader:true})
            this.setState({isPageLoading:true})
            let token    =  localStorage.getItem('session_token') 
            let config   = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GLOBAL_SERACH.replace(':query',this.props.searchQuery),
                config
            ).then((response) => {
                let data = response.data
                if (data.status_code === 200) {
                    let teamData    = data.data.team_data
                    let taskData    = data.data.task_data
                    let projectData = data.data.project_data
                    this.setState({
                        teamData:teamData,
                        taskData:taskData,
                        projectData:projectData,
                        isPageLoading:false
                    })
                }
                this.props.loader({loader:false})
            }).catch((error) => {
                this.setState({isPageLoading:false})
                this.props.loader({loader:false})
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount(){
        this.globalSearchHandler()
    }

    teamjsx = (function(teamData){
        try{
            return teamData.map((data,index)=>{
                let image = (data.profile_image !== "") ? data.profile_image : "/placeholder.png";
                if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                    let avatar_bg = (data.avatar_bg?data.avatar_bg:"5D6DF6").replace('#','')
                    image = `https://ui-avatars.com/api/?name=${data.name}&color=FFFFFF&length=1&background=${avatar_bg}`
                }
                return(
                    <Col md={{span:3}} key={index}>
                        <Col className="d-flex p-2 member_cols">
                            <Col md={{span:2}} className="p-2">
                                <img src={image} alt={data.name}/>
                            </Col>
                            <Col md={{span:10}} className="p-2">
                                <h3><Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>{data.name}</Marker></h3>
                                <h5><Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>{data.role}</Marker></h5>
                            </Col>
                        </Col>
                    </Col>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    boardProgressJsx = (function(status){
        try{
            let markerStatusJsx = <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>{status}</Marker>
            return (
                <>
                    { 
                    status === 'Draft' ?
                        <h3 style={{borderRadius:"5px"}} ref={(el) => { if (el) {el.style.setProperty('color', '#ED713C', 'important');el.style.setProperty('background', '#FFEAE1', 'important');}}} >{markerStatusJsx}</h3>
                    :
                    status === 'Due' ?
                        <h3 style={{borderRadius:"5px"}} ref={(el) => { if (el) {el.style.setProperty('color', '#dc6562', 'important');el.style.setProperty('background', '#ffe5e4', 'important');}}} >{markerStatusJsx}</h3>
                    :
                        <h3 style={{borderRadius:"5px"}} ref={(el) => { if (el) {el.style.setProperty('color', '#6C9D4A', 'important');el.style.setProperty('background', '#E7F8DC', 'important');}}} >{markerStatusJsx}</h3>
                    }
                </>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    projectJsx = (function (projectData) {
        try{
            return projectData.map((data,index)=>{
                return(
                    <Col md={{ span : 12 }} className={"member_sec"} key={index}>
                        <Col className="d-flex p-2 member_cols  flex-wrap">
                            <Col md={{span:6}} xs={{span:12}} className="p-2 board_name">
                                <h3>
                                    <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                                        {data.name}
                                    </Marker>
                                </h3>
                                <h5><ReactTimeAgo date={new Date(data.updated_at)} locale="en" /></h5>
                            </Col>
                            <Col md={{span:6}} xs={{span:12}} sm={{span:12}}>
                                <Row >
                                    <Col md={{span:2}} xs={{span:3}} sm={{span:3}} className="d-flex p-2 board_task_count">
                                        <h3><span className="material-symbols-outlined">assignment</span></h3>
                                        <h3>
                                            <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                                                {data.task_count}
                                            </Marker>
                                        </h3>
                                    </Col>
                                    <Col md={{span:4}}  xs={{span:4}} sm={{span:3}} className="p-2 board_progress">
                                        {this.boardProgressJsx(data.status)}
                                    </Col>
                                    <Col md={{span:2}}  xs={{span:5}}  sm={{span:3}}className="p-2 board_avatar">
                                        <Avatar 
                                            teamData={data.members}
                                            limit={3} 
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Col>
                    </Col>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });
    
    openAddEditTaskModal = (function (modalType, data = {}) {
        try{
            GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:data.board_single_id}).then((teamMemberData) => {
                this.setState({
                    assigneeOption: teamMemberData,
                    showAddTaskModal: true,
                    taskModalType: modalType,
                    modalData: data
                });
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    hideAddEditTaskModal = (function () {
        try{
            this.setState({
                showAddTaskModal: false,
                taskModalType: "",
                modalData: {}
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    deleteTaskModal = (function (data) {
        try{
            this.setState({
                showConfirmRemoveTaskModal: true,
                removeTaskData: data,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    taskJsx = (function (taskData) {
        try{
            return taskData.map((taskDetail, index) => {
                let hours = String(new Date(taskDetail.start_date).getHours()).padStart(2, "0");
                let minutes = String(new Date(taskDetail.start_date).getMinutes()).padStart(2, "0");
                let AM_PM = (hours >= 12) ? "PM" : "AM";
                let  date1 = new Date();
                let  date2 = new Date(taskDetail.start_date);
                let  diffTime = Math.abs(date2 - date1);
                let  diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                let  endTimeDiff =  false
                if ( taskDetail.end_date > 0 ) {
                    endTimeDiff = taskDetail.end_date*1000 < new Date().getTime()*1000 && taskDetail.status !== "done" ?true:false
                } else {
                    let today_end_date = new Date();
                    today_end_date.setHours(23, 59, 59, 0)
                    endTimeDiff = taskDetail.start_date*1000 > today_end_date.getTime()*1000 && taskDetail.status !== "done" ?true:false
                }
                    
                return (
                    <div className="col-md-6 dtl px-4" key={"overdue-task-id-" + taskDetail.id} onClick={(e) => this.openAddEditTaskModal("Edit", taskDetail)}>
                        <div className="d-flex text-white justify-content-start task_sec">
                            <Col md={{span:(endTimeDiff?3:taskDetail.status === "done"?3:3)}} className="schedule">
                                {
                                endTimeDiff ?
                                    <span className="time_schedule">
                                        <i className='material-icons-outlined' style={{ cursor: "pointer" }}>sync_problem</i> Reschedule
                                    </span>
                                : taskDetail.status === "done" ?
                                    <Row className="time">
                                        <Col md={{span:3}} className="ps-3">
                                            <i className="material-icons todotask-icon active" style={{ cursor: "pointer" }} >check</i>
                                        </Col>
                                        <Col md={{span:9}}>
                                            <span className='time_schedule_ongoing '>{hours + ":" + minutes + " "+AM_PM}</span>
                                        </Col>
                                    </Row>
                                :
                                    <Row className="time">
                                        <Col md={{span:3}} className="ps-3">
                                            <i className="material-icons todotask-icon" style={{ cursor: "pointer" }}>check</i>
                                        </Col>
                                        <Col md={{span:9}}>
                                            <span className='time_schedule_ongoing '>{hours + ":" + minutes + " "+AM_PM}</span>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Col className="vl"></Col>
                            <Col md={{span:8}} className="d-grid align-items-center head_sec">
                                <h4 className="task_head">
                                    <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>
                                        {taskDetail.task_name}
                                    </Marker>
                                </h4>
                                <h5 className="task_detail">
                                    <span>
                                        <Marker mark={this.props.searchQuery} options={{ className: "custom-marker-1" }}>{taskDetail.task_description}</Marker>
                                    </span>
                                    <span> - In Progress {diffDays} days</span>
                                </h5>
                                <ul className="task_actions">
                                    {taskDetail.comments_count > 0 ? <li><i className="material-icons-outlined">sms</i> {taskDetail.comments_count} comments</li> : <></>}
                                    {taskDetail.sub_task_count > 0 ? <li><i className="material-icons-outlined">assignment</i> {taskDetail.sub_task_count} Sub-tasks</li> : <></>}
                                </ul>
                            </Col>
                        </div>
                    </div>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    onTaskAdd = (function(){
        try{
            this.globalSearchHandler()
            this.hideAddEditTaskModal()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

  render() {
    let searchQuery = this.props.searchQuery
    if (searchQuery)
        if (searchQuery !== this.searchQuery){
            this.searchQuery = searchQuery
            this.globalSearchHandler()
        }
    return (
        <>
        <Col className='px-3 py-3 gb_search'>
            { !this.state.isPageLoading ?
                <Row>
                    <Col md={{span:12}} className='gb_search_team_data'>
                        <Col md={{span:12}} className='gb_search_result'>
                            <Col md={{span:12}} className='gb_search_heading'>Team members <span className='gb_search_count'>({this.state.teamData.length})</span></Col>
                        </Col>
                        <Col md={{span:12}} className='gb_team_data team_member px-3'>
                            <Row>
                                {this.teamjsx(this.state.teamData)}
                            </Row>
                        </Col>
                    </Col>
                    <Col md={{span:12}} className='gb_search_task_data overdue_task'>
                        <Col md={{span:12}} className='gb_search_result'>
                            <Col md={{span:12}} className='gb_search_heading'>Task <span className='gb_search_count'>({this.state.taskData.length})</span></Col>
                        </Col>
                        <Col md={{span:12}} className='task_list px-3'>
                            <Row className='task_list'>
                                {this.taskJsx(this.state.taskData)}
                            </Row>
                        </Col>
                    </Col> 
                    <Col md={{span:12}} className='gb_search_project_data'>
                        <Col md={{span:12}} className='gb_search_result'>
                            <Col md={{span:12}} className='gb_search_heading'>Project <span className='gb_search_count'>({this.state.projectData.length})</span></Col>
                        </Col>
                        <Col md={{span:12}} className='gb_team_data team_member px-3'>
                            <Row>
                                    {this.projectJsx(this.state.projectData)}
                            </Row>
                        </Col>
                    </Col>
                </Row>
            :
                <>""</>
            }
        </Col>
        {
            this.state.showAddTaskModal &&
            <AddTaskModal
                modalSize="lg"
                contentClassName="add_new_member_form add_task"
                show={this.state.showAddTaskModal}
                onTaskAdd={() => this.onTaskAdd()}
                taskModalType={this.state.taskModalType}
                hideAddEditTaskModal={() => this.hideAddEditTaskModal()}
                modalData={this.state.modalData}
                assigneeOption={this.state.assigneeOption}
                onDeleteTask={(data) => this.deleteTaskModal(data)}
            />
        }
        </>
    )
  }
}

const mapStateToProps = (state) => ({
    searchQuery:state.CurrentProjectBoard.searchQuery
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(GlobalSearch);

// export default GlobalSearch