import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Spinner,Col, } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import APIs from '../../../../../utils/APIs';
import AddTaskForm from '../Forms/AddTaskForm';
import Looger from '../../../../../utils/Logger';


class AddTaskModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limitExhausted : false,
            loading : true,
            axiosSource : axios.CancelToken.source(),
            PlanFeatures : [],
            CallApi:false,
            taskFormFooter:false
        };
    }

    handleHideModal = (function (e) {
        try{
            this.setState({
                CallApi:true
            })
            for (var i = 0;i < e; i++){
                this.handleHideModal()
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);


    setPlanFeatures = (function(){
        try{
            if(this.props.taskModalType === "Add") {
                let userSelectedPlan = {};
                try {
                    userSelectedPlan = JSON.parse(atob(localStorage.getItem('plan_info')));
                } catch(e) {
                    userSelectedPlan = JSON.parse(localStorage.getItem('plan_info'));
                }
                let PlanFeatures = [];
                for(let i=0; i< userSelectedPlan.features.length;i++) {
                    PlanFeatures[userSelectedPlan.features[i].label.replaceAll(" ", "_")] = userSelectedPlan.features[i].is_active
                }

                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
                axios.get(
                    APIs.BASE_URL + APIs.GET_MY_TASK_COUNT,
                    config
                ).then((response) => {
                    if(PlanFeatures.hasOwnProperty('20_tasks') && response.data.data.task_count >= 20) {
                        this.setState({
                            limitExhausted : true,
                            loading : false,
                            PlanFeatures : userSelectedPlan
                        })
                    } else {
                        this.setState({
                            limitExhausted : false,
                            loading : false,
                            PlanFeatures : userSelectedPlan
                        })
                    }
                }).catch((error) => {
                    //
                })
            } else {
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    getPlanInfo() {
        try{
            let projectId   = localStorage.getItem('project_id')
            let token       = localStorage.getItem('session_token');
            let config      = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                `${APIs.BASE_URL}/get-plan-info/${projectId}`,
                config
            ).then((response) => {      
                this.setState({
                    PlanFeatures : response.data.data.plan_info,
                    loading : false
                })
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    componentDidMount() {
        this.getPlanInfo()
        this.setPlanFeatures()
    }

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    modelCloseFooter = (function(e){
        try{
            e.preventDefault()
            this.setState({
                taskFormFooter:this.state.taskFormFooter ? !this.state.taskFormFooter : true
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    render() {
        if (this.state.CallApi){
            this.props.hideAddEditTaskModal()
        }
        return (
            <Modal
                show={this.props.show}
                onHide={() => {this.handleHideModal(2)}}
                keyboard={true}
                contentClassName={this.props.contentClassName}
                size={this.props.modalSize}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {  ! this.state.limitExhausted &&
                            <Col md={{ span: 12}} className="task-enlarge">
                                <span onClick={ this.modelCloseFooter} className="rounded-circle default_color click_cursor">
                                    <span className="material-symbols-outlined">open_with</span>
                                </span>
                            </Col>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={ this.state.taskFormFooter ? {paddingBottom:"0px"} : {} } >
                    {
                        this.state.loading ? <div style={{display: "flex",justifyContent: "center",alignItems: "center", height: "100px"}}><Spinner animation="border" variant="primary" /></div> :
                        <>
                            { 
                                this.state.limitExhausted ? <>Your task limit has reached, please try upgrading your plan by clicking <NavLink to={'/settings/plans'}> here</NavLink></> : 
                                <AddTaskForm
                                    taskFormData={this.props.modalData}
                                    taskFormType={this.props.taskModalType}
                                    assigneeOption={this.props.assigneeOption}
                                    onTaskAdd={(data) => this.props.onTaskAdd(data)}
                                    onDeleteTask={(data) => this.props.onDeleteTask(data)}
                                    handleHideModal={() => this.props.hideAddEditTaskModal()}
                                    PlanFeatures={this.state.PlanFeatures}
                                    CallApi={this.state.CallApi}
                                    taskFormFooter={this.state.taskFormFooter}
                                />
                            }
                        </>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}

export default AddTaskModal;