import axios from 'axios';
import React, { Component } from 'react';
import validator from 'validator';
import { Alert, Col, Dropdown, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
// import { Scrollbars } from 'react-custom-scrollbars-2';
import APIs from '../../../../utils/APIs';
import AddTaskButton from './AddTaskButton';
import GetTaskAssignee from './GetTaskAssignee';
import AddTaskModal from './Modals/AddTaskModal';
import PopUpModal from './Modals/PopUpModal';
import { Draggable,Droppable,DragDropContext } from 'react-beautiful-dnd';
import { connect } from "react-redux";
import Avatar from './Avatar';
import Looger from '../../../../utils/Logger';
import { loader } from '../../../../reducers/CurrentProjectBoard'

class TaskBoardView extends Component {
    static  project_id  = 0
    constructor(props) {
        super(props);
        this.project_id = this.props.project_id
        this.taskSwitcher = this.props.taskSwitcher
        let dayName = new Date().toLocaleString('en-us', { weekday: 'short' });
        let monthName = new Date().toLocaleString('en-us', { month: 'short' });
        let dateNumber = new Date().getDate();
        this.state = {
            date: dayName + " " + dateNumber + " " + monthName,
            task_data: {
                today_tasks: [],
                recurring_tasks: [],
                overdues_tasks: []
            },
            pendingTaskCount: 0,
            taskTypeInfo: {
                overdues_tasks: "Overdue tasks",
                recurring_tasks: "Recurring tasks",
                today_tasks: "Today tasks",
            },
            showAddTaskModal: false,
            showRemoveTaskResponseMessage: false,
            taskModalType: "",
            sectionTitle: "",
            modalData: {},
            assigneeOption: [],
            customTaskSections: [],
            showSectionAddUpdateModal: false,
            showConfirmDeleteSectionModalState: false,
            showSectionModalData: {},
            sectionAddUpdateModalType: "Add",
            showLoading : false,
            sectionSuccessMessage : "",
            customSectionTasksData : {},
            section_start_date_error : "",
            deletemessage:"",
            user_id:JSON.parse(localStorage.getItem('user')).id,
            axiosSource : axios.CancelToken.source()
        }
    }

    openAddEditTaskModal = (function (modalType = "Add", data = {}) {
        try{
            this.setState({
                showAddTaskModal: true,
                taskModalType: modalType,
                modalData: data
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    hideAddEditTaskModal = (function () {
        try{
            this.setState({
                showAddTaskModal: false,
                taskModalType: "",
                modalData: {}
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    getTasksObject = (function (taskData, otherInfo = "", params = null) {
        try{
            let customSectionTasksData = this.state.customSectionTasksData;
            let task_list = taskData.map((taskData) => {
                if (!customSectionTasksData["section_" + taskData.section_id]) {
                    customSectionTasksData["section_" + taskData.section_id] = [];
                }
                customSectionTasksData["section_" + taskData.section_id].push(taskData)
                return null;
            });
            this.setState({
                customSectionTasksData: customSectionTasksData
            });
            let tempArray = [];
            for(let i =0; i<task_list.length;i++) {
                if (task_list[i] !== null) {
                    tempArray.push(task_list[i]);
                }
            }
            return tempArray
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    loadTasks = (function () {
            try{
                this.props.loader({loader:true})
                let switcher = localStorage.getItem('switcher');
                let token = localStorage.getItem('session_token');
                let start_date = new Date();
                let end_date = new Date();
                start_date.setHours(0, 0, 0, 0)
                end_date.setHours(23, 59, 59, 0)
                
                let params = {
                    start_date: this.getFormatedDate(start_date),
                    end_date: this.getFormatedDate(end_date),
                    current_date: this.getFormatedDate(new Date()),
                    board_id:this.project_id,
                    switcher:switcher
                }
                let config = { headers: { authorization: `Bearer ${token}` }, params: params, cancelToken: this.state.axiosSource.token };
                axios.get(
                    APIs.BASE_URL + APIs.GET_TASK_API,
                    config
                ).then((response) => {
                    if (response.data.status_code === 200) {
                        this.setState({
                            customSectionTasksData: {},
                        });
                        this.props.loader({loader:false})
                        let tempTaskData = response.data.data.today_tasks;
                        tempTaskData = tempTaskData.concat(response.data.data.recurring_tasks);
                        tempTaskData = tempTaskData.concat(response.data.data.overdues_tasks);

                        tempTaskData = tempTaskData.map((data, index) => {
                            if(data.section_id === null){
                                data.section_id = 0;
                            }
                            return data;
                        })

                        let today_tasks     = this.getTasksObject(tempTaskData);

                        let taskData = {
                            today_tasks: today_tasks, //today_tasks,
                            recurring_tasks: [], //recurring_tasks,
                            overdues_tasks: [], //overdues_tasks
                        }

                        this.setState({
                            task_data: taskData,
                            pendingTaskCount: (response.data.data.overdues_tasks.length + response.data.data.recurring_tasks.length + response.data.data.today_tasks.length)
                        });
                        GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                            this.setState({
                                assigneeOption: data
                            });
                        });
                    } else {
                        //
                    }
                }).catch((error) => {
                    // Capture Error Logs
                    Looger(error)
                });
            } catch(error) {
                // Capture Error Logs
                Looger(error)
            }
    }).bind(this);

    getFormatedDate = (dateObject) => {
        try{
            let month = dateObject.getMonth() + 1;
            let day = dateObject.getDate();
            let year = dateObject.getFullYear();
            let hours = dateObject.getHours();
            let minutes = dateObject.getMinutes();
            let seconds = dateObject.getSeconds();
            //eslint-disable-next-line
            let timezone = dateObject.toString().match(/([-\+][0-9]+)\s/)[1];
            timezone = timezone.substr(0, 3) + ":" + timezone.substr(3);
            if (month < 10) {
                month = `0${month}`;
            }
            if (day < 10) {
                day = `0${day}`;
            }
            if (hours < 10) {
                hours = `0${hours}`;
            }
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000${timezone}`
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    onTaskAdd = (function (data) {
        try{
            this.hideAddEditTaskModal();
            this.loadTasks();
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    getHeaderDropdown = (function (taskType, customSectionData = {},todayTask=[]) {
        try{
            let owner_id = customSectionData.owner_id
            if (taskType !== "customSection") {
                let data = {}
                if (taskType === "recurring_tasks") {
                    data = {
                        type : "recurring",
                        frequency : "daily"
                    }
                }
                if (taskType === "overdue_tasks") {
                    return (<></>);
                } else {
                    return (
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="default" id={"member-action-dropdown-logout"}>
                                <i className="material-icons-outlined">more_horiz</i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className='d-flex' onClick={(e) => this.openAddEditTaskModal("Add", data)}><i className="material-icons-outlined">add_box</i>Create New Task</Dropdown.Item>
                                {
                                    taskType === "today_tasks" && <Dropdown.Item onClick={(e) => this.showHideMoveSectionPopup(false, { id: 0, label: "Today's Tasks" })} className='d-flex'><i className="material-icons-outlined">ios_share</i> Move to another day</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
            return (
                
                <Dropdown align="end">
                    <Dropdown.Toggle variant="default" id={"member-action-dropdown-logout"}>
                        <i className="material-icons-outlined">more_horiz</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className='d-flex' onClick={(e) => this.openAddEditTaskModal("Add", {section_id:customSectionData.id})}><i className="material-icons-outlined">add_box</i>Create New Task</Dropdown.Item>
                        <Dropdown.Item className='d-flex' onClick={(e) => this.showAddUpdateSectionPopup('Updat', customSectionData)}><i className="material-icons-outlined">border_color</i> Edit Section</Dropdown.Item>
                        {   this.state.user_id === owner_id && 
                            <>
                            {
                                todayTask.length > 0 &&
                                <Dropdown.Item className='d-flex' onClick={(e) => this.showHideMoveSectionPopup(false, customSectionData)}><i className="material-icons-outlined">ios_share</i> Move to another day</Dropdown.Item>
                            }
                            </>
                        }
                        { todayTask.length > 0  && customSectionData.id === 0 ?
                            <Dropdown.Item className='d-flex' onClick={(e) => this.showConfirmDeleteSectionModal(customSectionData)}><i className="material-icons-outlined">delete_outline</i>Delete Tasks</Dropdown.Item>
                            : customSectionData.id > 0 &&
                            <Dropdown.Item className='d-flex' onClick={(e) => this.showConfirmDeleteSectionModal(customSectionData)}><i className="material-icons-outlined">delete_outline</i>Delete Section</Dropdown.Item>
                        }

                    </Dropdown.Menu>
                </Dropdown>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    handleSectionMoveToAnotherDay = (function(e) {
        try{
            e.preventDefault();
            if (this.state.section_start_date === "") {
                this.setState({
                    section_start_date_error : "Please select a valid date"
                })
            } else {
                this.setState({
                    loading:true
                });
                let today_start_date = new Date();
                let today_end_date = new Date();
                today_start_date.setHours(0,0,0,0)
                today_end_date.setHours(23,59,59,0)
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
                let data = {
                    section_id: this.state.moveSectionData.id,
                    date: this.getFormatedDate(new Date(this.state.section_start_date)).split(" ")[0],
                    today_start_date: this.getFormatedDate(today_start_date),
                    today_end_date: this.getFormatedDate(today_end_date),
                    task_view: "board_view",
                };
                axios.post(
                    APIs.BASE_URL + APIs.TASK_SECTION_MOVE_API,
                    data,
                    config
                ).then((response) => {
                    if(response.data.status_code === 200) {
                        this.setState({
                            loading : false
                        });
                        this.showHideMoveSectionPopup(true, {})
                    }
                    this.loadSections();
                    this.loadTasks();
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                    this.showHideMoveSectionPopup(true, {})
                    this.loadSections();
                    this.loadTasks();
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    showHideMoveSectionPopup = (function(toggle = false, customSectionData) {
        try{
            this.setState({
                moveSectionPopup: !toggle,
                moveSectionData: !toggle ? customSectionData : {},
                section_start_date : ""
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    setSectionStartDate = (function (date) {
        try{
            this.setState({ section_start_date: date, section_start_date_error : "" });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getTodayTasks = (function (taskType, showAddTask = false, customSectionData={}, isDraggable = false, isDroppable = false) {
        try{
            let todayTask = [];
            let taskHeading = "Today's Tasks";
            let sectionID = "t1";
            let isDropDisabled = true;
            switch (taskType) {
                case "customSection":
                    if(customSectionData.id === "t1") {
                        if(this.state.task_data.today_tasks.length > 0) {
                            todayTask = todayTask.concat(this.state.task_data.today_tasks);
                        }
                        if(this.state.task_data.recurring_tasks.length > 0) {
                            todayTask = todayTask.concat(this.state.task_data.recurring_tasks);
                        }
                    } else {
                        if (this.state.customSectionTasksData["section_" + customSectionData.id] !== undefined) {
                            todayTask = this.state.customSectionTasksData["section_" + customSectionData.id];
                        }
                    }
                    taskHeading = customSectionData.label;
                    sectionID = customSectionData.id;
                    isDropDisabled = false
                    break;
                default:
                    todayTask = this.state.task_data.today_tasks;
            }
            return (
                <div className="single-board" key={ "task-type-" + taskHeading.replaceAll(/\s/g, '') }>
                    <div className='boardHeader'>
                        <span className="board-name-label">{taskHeading}</span>
                        {
                            todayTask.length > 0 && <span className="badge">{todayTask.length}</span>
                        }
                        {this.getHeaderDropdown(taskType, customSectionData,todayTask)}
                    </div>
                    <div style={{ width: 250, height: 350, overflowY: "auto" }} className="board">
                        <Droppable droppableId={"droppableId-" + sectionID} isDropDisabled={isDropDisabled} type="TASKS">
                            {(provided, snapshot) => (
                                <div
                                    className='task-containers'
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {todayTask.map((data, index) => { return (this.getTaskDiv(data, index, isDraggable, isDroppable)) })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    {/*
                        !showAddTask ?
                        <></> :*/
                        <div className='boardFooter'>
                            <AddTaskButton pendingTaskCount={1} isBoardView={true} openAddEditTaskModal={() => this.openAddEditTaskModal("Add", {section_id:sectionID})} />
                        </div>
                    }
                </div>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getTaskTags = (function (data, index,taskId) {
        try{
            return (
                <span key={"tag-key-" + index} className={'tags_sec ' + data.tag_class}>#{data.tag_name}</span>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    urlify =  (function(text) {
        try{
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, function(url) {
            return '<a class="generated_link" target="_blank" href="' + url + '">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    getTaskDiv = (function (data, index, isDraggable, isDroppable) {
        try{
            if(data.status === "yet_to_start" && data.end_date !== null  && data.end_date > 0 && data.end_date < new Date().getTime()) {
                data.status = "overdue";
            }
            return (
                <Draggable draggableId={"draggable-" + data.id} index={data.id} key={"task-div-draggable-" + data.id}>
                    {(provided, snapshot) => (
                        <div
                            className={snapshot.isDragging ? "dragging" : "aaaaa"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className={"boardTaskWrap mt-2 task_body " + data.status + " "  + (snapshot.isDragging ? "dragging" : "aaaaa")} key={"task-board-view-id-" + data.id} onClick={(e) => this.openAddEditTaskModal("Edit Add", data)}>
                                <div className="task_data">
                                    <div className="icon-and-task-name">
                                        {
                                            data.status === "overdue" ? <i className="material-icons-outlined" style={{cursor: "pointer"}}>sync_problem</i> : <></>
                                        }
                                        <h3>{data.task_name}</h3>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: this.urlify(data.task_description) }}></p>
                                    <div className="popup_task_info">
                                        {
                                            data.tags.map((tagData, tagIndex) => { return (this.getTaskTags(tagData, tagIndex, data.id)) })
                                        }
                                    </div>
                                </div>
                                <hr className='popup-hr-edge-to-edge' style={{ marginLeft: "-11px", marginRight: "-11px" }} />
                                <div className="task_footer">
                                    <div className="row task_foot">
                                        <Col className="col-md-8">
                                            <div className="d-flex">
                                                <div className="task-comments">
                                                    <i className='material-icons-outlined'>sms</i>
                                                    <span>{data.comments_count}</span>
                                                </div>
                                                <div className="task-sub-task">
                                                    <i className='material-icons-outlined'>assignment</i>
                                                    <span>{data.sub_task_count}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4 text-end">
                                            <Avatar 
                                                teamData={data.members}
                                                limit={5} 
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    deleteTaskModal = (function (data) {
        try{
            this.setState({
                showConfirmRemoveTaskModal: true,
                removeTaskData: data,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    confirmRemoveTaskModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="" show={this.state.showConfirmRemoveTaskModal} onChange={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title'>Remove Task</div>
                    <div className='warning-message'>Are you sure you want to remove this task from your task list?</div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>No, cancel</button>
                        <button className='btn btn-primary' onClick={() => { this.handleRemoveTaskSubmit(this.state.removeTaskData) }}>Yes, remove</button>
                    </div>
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    confirmDeleteSectionModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="" show={this.state.showConfirmDeleteSectionModalState} onChange={() => { this.setState({ showConfirmDeleteSectionModalState: false, showSectionModalData: {} }) }}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title'>Delete section</div>
                    <div className='warning-message'>
                        Are you sure you want to delete this {this.state.showSectionModalData.label}?
                        <br/><span className='text-danger'>{this.state.deletemessage}</span>
                    </div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showConfirmDeleteSectionModalState: false, showSectionModalData: {} }) }}>No, cancel</button>
                        <button className='btn btn-primary' onClick={(e) => { this.deleteTheSection(e, this.state.showSectionModalData)}}>Yes, remove</button>
                    </div>
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleRemoveTaskSubmit = (function (data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
            axios.delete(
                APIs.BASE_URL + APIs.DELETE_TASK_API.replace(":id", data.id),
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    this.setState({
                        showRemoveTaskResponseMessage: `Task : ${data.task_name} has been deleted!`,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: "success"
                    });
                } else {
                    this.setState({
                        showRemoveTaskResponseMessage: response.data.message,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: ""
                    });
                }
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
                this.loadTasks();
            }).catch((error) => {
                this.setState({
                    showRemoveTaskResponseMessage: error.message,
                    showConfirmRemoveTaskModal: false,
                    actionIcon: ""
                });
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);

                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    removeTaskResponseMessage = (function () {
        try{
            return (
                <Alert variant="warning" dismissible className='remove-task-message'>
                    <div className='d-block'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className={this.state.actionIcon + " material-icons"}>{this.state.actionIcon !== "" ? "check" : "priority_high"}</i>
                            <span className="message">{this.state.showRemoveTaskResponseMessage}</span>
                        </div>
                    </div>
                </Alert>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    showAddSectionButton = (function(){
        return (
            <div className="single-board">
                <div className='boardHeader no-border'>
                    <div className='add_sec' onClick={(e) => {this.showAddUpdateSectionPopup("Add", {})}}>
                        <h3><i className="material-icons">add</i> Add New Section</h3>
                    </div>
                </div>
            </div>
        );
    })

    showAddUpdateSectionPopup = (function (type = "Add", data = {}) {
        try{
            let sectionTitle = "";
            if (data.label) {
                sectionTitle = data.label;
            }
            this.setState({
                showSectionAddUpdateModal: true,
                showLoading : false,
                showSectionModalData: data,
                sectionTitle : sectionTitle,
                sectionAddUpdateModalType: type,
                sectionError: "",
                sectionSuccessMessage : "",
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    showConfirmDeleteSectionModal = (function(data) {
        try{
            let sectionTitle = data.label;
            let project_owner_id = data.board_owner_id
            let deletemessage = this.state.deletemessage 
            let user_id = this.state.user_id
            if (project_owner_id !== user_id){
                deletemessage = 'Only the task you created will be deleted.'
            }
            this.setState({
                showConfirmDeleteSectionModalState: true,
                showLoading: false,
                showSectionModalData: data,
                sectionTitle: sectionTitle,
                sectionAddUpdateModalType: 'Delete',
                sectionError: "",
                sectionSuccessMessage: "",
                deletemessage:deletemessage,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    hideAddUpdateSectionPopup = (function () {
        try{
            this.setState({
                showSectionAddUpdateModal: false,
                showConfirmDeleteSectionModalState: false,
                showSectionModalData: {},
                showLoading: false,
                sectionAddUpdateModalType: "Add",
                sectionError : "",
                sectionTitle : "",
                sectionSuccessMessage: "",
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleSectionAddUpdateForm = (function(e) {
        try{
            e.preventDefault();
            let sectionTitle = this.state.sectionTitle;
            let tempSectionTitleError = "";
            if (validator.isEmpty(sectionTitle)) {
                tempSectionTitleError = "Enter a valid section name";
            } else if (!validator.isAlphanumeric(sectionTitle, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && sectionTitle.length > 0) {
                tempSectionTitleError = "Enter only alphanumeric characters.";
            }
            if (tempSectionTitleError === "") {
                this.setState({
                    showLoading : true
                });
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token};
                let params = { label: sectionTitle };
                if (this.state.sectionAddUpdateModalType.toLowerCase() !== "add") {
                    params.id = this.state.showSectionModalData.id;
                }
                let axiosCall =  null;
                if (this.state.sectionAddUpdateModalType.toLowerCase() === "add") {
                    axiosCall = axios.post(
                        APIs.BASE_URL + APIs.TASK_SECTION_API.replace(':id',this.project_id),
                        params,
                        config
                    )
                } else {
                    axiosCall = axios.patch(
                        APIs.BASE_URL + APIs.TASK_SECTION_API.replace(':id',this.project_id),
                        params,
                        config
                    )
                }
                axiosCall.then((response) => {
                    if(response.data.status_code !== 200) {
                        this.setState({
                            sectionError : response.data.message,
                            showLoading: false
                        })
                    } else {
                        this.setState({
                            showLoading: false,
                            sectionTitle : "",
                            sectionSuccessMessage: "Section " + (this.state.sectionAddUpdateModalType.toLowerCase()) +"ed successfully."
                        });
                        setTimeout(() => {
                            this.hideAddUpdateSectionPopup()
                        }, 2000)
                    }
                    this.loadSections()
                }).catch((error) => {
                    this.setState({
                        showLoading: false
                    });
                })
            } else {
                this.setState({
                    sectionError: tempSectionTitleError
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    loadSections =  (function() {
        try{
            this.loadTasks();
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.TASK_SECTION_API.replace(':id',this.project_id),
                config
            ).then((response) => {
                let data = response.data.data.map((section, index) => {
                    if(section.id === "t1") {
                        section.id = 0;
                    }
                    return section
                })
                this.setState({
                    customTaskSections: data
                });
            }).catch((error) => {
                //
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    deleteTheSection =  (function(e, data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
            this.setState({
                showLoading:true
            });
            axios.delete(
                APIs.BASE_URL + APIs.DELETE_SECTION_API.replace(":id",data.id+"?project_id="+this.project_id),
                config
            ).then((response) => {
                let customSectionTasksData = this.state.customSectionTasksData;
                customSectionTasksData["section_" + data.id] = []
                this.hideAddUpdateSectionPopup();
                this.loadSections();
                this.loadTasks()
                this.setState({
                    showLoading: false,
                    customSectionTasksData : customSectionTasksData
                });
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onDragEnd = (function(data) {
        try{
            let destination = data.destination;
            let source = data.source;
            if (destination !== null) {
                let section_id = parseInt(destination.droppableId.replace('droppableId-', ''));
                let params = { "task_id": source.index, "section_id": section_id, "sequence": destination.index };
                let token = localStorage.getItem('session_token');
                let config = { headers: { authorization: `Bearer ${token}` }, cancelToken: this.state.axiosSource.token };
                axios.patch(
                    APIs.BASE_URL + APIs.TASK_SECTION_ASSIGN_API,
                    params,
                    config
                ).then((response) =>  {}).catch((error) => {
                     // Capture Error Logs
                    Looger(error)
                })
                
                let today_tasks = this.getTasksObject(this.state.task_data.today_tasks, '', params);
                let recurring_tasks = this.getTasksObject(this.state.task_data.recurring_tasks, '', params);
                let overdues_tasks = this.getTasksObject(this.state.task_data.overdues_tasks, 'overdue', params);

                let taskData = {
                    today_tasks: today_tasks,
                    recurring_tasks: recurring_tasks,
                    overdues_tasks: overdues_tasks
                }

                if (
                    this.state.task_data.today_tasks.length === today_tasks.length &&
                    this.state.task_data.recurring_tasks.length === recurring_tasks.length &&
                    this.state.task_data.overdues_tasks.length === overdues_tasks.length
                ) {
                    let movedFromTaskID = source.index;
                    let movedToTaskID = source.index;
                    let movedTaskFrom = parseInt(source.droppableId.replace('droppableId-', ''));
                    let movedTaskTo = parseInt(destination.droppableId.replace('droppableId-', ''));
                    let customSectionTasksData = this.state.customSectionTasksData;
                    
                    if(!customSectionTasksData["section_" + movedTaskTo]) {
                        customSectionTasksData["section_" + movedTaskTo] = []
                    }

                    let movedFromData = customSectionTasksData["section_" + movedTaskFrom]
                    let movedToData = customSectionTasksData["section_" + movedTaskTo]
                    let key1 = null;
                    let key2 = null;
                    let movedTaskData = {};
                    for (let i = 0; i < movedFromData.length; i++) {
                        if (movedFromData[i].id === movedFromTaskID) {
                            key1 =  i;
                            movedTaskData = movedFromData[i];
                        }
                    }
                    for (let i = 0; i < movedToData.length; i++) {
                        if (movedToData[i].id === movedToTaskID) {
                            key2 = i;
                        }
                    }
                    movedFromData.splice(key1, 1);
                    movedToData.splice(key2, 0, movedTaskData)

                    customSectionTasksData["section_" + movedTaskFrom] = movedFromData
                    customSectionTasksData["section_" + movedTaskTo] = movedToData

                    this.setState({
                        customSectionTasksData: customSectionTasksData
                    });
                }

                this.setState({
                    task_data: taskData
                });
            }
            return true
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    showTheTaskSections = (function() {
        try{
            return (
                <>
                    <DragDropContext
                        onBeforeCapture={this.onBeforeCapture}
                        onBeforeDragStart={this.onBeforeDragStart}
                        onDragStart={this.onDragStart}
                        onDragUpdate={this.onDragUpdate}
                        onDragEnd={this.onDragEnd}
                    >
                    {
                        this.state.customTaskSections.map((customSection, index) => {
                            return this.getTodayTasks('customSection', false, customSection, true, true)
                        })
                    }
                    </DragDropContext>
                </>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    componentDidMount() {
        this.loadSections();
        this.loadTasks();
    }

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    sectionHandler = (function(e){
        try{
            let sectionName = e.target.value
            if (sectionName.trim() === '') {
                sectionName = sectionName.trim()
            } 
            this.setState({ 
                sectionTitle:sectionName, 
                sectionSuccessMessage: "", 
                sectionError:""
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render() {
        let project_id = this.props.project_id
        if (isNaN(project_id)){
            project_id = parseInt(localStorage.getItem('project_id'))
        }
        if (this.project_id !== project_id){
            this.project_id = project_id
            this.loadSections()
        }

        let taskSwitcher = this.props.taskSwitcher
        if (isNaN(taskSwitcher)){
            taskSwitcher = parseInt(localStorage.getItem('switcher'))
        }
        if (this.taskSwitcher !== taskSwitcher){
            this.taskSwitcher = taskSwitcher
            this.loadSections()
        }

        return (
            <>
                <Row className="mt-2 board_view_sec d-block">
                    <Col md={{span:12}}>
                        <div className="boardWrap">
                            <div className="horizontal-scrollable-area">
                                {this.showTheTaskSections()}
                                {this.showAddSectionButton()}
                            </div>
                        </div>
                    </Col>
                </Row>
                {this.state.showConfirmRemoveTaskModal ? this.confirmRemoveTaskModal() : <></>}
                {this.state.showRemoveTaskResponseMessage ? this.removeTaskResponseMessage() : <></>}
                {
                    this.state.showAddTaskModal ?
                            <AddTaskModal
                                modalSize="lg"
                                contentClassName="add_new_member_form add_task"
                                show={this.state.showAddTaskModal}
                                onTaskAdd={(data) => this.onTaskAdd(data)}
                                taskModalType={this.state.taskModalType}
                                hideAddEditTaskModal={() => {this.hideAddEditTaskModal();this.loadTasks()}}
                                modalData={this.state.modalData}
                                assigneeOption={this.state.assigneeOption}
                                onDeleteTask={(data) => this.deleteTaskModal(data)}
                            />
                        : <></>
                }
                {
                    this.state.showSectionAddUpdateModal && <PopUpModal contentClassName="add_new_member_form" title={this.state.sectionAddUpdateModalType + " new section"} show={this.state.showSectionAddUpdateModal} onChange={(e) => { this.hideAddUpdateSectionPopup() }}>
                        {this.state.sectionSuccessMessage !== "" ? <h3 className="section-add-success-message">{this.state.sectionSuccessMessage}</h3> :
                        <form onSubmit={(e) => {this.handleSectionAddUpdateForm(e)}} autoComplete='off'>
                            <div className={this.state.sectionError ? "mb-3 invalid" : "mb-3"}>
                                <label className="label">Section Name <span className="asterisk">*</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control shadow-none" name="sectionTitle" placeholder="Enter new section name" id="sectionTitle" maxLength="30" value={this.state.sectionTitle} onChange={(e) => { this.sectionHandler(e)}} autoComplete="off" />
                                </div>
                                {this.state.sectionError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.sectionError}</div> : <></>}
                            </div>
                            <div className="d-grid pt-1">
                                <button type="submit" className="btn btn-primary btn-block invite_sent_btn" disabled={this.state.showLoading}>
                                    {
                                        this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Continue"
                                    }
                                </button>
                            </div>
                        </form>
                        }
                    </PopUpModal>
                }
                {this.state.showConfirmDeleteSectionModalState ? this.confirmDeleteSectionModal() : <></>}

                {
                    this.state.moveSectionPopup && <PopUpModal contentClassName="add_new_member_form" title={"Move section tasks to another day"} show={this.state.moveSectionPopup} onChange={(e) => { this.showHideMoveSectionPopup(true, {}) }}>
                        <p className='model-sub-heading'>Move tasks in “{this.state.moveSectionData.label}” section to another day</p>
                        {this.state.sectionSuccessMessage !== "" && <h3 className="section-add-success-message">{this.state.sectionSuccessMessage}</h3>}
                        <form onSubmit={(e) => { this.handleSectionMoveToAnotherDay(e) }} autoComplete='off'>
                            <div className={this.state.sectionError ? "mb-3 invalid" : "mb-3"}>
                                <label className="label">New Date <span className="asterisk">*</span></label>
                                <div className="input-group section-new-date-picker">
                                    <DatePicker
                                        selected={this.state.section_start_date}
                                        dateFormat="E d MMM, yyyy"
                                        minDate={new Date().setDate(new Date().getDate() + 1)}
                                        onChange={(date) => this.setSectionStartDate(date)}
                                        placeholderText={'Select new start date'}
                                        onKeyDown={(e) => {e.preventDefault();}}
                                    />
                                    {
                                        this.state.section_start_date_error !== "" ?
                                            <Form.Control.Feedback type="invalid" className="errorMessage">
                                                <i className="material-icons">warning</i>{this.state.section_start_date_error}
                                            </Form.Control.Feedback>
                                            : <></>
                                    }
                                </div>
                                {this.state.sectionError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.sectionError}</div> : <></>}
                            </div>
                            <div className="d-flex pt-1" style={{ justifyContent: "space-between" }}>
                                <button onClick={(e) => { this.showHideMoveSectionPopup(true, {}) }} type="reset" style={{ width: "164px" }} className="profile-btn btn btn-light" disabled={this.state.showLoading}>Cancel</button>
                                <button type="submit" style={{ width: "164px" }} className="profile-btn btn btn-primary" disabled={this.state.showLoading}>
                                    {
                                        this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Continue"
                                    }
                                </button>
                            </div>
                        </form>
                    </PopUpModal>
                }
            </>
        );
    }
}

//export default TaskBoardView;
const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id,
    taskSwitcher:state.CurrentProjectBoard.taskSwitcher
});

const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(TaskBoardView);