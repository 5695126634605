import React, { Component } from 'react'
import {Col, Row, } from 'react-bootstrap';
import Looger from '../../../../../utils/Logger';

let colorPalletBg = [
    '44496d',
    'ed713c',
    'dc6562',
    '8ac264',
    '5eafdc',
    'b088f4',
    'd87648',
    'db5fa7',
    '8a6564'
]
class ColorPallet extends Component {
    constructor(props){
        super(props)
        this.state = {
            allColorPallet:colorPalletBg,
            selectedColorPallet:props.defaultColor,
        }
    }

    colorPalletHtml = (function(){
        try{
            let allColorPallet = this.state.allColorPallet
            return allColorPallet.map((data,index)=>{
                return(
                    <Col key={index} md={{span:1}}  xs={{span:1}} sm={{span:1}} className='default_color_pallet mb-2'>
                        <span style={{background:'#'+(data)}} onClick={(e)=>this.selectColorPalletHandler(e,data)}></span>
                    </Col>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })  

    selectColorPalletHandler = (function(e,colorCode){
        try{
            this.setState({
                selectedColorPallet:colorCode,
            })
            this.props.selectedColorPallet(colorCode)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    showColorPalletHandler = (function(type){
        try{
            this.setState({
                showColorPallet:type   
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

  render() {
    return (
        <>
            <Col className='default_color_pallet default' onMouseOver={()=>{this.showColorPalletHandler(true)}} >
                <span style={{background:'#'+(this.state.selectedColorPallet)}}></span>
            </Col>
            <Col md={{span:12}} xs={{span:12}} sm={{span:12}} className='default_color_pallet show' onMouseLeave={()=>{this.showColorPalletHandler(false)}}>
                <Row className='show_pallet'>{this.colorPalletHtml()}</Row>
            </Col>
        </>
    )
  }
}
export default ColorPallet;
