import axios from 'axios';
import React, { Component } from 'react';
import { Row, Col, InputGroup, FormControl, Alert,Tabs,Pagination } from 'react-bootstrap';
import APIs from '../../../utils/APIs';
import GetTaskAssignee from './Common/GetTaskAssignee';
import AddTaskModal from './Common/Modals/AddTaskModal';
import PopUpModal from './Common/Modals/PopUpModal';
import LoggedInLayout from './LoggedInLayout';
import { Marker } from "react-mark.js";
import { Helmet } from 'react-helmet';
import { Tab } from 'bootstrap';
import { connect } from "react-redux";
import Looger from '../../../utils/Logger';
import {loader} from '../../../reducers/CurrentProjectBoard'

class Overdue extends Component {
    static  project_id  = 0
    constructor(props) {
        super(props);
        this.project_id = this.props.project_id
        this.timeout = 0;
        let date = new Date();
        date.setHours(0,0,0,0);
        this.state = {
            start : 0,
            length : 50,
            overdue_search : "",
            loading : false,
            todayDate: date,
            loadMore:true,
            totalRecords : 0,
            totalRecordsLoaded : 0,
            showAddTaskModal: false,
            taskModalType: "",
            assigneeOption : [],
            location:window.location.pathname,
            first:0,
            noOfTaskDisplay:10,
            showConfirmRemoveTaskModal: false,
            removeTaskData: {},
            modalData: {},
            selectedPage:1,
            isActiveNext:false,
            totalTask:0,
            isActivePrev:false,
            taskData:{},
            taskType:localStorage.getItem('taskType') || 'incoming',
            axiosSource : axios.CancelToken.source(),
        };
    }

    getOverdueTasks = (function (key,start =0, length=50,selectedpage=1) {
        try{
            this.props.loader({loader:true})
            if (this.state.location === '/overdue'){
                document.body.classList.add('active')
            }
            let task_type = localStorage.getItem('taskType') || key
            let token = localStorage.getItem('session_token');
            let date = new Date();
            let first = this.state.first + 1;
        
            // date.setHours(0,0,0,0);
            let param = { 
                start : start,
                length: length,
                search: this.state.overdue_search,
                current_date: this.getFormatedDate(date),
                board_id:this.project_id,
                task_type:task_type,
                page:selectedpage,

            };
            let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_OVERDUE_TASKS_API, config
            ).then((response) => {
                let data = response.data.data;
                let total_task = response.data.total_task;
                let filter_task = response.data.filter_task;
                let no_Of_task_display = this.state.noOfTaskDisplay;
                let noOfPage = Math.ceil(total_task/no_Of_task_display)
                let selectedPage = response.data.page

                let isActiveNext = false
                let isActivePrev = false
                if ( noOfPage < 1){
                    isActiveNext = true
                    isActivePrev = true
                }
            
                if(data[task_type].length === 0) {
                    this.setState({
                        loadMore : false,
                        loading: false,
                        first: first,
                        taskData:{}
                    })
                } else {
                    let totalRecordsLoaded = this.state.totalRecordsLoaded
                    for (let i = 0; i < data[task_type].length; i++) {
                        totalRecordsLoaded++;
                    }
                    this.setState({
                        taskData:data,
                        noOfPage:noOfPage,
                        selectedPage:selectedPage,
                        isActiveNext:isActiveNext,
                        isActivePrev:isActivePrev,
                        totalTask:filter_task,
                        totalRecords: total_task,
                        loading: false,
                        first: first,
                        totalRecordsLoaded : totalRecordsLoaded
                    });
                }
                GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                    this.setState({
                        assigneeOption: data
                    });
                });
                this.props.loader({loader:false})
            }).catch((error) => {
                this.props.loader({loader:false})
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    getFormatedDate = (dateObject) => {
        try{
            let month = dateObject.getMonth() + 1;
            let day = dateObject.getDate();
            let year = dateObject.getFullYear();
            let hours = dateObject.getHours();
            let minutes = dateObject.getMinutes();
            let seconds = dateObject.getSeconds();
            //eslint-disable-next-line
            let timezone = dateObject.toString().match(/([-\+][0-9]+)\s/)[1];
            timezone = timezone.substr(0, 3) + ":" + timezone.substr(3);
            if (month < 10) {
                month = `0${month}`;
            }
            if (day < 10) {
                day = `0${day}`;
            }
            if (hours < 10) {
                hours = `0${hours}`;
            }
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000${timezone}`
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }

    onSearch = (function (e){
        try{
            this.props.loader({loader:true})
            let params = e.target.value.trim()
            this.setState({overdue_search:params})
            if (params !== ''){
                if (this.timeout) clearTimeout(this.timeout);
                this.setState({
                    [e.target.name] : params,
                });
                this.timeout = setTimeout(() => {
                    this.setState({
                        start: 0,
                        loadMore: true,
                        loading: true,
                        totalRecordsLoaded : 0,
                    })
                    this.props.loader({loader:false})
                    this.getOverdueTasks(0,0,this.state.noOfTaskDisplay)
                }, 500);
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    showOverdueTasks = (function (data, taskType) {
        try{
            return (
                <Col md={{ span: 12 }} className={"task_list overdues_view " + taskType} key={"overdue-task-container-" + taskType}>
                    <Row className="mt-1">
                        {this.getTaskBody(data,taskType)}
                    </Row>
                </Col>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    getTaskBody = (function (data,taskType) {
        try{
            return data.map((taskDetail, index) => {
                let hours = String(new Date(taskDetail.start_date).getHours()).padStart(2, "0");
                let minutes = String(new Date(taskDetail.start_date).getMinutes()).padStart(2, "0");
                let AM_PM = (hours >= 12) ? "PM" : "AM";
                let  date1 = new Date();
                let  date2 = new Date(taskDetail.start_date);
                let  diffTime = Math.abs(date2 - date1);
                let  diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return (
                    <div className="col-md-6 dtl" key={"overdue-task-id-" + taskDetail.id} onClick={(e) => this.openAddEditTaskModal("Edit", taskDetail)}>
                        <div className="d-flex text-white justify-content-start task_sec">
                                                
                            <Col md={{span:3}} className="schedule">
                                {
                                    taskType === "overdue" ?
                                    <span className="time_schedule">
                                        <i className='material-icons-outlined' style={{ cursor: "pointer" }}>sync_problem</i> Reschedule
                                    </span>
                                : taskDetail.status === "done" ?
                                    <span className="time">
                                        <i className="material-icons todotask-icon active" style={{ cursor: "pointer" }} >check</i>
                                        {hours + ":" + minutes + " "+AM_PM}
                                    </span>
                                :
                                    <span className="time">
                                        <i className="material-icons todotask-icon" style={{ cursor: "pointer" }}>check</i>
                                        {hours + ":" + minutes + " "+AM_PM}
                                    </span>
                                }
                            </Col>
                            <Col className="vl"></Col>
                            <Col  md={{span:8}} className="d-grid align-items-center head_sec">
                                <h4 className="task_head">
                                    {
                                        this.state.overdue_search === "" ? taskDetail.task_name :
                                        <Marker mark={this.state.overdue_search} options={{ className: "custom-marker-1" }}>
                                            {taskDetail.task_name}
                                        </Marker>
                                    }
                                </h4>
                                <h5 className="task_detail">
                                    <span>
                                        {
                                            this.state.overdue_search === "" ? taskDetail.task_description :
                                            <Marker mark={this.state.overdue_search} options={{ className: "custom-marker-1" }}>{taskDetail.task_description}</Marker>
                                        }
                                    </span>
                                    <span> - In Progress {diffDays} days</span>
                                </h5>
                                <ul className="task_actions">
                                    {taskDetail.comments_count > 0 ? <li><i className="material-icons-outlined">sms</i> {taskDetail.comments_count} comments</li> : <></>}
                                    {taskDetail.sub_task_count > 0 ? <li><i className="material-icons-outlined">assignment</i> {taskDetail.sub_task_count} Sub-tasks</li> : <></>}
                                </ul>
                            </Col>
                        </div>
                    </div>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    buttonLoadMoreClicked = (function (start, length) {
        try{
            this.setState({
                start: start,
                length: length,
                loading: true,
            }, this.getOverdueTasks(start, length));
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    loadMoreButton = (function() {
        try{
            if (this.state.loadMore) {
                let start   =   parseInt(this.state.start);
                let length =   parseInt(this.state.length);
                start       =   start + length;
                return (
                    <Row className='mb-3'>
                        <Col md={{ span: 8, offset: 2 }} className="text-center">
                            <div className="d-grid" id={"start:" + start + ",length:" + length} onClick={(e) => { this.buttonLoadMoreClicked(start, length)}}>
                                <button type="button" className="btn btn-primary btn-block add_task_btn"> Load more</button>
                            </div>
                        </Col>
                    </Row>
                )
            } else if (this.state.loading) {
                <Row className='mb-3'>
                    <Col md={{ span: 8, offset: 2 }} className="text-center">
                        <div className="d-grid">
                            <button type="button" className="btn btn-primary btn-block add_task_btn" disabled={true}> Loading...</button>
                        </div>
                    </Col>
                </Row>
            } else {
                return (<></>)
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    openAddEditTaskModal = (function (modalType = "Add", data = {}) {
        try{
            this.setState({
                showAddTaskModal: true,
                taskModalType: modalType,
                modalData: data
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    hideAddEditTaskModal = (function () {
        try{
            this.setState({
                showAddTaskModal: false,
                taskModalType: "",
                modalData: {}
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    deleteTaskModal = (function (data) {
        try{
            this.setState({
                showConfirmRemoveTaskModal: true,
                removeTaskData: data,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleRemoveTaskSubmit = (function (data) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.delete(
                APIs.BASE_URL + APIs.DELETE_TASK_API.replace(":id", data.id),
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    this.setState({
                        showRemoveTaskResponseMessage: `Task : ${data.task_name} has been deleted!`,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: "success"
                    });
                } else {
                    this.setState({
                        showRemoveTaskResponseMessage: response.data.message,
                        showConfirmRemoveTaskModal: false,
                        actionIcon: ""
                    });
                }
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
                this.loadTasks();
            }).catch((error) => {
                this.setState({
                    showRemoveTaskResponseMessage: error.message,
                    showConfirmRemoveTaskModal: false,
                    actionIcon: ""
                });
                setTimeout(() => {
                    this.setState({ showRemoveTaskResponseMessage: "", actionIcon: "" });
                }, 5000);
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    removeTaskResponseMessage = (function () {
        try{
            return (
                <Alert variant="warning" dismissible className='remove-task-message'>
                    <div className='d-block'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className={this.state.actionIcon + " material-icons"}>{this.state.actionIcon !== "" ? "check" : "priority_high"}</i>
                            <span className="message">{this.state.showRemoveTaskResponseMessage}</span>
                        </div>
                    </div>
                </Alert>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onTaskAdd = (function () {
        try{
            this.hideAddEditTaskModal();
            this.getOverdueTasks();
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    confirmRemoveTaskModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="" show={this.state.showConfirmRemoveTaskModal} onChange={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title'>Remove Task</div>
                    <div className='warning-message'>Are you sure you want to remove this task from your task list?</div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showConfirmRemoveTaskModal: false, removeTaskData: {} }) }}>No, cancel</button>
                        <button className='btn btn-primary' onClick={() => { this.handleRemoveTaskSubmit(this.state.removeTaskData) }}>Yes, remove</button>
                    </div>
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    selectedTabs = (function(key){
        try{
            localStorage.setItem('taskType',key)
            this.setState({
                taskType:key
            })
            this.getOverdueTasks(key,0,this.state.noOfTaskDisplay)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
        document.body.classList.remove('active')
    }

    handleDisplayTask = (function(e){
        try{
            this.setState({
                noOfTaskDisplay:parseInt(e.target.value)
            })
            this.getOverdueTasks(0,0, parseInt(e.target.value))
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    inputDisplayTaskNumber = (function(){
        try{
            let numberoFTask = [10,20,30,40,50]
            return numberoFTask.map((data,index)=>{
                return(
                    <option selected={(this.state.noOfTaskDisplay===data)}  key={"option"+index} defaultValue={data} >{data}</option>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    selectedPage = (function(e){
        try{
            this.setState({
                selectedPage:parseInt(e.target.text)
            })
            this.getOverdueTasks(0,0, this.state.noOfTaskDisplay,parseInt(e.target.text))
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    selectedActivePage = (function(e,type){
        try{
            let noOfPage        = parseInt(this.state.noOfPage)
            let selectedPage    = parseInt(this.state.selectedPage)
            let nextPrevPage    = selectedPage
            let isActivePrev    = false
            let isActiveNext    = false
            if  (type === 'prev'){
                if (selectedPage === 1){
                    isActivePrev = true
                } else {
                    nextPrevPage = selectedPage-1
                    this.getOverdueTasks(0,0, this.state.noOfTaskDisplay,nextPrevPage)
                }

            } else if (type === 'next'){
                if (selectedPage === noOfPage){
                    isActiveNext = true
                } else {
                    nextPrevPage = selectedPage+1
                    this.getOverdueTasks(0,0, this.state.noOfTaskDisplay,nextPrevPage)
                }
            }

            this.setState({
                isActiveNext:isActiveNext,
                isActivePrev:isActivePrev,
                selectedPage:nextPrevPage
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    html = (function(i){
        try{
            return(
                <Pagination.Item key={i} value={i} active={this.state.selectedPage===i+1} onClick={(e)=>{(this.state.selectedPage===i+1)?  this.selectedActivePage(e,'active') : this.selectedPage(e)}}>{i+1}</Pagination.Item>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    paginationPage = (function(){
        try{
            let noOfPage = this.state.noOfPage
            if ( noOfPage < 1){
                noOfPage = 1 
            }
            
            let field = []
            for(let i=0; i < noOfPage; i++){
                field.push(this.html(i))
            }
            return field 
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    startLength = (function(){
        try{
            let length = this.state.noOfTaskDisplay
            let page = this.state.selectedPage
            return  1+((page-1)*length)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    endLength = (function(){
        try{
            let length = this.state.noOfTaskDisplay
            let page = this.state.selectedPage
            let taskType = this.state.taskType
            let taskdata = this.state.taskData
            let length2 = length
            if (taskType in taskdata){
                length2 =  taskdata[taskType].length
            }
            return  ((page-1)*length)+length2
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render() {
    
        let project_id = this.props.project_id
        if (isNaN(project_id)){
            project_id = parseInt(localStorage.getItem('project_id'))
        }
        if (this.project_id !== project_id){
            this.project_id = project_id
            this.getOverdueTasks(this.state.taskType,0, this.state.noOfTaskDisplay);
        }

        if(this.state.first === 0) {
            this.getOverdueTasks(this.state.taskType,0, this.state.noOfTaskDisplay)
        }


        return (
            <LoggedInLayout>
                <Helmet>
                    <title>My Overdue Tasks - Orrderly</title>
                </Helmet>
                <Row className="section mt-3 list_view_sec overdue_task">
                    <Col md={{ span: 12 }} className="task_list tabs">
                        <Row>
                            <Col md={{ span: 9 }}>
                                <Tabs
                                    defaultActiveKey={this.state.taskType}
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="justify-content-start overdue_tabs"
                                    onSelect={(key) => this.selectedTabs(key)}
                                >
                                    <Tab eventKey="incoming" title={<>Incoming</>}></Tab>
                                    <Tab eventKey="today" title={<>Today</>}></Tab>
                                    <Tab eventKey="done" title={<>Done</>}></Tab>
                                    <Tab eventKey="overdue" title={<>Overdue</>}></Tab>
                                </Tabs>
                            </Col>
                            <Col md={{ span: 3 }}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Search task"
                                        name="overdue_search"
                                        id="overdue_search"
                                        value={this.state.overdue_search}
                                        onChange={(e) => { this.onSearch(e) }}
                                    />
                                    <InputGroup.Text>
                                        <span className="material-icons-outlined">search</span>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{ span: 12 }} className="list" style={{minHeight: 540}}>
                        {
                            Object.keys(this.state.taskData).map((task_type) => {
                                return this.showOverdueTasks(this.state.taskData[task_type], task_type);
                            })
                        }
                    </Col>
                </Row>
                <Row className="pagination_task_footer">
                    <Col md={{ span: 4 }} className="pagination_title">
                        <span  className='pagination_label'>Showing {this.startLength()} to {this.endLength()} of {this.state.totalTask} tasks</span>
                    </Col>
                    <Col  md={{ span: 8 }} className="pagination_page d-flex">
                        <Col className="d-flex">
                            <Col className="d-flex justify-content-end" >
                                <span className='pagination_label'>Display</span>
                                <select className="form-select" value={this.state.noOfTaskDisplay} onChange={(e)=>(this.handleDisplayTask(e))} aria-label="Default select example">
                                    {this.inputDisplayTaskNumber()}
                                </select>
                            </Col>
                            <Pagination>
                                <Pagination.Prev  className='p_prev' disabled={this.state.isActivePrev} onClick={(e)=>{this.selectedActivePage(e,'prev')}}/>
                                    {this.paginationPage()}
                                <Pagination.Next  className='p_next' disabled={this.state.isActiveNext}  onClick={(e)=>{this.selectedActivePage(e,'next')}} />
                            </Pagination>
                        </Col>
                    </Col>
                </Row>
                {/* {(this.state.totalRecords > 0 && this.state.totalRecordsLoaded !== this.state.totalRecords) ? this.loadMoreButton() : <></>} */}
                {this.state.showConfirmRemoveTaskModal ? this.confirmRemoveTaskModal() : <></>}
                {
                    this.state.showAddTaskModal ?
                        <AddTaskModal
                            modalSize="lg"
                            contentClassName="add_new_member_form add_task"
                            show={this.state.showAddTaskModal}
                            onTaskAdd={() => this.onTaskAdd()}
                            taskModalType={this.state.taskModalType}
                            hideAddEditTaskModal={() => this.hideAddEditTaskModal()}
                            modalData={this.state.modalData}
                            assigneeOption={this.state.assigneeOption}
                            onDeleteTask={(data) => this.deleteTaskModal(data)}
                        />
                        : <></>
                }
            </LoggedInLayout>
        );
    }
}

//export default Overdue;

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(Overdue);