import React, { Component } from 'react'

export default class Avatar extends Component {
    constructor(props){
        super(props)
        this.state = {
            teamData:this.props.teamData,
        }
    }

    teamJsx = (function(avatarShowLimit=3){
        let allAvatarLength     = this.state.teamData.length
        let teamData            = this.state.teamData.slice(0,avatarShowLimit)
        let countAvatarLength   = (allAvatarLength-teamData.length)
    
        return(
            <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
            {
                teamData.map((data,index)=>{
                    let image =  data.src;
                    return(
                        <li data-bs-toggle="tooltip" key={index} title="Disabled tooltip" className="avatar pull-up">
                            {
                                image.split("/image/")[1] === '__avatar_url_2_1.png'
                                ? <span style={{background:data.backgroundColor}} className="avatar-initial rounded-circle pull-up" data-bs-toggle="tooltip" data-bs-placement="bottom" title={data.avatar}>{data.avatar.charAt(0)}</span>
                                : <img className="rounded-circle" title={data.avatar} src={image} alt={data.avatar}/>
                            }
                        </li> 
                    )
                })
            }
            { countAvatarLength > 0 &&
                <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" className="avatar pull-up">
                    <span className="avatar-initial rounded-circle pull-up" data-bs-toggle="tooltip" data-bs-placement="bottom" title="3 more">+{countAvatarLength}</span>
                </li>
            }
        </ul>
        )
    })

  render() {
    return (
        this.teamJsx(this.props.limit)
    )
  }
}
