import axios from 'axios';

let LOGGER_API_ENDPOINT = 'https://api.symalite.com/api/logs/create'
let GET_CLIENT_IP_ADDRESS = 'https://api.ipify.org/?format=json' 
function Looger(error_json){
    try{
        let  errorLogs = error_json.stack
        axios.get(GET_CLIENT_IP_ADDRESS).then((response) => {
            let IP_ADDRESS =  response.data.ip
            CreateLogger(errorLogs,IP_ADDRESS)
        }).catch((error) => {
            console.log(error);
        }) 
    } catch(error) {
        console.log(error);
    }
}


function CreateLogger(errorLogs,IP_ADDRESS){
    try{
        let Logs = {}
        Logs['ip_address'] = IP_ADDRESS
        Logs['error'] = errorLogs
        let data  = {
            "product":"logger-test",
            "product_type":"orrderly frontend",
            "process":"frontend",
            "error_json":JSON.stringify(Logs),
            "user_id":"",
            "ip_address":IP_ADDRESS
        }
        if (Logs.user_id !== '' && Logs.user_id !== undefined ){
            data['user_id'] = Logs.user_id
        }
        axios.post(LOGGER_API_ENDPOINT,data).then((response) => {}).catch((error)=>{ console.log(error);})
    } catch(error) {
        console.log(error);
    }   
}
export default Looger
