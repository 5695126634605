import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Spinner, Dropdown } from 'react-bootstrap';
import PopUpModal from '../Common/Modals/PopUpModal';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import LoggedInLayout from '../LoggedInLayout';
import APIs from '../../../../utils/APIs';
import Labels from '../../../../utils/Labels';
import validator from 'validator';
import Looger from '../../../../utils/Logger';
import {loader} from '../../../../reducers/CurrentProjectBoard'

class BoardTeam extends Component {
    static  project_name  = ''
    static project_id = 0
    constructor(props) {
        super(props);
        this.project_name = this.props.project_name
        this.project_id = this.props.project_id
        this.state = {
            teamMembersList : [],
            showNewMemberModal:true,
            assigneeOption:[],
            invitedAssigneeOption:[],
            processType:"",
            modalTitle:"",
            roleError:"",
            role:"",
            showSpinner:false,
            processData:[],
            currentUser:[],
            selectedTeamIds:[],
            showAddTeamDiv:false,
            axiosSource : axios.CancelToken.source()
        };
    }

    fetchTeamMembers = (function (auth_token) {
        try{
            this.props.loader({loader:true})
            let token = localStorage.getItem('session_token');
            let param = { start: 0, length: 500 };
            let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get( 
                APIs.BASE_URL + APIs.GET_TEAM_BY_PROJECT_ID.replace(":id",this.project_id),
                config
            ).then((response) => {
                if(response.data.status_code === 200) {
                    this.setState({teamMembersList : response.data.data});
                    this.props.loader({loader:false})

                } else if(response.data.status_code === 403) {
                    this.setState({teamMembersList : []});
                    this.props.loader({loader:false})
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    singleTeamMemberDiv = (function(){
        try{
            return (
                <>
                {this.teamInfoDiv()}
                {this.emptyDiv()}
                </>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    teamInfoDiv = (function(){
        try{
            let teamData = this.state.teamMembersList
            let currentUser = this.state.currentUser
            if (teamData.length > 0){
                return teamData.map((teamInfo,index)=>{
                    let name = teamInfo.first_name !== "" ? teamInfo.first_name + " " + teamInfo.last_name : teamInfo.email;
                    let image = (teamInfo.profile_image !== "") ? teamInfo.profile_image : "/placeholder.png";
                    if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                        let avatar_bg = (teamInfo.avatar_bg?teamInfo.avatar_bg:"5D6DF6").replace('#','')
                        image = `https://ui-avatars.com/api/?name=${teamInfo.first_name}&color=FFFFFF&length=1&background=${avatar_bg}`
                    }
                    if (teamInfo.invite === 1){
                        return (
                            <Col md={{span :4}} xs={{span:12}} sm={{span:6}} className={"member_sec" + index} key={"single-team-member-"+index}>
                                <div className="d-flex p-2 member_cols">
                                    <Col md={{span:2}}  xs={{span:2}} sm={{span:2}}  className="p-2">
                                        <img src={image} alt={"profile pic " + name}/>
                                    </Col>
                                    <Col md={{span:8}} xs={{span:8}} sm={{span:8}} className="p-2">
                                        <h3>{name}</h3>
                                        <h5>{teamInfo.role}</h5>
                                    </Col>
                                    { currentUser.id !== teamInfo.id && teamInfo.admin_id === currentUser.id &&
                                        <Col md={{span:2}} xs={{span:2}} sm={{span:2}} className="p-2 ellipsis">
                                        <Dropdown align="end">
                                            <Dropdown.Toggle variant="default" id={"member-action-dropdown-" + teamInfo.id}>
                                                <i className="material-icons" style={{ color: "#9C9EAE" }}>more_vert</i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                    <Dropdown.Item onClick={()=>this.handleTeamMember(teamInfo,'update')} className='d-flex'><i className="material-icons">edit</i> &nbsp;Edit role</Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>this.handleTeamMember(teamInfo,'remove')} className='d-flex'><i className="material-icons">delete</i> &nbsp;Remove member</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> 
                                        </Col>
                                    }
                                </div>
                            </Col>
                        )
                    } else {
                        return null
                    }
                })
            } 
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    emptyDiv = (function() {
        try{
            return (
                <Col md={{span:4}} xs={{span:12}} sm={{span:6}} className={"member_sec0"} key={"single-team-member-5698"}>
                    {   this.state.invitedAssigneeOption.length > 1 ?
                        <div className="p-3 member_cols" onClick={() => this.handleTeamMember({},'add')}>
                            <h2 className="new_member_sec">
                                <i className='material-icons'>add</i> &nbsp;Add New Member
                            </h2>
                        </div>
                        :
                        <div className="p-3 member_cols not_active">
                            <h2 className="new_member_sec">
                                <i className='material-icons'>add</i> &nbsp;Add New Member
                            </h2>
                        </div>
                    }
                </Col>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });
    
    handleTeamMember = (function (data,processType) {
        try{
            let modalTitle = ""
            let role_name  = data.role
            if (processType === "update"){
                modalTitle = "Update member"
            } else if (processType === "add" ) {
                modalTitle = <div>Add new member<br/><span className="warning-message">Add new members to your project</span></div>
                role_name = 'default '
            } else {
                modalTitle = "Remove member"
            }
            this.setState({
                processType:processType,
                processData:data,
                modalTitle:modalTitle,
                role:role_name
            })
            this.toggleNewTeamMemberModal(data);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    checkValidRole = (function (role) {
        try{
            let roleError = "";
            if (!validator.isAlphanumeric(role, ["en-US"], { ignore: " !@#$%^&*()_+-={}|[]\\:;,.?/ \"'s" }) && role.length > 0) {
                roleError = "Enter a valid role";
            }
            this.setState({ role: role, roleError: roleError });
            return roleError;
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    onInputChangeHandler = (function(e){
        try{
            let role = e.target.value
            this.checkValidRole(role)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    handleFormSubmit = (function(event){
        try{
            event.preventDefault();
            let role        =   this.state.role;
            let roleError   =   (role.trim() === "") ? "Role is required" : ""; 
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            if (roleError === ""){
                this.setState({showSpinner:true})
                let axiosType  = ''
                let processType = this.state.processType
                if (processType === "update"){
                    let parms = {id:this.state.processData.board_team_id,role_name:this.state.role}
                    axiosType = axios.patch(APIs.BASE_URL + APIs.UPDATE_PROJECT_TEAM_ROLE, parms,config)
                } else if (processType === "add"){
                    let parms = {board:this.project_id,selectedTeamIds:this.state.selectedTeamIds}
                    axiosType = axios.post(APIs.BASE_URL + APIs.ADD_PROJECT_TEAM, parms,config)
                } else if (processType === "remove") {
                    axiosType = axios.delete(
                        APIs.BASE_URL + APIs.DELETE_PROJECT_TEAM.replace(':id',`${this.state.processData.id}/${this.project_id}`),
                        config)

                }  
                axiosType.then((response) => {
                    let data = response.data
                    if (data.status_code === 200) {
                        this.fetchTeamMembers()
                        this.fetchAllTeam()
                        this.setState({
                            showSpinner:false,
                            showNewMemberModal:true,
                        })
                        
                    } else {
                        this.setState({
                            showSpinner:false
                        })
                    }
                }).catch((error)=>{
                    // Capture Error Logs
                    Looger(error)
                })

            } else {
                this.setState({roleError:roleError})
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)
    
    selectedTeam = (function(e){
        try{
            let teamRole = e.target.getAttribute('data-role')
            let ids = this.state.selectedTeamIds
            let selectedTeamId = parseInt(e.target.value)
            if (e.target.checked) {
                let teamInfo = {'role':teamRole,"id":selectedTeamId}
                ids.push(teamInfo)
            } else {
                let key = ids.indexOf(selectedTeamId);
                ids.splice( key, 1)
            }
            this.setState({
                selectedTeamIds:ids
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }) 

    teamMemberListHtml = (function(){
        try{
            let data = this.state.assigneeOption
            let currentUser = this.state.currentUser
            return data.map((teaminfo,index)=>{
                let image = (teaminfo.profile_image !== "") ? teaminfo.profile_image : "/placeholder.png";
                if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                    let avatar_bg = (teaminfo.avatar_bg?teaminfo.avatar_bg:"5D6DF6").replace('#','')
                    image = `https://ui-avatars.com/api/?name=${teaminfo.first_name}&color=FFFFFF&length=1&background=${avatar_bg}`
                }

                if (teaminfo.invite === 1 && teaminfo.id !== currentUser.id){
                    return(
                        <Col  className="board_team_lists" md={{span:12}} key={"team-info-row-" +index}>
                            <Row>
                                <Col md={{span:1}} xs={{span:1}} sm={{span:1}}>
                                    <span><input type="checkbox" data-role={teaminfo.role} onChange={(e)=>{this.selectedTeam(e)}} value={teaminfo.id} className='form-check-input'/></span>
                                </Col>
                                <Col md={{span:1}} xs={{span:1}} sm={{span:1}} className="selection-board p-0 ps-1">
                                    <span className="project_icon">
                                        <img alt={teaminfo.first_name} src={image}/>
                                    </span>
                                </Col>
                                <Col md={{span:7}} xs={{span:7}} sm={{span:7}}>
                                    <Row className="board_team_list">
                                        <span className="team_list_name">{teaminfo.first_name}</span>
                                        <span className="team_list_role">{teaminfo.role}</span>
                                    </Row>
                                </Col>
                                {/* <Col md={{span:3}}>
                                    <span>{teaminfo.role}</span>
                                </Col> */}
                            </Row>
                        </Col>
                    )
                } else {
                    return null
                }
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    memberAddUpdateForm = (function(){
        try{
            return (
                <form onSubmit={this.handleFormSubmit} autoComplete='off'>
                    {
                        this.state.processType === "update"?
                            <div className={"mb-3"}>
                                <label className="label">{Labels.USER_ROLE} <span className="asterisk">*</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control shadow-none" name="role" placeholder="Enter new member role" id="role" value={this.state.role} onChange={this.onInputChangeHandler} autoComplete="off" />
                                </div>
                                {this.state.roleError ? <div className='errorMessage'><i className="material-icons">warning</i>{this.state.roleError}</div> : <></>}
                            </div>
                        : this.state.processType === "remove" ?
                            <>This Member Remove only {this.project_name} Board</>
                        :
                        <>
                            <Col md={{span:12}} className="control_team">
                                <Row>
                                    {this.teamMemberListHtml()}
                                </Row> 
                            </Col>    
                        </>
                    }
                    <div className="d-grid pt-4">
                        <button type="submit" className="btn btn-primary btn-block invite_sent_btn" disabled={this.state.showLoading}>
                            {
                                this.state.showLoading ? <Spinner size="sm" animation="border" variant="light" /> : "Continue"
                            }
                        </button>
                    </div>
                </form>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    toggleNewTeamMemberModal = (function() {
        try{
            let showNewMemberModal = !this.state.showNewMemberModal 
            this.setState({
                showNewMemberModal:showNewMemberModal,
                roleError:"",
                selectedTeamIds:[]
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    fetchAllTeam = (function(){
        try{
            let token = localStorage.getItem('session_token');
            let param = { start: 0, length: 500,board_id:this.project_id };
            let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get( 
                APIs.BASE_URL + APIs.TEAM_API,
                config
            ).then((response) => {
                if(response.data.status_code === 200) {

                    let invitedTeam = response.data.data.filter(checkTeam)
                    function checkTeam(data) {
                        return data.invite === 1;
                    }
                    let showAddTeamDiv = false
                    if (invitedTeam.length>1){
                        showAddTeamDiv = true
                    }

                    this.setState({
                        assigneeOption:response.data.data,
                        invitedAssigneeOption:invitedTeam,
                        showAddTeamDiv:showAddTeamDiv
                    })
                    
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    componentDidMount() {
        let token = localStorage.getItem('session_token');
        let currentUser = JSON.parse(localStorage.getItem('user'))
        if (token) {
            this.fetchTeamMembers(token);
            this.fetchAllTeam()
            this.setState({ currentUser:currentUser})
        }
    }    

    render() {
        let project_name = this.props.project_name
        let project_id = this.props.project_id
        if (project_name === null){
            project_name = localStorage.getItem('project_name')
            project_id = localStorage.getItem('project_id')
        }
        if (this.project_id !== project_id){
            this.project_id = project_id
            this.fetchTeamMembers()
            this.fetchAllTeam()
        }
        this.project_name = project_name
        
        return (
            <>
                <Helmet>
                    <title>My Team- Orrderly</title>
                </Helmet>
                <LoggedInLayout>
                    <Row className="task">
                        <Col md={{span:10}} className="left_col">
                            <h2 className="head">{this.project_name} Team</h2>
                        </Col>
                    </Row>
                    <Row className="team_member mt-3 px-xs-0">
                        <Col md={{span:10}} className="team-member-container">
                            <Row>
                                {this.singleTeamMemberDiv()}
                            </Row>
                        </Col>
                    </Row>
                    <PopUpModal contentClassName="add_new_member_form" title={this.state.modalTitle} show={!this.state.showNewMemberModal} onChange={()=>this.toggleNewTeamMemberModal()}>
                        {this.memberAddUpdateForm()}
                    </PopUpModal>
                </LoggedInLayout>
                
            </>
        );
    }
}

// export default Team;

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id,
    project_name:state.CurrentProjectBoard.project_name
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(BoardTeam);