import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

class Error404 extends Component {
    render() {
        return (
            <Row className='m-auto error_page'>
                <Col md={{span:12}} className="text-center">
                    <Col md={{span:12}}>
                        <img src="https://orrderly-storage.s3.amazonaws.com/image/error.png" alt="404 not found"/>
                    </Col>
                    <Col md={{span:12}}>
                        <h1>Oops!, Page not found</h1>
                    </Col>
                    <Col md={{span:12}} className="my-5">
                        <NavLink className="btn btn-primary" to="/" >Go back home</NavLink>
                    </Col>
                </Col>
            </Row>
        );
    }
}

export default Error404;