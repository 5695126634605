import React, { Component } from 'react';
import validator from 'validator';
import axios from "axios";
import BeforeLoginLayout from './BeforeLoginLayout';
import Labels from "../../utils/Labels";
import APIs from '../../utils/APIs';
import Cookies from 'js-cookie';
import { Spinner } from 'react-bootstrap';
import '../../static/css/auth.css';
import { Navigate } from 'react-router-dom';
import SignupStep2 from './SignupStep2';
import Looger from '../../utils/Logger';

class Signup extends Component {
    constructor(props) {
        super(props);
		this.state = {
			email: "",
			isLogin: false,
			submitButtonText: "Continue",
			submitButtonProp: false,
			emailError: "",
			axiosSource : axios.CancelToken.source(),
			ajaxResponse:false,
			accountIsExist:false,
			isSignupRedirectStep2:false,
		}
    }

	componentDidMount() {
		let user = localStorage.getItem('session_token');
		let userData = localStorage.getItem('user');
		let cookie = Cookies.get(btoa('user-data'))
        if(!cookie && APIs.ENV !== "LOCAL") {
            localStorage.removeItem("session_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user");
            localStorage.removeItem("plan_info");
            // window.location.href = "/";
            // this.setState({ isLogin: false });
        } else if (user !== "" && user !== null) {
			try {
				axios.post(
					APIs.BASE_URL + APIs.REFRESH_TOKEN_API,
					{ refresh: localStorage.getItem('refresh_token') },
					{ headers: { "Authorization": `Bearer ${user}` }, cancelToken : this.state.axiosSource.token }
				).then((response) => {
					Cookies.set(btoa('user-data'), btoa(userData), { expires : 365, secure : true, domain : APIs.DOMAIN })
					let data = response.data;
					localStorage.setItem("session_token", data.data.token.access);
					localStorage.setItem("refresh_token", data.data.token.refresh);
					localStorage.setItem('plan_info', btoa(JSON.stringify( data.data.plan_info)))
					this.setState({ isLogin: true });
				}).catch((error) => {
					if(error.message === "Landing Component got unmounted") {
						//
					} else {
						Cookies.remove(btoa('user-data'))
						localStorage.removeItem("session_token");
						localStorage.removeItem("refresh_token");
						localStorage.removeItem("user");
						localStorage.removeItem("plan_info");
					}
				});
			} catch (error) {
				Looger(error)
			}
		} else {
			Cookies.remove(btoa('user-data'))
		}
    }

	/**
	 * @function onChange
	 * @param {event} event
	 * @description On change of input value assign that value in respective state
	 * @returns void
	 */
	onChange = (function(event) {
		if (event.target.name === "email") {
			this.checkValidEmail(event.target.value.trim());
		}
	}).bind(this);

	/**
	 * @function checkValidEmail
	 * @param {string} email
	 * @description validate the email string while typing and display the message
	 * @returns string
	 */
	checkValidEmail = (function(email) {
		try{
			let emailError = "";
			if (!validator.isEmail(email) && email.length > 0) {
				emailError = "Invalid email address";
			}
			this.setState({ email: email, emailError: emailError });
			return emailError;
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function onFormSubmit
	 * @param {event} event to stop html form default behaviour
	 * @description based on email call the Signup step 1 API, and display respective message if have any error
	 * @returns void
	 */
	onFormSubmit = (function(event) {
		try{
			event.preventDefault();
			this.setErrorMessagesState({ emailError: "", submitButtonProp: true, isLogin: false, ajaxResponse:false,accountIsExist:false });
			let email 			= 	this.state.email.toLowerCase();
			let emailError 		= 	(email.trim() === "") ? "Email address is required" : "";
			emailError 			= emailError === "" ? this.checkValidEmail(email) : emailError;
			if (emailError === "") {
					axios.post(APIs.BASE_URL + APIs.SIGNUP_STEP_1_API, {
						email: email,
						source : "web_app",
						validation:true
					}, {cancelToken : this.state.axiosSource.token}).then((response) => {
						let responseMessage = response.data.message.toLowerCase().trim();
						if(responseMessage === "confirm your email address") {
							this.setErrorMessagesState({ emailError: "Please check your email to confirm your email address", submitButtonProp: false, isLogin: false, ajaxResponse : false,accountIsExist:true });
						} else if(responseMessage === "success") {
							this.setErrorMessagesState({ submitButtonProp: false, isLogin: false, ajaxResponse : true, accountIsExist:true });
						} else if(responseMessage === "email is already registered with us") {
							this.setErrorMessagesState({ emailError: response.data.message, submitButtonProp: false, isLogin: false, ajaxResponse : false,accountIsExist:false });
						} else if(responseMessage === "email address not validate") {
							this.setErrorMessagesState({ emailError: response.data.message, submitButtonProp: false, isLogin: false, ajaxResponse : false,accountIsExist:false });
						}  else {
							console.log(responseMessage)
						}
					}).catch((error) => {
						let message = error.message;
						if (error.message === "Network Error") {
							message = APIs.SERVER_NOT_AVAILABLE;
						} else if(error.message === "Landing Component got unmounted") {
							//
						}
						this.setErrorMessagesState({ emailError: message, submitButtonProp: false, isLogin: false,accountIsExist:false });
						// Capture Error Logs
						Looger(error)
					});
			} else {
				this.setErrorMessagesState({ emailError: emailError, submitButtonProp: false, isLogin: false,accountIsExist:false });
			}
			event.stopPropagation();
		} catch(error) {
			this.setErrorMessagesState({submitButtonProp: false, isLogin: false,accountIsExist:false });
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function setErrorMessagesState
	 * @param {JSON Object} data
	 * @description To set error messages and button text
	 * @returns void
	 */
	setErrorMessagesState = (function (data) {
		try{
			let emailError 			= 	data.emailError;
			let submitButtonProp 	= 	data.submitButtonProp;
			let isLogin 			= 	data.isLogin;
			let submitButtonText 	= 	submitButtonProp ? "Loading" : "Continue";
			let accountIsExist      = 	data.accountIsExist

			this.setState({
				emailError: emailError,
				isLogin: isLogin,
				submitButtonText: submitButtonText,
				ajaxResponse: data.ajaxResponse,
				submitButtonProp: submitButtonProp,
				accountIsExist:accountIsExist
			});
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
			<>
			{ this.state.ajaxResponse ?
				<SignupStep2 email={this.state.email} resePWDToken={'fdgfdgesryuier'}/>
				:
				<BeforeLoginLayout
					footerQues={Labels.QUES_HAVE_ACCOUNT}
					footerLinkText={"Sign in to your account"}
					footerLink={"/"}
					formTagLine={this.state.accountIsExist ? <div className='d-flex text-center justify-content-center align-items-center' style={{minHeight:150, color:"#5d6df6", fontSize: 20, lineHeight:1.2}}>{this.state.emailError}</div> : "First, enter your email"}
					formHeaderTagLine={Labels.SIGN_UP_H3}
					seoData={{
						"title" : "Sign up to Orrderly - Orrderly",
						"meta": [
							{"name":"description","content":"Sign up to get started with Orrderly."}
						]
					}}
				>
				{this.state.isLogin ? <Navigate to="/dashboard" /> : ""}
				{ this.state.accountIsExist?<></>:
					<form onSubmit={this.onFormSubmit} className="signup_form form_sec mt-5" autoComplete='off'>
						<div className={this.state.emailError ? "mb-3 invalid" : "mb-3"}>
							<label className="label d-none">{Labels.LOGIN_EMAIL} <span className="asterisk">*</span></label>
							<div className="input-group">
								<span className="input-group-text"><i className="material-icons">mail</i></span>
								<input type="text" className="form-control shadow-none" name="email" placeholder={Labels.EMAIL_PLACEHOLDER} id="email" value={this.state.email} onChange={this.onChange} autoComplete="off" />
							</div>
							{this.state.emailError ? <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.emailError}</div> : <></>}
						</div>
						<div className="d-grid pt-3">
							<button type="submit" className="btn btn-primary btn-block" disabled={this.state.submitButtonProp}>{this.state.submitButtonProp ? <Spinner size="sm" animation="border" variant="light" /> : this.state.submitButtonText}</button>
						</div>
					</form>
				}
				</BeforeLoginLayout>
			}
			</>
        );
    }
}

export default Signup;