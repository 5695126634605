import React, { Component } from 'react';
import {Row, Col, Dropdown,OverlayTrigger,Popover, Spinner} from 'react-bootstrap';
import APIs from '../../../../utils/APIs';
import axios from 'axios';
import AddTaskModal from './Modals/AddTaskModal';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago';
import GetTaskAssignee from './GetTaskAssignee';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import Looger from '../../../../utils/Logger'

TimeAgo.addLocale(en)

class Notification extends Component{
    static  project_id  = 0
    constructor(props){
        super(props);
        this.project_id = this.props.project_id
        this.state = {
            userData:JSON.parse(localStorage.getItem('user')),
            axiosSource : axios.CancelToken.source(),
            showAddTaskModal:false,
            showNotificationModal:true,
            taskModalType:'Edit',
            modalData:{},
            modelClosetrigger:false,
            notificationData:[],
            totalNotification:0,
            totalReadNotification:0,
            showAllNotification:false,
            logsIds:[],
            length:0,
            toggle:false,
            showLoader:true,
            isShowBadge:false,
            allLogs:0,
        }
    }
    
    urlify =  (function(text) {
        try{
            var urlRegex = /TSK\/\d{8}\/\S{1,3}\/\d{1,8}/gm;
            return text.match(urlRegex)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    fetchNotification = (function(length=5) {
        try{
            let userId  =   this.state.userData.id
            let param   =   { id:userId ,start: 0, length: length };
            let token   =   localStorage.getItem('session_token');
            let config  =   { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_USER_LOGS,
                config
            ).then((response) => {
                let data = response.data
                if (data.status_code === 200) {
                    this.fetchNotificationCount()
                    console.log(data.total_logs)
                    this.setState({
                        notificationData:data.data,
                        totalNotification:data.total_logs,
                        totalReadNotification:data.read_count,
                        length:data.length,
                        allLogs:data.count,
                        logsIds:data.logs_ids,
                        showLoader: false
                    })
                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    fetchNotificationCount = (function() {
        try{
            let token   =   localStorage.getItem('session_token');
            let config  =   { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_USER_LOGS_COUNT,
                config
            ).then((response) => {
                let data = response.data
                if (data.status_code === 200) {
                    let isShowBadge = false
                    if (data.data.count > 0) {
                        isShowBadge  = true
                    }
                    this.setState({isShowBadge:isShowBadge})

                }
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    readNotification = (function(processtype='single',logsIds){
        try{
            let token   =   localStorage.getItem('session_token');
            let params  =   {'id':logsIds}
            let config  =   { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            axios.patch(
                APIs.BASE_URL + APIs.READ_USER_LOGS,
                params,
                config
            ).then((response)=>{
                let data = response.data
                if (data.status_code === 200){
                    if(processtype === 'all'){
                        this.fetchNotification()
                    }
                }
            }).catch((error)=>{
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    getTaskData = (function(taskData,logsIds) {
        try{
            let task_id  = parseInt(taskData[0].split("/")[3])
            this.setState({showNotificationModal:false})
            let param   =   { start: 0, length: 1000 };
            let token   =   localStorage.getItem('session_token');
            let config  =   { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.GET_SINGLE_TASK_API.replace(":id", task_id),
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    response.data.data.assigned_by_id = response.data.data.assigned_by
                    response.data.data.assigned_to_id = response.data.data.assigned_to
                    this.setState({
                        modalData: response.data.data,
                    },this.openTaskModal(response.data.data))
                    this.readNotification('single',[logsIds])
                }
            }).catch((error) => {
                 // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    openTaskModal = (function (taskData = {}) {
        try{
            GetTaskAssignee({axiosSource:this.state.axiosSource,project_id:this.project_id}).then((data) => {
                if(!taskData.start_date) {
                    let date = new Date(this.props.title);
                    let date2 = new Date(this.props.title);
                    date.setHours(0,0,0,0);
                    date2.setHours(23,59,59,0);
                    taskData.start_date = date.getTime()
                    taskData.end_date = date2.getTime()
                    taskData.type = "one_time"
                }
                this.setState({
                    assigneeOption: data,
                    showAddTaskModal: true,
                });
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    notificationDiv = (function(){
        try{
            let notificationData = this.state.notificationData
            return notificationData.map((data,index)=>{
                return(
                    <div key={index}>
                        <Col className="px-3">
                            <p>
                                {data.description.replace(this.urlify(data.description),'')}
                                <span  className="default_colors dtl" onClick={()=>this.getTaskData(this.urlify(data.description),data.id)} >{this.urlify(data.description)}</span> 
                                <span>&nbsp;/&nbsp;<ReactTimeAgo date={new Date(data.created_at*1000)} locale="en" /></span>
                            </p>
                        </Col>
                        <Dropdown.Divider />
                    </div>
                )
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    showAllNotificationHandel = (function(processType){
        try{
            let isShow = true
            let length = this.state.length 
            if (processType === 'back') {
                isShow = false
            } else if (processType === 'load') {
                length = this.state.length+5
            }   
            this.setState({
                showAllNotification:isShow,
                length:length
            },this.fetchNotification(length))
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    readAllNotificationHnadler = (function(){
        try{
            let logsIds = this.state.logsIds
            this.readNotification('all',logsIds)
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount(){
        this.fetchNotificationCount()
    }

    popover = (function(){
        try{
            return(
                <Popover id="popover-basic" className='notify_popover'>
                    {   this.state.showLoader?
                        <Col className="px-3 notify_loader">
                            <Spinner animation="border" variant="primary" />
                        </Col>
                    :
                        <>
                            { this.state.totalNotification > 0 ? 
                            <>
                                <Popover.Header as="div">
                                    <Row>
                                    { ! this.state.showAllNotification ?
                                        <>
                                        <Col className='notify_notify'>
                                            <h6>Notifications ({this.state.totalNotification})</h6>
                                        </Col>
                                        <Col className='notify_read'>
                                            <span onClick={()=>this.readAllNotificationHnadler()} className='default_colors dtl'>
                                                Mark All as Read
                                            </span>
                                        </Col>
                                        <Col className='notify_setting'>
                                            <NavLink to="/settings/notification" className="default_colors dtl">Settings</NavLink>
                                        </Col>
                                        </>
                                        :
                                        <>
                                            <Col  className='notify_notify back_step'>
                                                <span  onClick={()=>this.showAllNotificationHandel('back')} className="material-symbols-outlined dtl">keyboard_backspace</span>
                                            </Col>
                                            <Col  className='notify_notify next_step'>
                                                <h6>Notifications ({this.state.totalNotification})</h6>
                                            </Col>
                                        </>
                                    }
                                    </Row>
                                </Popover.Header>
                                <Popover.Body>
                                        { this.state.totalReadNotification < 1 ?<>
                                            <Col className='px-3 body_header'>
                                                We’re excited to welcome you to Orrderly and we’re even more excited about what we’ve got planned. You’re already on your way to staying organized and super productive. / Now
                                            </Col>
                                            <Dropdown.Divider className='body_header_div'/>
                                            </>
                                            :
                                            <Col className='empty_notify'></Col>
                                        }
                                        <Col className={(this.state.showAllNotification?'notification_bar':'notification_view')}>
                                            {this.notificationDiv()}
                                        </Col>
                                        { ! this.state.showAllNotification ?
                                            <Col className='px-3 notify-all'>
                                                <span onClick={()=>this.showAllNotificationHandel('next')} className='default_colors dtl'>
                                                    View All
                                                </span>
                                            </Col>
                                        :   
                                            <>
                                                { this.state.allLogs > this.state.length &&
                                                    <Col className="px-3 notify-all" style={{textAlign: "center"}}>
                                                        <span onClick={()=>this.showAllNotificationHandel('load')} 
                                                        className={"default_colors dtl "+(this.state.allLogs > this.state.length?"":"disabeld")}
                                                        >Load more</span>
                                                    </Col>
                                                }
                                            </>
                                        }
                                </Popover.Body>
                            </>
                            :
                            <Col className="px-3 notify_loader  notify_not ">
                                <h6>No new notifications</h6>
                            </Col>
                            }
                        </>
                    }
                </Popover>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    hideAddEditTaskModal = (function(){
        try{
            this.setState({
                showAddTaskModal:false,
                showNotificationModal:true,
            })
            this.fetchNotification()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    onTaskAdd = (function(data){
        try{
            this.hideAddEditTaskModal()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    deleteTaskModal = (function(data){
        try{
            this.hideAddEditTaskModal()
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    toggleHandler = (function(){
        try{
            let toggle = !this.state.toggle
            this.setState({
                toggle:toggle
            })
            if (toggle) {
                this.fetchNotification()
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    render(){
        return(
                <>
                    <OverlayTrigger 
                        trigger="click" 
                        key='bottom'
                        placement='bottom'
                        overlay={this.state.showNotificationModal?this.popover():<></>}
                        containerPadding={235}
                    >
                        <span className={"material-symbols-outlined dtl "+(this.state.isShowBadge? "notifications_badge":"")} onClick={()=>this.toggleHandler()}>notifications</span>
                    </OverlayTrigger>    
                    {
                        this.state.showAddTaskModal ?
                            <AddTaskModal
                                modalSize="lg"
                                contentClassName="add_new_member_form add_task"
                                show={this.state.showAddTaskModal}
                                onTaskAdd={(data) => this.onTaskAdd(data)}
                                taskModalType={this.state.taskModalType}
                                hideAddEditTaskModal={() => this.hideAddEditTaskModal()}
                                modalData={this.state.modalData}
                                assigneeOption={this.state.assigneeOption}
                                onDeleteTask={(data) => this.deleteTaskModal(data)}
                            />
                            : <></>
                    }
                </>
        )
    }
}

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id
});

export default connect(mapStateToProps)(Notification);
// export default Notification