import axios from 'axios';
import React, { Component } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import APIs from '../../../../../utils/APIs';
import UpgradePlanStep1 from './UpgradePlanStep1';
import UpgradePlanStep2 from './UpgradePlanStep2';
import Looger from '../../../../../utils/Logger';

const Paddle = window.Paddle;

class GetPlans extends Component {
    constructor(props) {
        super(props);
        let userSelectedPlan = {};
        try {
            userSelectedPlan = JSON.parse(atob(localStorage.getItem('plan_info')));
        } catch(e) {
            userSelectedPlan = JSON.parse(localStorage.getItem('plan_info'));
        }
        
        this.state = {
            plans : [],
            userSelectedPlan : userSelectedPlan,
            showModal : false,
            modalTitle : "",
            modalBodyText : "",
            buttonText : "Continue",
            closeButtonVarient : "default",
            continueButtonVarient : "success",
            paymentJson : {},
            modalLoading:false,
            modalActionTaken : false,
            planQuantity:1,
            nextStepValues:1,
            nextStep:false,
            step2:false,
            oldPlanId:userSelectedPlan.subscription_plan_id,
            userPlanData:userSelectedPlan,
            maximumMemberLimit:0,
            axiosSource : axios.CancelToken.source(),
        }
    }

    getPlans = (function() {
        try{
            let selectedPlanInfo = JSON.parse(atob(localStorage.getItem('plan_info')))
            let is_Old = false
            const old_PlanIds = [770694, 770693, 770691,770690]
            if (Object.keys(selectedPlanInfo).length > 0){
                console.log(selectedPlanInfo.subscription_plan_id)
                is_Old = old_PlanIds.includes(selectedPlanInfo.subscription_plan_id);
            }
            const params = { active: is_Old };
            axios.get(
                APIs.BASE_URL + APIs.GET_PLAN_API,
                {params},
                {cancelToken : this.state.axiosSource.token }
            ).then((response) => {
                this.setState({
                    selectedPlanId:selectedPlanInfo.subscription_plan_id,
                    plan_side:selectedPlanInfo.plan_side,
                    planQuantity:selectedPlanInfo.quantity,
                    teamQuantity:selectedPlanInfo.quantity
                })

                let allPlanData = response.data.data 
                let purchasedPlanData = JSON.parse(atob(localStorage.getItem('plan_info') || 'e30=' ))
                if (purchasedPlanData.plan_name === 'trial' && purchasedPlanData.subscription_plan_id === 30 ){
                    allPlanData = allPlanData.filter((data)=>{return data.plan_id>=30}).reverse();
                } else {
                    allPlanData = allPlanData.filter((data)=>{return data.plan_id!==30})
                }
                let planData = allPlanData.map((Data, index) => {
                    Data.CanSwitchPlan = true;
                    if(selectedPlanInfo.plan_name.toLowerCase() === "pro" || selectedPlanInfo.plan_name.toLowerCase() === "ultimate") {
                        if(Data.plan_name.toLowerCase() === "lite") {
                            Data.CanSwitchPlan = false;
                        }
                        if(selectedPlanInfo.plan_type === "year" && Data.plan_type === "month") {
                            Data.CanSwitchPlan = false;
                        }
                        if(selectedPlanInfo.plan_name.toLowerCase() === "ultimate" && Data.plan_name !== "ultimate") {
                            Data.CanSwitchPlan = false;
                        }
                    }
                    if (this.state.userSelectedPlan.plan_side === 'admin'){
                        Data.CanSwitchPlan  = false;
                    }

                    Data.selectedPlan = false
                    if(this.state.userSelectedPlan.subscription_plan_id < 100 && Data.plan_id < 100) {
                        Data.selectedPlan = true
                    } else if(Data.plan_id === this.state.userSelectedPlan.subscription_plan_id  ){
                        Data.selectedPlan = true
                    }
                    if(!isNaN(Data.plan_price) && Data.plan_price > 0) {
                        Data.plan_price = 0
                    }
                    return Data;
                })

                const plan_type = ['month', 'year'];

                const getByAuthor = (plan_type, items) => {return items.filter(value => value.plan_type === plan_type)};
                
                const planInfo = plan_type.reduce(
                    (previous, plan_type) => ({...previous,[plan_type]: getByAuthor(plan_type,  planData)}),{}
                );
                
                let types = this.props.type
                let allPlans = planData
                if (types === 'year'){
                    allPlans = planInfo.year
                }else{
                    allPlans = planInfo.month
                }
                
                this.setState({
                    plans: allPlans,
                });

                allPlans.map((data, index) => {
                    if(data.plan_id > 100) {
                        Paddle.Product.Prices(data.plan_id, 1, (e) => this.setProductPricing(index, data, e))
                    }
                    if(data.plan_id === selectedPlanInfo.subscription_plan_id) {
                        this.getPlanfeatures(data.plan_id, data)
                    }
                    return null
                })
            }).catch((error) => {
                // Capture Error Logs
                Looger(error)
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    setProductPricing = (function(index, productData, responseData) {
        try{
            let Plans = this.state.plans;
            if (Plans[index] !== undefined){
                Plans[index].plan_price = responseData.recurring.price.gross;
            }
            this.setState({plans:Plans})
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }

    }).bind(this)

    nextStep = (function(w,r,v){
        try{
            let nextStepValues = []
            nextStepValues = {
                planQuantity:this.state.planQuantity,
                userSelectedPlan:this.state.userSelectedPlan,
                previousQuantity:this.state.teamQuantity,
                maximumMemberLimit:this.state.maximumMemberLimit
            }
            this.setState({
                nextStep:true,
                nextStepValues:nextStepValues,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    getPlanfeatures = (function(plan_id,plandata){
        try{
            var regex = /\d+/g;
            let Features = plandata.features
            let plandataFeatures = Features.filter((data)=>{
                if (data.label){
                    if (data.label.indexOf('team members') > -1 && data.label.indexOf('assign tasks to team members') < 0){
                        let matches = data.label.match(regex);
                        let teamMemberLimit = 'infinity'
                        if (matches!==null){
                            teamMemberLimit = parseInt(matches[0])
                        }
                        this.setState({
                            maximumMemberLimit:teamMemberLimit,
                        })
                        return teamMemberLimit
                    } 
                }
            })
            let planQuantity = this.state.planQuantity
            let matches = plandataFeatures[0].label.match(regex);
            let teamMemberLimit = 'infinity'
            if (matches!==null){
                teamMemberLimit = parseInt(matches[0])
            }

            let limit = 1
            if (teamMemberLimit !== 'infinity'){
                if (teamMemberLimit === planQuantity){
                    limit = teamMemberLimit
                } else if (planQuantity > 10){
                    limit = 10
                }    
            } else {
                limit = this.state.planQuantity
            }
            this.setState({
                planQuantity:limit,
                userSelectedPlan : plandata,
                selectedPlanId:plan_id
            })
        } catch(error) {
            // Capture Error Logs
            // Looger(error)
        }
    })

    decrementPlanQty = ((e) => {
        try{
            let previousQty = this.state.planQuantity
            let teamQuantity = this.state.teamQuantity
            if (e.target.value-1 > teamQuantity-1){
                previousQty = (previousQty-1 >= 1) ? (previousQty-1) : 1
            }
            this.setState({
                planQuantity    :   previousQty,
                InputKey        :   new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    incrementPlanQty = (() => {
        try{
            let maximumMemberLimit = this.state.maximumMemberLimit
            let previousQty = this.state.planQuantity
            let limit = (previousQty+1)
            if (previousQty === maximumMemberLimit){
                limit = maximumMemberLimit
            }
            this.setState({
                planQuantity    : limit,
                InputKey        : new Date().getTime()
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    updatePlanQuantity = ((e) => {
        try{
            let planQty = 0
            if(!isNaN(e.target.value)) {
                planQty =parseInt(e.target.value);
            }
            this.setState({
                planQuantity    : planQty,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    planFeatures = (function(){
        try{
            return(
                <ul className="price">
                {
                    this.state.userSelectedPlan.features.map((feature, index) => {
                        if (feature.is_active) {
                            return <li className="active-plan p-1" key={"feature-"+index+"-"+this.props.type}>{feature.label}</li>
                        } else {
                            return <li className="in-active-plan p-1" key={"feature-"+index+"-"+this.props.type}>{feature.label}</li>
                        }
                    })
                }
                {
                    this.state.plan_side === 'admin' || this.state.userSelectedPlan.plan_id < 100?
                        <li className="grey upgradep-plan mt-1">
                            <Col md={{span:4}}>
                                <button disabled variant='dark' className="btn dlt-btn">{this.state.userSelectedPlan.selectedPlan?'Selected':'Plan not available'}</button>
                            </Col>
                        </li>
                    : this.state.userPlanData.plan_status === 'trialing'?
                        <li className="grey upgradep-plan mt-1">
                            <Col md={{span:4}}>
                                <button disabled variant='dark' className="btn dlt-btn">{this.state.userSelectedPlan.selectedPlan?'Trialing Plan is Process':'Plan not available'}</button>
                            </Col>
                        </li>
                    :
                        this.state.userSelectedPlan.CanSwitchPlan === true  ?
                        <li className="grey upgradep-plan mt-4">
                            <Col md={{span:12}}>
                                <Row>
                                    {
                                        (this.state.userSelectedPlan.plan_id < 100) ? <></> :
                                        <Col md={4} xs={{span:6}} sm={{span:3}} className="plan_upgradep_btn">
                                            <InputGroup className="mb-3">
                                                <Button variant="outline-secondary" className='no-border-light-bg' onClick={(e) => {this.decrementPlanQty(e)}} value={this.state.planQuantity} key={"decrease-plan-quantity"}>-</Button>
                                                <FormControl  type="number"className="no-border-text-box" onChange={(e) => {this.updatePlanQuantity(e)}} readOnly key={this.state.InputKey} defaultValue={this.state.planQuantity}  />
                                                <Button variant="outline-secondary" className='no-border-light-bg' onClick={() => {this.incrementPlanQty()}} value={this.state.planQuantity} key={"increase-plan-quantity"}>+</Button>
                                            </InputGroup>
                                        </Col>
                                    }
                                    <Col md={4} xs={{span:6}} sm={{span:4}} >
                                        <button variant='dark'className="btn dlt-btn"  onClick={(e)=> {this.nextStep(this.state.userSelectedPlan.plan_id, this.state.userSelectedPlan.selectedPlan)}}>Upgrade Plan</button>
                                    </Col>
                                </Row>
                            </Col>
                        </li>
                    :
                        <li className="grey upgradep-plan mt-4">
                            <Col md={{span:4}}>
                                <button disabled variant='dark'className="btn dlt-btn">Plan not available</button>
                            </Col>
                        </li>
                }
                </ul>
            )
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    backstep = (function(data){
        try{
            let backStep = this.state.nextStep
            let nextStep = this.state.step2
            let planQuantity = this.state.planQuantity
            if (data.stepType === 'back'){
                backStep =  false
                nextStep =  false
            }else if (data.stepType === 'next'){
                backStep =  true
                planQuantity = data.planQuantity
                if (planQuantity > this.state.nextStepValues.previousQuantity){
                    nextStep =  true
                }
            }
            this.setState({
                nextStep:backStep,
                step2:nextStep,
                planQuantity:planQuantity,
                planQuantityfinal:planQuantity-this.state.nextStepValues.previousQuantity,
                totalqty:planQuantity,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    backstep1 =  (function(e){
        try{
            this.setState({
                nextStep:true,
                step2:false,
            })
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentDidMount(){
        if (this.state.plans.length === 0) {
            this.getPlans();
        }
    }

    render() {
        return (
            <>
                { ! this.state.nextStep ?
                <Row className='plan-tab-content' key={"plan-type" + this.props.type}>
                    <Col md={{span:5}} className="text-center columns_Sec">
                        {
                            this.state.plans.map((data, index) => {
                                return (
                                    <Col md={{span:12}} onClick={()=>this.getPlanfeatures(data.plan_id,data)} className={"text-start columns_Sec mt-4 plan_div " + (data.selectedPlan ? "task_sec active ":"") + ( this.state.selectedPlanId === data.plan_id ? 'clicked':"" ) }key = {"plan-id-"+data.id+"-"+this.props.type}
                                    >
                                        <div className="columns">
                                            <div className="price">
                                                <Row>
                                                    <Col md={{span:1}} xs={{span:1}} sm={{span:1}}></Col>
                                                    <Col md={{span:9}} xs={{span:9}} sm={{span:9}} className={ data.selectedPlan ? "activated":""}>
                                                        { 
                                                            data.selectedPlan ? <span><i className="material-icons todotask-icon">check</i></span>: ""
                                                        } 
                                                        <div className='plan_content'>
                                                            <div className="header plan-name">{data.plan_name}</div>
                                                            <div className="grey plan-price">{(data.plan_price === 0 ? `1 ${data.plan_type} - 0` : `1 ${data.plan_type} - `+data.plan_price)}</div>
                                                            <div className="plan-description">{data.description}</div>
                                                        </div>
                                                    </Col>
                                                    <Col md={{span:1}} xs={{span:1}} sm={{span:1}}>
                                                        <span className="material-icons-outlined">arrow_forward_ios</span>
                                                    </Col>
                                                </Row>
                                            </div>                                    
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Col>
                    <Col md={{span:7}} className="text-center columns_Sec">
                        {this.planFeatures()}
                    </Col>
                </Row>
                :
                ! this.state.step2 ? <UpgradePlanStep1 data={this.state.nextStepValues} onChange={(e)=> {this.backstep(e)}}/> : <></>
                }
                {
                    this.state.step2  ? <UpgradePlanStep2 oldPlanId = {this.state.oldPlanId} totalqty = {this.state.totalqty} key={"calendar-main-start"} onChange={(e)=> {this.backstep1(e)}} planQuantity= {this.state.planQuantityfinal} data={this.state.nextStepValues}/> : <></>
                }
            </>
        );
    }
}

export default GetPlans;
