import React, { Component } from 'react';
import BeforeLoginLayout from './BeforeLoginLayout';
import Labels from "../../utils/Labels";
import APIs from '../../utils/APIs';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import PasswordChecklist from "react-password-checklist";
import validator from 'validator';
import { Spinner } from 'react-bootstrap';
import Looger from '../../utils/Logger'

function ResetPassword(props) {
	let {token} = useParams();
	return <ResetPasswordClass resePWDToken={token} />
}

class ResetPasswordClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password : "",
			confirm_password : "",
            submitButtonText : "Continue",
            submitButtonProp : false,
			resePWDToken: props.resePWDToken,
			isTokenValid : null,
			showPassword: false,
			passwordError: "",
			showConfirmPassword: false,
			confirmPasswordError: "",
			RESET_PWD_H3: Labels.RESET_PWD_H3,
			RESET_PWD_H6: Labels.RESET_PWD_H6,
			isPasswordReset : false,
			axiosSource : axios.CancelToken.source(),
        }
    }

    /**
	 * @function onChange
	 * @param {event} event
	 * @description On change of input value assign that value in respective state
	 * @returns void
	 */
	onChange = (function(event) {
		try{
			let str = "";
			if (event.target.name !== "password") {
				str = "confirm";
			}
			this.checkPasswordStrength(event.target.value, str);
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function checkPasswordStrength
	 * @param {string} password
	 * @description validate the password string while typing and display the message
	 * @returns string
	 */
	checkPasswordStrength = (function (password, fieldName) {
		try{
			let passwordError = "";
			if (!validator.isStrongPassword(password, [{ minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1, returnScore: false, pointsPerUnique: 1, pointsPerRepeat: 0.5, pointsForContainingLower: 10, pointsForContainingUpper: 10, pointsForContainingNumber: 10, pointsForContainingSymbol: 10 }]) && password.length > 0) {
				passwordError = "week password";
			}
			if (fieldName === "") {
				this.setState({ password: password, passwordError: passwordError });
			} else {
				if (password !== this.state.password) {
					passwordError = "Password and confirm password must be same."
				}
				this.setState({ confirm_password: password, confirmPasswordError: passwordError });
			}
			return passwordError;
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

    /**
	 * @function onFormSubmit
	 * @param {event} event to stop html form default behaviour
	 * @description based on username and password call the login API, and display respective message in parent toast
	 * @returns void
	 */
	 onFormSubmit = (function(event) {
		try{
			event.preventDefault();
			this.setErrorMessagesState({ passwordError: "", confirmPasswordError: "", submitButtonProp: true});
			let password = this.state.password;
			let confirmPassword = this.state.confirm_password;

			let passwordError = (password.trim() === "") ? "Password is required" : "";
			let confirmPasswordError = (confirmPassword.trim() === "") ? "Confirm password is required" : "";

			passwordError = passwordError === "" ? this.checkPasswordStrength(password, "") : passwordError;
			confirmPasswordError = confirmPasswordError === "" ? this.checkPasswordStrength(password, "confirm") : confirmPasswordError;
			confirmPasswordError = (confirmPasswordError === "" && password.trim() !== confirmPassword.trim()) ?  "Password and confirm password must be same" : confirmPasswordError;

			if (confirmPasswordError === "" && passwordError === "") {
				let param = { password: password, token : this.state.resePWDToken};
				axios.patch(
					APIs.BASE_URL + APIs.RESET_PWD_API,
					param, {cancelToken : this.state.axiosSource.token}
				).then((response) => {
					if (response.data.status_code === 200) {
						this.setState({ isPasswordReset: true, isTokenValid: false });
					} else if (response.data.status_code === 401) {
						this.setState({ 
							passwordError: response.data.message,
							submitButtonProp: false,
							submitButtonText : "Continue",
						});

					} else {
						this.setState({ isPasswordReset: false, isTokenValid: false });
					}
				}).catch((error) => {
					this.setState({ isPasswordReset: false, isTokenValid: false });
					// Capture Error Logs
					Looger(error)
				})
			} else {
				this.setErrorMessagesState({ passwordError: passwordError, confirmPasswordError: confirmPasswordError, submitButtonProp: false });
			}
			event.stopPropagation();
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function setErrorMessagesState
	 * @param {JSON Object} data
	 * @description To set error messages and button text
	 * @returns void
	 */
	setErrorMessagesState = (function (data) {
		try{
			let passwordError = data.passwordError;
			let confirmPasswordError = data.confirmPasswordError;
			let submitButtonProp = data.submitButtonProp;
			let submitButtonText = submitButtonProp ? "Loading" : "Continue";

			this.setState({
				passwordError: passwordError,
				confirmPasswordError: confirmPasswordError,
				submitButtonText: submitButtonText,
				submitButtonProp: submitButtonProp
			});
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	validateToken = (function() {
		try{
			axios.get(
				APIs.BASE_URL + APIs.VALIDATE_RESET_TOKEN_API,
				{ params: { token: this.state.resePWDToken }, cancelToken : this.state.axiosSource.token }
			).then((response) => {
				if (response.data.status_code === 200) {
					this.setState({ isTokenValid:true});
				} else {
					this.setState({ isTokenValid: false });
				}
			}).catch((error) => {
				this.setState({ isTokenValid: false });
				// Capture Error Logs
				Looger(error)
			})
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	togglePasswordField = (function (e, str) {
		try{
			if(str === "") {
				this.setState({
					showPassword: !this.state.showPassword
				});
			} else {
				this.setState({
					showConfirmPassword: !this.state.showConfirmPassword
				});
			}
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	})

	generatePasswordLink = () => {
		try{
			if (this.state.isPasswordReset) {
				return (
					<div>
						<div>Your Password has been updated successfully.</div>
						<div>To login <NavLink className="custom-link" to={"/"}>click here</NavLink>.</div>
					</div>
				)
			} else {
				return (
					<div>
						<div>Reset password link has been expired.</div>
						<div>To generate a new link <NavLink className="custom-link" to={"/forgot-password"}>Click here</NavLink>.</div>
					</div>
				)
			}
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}

	componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }
    
    render() {
		if (this.state.isTokenValid === null) {
			this.validateToken();
		}
		let isTokenValid = this.state.isTokenValid;
        return (
			<BeforeLoginLayout
				footerQues={"Remember password?"}
				footerLinkText={"Sign in to your account"}
				footerLink={"/"}
				formTagLine={((isTokenValid === true || isTokenValid === null)) ? this.state.RESET_PWD_H6 : this.generatePasswordLink()}
				formHeaderTagLine={this.state.RESET_PWD_H3}
				seoData={{
					"title" : "Reset Your Orrderly Password - Orrderly",
					"meta": [
						{"name":"description","content":"Reset your Orrderly password to gain access to your account."}
					]
				}}
			>
				{
				(!(isTokenValid === true || isTokenValid === null)) ? <></> :
				<form onSubmit={this.onFormSubmit} className="signup_form form_sec mt-5" autoComplete='off'>
					<div className={this.state.passwordError ? "invalid mb-3" : "mb-3"}>
						<label className="label d-none">{Labels.CREATE_PWD} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">lock</i></span>
							<input type={!this.state.showPassword ? "password" : "text"} className="form-control shadow-none" placeholder={Labels.CREATE_PWD} name="password" id="password" value={this.state.password} onChange={this.onChange} autoComplete="off" />
							<span style={{ cursor: "pointer", userSelect: "none" }} className="input-group-text" onClick={(e) => this.togglePasswordField(e, '')}><i className="material-icons">{!this.state.showPassword ? "visibility" : "visibility_off"}</i></span>
							<PasswordChecklist
								rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
								minLength={8}
								value={this.state.password}
								onChange={(isValid) => { }}
							/>
						</div>
					</div>
					<div className={this.state.confirmPasswordError ? "invalid mb-3" : "mb-3"}>
						<label className="label d-none">{Labels.CONF_PWD_PLACEHOLDER} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">lock</i></span>
							<input type={!this.state.showConfirmPassword ? "password" : "text"} className="form-control shadow-none" placeholder={Labels.CONF_PWD_PLACEHOLDER} name="confirm_password" id="confirm_password" value={this.state.confirm_password} onChange={this.onChange} autoComplete="off" />
							<span style={{ cursor: "pointer", userSelect: "none" }} className="input-group-text" onClick={(e) => this.togglePasswordField(e, 'confirm')}><i className="material-icons">{!this.state.showConfirmPassword ? "visibility" : "visibility_off"}</i></span>
							<PasswordChecklist
									rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
								minLength={8}
								value={this.state.confirm_password}
								valueAgain={this.state.password}
								onChange={(isValid) => { }}
							/>
						</div>
					</div>
					{ this.state.passwordError && 
						<div className="errorMessage"><i className="material-icons">warning</i>{this.state.passwordError}</div>	
					}
					<div className="d-grid pt-3">
						<button type="submit" className="btn btn-primary btn-block" disabled={this.state.submitButtonProp}>{this.state.submitButtonProp ? <Spinner size="sm" animation="border" variant="light" /> : this.state.submitButtonText}</button>
					</div>
				</form>
				}
			</BeforeLoginLayout>
        );
    }
}

export default ResetPassword;