import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class PopUpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleHideModal = (function() {
        this.props.onChange()
    }).bind(this)

    render() {
        let modalSize = this.props.modalSize ? this.props.modalSize : "";
        return (
            <Modal
                show={this.props.show}
                onHide={() => this.handleHideModal()}
                backdrop="static"
                keyboard={true}
                contentClassName={this.props.contentClassName}
                size={modalSize}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
}
export default PopUpModal;