import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import no_task_today from '../../../../static/images/logged_in/no-task.png';
import all_task_done_today from '../../../../static/images/logged_in/completed.png';

class AddTaskButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBoardView : props.isBoardView
        };
    }

    checkAndReturnMyTasks = (function () {
        let doneTask = localStorage.getItem('doneTaskCount') ? localStorage.getItem('doneTaskCount') : 0;
        if (this.props.pendingTaskCount === 0 && doneTask === 0) {
            return (
                <>
                    <img src={no_task_today} alt="No task today yet" />
                    <h4>You have no task set for today</h4>
                    <h5>Add a new task to get more stuffs done today!!!</h5>
                </>
            );
        } else if (this.props.pendingTaskCount === 0 && doneTask > 0) {
            return (
                <>
                    <img src={all_task_done_today} alt="all today task done" />
                    <h4>You have completed your task for today!!!</h4>
                    <h5>Add a new task to get more stuffs done today!!!</h5>
                </>
            );
        }
    });

    addTaskButton = (function () {
        let marginTop = 0;
        let firstColSpan = 11;
        let firstColOffset = 0;
        let secondColSpan = 9;
        let secondColOffset = 0;
        if (this.props.pendingTaskCount === 0) {
            marginTop = 5;
            firstColSpan = 12;
            firstColOffset = 0;
            secondColSpan = 8
            secondColOffset = 2;
        }
        if (this.state.isBoardView) {
            secondColSpan = 12;
            firstColSpan = 12;
        }
        return (
            <Row className={"mt-" + marginTop + (this.state.isBoardView ? "" : " mb-5")}>
                <Col md={{ span: firstColSpan, offset: firstColOffset }} className="text-center no_task">
                    <Row>
                        <Col md={{ span: secondColSpan, offset: secondColOffset }} xs={{span:12}} sm={{span:12}}>
                            {this.checkAndReturnMyTasks()}
                            <div className="d-grid mt-3">
                                <button type="button" className="btn btn-primary btn-block add_task_btn" onClick={() => { this.props.openAddEditTaskModal('Add', {})}}><i className='material-icons'>add</i>Add New Task</button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    });
    
    render() {
        return (
            <>{ this.addTaskButton(11, 9, 0, 0) }</>
        );
    }
}

export default AddTaskButton;