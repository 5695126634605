import React, { Component } from 'react';
import {Row, Col, Dropdown, InputGroup, FormControl} from 'react-bootstrap';
import { Navigate, NavLink } from 'react-router-dom';
import Notification from './Notification';
import { connect } from "react-redux";
import { global_search_query } from '../../../../reducers/CurrentProjectBoard'
import PopUpModal from './Modals/PopUpModal';
import Looger from '../../../../utils/Logger'

var deleteCookie = function(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.orrderly.com;';
};

class Topbar extends Component {
    constructor(props) {
        let isLoggedIn = localStorage.getItem("user") !== null ? true : false;
        let user = isLoggedIn ? JSON.parse(localStorage.getItem("user")) : {};
        super(props);
        this.timeout = 0;
        this.state = {
            isLoggedIn: isLoggedIn,
            user: user,
            searchQuery:'',
            showLogoutModal:false
        }
    }

    logoutMyUser = (function (type) {
        try{
            if (type === 'close') {
                localStorage.removeItem("user");
                localStorage.removeItem("session_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("plan_info");
                localStorage.removeItem("project_id");
                localStorage.removeItem("project_name");
                localStorage.removeItem("allProjectList");
                localStorage.removeItem("account_info");
                localStorage.removeItem("confirm");
                localStorage.removeItem("switcher");
                localStorage.removeItem("theme");
                localStorage.removeItem("active_tab");
                deleteCookie(btoa('user-data'))
                window.location.href="/"
            } else if(type === 'open'){
                this.setState({
                    showLogoutModal:true
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    });

    switchTheme = (function() {
        try{
            let theme = localStorage.getItem('theme');
            let nowTheme = "";
            let removeTheme = "";
            if(theme) {
                if(theme==="light") {
                    localStorage.setItem('theme', 'dark');
                    nowTheme = "dark-theme";
                    removeTheme = "light-theme";
                } else {
                    localStorage.setItem('theme', 'light');
                    nowTheme = "light-theme";
                    removeTheme = "dark-theme";
                }
            } else {
                localStorage.setItem('theme', 'dark');
                nowTheme = "dark-theme";
                removeTheme = "light-theme";
            }
            // let body = document.querySelector("body");
            document.body.classList.add(nowTheme);
            document.body.classList.remove(removeTheme);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    globalSearchHandler  = (function(e){
        try{
            e.preventDefault();
            if (this.timeout) clearTimeout(this.timeout);
            let searchQuery = e.target.value.trim()
            this.setState({
                searchQuery:searchQuery
            })
            this.timeout = setTimeout(() => {
                this.props.global_search_query({searchQuery:searchQuery})
            }, 500);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })
    
    render() {
        let UserName = "";
        let first_name = "";
        let image = "/placeholder.png";
        try {
            UserName = this.state.user.first_name.trim() !== "" ? (this.state.user.first_name)  : this.state.user.email;
            first_name = UserName.indexOf('@') > -1 ? UserName.split('@')[0] : UserName
            image =  this.state.user.profile_image
            if ( image !== undefined ){
                if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                    let avatar_bg = (this.state.user.avatar_bg?this.state.user.avatar_bg:"5D6DF6").replace('#','')
                    image = `https://ui-avatars.com/api/?name=${UserName}&color=FFFFFF&length=1&background=${avatar_bg}`
                }
            }
        } catch (Exception) {
            this.logoutMyUser('close');
        }

        return (
            <>
                {
                    !this.state.isLoggedIn ? 
                    <Navigate to="/" /> : 
                    <>
                    <Col md={{ span: 10}} className="right_side">
                        <Row className="header">
                            <div className="col-md-5 d-grid align-items-center left_col">
                                <h3><span data-bs-toggle="offcanvas" data-bs-target="#sidebar_side" className="material-symbols-outlined off-canvas ">menu</span>
                                    Welcome, <span className="top-nav-heading">{first_name}</span> !
                                </h3>
                                <h4>Let's do the best today 👋</h4>
                            </div>
                            <div className="col-md-7 p-0">
                                <div className="d-flex text-white justify-content-end">
                                    <div className="p-2 me-3 d-grid align-items-center head_sec overdue_task">
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <span className="material-icons-outlined">search</span>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Search..."
                                                name="overdue_search"
                                                id="overdue_search"
                                                value={this.state.searchQuery}
                                                className='ps-0'
                                                onChange={(e) => { this.globalSearchHandler(e) }}
                                            />
                                        </InputGroup>                                     
                                    </div>
                                    <div className="p-2 d-grid align-items-center head_sec notifications">
                                        <Notification/>                                        
                                    </div>
                                    <div className="p-2 d-grid align-items-center head_sec"> 
                                        <Dropdown align="end">
                                                <Dropdown.Toggle variant="default" className='text-uppercase' id={"member-action-dropdown-logout"}><span id="user-name-top-bar">{UserName}</span>

                                                <img src={image} alt="user placeholder" className='border border-1 border-secondary rounded-circle' />

                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <NavLink to="/settings" className='d-flex dropdown-item'>
                                                    <i className="material-icons">person_outline</i>
                                                    &nbsp;My Profile
                                                </NavLink>
                                                {/* <NavLink to="/settings" className='d-flex dropdown-item'>
                                                    <i className="material-icons">settings</i>
                                                    &nbsp;Settings
                                                </NavLink> */}
                                                <a href="https://docs.orrderly.com" target={'_blank'} rel="noreferrer" className='d-flex dropdown-item'>
                                                    <i className="material-icons">priority_high</i>
                                                    &nbsp;Help &amp; Support
                                                </a>
                                                <NavLink to="/upgrade-plan" className='d-flex dropdown-item'>
                                                    <i className="material-icons">bolt</i>
                                                    &nbsp;Upgrade
                                                </NavLink>
                                                <Dropdown.Item onClick={() => this.logoutMyUser('open')} className='d-flex'><i className="material-icons">logout</i> &nbsp;Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="p-2 d-grid align-items-center head_sec d-none1" onClick={(e) => {this.switchTheme()}}>
                                        <span className="dark material-symbols-outlined">dark_mode</span>
                                        <span className="light material-symbols-outlined">wb_sunny</span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        {
                            this.props.className === "" ?
                                <Row className={(this.props.className ? this.props.className : "") + "section"} >
                                    {this.props.children}
                                </Row>
                                :
                                <div className={(this.props.className ? this.props.className : "") + "section"} >
                                    {this.props.children}
                                </div>
                        }
                    </Col>
                    <PopUpModal contentClassName="add_new_member_form logout_pop" title="" show={this.state.showLogoutModal} onChange={() => { this.setState({ showLogoutModal: false, removeTaskData: {} }) }}>
                    <div className="icon-container">
                        <i className="material-icons">priority_high</i>
                    </div>
                    <div className='warning-title mb-5'>Are you sure you want to logout?</div>
                    <div className='button-container'>
                        <button className='btn btn-default' onClick={() => { this.setState({ showLogoutModal: false, removeTaskData: {} }) }}>No</button>
                        <button className='btn btn-primary' onClick={() => { this.logoutMyUser('close') }}>Yes</button>
                    </div>
                    </PopUpModal>
                </>
                }
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    searchQuery:state.CurrentProjectBoard.searchQuery,
});
  
const mapDispatchToProps = {global_search_query};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);



// export default Topbar;
