import React, { Component } from 'react';
import BeforeLoginLayout from './BeforeLoginLayout';
import Labels from "../../utils/Labels";
import APIs from '../../utils/APIs';
import { Navigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import PasswordChecklist from "react-password-checklist";
import validator from 'validator';
import { Spinner } from 'react-bootstrap';
import Cookies from 'js-cookie';
import Looger from '../../utils/Logger';

// function SignupStep2(props) {
// 	let {token} = useParams();
// 	return <SignupStep2Class resePWDToken={token} />
// }

class SignupStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password : "",
			confirm_password : "",
			isLogin : false,
			username : "",
            submitButtonText : "Continue",
            submitButtonProp : false,
			resePWDToken: props.resePWDToken,
			isTokenValid : true,
			showPassword: false,
			passwordError: "",
			email:props.email,
			usernameError: "",
			showConfirmPassword: false,
			confirmPasswordError: "",
			RESET_PWD_H3: Labels.RESET_PWD_H3,
			RESET_PWD_H6: Labels.RESET_PWD_H6,
			isPasswordReset : false,
			axiosSource : axios.CancelToken.source(),
        }
    }

    /**
	 * @function onChange
	 * @param {event} event
	 * @description On change of input value assign that value in respective state
	 * @returns void
	 */
	onChange = (function(event) {
		try{
			let str = "";
			let usernameError = "";
			if (event.target.name !== "password" && event.target.name !== "username") {
				str = "confirm";
			}
			// usernameError
			if(event.target.name !== "username") {
				this.checkPasswordStrength(event.target.value, str);
			} else {
				let full_name = event.target.value;

				if (full_name.length >= 30) {
					usernameError = 'Full name must be less then 30 character'
				} else if ( full_name.length === 0 ) {
					usernameError = "Full name is required"

				} else if (!validator.isAlpha(full_name.trim(),["en-US"], { ignore: " " })){
					usernameError = "Full name should be only character"
				} else if (full_name.length === full_name.trim().length || full_name.length === full_name.trim().length+1) {
					// this.setState({
					// 	userInfo: userInfo
					//	})
				} else {
					full_name = full_name.replaceAll("  ", " ")
				}

				this.setState({
					username : full_name,
					usernameError : usernameError
				})
			}
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function checkPasswordStrength
	 * @param {string} password
	 * @description validate the password string while typing and display the message
	 * @returns string
	 */
	checkPasswordStrength = (function (password, fieldName) {
		try{
			let passwordError = "";
			if (!validator.isStrongPassword(password, [{ minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1, returnScore: false, pointsPerUnique: 1, pointsPerRepeat: 0.5, pointsForContainingLower: 10, pointsForContainingUpper: 10, pointsForContainingNumber: 10, pointsForContainingSymbol: 10 }]) && password.length > 0) {
				passwordError = "week password";
			}
			if (fieldName === "") {
				this.setState({ password: password, passwordError: passwordError });
			} else {
				if (password !== this.state.password) {
					passwordError = "Password and confirm password must be same."
				}
				this.setState({ confirm_password: password, confirmPasswordError: passwordError });
			}
			return passwordError;
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
		
	}).bind(this);

    /**
	 * @function onFormSubmit
	 * @param {event} event to stop html form default behaviour
	 * @description based on username and password call the login API, and display respective message in parent toast
	 * @returns void
	 */
	onFormSubmit = (function(event) {
		try{
			event.preventDefault();
			this.setErrorMessagesState({ passwordError: "", usernameError: "", submitButtonProp: true});
			let password = this.state.password;
			let username = this.state.username;
			let usernameError = this.state.usernameError;
			let passwordError = (password.trim() === "") ? "Password is required" : "";
			if (username.trim() === ""){
				usernameError = "Full name is required"
			}
			passwordError = passwordError === "" ? this.checkPasswordStrength(password, "") : passwordError;
			if (usernameError === "" && passwordError === "") {
				let param = { 
					email: this.state.email,
					password: password,  
					username : username,
					source : "web_app",
					validation:false,
				};
				axios.post(
					APIs.BASE_URL + APIs.SIGNUP_STEP_1_API,
					param, {cancelToken : this.state.axiosSource.token}
				).then((response) => {
					this.setLocalStroageData(response.data);
					this.setState({ submitButtonProp: false, isLogin: true });
					event.stopPropagation();
				}).catch((error) => {
					this.setState({ isPasswordReset: false, isTokenValid: false });
					// Capture Error Logs
					Looger(error)
				})
			} else {
				this.setErrorMessagesState({ passwordError: passwordError, usernameError: usernameError, submitButtonProp: false });
			}
			event.stopPropagation();
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);

	/**
	 * @function setErrorMessagesState
	 * @param {JSON Object} data
	 * @description To set error messages and button text
	 * @returns void
	 */
	setErrorMessagesState = (function (data) {
		try{
			let passwordError = data.passwordError;
			let usernameError = data.usernameError;
			let submitButtonProp = data.submitButtonProp;
			let submitButtonText = submitButtonProp ? "Loading" : "Continue";

			this.setState({
				passwordError: passwordError,
				usernameError: usernameError,
				submitButtonText: submitButtonText,
				submitButtonProp: submitButtonProp
			});
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}).bind(this);


	togglePasswordField = (function (e, str) {
		if(str === "") {
			this.setState({
				showPassword: !this.state.showPassword
			});
		} else {
			this.setState({
				showConfirmPassword: !this.state.showConfirmPassword
			});
		}
	})

	generatePasswordLink = () => {
		try{
			if (this.state.isPasswordReset) {
				return (
					<div>
						<div>Your Password has been updated successfully.</div>
						<div>To login <NavLink className="custom-link" to={"/"}>click here</NavLink>.</div>
					</div>
				)
			} else {
				return (
					<div>
						<div>Reset password link has been expired.</div>
						<div>To generate a new link <NavLink className="custom-link" to={"/forgot-password"}>Click here</NavLink>.</div>
					</div>
				)
			}
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	}

	componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

	/**
	 * @function setLocalStroageData
	 * @param {JSON Object} data
	 * @description set the value of "session_token" and "user" in localStorage
	 * @returns void
	 */
	 setLocalStroageData = (data) => {
		try{
			localStorage.setItem("session_token", data.data.token.access);
			localStorage.setItem("refresh_token", data.data.token.refresh);
			localStorage.setItem("project_id", data.data.project_info.id);
			localStorage.setItem("project_name", data.data.project_info.project_name);
			localStorage.setItem("allProjectList", JSON.stringify(data.data.allProjectList));
			localStorage.setItem("user", JSON.stringify(data.data.user_info));
			localStorage.setItem('confirm',parseInt(data.data.active))
			localStorage.setItem('account_info',data.data.filled)
			// localStorage.setItem("plan_info", btoa(JSON.stringify(data.data.plan_info)));
			Cookies.set(btoa('user-data'), btoa(JSON.stringify(data.data.user_info)), { expires : 365, secure : true, domain : APIs.DOMAIN })
		} catch(error) {
			// Capture Error Logs
			Looger(error)
		}
	};
    
    render() {
        return (
			<BeforeLoginLayout
				footerQues={"Already have an account with Orrderly?"}
				footerLinkText={"Sign in to your account"}
				footerLink={"/"}
				formTagLine={ "Enter your full name and create password"}
				formHeaderTagLine={"Get started with your account"}
				seoData={{
					"title" : "Sign up to Orrderly - Orrderly",
					"meta": [
						{"name":"description","content":"Sign up to get started with Orrderly."}
					]
				}}
			>
				{this.state.isLogin ? <Navigate to="/dashboard" /> : ""}
				<form onSubmit={this.onFormSubmit} className="signup_form form_sec mt-5" autoComplete='off'>
					<div className={this.state.usernameError ? "mb-3 invalid" : "mb-3"}>
						<label className="label d-none">{"Full name"} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">person</i></span>
							<input type="text" className="form-control shadow-none" name="username" placeholder={"Enter full name"} id="username" value={this.state.username} onChange={this.onChange} autoComplete="off" />
						</div>
						{this.state.usernameError ? <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.usernameError}</div> : <></>}
					</div>
					<div className={this.state.passwordError ? "invalid mb-3" : "mb-3"}>
						<label className="label d-none">{Labels.CREATE_PWD} <span className="asterisk">*</span></label>
						<div className="input-group">
							<span className="input-group-text"><i className="material-icons">lock</i></span>
							<input type={!this.state.showPassword ? "password" : "text"} className="form-control shadow-none" placeholder={Labels.CREATE_PWD} name="password" id="password" value={this.state.password} onChange={this.onChange} autoComplete="off" />
							<span style={{ cursor: "pointer", userSelect: "none" }} className="input-group-text" onClick={(e) => this.togglePasswordField(e, '')}><i className="material-icons">{!this.state.showPassword ? "visibility" : "visibility_off"}</i></span>
							<PasswordChecklist
								rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
								minLength={8}
								value={this.state.password}
								onChange={(isValid) => { }}
								messages={{
									number: "Number (0-9)",
									minLength: "8 characters",
									specialChar: "",
									capital: "Capital letter (A-Z)",
									lowercase: "Lowercase letter (a-z)",
								}}
							/>
						</div>
						{this.state.passwordError ? <div className='errorMessage'><i className="material-symbols-outlined">warning</i>{this.state.passwordError}</div> : <></>}
					</div>
					<div className="d-grid pt-3">
						<button type="submit" className="btn btn-primary btn-block" disabled={this.state.submitButtonProp}>{this.state.submitButtonProp ? <Spinner size="sm" animation="border" variant="light" /> : this.state.submitButtonText}</button>
					</div>
				</form>
			</BeforeLoginLayout>
        );
    }
}

export default SignupStep2;