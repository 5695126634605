import React, { Component } from 'react';
import LoggedInLayout from './LoggedInLayout';
import TeamMembers from './Common/TeamMembers';
import axios from 'axios';
import { Row, Col, Alert, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import PopUpModal from './Common/Modals/PopUpModal';
import APIs from '../../../utils/APIs';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import Looger from '../../../utils/Logger';
import {loader} from "../../../reducers/CurrentProjectBoard"

class Team extends Component {
    constructor(props) {
        super(props);
        this.timeout = 0;
        this.state = {
            teamMembersList : [],
            pendingInvites: [],
            isLoggedIn : true,
            loading : false,
            showPendingInviteModal : false,
            pendingInviteCount : 0,
            showRemoveMemberMessage : "",
            actionIcon : "",
            actionId : "",
            showAcceptLoading : true,
            showDeclineLoading : true,
            searchQuery:'',
            axiosSource : axios.CancelToken.source()
        };
    }

    fetchTeamMembers = (function (token) {
        try{
            this.props.loader({loader:true})
            let param = { start: 0, length: 500,search:this.state.searchQuery };
            let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            this.setState({
                teamMembersList : [],
            });
            axios.get( 
                APIs.BASE_URL + APIs.TEAM_API,
                config
            ).then((response) => {
                if(response.data.status_code === 200) {
                    this.setState({
                        teamMembersList : response.data.data,
                        loading : false,
                    });
                } else if(response.data.status_code === 403) {
                    // Logout
                    this.setState({
                        teamMembersList : [],
                    });
                }
                this.props.loader({loader:false})
            }).catch((error) => {
                // Capture Error Logs
			    Looger(error)
            });
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    }).bind(this);

    getPendingInvites = (function (token) {
        try{
            let param = { start: 0, length: 500 };
            let config = { headers: { authorization: `Bearer ${token}` }, params: param, cancelToken : this.state.axiosSource.token };
            axios.get(
                APIs.BASE_URL + APIs.TEAM_PENDING_INVITES_API,
                config
            ).then((response) => {
                if (response.data.status_code === 200) {
                    this.setState({
                        pendingInvites: response.data.data,
                        pendingInviteCount: response.data.pending_invites,
                        loading : false
                    });
                }
            }).catch((error) => {
                // Capture Error Logs
			    Looger(error)
            });
        } catch(error) {
			// Capture Error Logs
			Looger(error)
		}
    }).bind(this);

    componentDidMount() {
        let token = localStorage.getItem('session_token');
        if (token) {
            this.fetchTeamMembers(token);
            this.getPendingInvites(token);
        }
    }

    refreshData = (function (token) {
        try{
            this.fetchTeamMembers(token);
            this.getPendingInvites(token);
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this)

    togglePendingInviteModal = (function () {
        try{
            let showPendingInviteModal = this.state.showPendingInviteModal
            this.setState({
                showPendingInviteModal : !showPendingInviteModal,
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    showPendingInvitesDiv = (function() {
        try{
            if (this.state.pendingInvites.length === 0) {
                return (
                    <Row className="invite_members" key={"pending-invites-id-1"}>
                        <Col md={{ span: 12 }}>
                            <h4>No pending invites</h4>
                        </Col>
                    </Row>
                )
            } else {
                return this.state.pendingInvites.map((data, index) => {
                    let name = data.first_name !== "" ? data.first_name + " " + data.last_name : data.email;
                    let image = (data.profile_image !== "") ? data.profile_image : "/placeholder.png";
                    if (image.split('/image/')[1] === '__avatar_url_2_1.png'){
                        let avatar_bg = (data.avatar_bg?data.avatar_bg:"5D6DF6").replace('#','')
                        image = `https://ui-avatars.com/api/?name=${name}&color=FFFFFF&length=1&background=${avatar_bg}`
                    }
                    return(
                        <Row className="invite_members" key={"pending-invites-id"+index}>
                            <Col md={{span:7}} xs={{span:6}} sm={{span:7}} >
                                <h4>
                                    <img
                                        src={image}
                                        alt={"profile pic " + name}
                                        ref={img => this.img = img}
                                        onError={
                                            () => this.img.src = "/placeholder.png"
                                        }
                                    />
                                {name}</h4>
                            </Col>
                            <Col md={{span:5}} xs={{span:6}} sm={{span:5}} className="invite_member_btn">
                                <button onClick={() => this.performActionOnPendingInvites(data, 'accept')} type="button" className="btn btn-primary accept_btn" disabled={this.state.showLoading}>
                                    {this.state.showAcceptLoading && this.state.actionId === data.id ? <Spinner size="sm" animation="border" variant="light" /> : "Accept"}
                                </button>
                                &nbsp;<button onClick={() => this.performActionOnPendingInvites(data, 'decline')} type="button" className="btn btn-primary decline_btn" disabled={this.state.showLoading}>{this.state.showDeclineLoading && this.state.actionId === data.id ? <Spinner size="sm" animation="border" variant="primary" /> : "Decline"}</button>
                            </Col>
                        </Row>
                    )
                })
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    performActionOnPendingInvites = (function (data, type) {
        try{
            let token = localStorage.getItem('session_token');
            let config = { headers: { authorization: `Bearer ${token}` }, cancelToken : this.state.axiosSource.token };
            let param = {};
            let API = APIs.TEAM_MEMBER_ACCEPT_INVITE_API;
            param = { id: data.id };

            if (type === "decline") {
                API = APIs.TEAM_MEMBER_DECLINE_INVITE_API;
                this.setState({
                    showDeclineLoading : true,
                    showLoading : true,
                    showAcceptLoading : false,
                    actionId: data.id
                })
            } else {
                this.setState({
                    showDeclineLoading: false,
                    showLoading: true,
                    showAcceptLoading: true,
                    actionId: data.id
                })
            }
            axios.patch(
                APIs.BASE_URL + API,
                param,
                config
            ).then((response) => {
                this.setState({
                    showRemoveMemberMessage: response.data.message,
                    restoreMember: false,
                    actionIcon: "success",
                    showPendingInviteModal:false,
                    showDeclineLoading: false,
                    showLoading: false,
                    showAcceptLoading: false,
                    actionId : "",
                }, this.refreshData(token));
                setTimeout(() => {
                    this.setState({ showRemoveMemberMessage: "" });
                }, 5000);
            }).catch((error) => {
                this.setState({
                    showRemoveMemberMessage: APIs.SERVER_NOT_AVAILABLE,
                    restoreMember: false,
                    actionIcon: "",
                    showPendingInviteModal: false,
                    showDeclineLoading: false,
                    showLoading: false,
                    showAcceptLoading: false,
                    actionId: "",
                });
                setTimeout(() => {
                    this.setState({ showRemoveMemberMessage: "" });
                }, 5000);

                // Capture Error Logs
                Looger(error)
            });
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    pendingInviteModal = (function () {
        try{
            return (
                <PopUpModal contentClassName="add_new_member_form" title="Pending Invites" show={this.state.showPendingInviteModal} onChange={()=>this.togglePendingInviteModal()}>
                    {this.showPendingInvitesDiv()}
                </PopUpModal>
            );
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    }).bind(this);

    searchTeamHandler = (function(e){
        try{
            e.preventDefault();
            let data = e.target.value.trim()
            this.setState({searchQuery:data})
            if (data !== ''){
                this.props.loader({loader:true})
                if (this.timeout) clearTimeout(this.timeout);
                this.setState({
                    searchQuery:data,
                })
                this.timeout = setTimeout(() => {
                    this.props.loader({loader:false})
                    let token = localStorage.getItem('session_token');
                    this.fetchTeamMembers(token);
                }, 500);
            }
        } catch(error) {
            // Capture Error Logs
            Looger(error)
        }
    })

    componentWillUnmount() {
        if (this.state.axiosSource) {
            this.state.axiosSource.cancel("Landing Component got unmounted");
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>My Team- Orrderly</title>
                </Helmet>
                <LoggedInLayout>
                    <Row className="task">
                        <Col md={{span:7}} xs={{span:6}} sm={{span:4}} className="left_col">
                            <h2 className="head">Team members</h2>
                        </Col>
                        <Col md={{span:2}} xs={{span:6}} sm={{span:4}} className="text-end view_sec invite ">
                            <h5 className="pending_invites" onClick={()=> this.togglePendingInviteModal()}>Pending invites <span className="badge">{this.state.pendingInviteCount}</span></h5>
                        </Col>
                        <Col md={{span:3}} xs={{span:12}} sm={{span:4}} className='overdue_task global_team_member'>
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Search team"
                                    name="overdue_search"
                                    id="overdue_search"
                                    value={this.state.searchQuery}
                                    onChange={(e) => { this.searchTeamHandler(e) }}
                                />
                                <InputGroup.Text>
                                    <span className="material-icons-outlined">search</span>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="team_member mt-3">
                        <Col md={{span:10}} className="team-member-container">
                            <TeamMembers searchQuery={this.state.searchQuery} teamData={this.state.teamMembersList} refreshData={(data) => {this.refreshData(data)}} />
                            {
                                this.state.showRemoveMemberMessage !== "" ?
                                    <Alert variant="warning" dismissible className='remove-member-message'>
                                        <div className='d-block'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <i className={this.state.actionIcon + " material-icons"}>{this.state.actionIcon !== "" ? "check" : "priority_high"}</i>
                                                <span className="message">{this.state.showRemoveMemberMessage}</span>
                                            </div>
                                        </div>
                                    </Alert>
                                    : <></>
                            }
                        </Col>
                    </Row>
                </LoggedInLayout>
                { this.state.showPendingInviteModal ? this.pendingInviteModal() : <></> }
            </>
        );
    }
}

// export default Team;

const mapStateToProps = (state) => ({
    project_id: state.CurrentProjectBoard.project_id
});
const mapDispatchToProps = {loader};

export default connect(mapStateToProps,mapDispatchToProps)(Team);