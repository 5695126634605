import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import MainLogo from '../../logo.png';
import MainLogoWhite from '../../logo-white.png';
import LogoOWhite from '../../logo-o-white.png';
import '../../static/css/auth.css';
import { Helmet } from 'react-helmet';

class BeforeLoginLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin : false
        };
    }
	
    render() {
        return (
            <Container>
                <Helmet>
                    <title>{this.props.seoData.title}</title>
                    {
                        this.props.seoData.meta.map((data, index) => {
                            return <meta name={data.name} content={data.content} key={data.name + "-" + index} />
                        })
                    }
                </Helmet>
                <Row>
                    <Col md={{span:6,offset:3}} className="pt-5">
                        <div className="form-container">
                            <div className='auth-header text-center mt-3 pt-3 mb-3'>
                                <NavLink to="/">
                                    <img src={MainLogo} style={{width: '154px', height: '36px'}} className="img-fluid dark m-auto" alt="logo" />
                                    <img src={MainLogoWhite} style={{width: '154px', height: '36px'}} className="img-fluid light m-auto" alt="logo" />
                                </NavLink>
                                <div className='auth-header-tag-line mt-5'>
                                    {this.props.formHeaderTagLine}
                                </div>
                            </div>
                            <div className='auth-body'>
                                <div className='step-1-tag-line'>
                                    {this.props.formTagLine}
                                </div>
                                {this.props.children}
                            </div>
                            <div className='auth-footer'>
                                <div className='auth-footer-question'>{this.props.footerQues}</div>
                                <NavLink to={this.props.footerLink} className="auth-footer-link">{this.props.footerLinkText}</NavLink>
                            </div>
                            <div className='page-footer'>
                                <img src={LogoOWhite} className="footer-logo" alt="footer logo" />
                                <div className='copyright-text'>Copyright © Orrderly {new Date().getFullYear()} | All Rights Reserved</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}



export default BeforeLoginLayout;